import React, {Fragment, Component} from 'react';
import DashboardCard from 'Components/DashboardCard';
import ActionLoader from 'Components/ActionLoader';
import { TTBIL } from 'Constants/commonConstants';
import { getUser } from 'Utils/storage';
import { ReactComponent as CloseIcon } from './icons/close-icon.svg';
import { ReactComponent as Dropup } from './icons/drop-up-arrow.svg';
import { Link } from 'react-router-dom';

class DashboardCardContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableShown: false,
            activeStatus: -1
        }
    }
    getTrips = (statusType, status)=> {
        const { tableShown, activeStatus } = this.state; 
        this.setState({
            tableShown: (statusType===activeStatus && tableShown)?false:true,
            activeStatus:  statusType,
            status
        }, ()=>{activeStatus!==statusType ? this.props.onSelect(statusType) : ''});
    }
    render() {
        const {statuses, header, trips, isDataLoaded, tripSubStatus, activeType} = this.props
        return (
            <div className="pr">
                <header className="fs-18 theme-color fBolder mb-10">
                    <span>{header}</span>
                    
                </header>
                <div className="display-inline">
                    {
                        statuses && statuses.map((status, index)=> {
                            if ((status && status.count) || tripSubStatus) {
                                return <DashboardCard key={`${index + 1}`} status={status} onClick={()=>{
                                    // if(tripSubStatus) {
                                        this.props.onSelect(status.type, status.location_type_status)
                                    // } else {
                                    //     this.getTrips(status.type, status.status);
                                    // }
                                }} tripSubStatus={tripSubStatus} activeType={activeType}/>
                            }
                        })
                    }
                    {TTBIL.includes(getUser().company_id) && this.props.trip_mode === 2 && <button className="min-wt-150 br-5 pr-5 pl-5 pt-10 box-shadow mr-10 display-inline curP ht-40" style={{backgroundColor: "rgb(0, 71, 146)", border: 'none', color: 'white'}} onClick={this.props.ttbilFilter}>{`FILTER`}<i className="arrow bottom ml-50 mb-5"></i></button>}
                </div>
                <div 
                    className="trip-table-container mt-10 pr"
                    style={{
                        // height: this.state.tableShown?((trips.length)*70 + 150):0,
                        height:0,
                        transition: '0.5s height',
                        overflow: 'hidden'
                    }}
                    >
                    <header className="flex flex-middle flex-1 fs-16 theme-color fBolder mb-20">TRIPS - {this.state.status} <span onClick={()=>{this.getTrips(this.state.activeStatus)}} className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5"><CloseIcon /></span></header>
                    <div className="flex mb-5">
                        <span className="fBolder fs-12 header-light-color wt-150 pl-15">Do No</span>
                        <span className="fBolder fs-12 header-light-color wt-200 pl-15">Item</span>
                        <span className="fBolder fs-12 header-light-color wt-150 pl-15">Value</span>
                        <span className="fBolder fs-12 header-light-color wt-200 pl-15">Location</span>
                        <span className="fBolder fs-12 header-light-color wt-200 pl-15">Location Type</span>
                        <span className="fBolder fs-12 header-light-color wt-150 pl-15">Time</span>
                        <span className="fBolder fs-12 header-light-color wt-150 pl-15">Hours</span>
                    </div>
                    {
                        !isDataLoaded && this.state.tableShown && <ActionLoader />
                    }
                    {
                        isDataLoaded && trips && trips.length === 0 &&  
                        <span className="pa mt-20 contentCenter fBolder fs-12 header-light-color pl-15">
                            No Trip is available
                        </span>
                    }
                    {
                        (trips || []).map((trip, index)=> {
                            return (
                                <Link key={index} to={"/trip/" + trip.trip_id} className="pr flex ht-70 flex-middle trip-row br-5">
                                    <div className="wt-150 pl-15">
                                        <div className="fBolder fs-16 table-text-color mb-5">{trip.do_no}</div>
                                    </div>
                                    <div className="wt-200 pl-15">
                                        <div className="fs-16 table-text-color mb-5">{trip.item_name}</div>
                                    </div>
                                    <div className="wt-150 pl-15">
                                        <div className="fs-16 table-text-color mb-5">{trip.value}</div>
                                    </div>
                                    <div className="wt-200 pl-15">
                                        <div className="fs-16 table-text-color mb-5">{trip.location}</div>
                                    </div>
                                    <div className="wt-200 pl-15">
                                        <div className="fs-16 table-text-color mb-5">{trip.location_type}</div>
                                    </div>
                                    <div className="wt-150 pl-15">
                                        <div className="fs-16 table-text-color mb-5">{trip.time}</div>
                                    </div>
                                    <div className="wt-150 pl-15">
                                        <div className="fs-16 table-text-color mb-5">{trip.hours}</div>
                                    </div>
                                    {trip.is_tracked && <div className="trip-track pa r-10 bg-theme border-theme br-5 wt-70 flex flex-center flex-middle ht-25">
                                        <Link to={'/tracking/' + trip.trip_id} className="fs-13 fBolder theme-color">Track</Link>
                                    </div>
                                    }
                                </Link> 
                            )
                        })  
                    }
                    <Link className="theme-color bg-theme border-theme br-5 ht-30 flex flex-middle flex-center wt-100 mt-20 fRight" to={"/trips"}>
                        View All
                    </Link>
                </div>
                
            </div>
        )
    }
}

export default DashboardCardContainer;