import React, { Component } from 'react';
import AnalyticsCard from 'Components/DashboardCard/AnalyticsCard';

class AnalyticsCardContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableShown: false,
            activeStatus: 6
        }
    }
    getTrips = (statusType, code, location_type_status)=> {
        this.props.onSelect(statusType, code, location_type_status, true);
    }

    onSubTypeClick = (e, statusType, code, location_type_status, isSync, subType, subTypeColor) => {
        if (subType > 0) {
            e.stopPropagation();
            this.props.onSelect(statusType, code, location_type_status, isSync, subType, subType === -1 ? code : subTypeColor);
        }
    }
    render() {
        const {statuses, activeStatus} = this.props;
        return (
            <div className="pr">
                <div className="flex flex-between flex-column mt-10 ml-30">
                    {
                        statuses.map((status, index)=> {
                            return <AnalyticsCard
                                key={index}
                                status={status}
                                activeStatus={activeStatus}
                                onClick={()=>{this.getTrips(status.type, status.status, status.location_type_status)}}
                                onSubTypeClick={this.onSubTypeClick}
                            />
                        })
                    }
                </div>
            </div>
        )
    }
}

export default AnalyticsCardContainer;
