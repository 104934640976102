import React, { Component, Fragment } from "react";
import "./OtpLoginFormCard.scss";
import Logo from "Components/Logo/index";
import Tabs from "Components/Tabs/index";
import { ReactComponent as PasswordVisible } from "./passwordvisible.svg";
import { ReactComponent as PasswordNotVisible } from "./passwordNotVisible.svg";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
import { ToastContainer, toast } from "react-toastify";

class OtpLoginFormCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailMobileFocused: false,
    };
  }
  render() {
    const {
      companyTypeTabButton,
      companyType,
      selectCompanyType,
      toggleOtpLogin,
      onClickSendOtp,
      handleEmailOrMobileChange,
      emailOrMobileInput,
    } = this.props;
    return (
      <Fragment>
        {/* <Rectangle className={"otpRectangleTopL"} /> */}
        {/* <Rectangle className={"otpRectangleTopS"} /> */}
        <div className="otpLoginFormCardMain">
          <ToastContainer />
          <div className="otpLoginFormCard">
            <div className="otpLoginFormLogo">
              <Logo className="otpLoginLogoImage" />
            </div>
            <div className="companyTypeButton">
              <Tabs
                tabs={companyTypeTabButton}
                activeTabId={companyType}
                selectTab={selectCompanyType}
              />
            </div>
            <div className="otpLoginFormEmailMain">
              <p className="otpLoginFormEmailLable">Email or Mobile Number</p>
              <div className={`otpLoginFormEmailBox ${this.state.isEmailMobileFocused ? 'box-focused' : ''}`}>
                <input 
                type="text" 
                className="otpLoginFormEmailInput"
                  value={emailOrMobileInput}
                  onChange={handleEmailOrMobileChange}
                  onFocus={() => this.setState({ isEmailMobileFocused: true })}
                  onBlur={() => this.setState({ isEmailMobileFocused: false })}
                >
                </input>
              </div>
            </div>

            <div className="otpLoginButtonBox">
              <Button
                Name={"Send OTP"}
                className={"otpLoginButton"}
                onClick={onClickSendOtp}
              />
            </div>
            <div className="backToSignInLableBox">
              <p className="backToSignInLable" onClick={toggleOtpLogin}>
                Back To SignIn
              </p>
            </div>
          </div>
        </div>
        {/* <Rectangle className={"otpRectangleBottomL"} /> */}
        {/* <Rectangle className={"otpRectangleBottomS"} /> */}
      </Fragment>
    );
  }
}
export default OtpLoginFormCard;
