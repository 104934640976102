import React, { Component, Fragment } from 'react';
import moment from 'moment';
import className from 'classnames';
import Countdown from 'react-countdown';
import { ReactComponent as RedTimer } from 'Pages/Signin/icons/timer-red.svg';


export default class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: this.props.currentTime ? moment(new Date(this.props.currentTime)) : moment(new Date())
        };
    }

    componentDidMount() {
        this.setInitialStateData();
        // this.getCurrentDate()
    }

    componentWillReceiveProps(nextProps) {
        this.setInitialStateData(false, nextProps);
    }

    setInitialStateData = (hasTimer, nextProps) => {
        let { from, to } = nextProps ? nextProps : this.props;
        this.setState({
            from: moment(from), 
            to: moment(to),
            // currentDate: this.props.currentTime?moment(new Date(this.props.currentTime)):moment(new Date())
        }, () => {
            this.updateDiff();
            // this.setupTimer();
        });
        if (hasTimer) {
            this.interval = setInterval(() => {
                this.setInitialStateData();
            }, 2000);
        }
    }

    // getCurrentDate = () => {
    //     const { currentDate } = this.state;
    //     // if(this.timerInterVal) {
    //     //     clearInterval(this.timerInterVal);
    //     // }
    //     this.timerInterVal = setInterval(() => {
    //         this.setState({ currentDate: this.timerInterVal ? currentDate.add(1, 'seconds') : currentDate })
    //     }, 1000);
    // }

    setupTimer = () => {
        this.updateDiff();
        const intervalId = setInterval(() => {
            const duration = moment.duration(this.state.to.diff(this.state.currentDate));
            if(duration.days() == '0' && duration.hours() == '0' && duration.minutes() == '0') {
                clearInterval(this.state.intervalId);
                return;
            }
            this.setState({
                to: this.state.to.subtract(1, 'minutes')
            }, () => {
                this.updateDiff();
            })
        }, 1000 * 60);
        this.setState({
            intervalId: intervalId
        });
    }

    componentWillUnmount() {
        if(this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
        // if (this.props.isRelayBid) {
        //     clearInterval(this.interval);
        // }
        // if (this.timerInterVal) {
        //     clearInterval(this.timerInterVal);
        // }
    }
    


    updateDiff() {
        const { showInfo } = this.props;
        const prefix = showInfo ? 'Ends in ' : '';

        const duration = moment.duration(this.state.to.diff(this.state.currentDate));
        if(duration.minutes() < 0) {
            clearInterval(this.state.intervalId);
            return;
        }
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        let formattedDiff = "";
        // Difference might be in years!
        if(years > 0) {
            formattedDiff = formattedDiff + (years > 0 ? years + ' Year(s) ': '');
        }
        if(months > 0) {
            formattedDiff = formattedDiff + (months > 0 ? months + ' Month(s) ': '');
        }
        if(days > 0) {
            formattedDiff = formattedDiff + (days > 0 ? days + ' Day(s) ': '');            
        }
        if(hours > 0) {
            formattedDiff = formattedDiff + (hours > 0  ? hours + 'h ': '');      
        }

        if(minutes > 0) {
            formattedDiff = formattedDiff + (minutes > 0  ? minutes + 'm ': '');      
        }

        if(seconds > 0) {
            formattedDiff = formattedDiff + (seconds > 0  ? seconds + 's ': '');   
        }

        this.setState({
            currentDiff: prefix + formattedDiff
        });

    }
    
    render() {
        let { from, to, currentDiff, currentDate } = (this.state || {});    
        const { isOTP } = this.props;
        const originalFrom = this.props.from;
        const originalTo = this.props.to;
        const showInfo = this.props.showInfo;
        // const isBiddingExpired = to && currentDate.isAfter(to);
        // const isBiddingYetToStart = from && from.isAfter(currentDate);
        // const duration = to ? moment(new Date(to)) : moment(new Date());
        const duration = new Date(to)
        // const offsetInMilliseconds = (5 * 60 + 30) * 60 * 1000;
        // const adjustedDate = new Date(duration.getTime() - offsetInMilliseconds);
        // const gmtDate = adjustedDate.toGMTString();
        // if (this.props.isRelayBid && (!duration || duration < originalTo)) {
        //     this.setInitialStateData(true);
        // }
        // console.log("render timer props", this.props)
        // console.log("render timer state", this.state)
        // console.log("duration", duration)
        // console.log("to", moment(to).format("dddd, MMMM Do YYYY, h:mm:ss a"))
        // console.log("toGMT", moment(to).utc(true))

        return (
            <Fragment>
                {originalFrom && originalTo && <div>
                    <RedTimer className="mt-5 mr-5" />
                    {
                        <span className={className("fs-14 lh-15p fBold")}> 

                        {
                            duration &&
                            <Countdown
                            date={duration}
                                renderer={
                                    ({ days, hours, minutes, seconds, completed }) => {
                                        if (!completed && isOTP) {
                                            return (<span style={{color:"red"}}>
                                                OTP expires in {minutes}m {seconds}s
                                            </span>);
                                        }
                                        if(completed && isOTP) {
                                            this.props.otpExpired();
                                            
                                        }
                                        return null;
                                    }
                                }
                            />
                        }

                        </span>
                    }
                </div>}
                {
                 !(originalFrom && originalTo) && <div className="flex flex-middle">N/A</div>
                }
            </Fragment>
        )
    }
}
