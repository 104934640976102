import React, { Component, Fragment } from "react";
import { Polyline } from "react-google-maps";
import CustomMarker from "Components/MapMarkers/CutomMarker";
import { subTypeColor } from "Constants/commonConstants";
import mapIcon from "./mapIcon.png";
import pinT1Icon from './pinT1.png'
const getLatLangGoogleObj = (obj) => new google.maps.LatLng(obj.latitude, obj.longitude);

const img = "M21.474,377.522V117.138c0-14.469,11.729-26.199,26.199-26.199h260.25c14.469,0,26.198,11.73,26.198,26.199v260.385 c0,4.823-3.909,8.733-8.733,8.733H30.207C25.383,386.256,21.474,382.346,21.474,377.522z M231.634,466.724 c0,30.01-24.329,54.338-54.338,54.338c-30.009,0-54.338-24.328-54.338-54.338c0-30.011,24.329-54.338,54.338-54.338 C207.305,412.386,231.634,436.713,231.634,466.724z M204.464,466.724c0-15.005-12.164-27.169-27.169-27.169 s-27.17,12.164-27.17,27.169s12.165,27.17,27.17,27.17S204.464,481.729,204.464,466.724z M130.495,412.385H8.733 c-4.823,0-8.733,3.91-8.733,8.733v26.495c0,4.823,3.91,8.733,8.733,8.733h97.598C108.879,438.862,117.704,423.418,130.495,412.385z  M515.938,466.724c0,30.01-24.329,54.338-54.338,54.338c-30.01,0-54.338-24.328-54.338-54.338 c0-30.011,24.328-54.338,54.338-54.338C491.609,412.385,515.938,436.713,515.938,466.724z M488.77,466.724 c0-15.005-12.165-27.169-27.17-27.169c-15.006,0-27.169,12.164-27.169,27.169s12.164,27.17,27.169,27.17 S488.77,481.729,488.77,466.724z M612,421.118v26.495c0,4.823-3.91,8.733-8.733,8.733h-70.704 c-5.057-34.683-34.906-61.427-70.961-61.427c-36.062,0-65.912,26.745-70.969,61.427H248.261 c-2.549-17.483-11.373-32.928-24.164-43.961h134.994V162.594c0-9.646,7.82-17.466,17.466-17.466h82.445 c23.214,0,44.911,11.531,57.9,30.77l53.15,78.721c7.796,11.547,11.962,25.161,11.962,39.094v118.672h21.253 C608.09,412.385,612,416.295,612,421.118z M523.408,256.635l-42.501-60.393c-1.636-2.324-4.3-3.707-7.142-3.707H407.47 c-4.822,0-8.733,3.91-8.733,8.733v60.393c0,4.824,3.91,8.733,8.733,8.733h108.798C523.342,270.394,527.48,262.421,523.408,256.635z";

const getColor = (id) => {
    let colors = subTypeColor.find((type) => {return type.subType == id }) || {};
    return colors.color;
}

const pingColors = ['#fff', '#ffff00', '#8E44AD', '#3498DB', '#00FF00', '#E74C3C'];

const getLatLngPolyline = ({ origin, destination }) => [
    { lat: origin.latitude, lng: origin.longitude },
    { lat: destination.latitude, lng: destination.longitude },
]

export default class MapMarkers extends Component {
    render() {
      const {
        source, destination, lastKnownLocation, vehicleDetails, markedLocation,
        directionMarkers, returnDirectionPoints, activeTabId, currentLocation,
        subtype, routeMap, locationTypes
      } = this.props;
      const queryParams = new URLSearchParams(window.location.search);
      const url_branchId = queryParams.get('branch_id') || null;
      let locations = [source, ...this.props.locations];
      if (url_branchId !==null && destination && destination.branch_id === url_branchId) {
        locations.push(destination);
    }else if(url_branchId ===null){
        locations.push(destination);
    }
      const isRoundTrip = routeMap ? routeMap[0].branch_id === routeMap[routeMap.length - 1].branch_id : false;
      // const sourceLatLng = getLatLangGoogleObj(routeMap ? routeMap[0] : source);
      // const destinationLatLng = getLatLangGoogleObj(routeMap ? routeMap[routeMap.length - 1] : destination);
      //const lastKnownLocationLatLng = ( activeTabId == 3) ? getLatLangGoogleObj(currentLocation) : getLatLangGoogleObj(lastKnownLocation?lastKnownLocation:{});
      const lastKnownLocationLatLng = getLatLangGoogleObj(lastKnownLocation || {});
      var locationsLatLng = (locations || []).map((loc) => getLatLangGoogleObj(loc));
      let locationsLoopReachedCurrentLocation = false;
      const colorsMap = {};
      let pingColorsIndex = 0;
      let fastTagLatLng = (this.props.fastTagDetails || []).map((fastTag) => {
        const fastTagData = fastTag.toll_plaza_geocode.split(',');
        if(fastTagData.length > 0){
            return{
                latitude: fastTagData[0], longitude: fastTagData[1]
            }
        }
      })
      return (
        <div>
            {
                locations.map((location, index)=> {
                    if(index === locations.length - 1) return null;
                    if(
                        lastKnownLocation &&
                        lastKnownLocation.latitude === location.latitude &&
                        lastKnownLocation.longitude === location.longitude
                        ) {
                            locationsLoopReachedCurrentLocation = true;
                        }
                    return (
                        <Polyline
                            path={[locationsLatLng[index], locationsLatLng[index + 1]]}
                            geodesic={true}
                            options={{
                                strokeColor: (locationsLoopReachedCurrentLocation || locations.length < 3) ? "#9fa8da" : "#3f51b5",
                                strokeOpacity: 0, // increase value to show the line from source to destnation
                                strokeWeight: 4,
                            }}
                            key={'poly' + index}
                        />
                    )
                })
            }
            {
                locations.map((location, index)=> {
                    const number = location.number;
                    let markerColor = colorsMap[number];
                    if (number && !markerColor) {
                        markerColor = pingColors[pingColorsIndex];
                        colorsMap[number] = markerColor;
                        pingColorsIndex = pingColorsIndex + 1;
                    }

                    const latLng = getLatLangGoogleObj(location);
                    if(lastKnownLocationLatLng && latLng.lat() === lastKnownLocationLatLng.lat() && latLng.lng() === lastKnownLocationLatLng.lng() ) {
                        return null;
                    }
                    return (
                        <>
                        <CustomMarker
                            position={getLatLangGoogleObj(location)}
                            icon={{
                                path: google.maps.SymbolPath.CIRCLE,
                                scale: 5,
                                fillColor: location.is_stopped && !!location.is_stopped? "orange":markerColor,
                                fillOpacity: 1,
                                strokeWeight: 1
                            }}
                            location = {location}
                            zIndex={20}
                            key={'location' + index}
                        >
                        </CustomMarker>
                        
                        {/* Polyline to show according to GPS Lat Lng
                        <Polyline
                        path={getLatLngPolyline({
                            origin: location,
                            destination: destLatLng,
                          })}
                        geodesic={true}
                        options={{
                                  geodesic: true,
                                  strokeOpacity: 0,
                                  strokeWeight: 2,
                                  strokeColor: "green",
                                  icons: [
                                    {
                                      icon: {
                                        path: 'M 0,0 0,1',
                                        strokeOpacity: 1,
                                        strokeWeight: 2,
                                        scale: 3,
                                      },
                                      offset: '0',
                                      repeat: '10px',
                                    },
                                  ],
                                }}
                        key={'poly' + index}
                    /> */} 

                    
                    </>
                    )
                })
            }
            {(fastTagLatLng || []).map((fastTag, ftIndex) => {
                return(
                    <div className="wt-20 ht-20">
                    <CustomMarker
                    position={getLatLangGoogleObj(fastTag)}
                    icon={{
                        // url: `https://maps.google.com/mapfiles/ms/icons/red-dot.png`,
                        url:pinT1Icon,
                        scale: 1,
                        fillOpacity: 1,
                        strokeWeight: 1,
                        scaledSize: new window.google.maps.Size(22, 33),
                      }}
                    // icon={{
                    //     path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                    //     fillColor: "green",
                    //     fillOpacity: 0.6,
                    //     strokeWeight: 0,
                    //     rotation: 0,
                    //     scale: 1.5,
                    //     anchor: new google.maps.Point(0, 20),
                    //     // path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0"
                    //     // path: "M71.84,20.15C66.91,12.49,58.56,7.46,49.08,7.46c-9.47,0-17.82,5.03-22.76,12.69c-3.97,6.16-5.36,13.63-4.06,20.85  c0.29,1.64,0.72,3.65,1.35,5.97c2.79,10.32,18.68,30.56,25.47,51.74c7.25-21.78,22.68-41.42,25.47-51.74  c0.63-2.32,1.06-4.33,1.36-5.97C77.19,33.78,75.81,26.31,71.84,20.15z M50.55,14.82h4.61l2.44,11.81h-7.05V14.82z M43.01,14.82h4.61  v11.81h-7.04L43.01,14.82z M47.62,51.44H34.16l5-18h8.45V51.44z M50.55,51.44v-18H59l5.01,18H50.55z M70.52,32.5h-4.17v6.04h-5.03  V32.5H36.85v6.04h-5.02V32.5h-4.18v-4.93h42.87V32.5z"
                    // }}
                    // label = {{
                    //     text: "T", // codepoint from https://fonts.google.com/icons
                    //     fontFamily: "Material Icons",
                    //     color: "#ffffff",
                    //     fontSize: "18px",
                    //     fontWeight: 500
                    //   }}
                    // label='T'
                    location = {fastTag}
                    zIndex={1}
                    key={'direction' + ftIndex}
                >
                </CustomMarker>
                </div>
            )})}
            {
                directionMarkers.map((marker, index)=> {
                    const latLng = getLatLangGoogleObj(marker);
                    return (
                        <CustomMarker
                            position={latLng}
                            icon={{
                                path: google.maps.SymbolPath.CIRCLE,
                                scale: 2.8,
                                fillColor: "#808080",
                                fillOpacity: 1,
                                strokeWeight: 1
                            }}
                            location = {latLng}
                            zIndex={1}
                            key={'direction' + index}
                        >
                        </CustomMarker>
                    )
                })
            }
            {
                returnDirectionPoints.map((marker, index)=> {
                    const latLng = getLatLangGoogleObj(marker);
                    return (
                        <CustomMarker
                            position={latLng}
                            icon={{
                                path: google.maps.SymbolPath.CIRCLE,
                                scale: 2.8,
                                fillColor: "#808080",
                                fillOpacity: 1,
                                strokeWeight: 1
                            }}
                            location = {latLng}
                            zIndex={1}
                            key={'direction' + index}
                        >
                        </CustomMarker>
                    )
                })
            }
            {
                !isRoundTrip ?(
                    url_branchId !==null?
                    routeMap.filter(obj => obj.branch_id === url_branchId || obj.source)
                    .map((route, routeInd) => {
                        return(
                            <Fragment>
                                <CustomMarker
                                    position={getLatLangGoogleObj(route)}
                                    icon={{
                                        path: google.maps.SymbolPath.CIRCLE,
                                        scale: 6,
                                        fillColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code,
                                        fillOpacity: 1,
                                        strokeWeight: 1,
                                        strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code
                                    }}
                                    location = {route}
                                    zIndex={20}
                                    key={`${routeInd} marker-source`}
                                >
                                </CustomMarker>
                                <CustomMarker
                                    position={getLatLangGoogleObj(route)}
                                    icon={{
                                        path: google.maps.SymbolPath.CIRCLE,
                                        scale: 9,
                                        fillColor: "#fff",
                                        fillOpacity: 1,
                                        strokeWeight: 1,
                                        strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code
                                    }}
                                    zIndex={19}
                                    key={`${routeInd} marker-source-wrapper`}
                                >
                                </CustomMarker>
                                
                            </Fragment>
                        )
                })
                :routeMap.map((route, routeInd) => {
                    
                    return(
                        <Fragment>
                            <CustomMarker
                                position={getLatLangGoogleObj(route)}
                                icon={{
                                    path: google.maps.SymbolPath.CIRCLE,
                                    scale: 6,
                                    fillColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code,
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code
                                }}
                                location = {route}
                                zIndex={20}
                                key={`${routeInd} marker-source`}
                            >
                            </CustomMarker>
                            <CustomMarker
                                position={getLatLangGoogleObj(route)}
                                icon={{
                                    path: google.maps.SymbolPath.CIRCLE,
                                    scale: 9,
                                    fillColor: "#fff",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code
                                }}
                                zIndex={19}
                                key={`${routeInd} marker-source-wrapper`}
                            >
                            </CustomMarker>
                            
                        </Fragment>
                    )
            })
                )
               
                // <Fragment>
                //     {/* MARKER FOR SOURCE START....................  */}
                //     <CustomMarker
                //         position={sourceLatLng}
                //         icon={{
                //             path: google.maps.SymbolPath.CIRCLE,
                //             scale: 6,
                //             // fillColor: source.source_code,
                //             fillColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === routeMap[0].location_type) || {}).code,
                //             fillOpacity: 1,
                //             strokeWeight: 1,
                //             strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === routeMap[0].location_type) || {}).code,
                //         }}
                //         // location = {source}
                //         location={routeMap[0]}
                //         zIndex={20}
                //         key={'marker-source'}
                //     >
                //     </CustomMarker>
                //     <CustomMarker
                //         position={sourceLatLng}
                //         icon={{
                //             path: google.maps.SymbolPath.CIRCLE,
                //             scale: 9,
                //             fillColor: "#fff",
                //             fillOpacity: 1,
                //             strokeWeight: 1,
                //             // strokeColor: source.source_code
                //             strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === routeMap[0].location_type) || {}).code,
                //         }}
                //         zIndex={19}
                //         key={'marker-source-wrapper'}
                //     >
                //     </CustomMarker>
                //     {/* MARKER FOR SOURCE END....................  */}

                //     {/* MARKER FOR DESTINATION START....................  */}
                //     <CustomMarker
                //         position={destinationLatLng}
                //         icon={{
                //             path: google.maps.SymbolPath.CIRCLE,
                //             scale: 6,
                //             // fillColor: destination.destination_code,
                //             fillColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === routeMap[routeMap.length-1].location_type) || {}).code,
                //             fillOpacity: 1,
                //             strokeWeight: 1,
                //             strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === routeMap[routeMap.length-1].location_type) || {}).code,
                //         }}
                //         // location = {destination}
                //         location = {routeMap[routeMap.length-1]}
                //         zIndex={20}
                //         key={'marker-destination'}
                //     >
                //     </CustomMarker>
                //     <CustomMarker
                //         position={destinationLatLng}
                //         icon={{
                //             path: google.maps.SymbolPath.CIRCLE,
                //             scale: 9,
                //             fillColor: "#fff",
                //             fillOpacity: 1,
                //             strokeWeight: 1,
                //             strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === routeMap[routeMap.length-1].location_type) || {}).code,
                //             // strokeColor: destination.destination_code
                //           }}
                //         zIndex={19}
                //         key={'marker-destination-wrapper'}
                //     >
                //     </CustomMarker>
                    // {/* MARKER FOR DESTINATION END.......................  */}
                // </Fragment>
                :(
                    url_branchId !==null?
                    routeMap.filter(obj => obj.branch_id === url_branchId || obj.source)
                    .map((route, routeInd) => {
                        if (routeMap.length - 1 !== routeInd) {
                            return(
                                <Fragment>
                                    <CustomMarker
                                        position={getLatLangGoogleObj(route)}
                                        icon={{
                                            path: google.maps.SymbolPath.CIRCLE,
                                            scale: 6,
                                            fillColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code,
                                            fillOpacity: 1,
                                            strokeWeight: 1,
                                            strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code
                                        }}
                                        location = {route}
                                        zIndex={20}
                                        key={`${routeInd} marker-source`}
                                    >
                                    </CustomMarker>
                                    <CustomMarker
                                        position={getLatLangGoogleObj(route)}
                                        icon={{
                                            path: google.maps.SymbolPath.CIRCLE,
                                            scale: 9,
                                            fillColor: "#fff",
                                            fillOpacity: 1,
                                            strokeWeight: 1,
                                            strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code
                                        }}
                                        zIndex={19}
                                        key={`${routeInd} marker-source-wrapper`}
                                    >
                                    </CustomMarker>
                                </Fragment>
                            )
                        }
                    }):
                    routeMap.map((route, routeInd) => {
                        if (routeMap.length - 1 !== routeInd) {
                            return(
                                <Fragment>
                                    <CustomMarker
                                        position={getLatLangGoogleObj(route)}
                                        icon={{
                                            path: google.maps.SymbolPath.CIRCLE,
                                            scale: 6,
                                            fillColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code,
                                            fillOpacity: 1,
                                            strokeWeight: 1,
                                            strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code
                                        }}
                                        location = {route}
                                        zIndex={20}
                                        key={`${routeInd} marker-source`}
                                    >
                                    </CustomMarker>
                                    <CustomMarker
                                        position={getLatLangGoogleObj(route)}
                                        icon={{
                                            path: google.maps.SymbolPath.CIRCLE,
                                            scale: 9,
                                            fillColor: "#fff",
                                            fillOpacity: 1,
                                            strokeWeight: 1,
                                            strokeColor: locationTypes && locationTypes.length > 0 && (locationTypes.find(locType => locType.id === route.location_type) || {}).code
                                        }}
                                        zIndex={19}
                                        key={`${routeInd} marker-source-wrapper`}
                                    >
                                    </CustomMarker>
                                </Fragment>
                            )
                        }
                    })
                )
             
            }

            {/* MARKER FOR CURRENT LOCATION START....................  */}
            {
                lastKnownLocation &&
                <CustomMarker
                    position={lastKnownLocationLatLng}
                    icon={{
                        //url: sourceLatLng.lng() < destinationLatLng.lng()?'https://image.flaticon.com/icons/svg/45/45880.svg':'/icons/truck-left.svg',
                        path: img,
                        height: 1,
                        width: 1,
                        fillColor: subtype ? getColor(subtype) : 'black',
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: 'black',
                        scale: 0.03
                    }}
                    location = {lastKnownLocation}
                    zIndex={20}
                    key={'last-known-location'}
                >
                </CustomMarker>
            }

            {
            /* {
                currentLocation && (activeTabId == 3) &&
                <CustomMarker
                    position={lastKnownLocationLatLng}
                    icon={{
                        url: 'https://image.flaticon.com/icons/svg/45/45880.svg',
                        height: 10,
                        width: 12,
                        scaledSize: new google.maps.Size(20, 20)
                    }}
                    location = {lastKnownLocation}
                    zIndex={20}
                    key={'last-known-location'}
                >
                </CustomMarker>
            } */
            }

            {/* MARKER FOR CURRENT LOCATION END....................  */}
            {
                markedLocation &&
                <CustomMarker
                    position={getLatLangGoogleObj(markedLocation)}
                    icon={{
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 4,
                        fillColor: "#fff",
                        fillOpacity: 1,
                        strokeWeight: 1
                    }}
                    location = {markedLocation}
                    zIndex={20}
                    key={'marked-location'}
                    showInfoWindowAlways = {true}
                >
                </CustomMarker>
            }
            { !!this.props.wayPoints && <Fragment>
                { this.props.wayPoints.map((route,ind)=>{
                 
                   return (
                     <CustomMarker
                       position={getLatLangGoogleObj(route)}
                       icon={{
                         url: mapIcon,
                         scaledSize: new window.google.maps.Size(30, 30),
                         // path: google.maps.SymbolPath.CIRCLE,
                         // scale:0.1,
                         // fillColor:"#0079f2",
                         // fillOpacity: 1,
                         // strokeWeight: 1,
                         // strokeColor: "#0079f2",
                       }}
                       location={{
                         ...route,
                         date:false,
                         location: `${route.city},${route.state}`,
                       }}
                       zIndex={20}
                       key={ind}
                     ></CustomMarker>
                   );
                })

                }
            </Fragment>
                  
            }
        </div>
      );
    }
}
