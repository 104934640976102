import React, { Component } from 'react';
import { getCompanyName, getProviderSideCompanyName } from 'Utils/common';
import LorealMileStone from '../Loreal/MileStone/index';
import PantalonsMileStone from '../Pantaloons/MileStone/index';
import PimaryMileStone from '../MileStone/index';
import { getCompanyType } from 'Utils/storage';
import { PROVIDER } from 'Constants/commonConstants';
import { connect } from 'react-redux';
 
const MileStone = (props) => {
  const companyName = getCompanyName();
  const companyType = getCompanyType();
  const providerSideCompanyName = getProviderSideCompanyName()
  if(companyType === PROVIDER){
    switch (providerSideCompanyName) {
      case 'loreal':
        return <LorealMileStone />;
      case 'pantaloons':
        return <PantalonsMileStone />;
      default:
        return <PimaryMileStone />;
    }
  }else{
    switch (companyName) {
      case 'loreal':
        return <LorealMileStone />;
      case 'pantaloons':
        return <PantalonsMileStone />;
      default:
        return <PimaryMileStone />;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    trips:state.trips
  }
};

 
export default connect(mapStateToProps)(MileStone);
// export default MileStone