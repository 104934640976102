import React, { Fragment }from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Sticky from 'Components/Sticky';
import TripTable from 'Containers/TripTable';
import Tabs from 'Components/Tabs';
import Dropdown from 'Components/SearchDropdown';
import SelectDropdown from 'Components/Dropdown';
import PaginationDropdown from 'Components/PaginationDropdown';
import ActionLoader from 'Components/ActionLoader';

import {
    GET_TRIPS, GET_TRANSPORTER_SEACH, GET_PLANT_SEACH, GET_ITEMS_SEACH, GET_CUSTOMER_SEARCH, POST_TRANSPORTER_DETAILS,
    GET_GPS_VENDORS, CONFIRM_AT_ORIGIN, GET_DETAILED_LOG, GET_VEHICLE_DETAIL_OPTIONS, GET_VEHICLE_DATA, GET_VEHICLE_REMARKS,
    GET_OPERATOR_TYPE, GET_NUMBERS_OPERATOR_TYPE, GET_TRIP_STATUS_COUNT, DOWNLOAD_TRIP, CONFIRM_REJECT_VEHICLE, GET_SERVICE_PROVIDERS, GET_TRIP_CONSIGNEE_DROPDOWN, GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN, GET_TRIP_TRANSPORTERS_DROPDOWN
} from 'Constants/actionConstants';
import Button from 'Components/Button';
import ResponsiveTable from 'Components/ResponsiveTable';
import { getCompanyType, setPageOffset, getPagesOffset, clearStorage, getBoundType, getUser, canUserEdit } from 'Utils/storage';
import { SEEKER , truck_location_tab, subTypes, trackingMode, TRANSPORT_TYPE, BOUND_TYPE_TABS, secondary_truck_type, TTBIL, AWL, trackingModeAWL, TRACKING_TYPES_MAP, PANTALOONS, CARGILL } from 'Constants/commonConstants';
import Checkbox from 'Components/Checkbox';
import Select from 'react-select';
import Overlay from 'Components/Overlay';
import DetailedLog from 'Components/DetailedLog';
import DashboardCardContainer from 'Components/DashboardCard/DashboardCardContainer';
import { GALAXY } from 'Constants/commonConstants';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const transporters = [
  'VRL',
  'SRS'
];
const planDepot = [
  'Plan 1',
  'Plan 2'
]

const truckType = [...truck_location_tab];

const userDetails = getUser();

const userCanEdit = canUserEdit();

const isSeeker =  getCompanyType() === SEEKER;

const seekerId = isSeeker ? userDetails.company_id : (userDetails.seeker_ids && userDetails.seeker_ids[0]);

class TripsListing extends React.Component {
  constructor() {
    super();
    this.state = {
      serviceType: 0,
      boundType: 0,
      type: 0,
      limit: 10,
      offset: 0,
      transporterSearch: localStorage.getItem('selectedTransporter') || '',
      plantSearch: localStorage.getItem('selectedPlant') || '',
      branch_id: '',
      itemsSearch: localStorage.getItem('selectedItem') || '',
      customerSearch: localStorage.getItem('selectedCustomer') || '',
      search: '',
      truck_location_status: 2,
      truck_type: truckType,
      sub_type: '',
      trip_mode: TTBIL.includes(userDetails.company_id) ? 2 : 1,
      showLog: false,
      tripId: null,
      trackingType: null,
      transport_type: TRANSPORT_TYPE[0].id,
      location_status_type: null,
      interval: '',
      tripPlantsandDepotsListSelectedAll:false,
      tripTransportersListSelectAll:false,
      tripConsigneeListSelectAll:false,
    }
  }
  getQueryStringValue = (key)=> {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  }

  // getTripsStatusCount = () => {
  //   const { dispatch } = this.props;
  //   const { limit, transporterSearch, plantSearch, itemsSearch, search, type, branch_id } = this.state;
  //   let getDetails = getPagesOffset();
  //   getDetails = JSON.parse(getDetails);
  //   const { offset, serviceType, boundType, truck_location_status, sub_type, trip_mode, transport_type } = getDetails ? getDetails : this.state;
  //   setPageOffset({offset: this.state.offset , serviceType : this.state.serviceType, transport_type: this.state.transport_type, boundType: this.state.boundType,  truck_location_status: this.state.truck_location_status, sub_type: this.state.sub_type, trip_mode: this.state.trip_mode});
  //   dispatch({
  //     type: GET_TRIP_STATUS_COUNT,
  //     params: {
  //       offset: this.state.offset,
  //       limit: limit,
  //       boundType: boundType > 0?boundType:"",
  //       serviceType: serviceType > 0?serviceType: "",
  //       transporterSearch,
  //       plantSearch,
  //       itemsSearch,
  //       //bound_type: bound_type,
  //       //type: status_type,
  //       search: searchText?searchText:search,
  //       truck_location_status,
  //       sub_type,
  //       trip_mode,
  //       transport_type
  //     }
  //   });
  // }

  searchTrips = (pagination=false, searchText, isDownload=false)=> {
    const { dispatch } = this.props;
    const { limit, transporterSearch, plantSearch, itemsSearch, search, type, branch_id,branch_ids, provider_id,provider_ids, customerSearch, selectedTrackingType, selectedOperatorType, selectedGPSType, searchField } = this.state;
    let getDetails = getPagesOffset();
    getDetails = JSON.parse(getDetails);
    console.log('getDetails111:', getDetails)
    const {
      interval, offset, serviceType, boundType, truck_location_status, sub_type, trip_mode,
      transport_type, location_type_status, group_id, untracked_message
    } = getDetails ? getDetails : this.state;
    // const bound_status_types = JSON.parse(getBoundType());
    // const bound_type = (bound_status_types && bound_status_types.boundType) ? bound_status_types.boundType : '';
    // const status_type = (bound_status_types && bound_status_types.statusType) ? bound_status_types.statusType : '';
    if(!pagination) {
      this.setState({
        offset: +offset,
        serviceType: serviceType,
        boundType: boundType,
        truck_location_status: truck_location_status,
        sub_type,
        trip_mode,
        location_type_status: location_type_status
      }, ()=> {
        const params = {
          offset: isDownload ? 0 : +offset,
          limit: isDownload ? 500 : limit,
          boundType: boundType > 0 ? boundType : "",
          serviceType: serviceType > 0 ? serviceType : "",
          search: searchText ? searchText : search,
          //type: status_type,
          transporterSearch,
          group_id,
          plantSearch,
          itemsSearch,
          customerSearch,
          branch_id,
          branch_ids,
          provider_ids,
          truck_location_status,
          sub_type,
          trip_mode,
          interval,
          transport_type,
          location_type_status,
          provider_id,
          tracking_type: selectedTrackingType && selectedTrackingType.id,
          vendor_type: selectedTrackingType && (selectedTrackingType.id === 2 ? (selectedOperatorType && selectedOperatorType.id) : (selectedTrackingType.id === 3 ? (selectedGPSType && selectedGPSType.id) : undefined)),
          search_field: searchField || undefined,
          untracked_message
        }
        if(isDownload) {
          params.download = 1;
          dispatch({
            type: DOWNLOAD_TRIP,
            params
          })
        } else {
          dispatch({
            type:GET_TRIP_STATUS_COUNT,
            params
          })
          dispatch({
            type: GET_TRIPS,
            params
          });
        }
      });
      return;
    }

    setPageOffset({
      offset: this.state.offset,
      serviceType: this.state.serviceType,
      transport_type: this.state.transport_type,
      boundType: this.state.boundType,
      truck_location_status: this.state.truck_location_status,
      sub_type: this.state.sub_type,
      trip_mode: this.state.trip_mode,
      location_type_status: this.state.location_type_status,
      interval: pagination ? interval : '',
    });

    const params = {
      offset: this.state.offset,
      limit: limit,
      boundType: boundType > 0?boundType:"",
      serviceType: serviceType > 0?serviceType: "",
      transporterSearch,
      plantSearch,
      itemsSearch,
      //bound_type: bound_type,
      //type: status_type,
      search: searchText?searchText:search,
      truck_location_status,
      sub_type,
      trip_mode,
      transport_type,
      location_type_status,
      interval,
      provider_id
    }
    dispatch({
      type:GET_TRIP_STATUS_COUNT,
      params
    })
    dispatch({
      type: GET_TRIPS,
      params
    });
  }

  genericSearch = (searchText, searchField) => {
    setPageOffset({
      offset: 0 ,
      serviceType : this.state.serviceType,
      boundType: this.state.boundType,
      truck_location_status: this.state.truck_location_status,
      location_type_status: this.state.location_type_status,
      sub_type: this.state.sub_type,
      trip_mode: this.state.trip_mode,
      transport_type: this.state.transport_type
    });

    this.setState({
      search: searchText,
      searchField: searchField,
      offset: 0
    }, this.searchTrips);
  }

  searchTransporters = (value)=> {
    this.setState({
      transporterSearch: value
    }, ()=> {
      if(!value) {
        this.searchTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_TRANSPORTER_SEACH,
        transporterSearch: value
      })
    });
  }
  selectTransporter = (value)=> {
    clearStorage();
    localStorage.setItem('selectedTransporter', value.name);
    this.setState({
      transporterSearch: value.name,
      provider_id: value.provider_id
    },this.searchTrips);
  }
  selectTransporterOptions = (value)=> {
    clearStorage();
    localStorage.setItem('selectedTransporter', value.value);
    this.setState({
      // transporterSearch: value.name,
      provider_id: value.value
    },this.searchTrips);
  }

  searchPlants = (value)=> {
    this.setState({
      plantSearch: value
    }, ()=> {
      if(!value) {
        this.searchTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_PLANT_SEACH,
        plantSearch: value,
        includeConsignees: TTBIL.includes(userDetails.company_id) ? 1 : undefined,
        provider_id: this.state.provider_id ? this.state.provider_id : undefined
      })
    });
  }
  selectPlant = (value)=> {
    clearStorage();
    localStorage.setItem('selectedPlant', value);
    this.setState({
      plantSearch: value,
    },this.searchTrips);
  }

  searchItems = (value)=> {
    this.setState({
      itemsSearch: value
    }, ()=> {
      if(!value) {
        this.searchTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_ITEMS_SEACH,
        itemsSearch: value
      })
    });
  }
  selectItem = (value)=> {
    clearStorage();
    localStorage.setItem('selectedItem', value);
    this.setState({
      itemsSearch: value
    },this.searchTrips);
  }

  selectTrackingType = (value) => {
    this.setState({selectedTrackingType: value}, this.searchTrips)
  }

  selectVendorType= (gpsVendor) => {
    const gpsVendors = {
        id: gpsVendor.id,
        name: gpsVendor.name
    }
    this.setState({
        selectedGPSType: gpsVendors
    }, this.searchTrips);
}

  selectOperatorType = (value) => {
    this.setState({selectedOperatorType: value}, this.searchTrips)
  }

  searchCustomers = (value)=> {
    this.setState({
      customerSearch: value
    }, ()=> {
      if(!value) {
        this.searchTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_CUSTOMER_SEARCH,
        customerSearch: value
      })
    });
  }
  selectCustomer = (value)=> {
    clearStorage();
    localStorage.setItem('selectedCustomer', value);
    this.setState({
      customerSearch: value
    },this.searchTrips);
  }


  selectServiceType = (serviceType)=> {
    setPageOffset({offset: 0, serviceType : serviceType, boundType: serviceType===0?0:this.state.boundType, truck_location_status: this.state.truck_location_status, sub_type: this.state.sub_type, trip_mode: this.state.trip_mode, transport_type: this.state.transport_type , location_type_status: this.state.location_type_status})
    this.setState({
      serviceType,
      boundType: serviceType===0?0:this.state.boundType,
      truck_location_status: 2,
      location_type_status: null,
      sub_type: ''
    }, this.searchTrips);
  }

  selectTrackingMode = (trackingMode) => {
    const truck_type = trackingMode === 1 ? truckType : [...secondary_truck_type];
    setPageOffset({offset: 0 , serviceType : this.state.serviceType, boundType: this.state.boundType,  truck_location_status: 2, sub_type: '', trip_mode : trackingMode, transport_type: this.state.transport_type, location_type_status: this.state.location_type_status });
    this.setState({
      trip_mode: trackingMode,
      boundType: this.state.boundType,
      truck_location_status: 2,
      location_type_status: null,
      sub_type: '',
      truck_type
    }, this.searchTrips);
  }

  selectBoundType = (boundType)=> {
    setPageOffset({offset: 0, serviceType : this.state.serviceType, boundType: boundType, transport_type: this.state.transport_type, truck_location_status: this.state.truck_location_status, sub_type: this.state.sub_type, trip_mode: this.state.trip_mode, location_type_status: this.state.location_type_status})
    this.setState({
      boundType
    }, this.searchTrips);
  }

  selectTransportType = (transport_type) => {
    setPageOffset({offset: 0, serviceType : this.state.serviceType, transport_type: transport_type, boundType: this.state.boundType, truck_location_status: this.state.truck_location_status, sub_type: this.state.sub_type, trip_mode: this.state.trip_mode, location_type_status: this.state.location_type_status})
    this.setState({
      transport_type,
    }, this.searchTrips);
  }

  tripStatus = (id, locationStatus) => {
    // const {truck_type} = this.state
    // for(var trip in truck_type) {
    //   if(truck_type[trip].id == id ) {
    //     truck_type[trip].enabled = true;
    //   } else {
    //     truck_type[trip].enabled = false;
    //   }
    // }
    setPageOffset({offset: 0, serviceType : this.state.serviceType, boundType: this.state.boundType, truck_location_status: id, sub_type: '', trip_mode: this.state.trip_mode, transport_type: this.state.transport_type, location_type_status: locationStatus  });
    this.setState({
      truck_location_status: id,
      location_type_status: locationStatus,
      // truck_type,
      sub_type: ''
    }, this.searchTrips);
  }
  changeOffset = (offset)=> {
    this.setState({
      offset
    }, ()=> {
      this.searchTrips(true)
    })
  }
  submitTransporterDetails = (transporterDetails)=> {
    const { dispatch } = this.props;
    dispatch({
      type: POST_TRANSPORTER_DETAILS,
      data: transporterDetails,
      onSuccess: () => {
        this.searchTrips();
      }
    })
  }

  onClickPagination = (type) => {
    let { offset } = this.state;
    if( type == 'previous' && offset != 0) {
      offset -= 10;
      this.setState({
        offset
      }, ()=> {
        this.searchTrips(true)
      })
    } else if ( type == 'next') {
      offset += 10;
      this.setState({
        offset
      }, ()=> {
        this.searchTrips(true)
      })
    }
  }

  selectSubType = (value) => {
    setPageOffset({
      offset: 0,
      serviceType : this.state.serviceType,
      boundType: this.state.boundType,
      truck_location_status: this.state.truck_location_status,
      location_type_status: this.state.location_type_status,
      sub_type: value.type,
      trip_mode: this.state.trip_mode,
      transport_type: this.state.transport_type
    });

    this.setState({ sub_type : value.value }, () => {
      this.searchTrips();
    });
  }

  confirmAtOrigin = (data) => {
    const { dispatch } = this.props;
    const { offset, limit, boundType, serviceType, transporterSearch, plantSearch, itemsSearch, search, truck_location_status, sub_type, trip_mode, transport_type, location_type_status, searchField } = this.state;
    const params = {
      offset,
      limit,
      boundType,
      serviceType,
      transporterSearch,
      plantSearch,
      itemsSearch,
      search,
      truck_location_status,
      location_type_status,
      sub_type,
      trip_mode,
      transport_type: transport_type,
      search_field: searchField
    }

    dispatch({ type: CONFIRM_AT_ORIGIN, data, params });
  }

  searchDetailedRouteLog = (search, startDate, endDate, trackingType, offset, limit, order_by)=> {
    const { dispatch, match } = this.props;
    dispatch({
        type:GET_DETAILED_LOG,
        tripId: this.state.tripId,
        search,
        trackingType : trackingType || this.state.trackingType,
        startDate,
        endDate,
        offset,
        limit,
        order_by
    });
  }

  getVehicleRemarks = (type) => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_VEHICLE_REMARKS,
      is_type_reject: type
    })
  }

  rejectVehicle = (data) => {
    const { dispatch } = this.props;
    const { offset, limit, boundType, serviceType, transporterSearch, plantSearch, itemsSearch, search, truck_location_status, sub_type, trip_mode, transport_type, location_type_status } = this.state;
    const params = {
      offset,
      limit,
      boundType,
      serviceType,
      transporterSearch,
      plantSearch,
      itemsSearch,
      search,
      truck_location_status,
      location_type_status,
      sub_type,
      trip_mode,
      transport_type: transport_type,
    }
    dispatch({
      type: CONFIRM_REJECT_VEHICLE,
      data,
      params
    })
  }

  showlogDetails = (tripId, trackingType) => {
      const  logShow = this.state.showLog;
      this.setState({
        showLog: !logShow,
        tripId,
        trackingType
      }, () => {
        if(this.state.showLog){
          this.searchDetailedRouteLog();
        }
      })
  }

  selectVehicleServiceType = (serviceType) => {
    const { dispatch } = this.props;
    const params = {
      seeker_id: seekerId,
      service_type: serviceType.id
    }
    dispatch({ type: GET_VEHICLE_DETAIL_OPTIONS, params });
  }

  onChangeVehicleDetails = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: GET_VEHICLE_DATA, params: data });
  }

  getNumbersOperator = (data) => {
    this.props.dispatch({
      type: GET_NUMBERS_OPERATOR_TYPE,
      params: data
    })
  }

  setCounts = (tabs, counts) => {
    tabs.map((item) => {
      item.count = counts[item.id] || 0;
      return;
    });
  }

  clearItemFilter = () => {
    localStorage.removeItem('selectedItem');
    this.setState({itemsSearch: ''}, this.searchItems);
  }

  clearTrackingType = () => {
    this.setState({selectedTrackingType: ''})
  }

  clearVendorType = () => {
    this.setState({selectedGPSType: ''})
  }
   
  clearOperatorType = () => {
    this.setState({selectedOperatorType: ''})
  }

  clearPlantFilter = () => {
    localStorage.removeItem('selectedPlant');
    this.setState({plantSearch: ''}, this.searchPlants);
  }
  
  clearCustomerFilter = () => {
    localStorage.removeItem('selectedCustomer');
    this.setState({customerSearch: ''}, this.searchCustomers);
  }

  clearTransporterFilter = () => {
    localStorage.removeItem('selectedTransporter');
    this.setState({transporterSearch: ''}, this.searchTransporters);
  }

  fetchServiceProvider = () => {
    const { dispatch } = this.props;
    const { boundType } = this.state
    dispatch({
      type: GET_SERVICE_PROVIDERS,
      params: {
        'customer_id' : getUser().company_id,
        'bound_type' : boundType
      },
    });
  };
  fetchTripConsignees = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_TRIP_CONSIGNEE_DROPDOWN,
      params: {},
    });
  };

  getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  onChangeConsigneeList = (selectedOptions, event,options) => {
    const{tripConsigneeListSelectAll}=this.state;
    const {tripConsigneeList}=this.props;
    let selectedConsigneeIds = selectedOptions.map(option => option.value);
  
    // If the "*" (All) option is selected, include all options in the payload
    if (event.action === "select-option" && event.option.value === "*") {
        if (!tripConsigneeListSelectAll) {
        selectedConsigneeIds = tripConsigneeList.map(sp => sp.branch_id);
        selectedOptions=tripConsigneeList.map(sp => {
          return {
            label: sp.display_name,
            value: sp.branch_id,
          };
        })
      }else{
        //deselect all
        selectedConsigneeIds=[],
        selectedOptions=[]
      }
    }
  
    this.setState(prevState => ({
      tripConsigneeListSelectAll:!tripConsigneeListSelectAll,
      transporter_name: selectedOptions,
      branch_ids: selectedConsigneeIds.join(','), 
    }), () => {
      this.searchTrips();
    });
  }
  fetchTripPlantsAndDepots = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN,
      params: {},
    });
  };
  onChangePlantsandDepotsList = (selectedOptions, event) => {
    const{tripPlantsandDepotsListSelectedAll}=this.state;
    const{tripPlantsandDepotsList}=this.props;
    let selectedPlantsAndDepotIds = selectedOptions.map(option => option.value);
  
    // If the "*" (All) option is selected, include all options in the payload
    if (event.action === "select-option" && event.option.value === "*") {
        if (!tripPlantsandDepotsListSelectedAll) {
        selectedPlantsAndDepotIds =tripPlantsandDepotsList.map(sp => sp.branch_id);
        selectedOptions=tripPlantsandDepotsList.map(sp => {
          return {
            label: sp.display_name,
            value: sp.branch_id,
          };
        })
      }else{
        // Deselect all options
        selectedPlantsAndDepotIds = [];
        selectedOptions = [];

      }
    }
  
    this.setState(prevState => ({
      tripPlantsandDepotsListSelectedAll: !tripPlantsandDepotsListSelectedAll,
      plantsAndDepotsName: selectedOptions,
      branch_ids: selectedPlantsAndDepotIds.join(','), 
    }), () => {
      this.searchTrips();
    });
  }
  fetchTripTransportersForMultiSelect = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_TRIP_TRANSPORTERS_DROPDOWN,
      params: {},
    });
  };
  onChangeMultiselectTransportersList = (selectedOptions, event) => {
    const{tripTransportersListSelectAll}=this.state;
    const{tripTransportersList}=this.props;
    let selectedTransporterIds = selectedOptions.map(option => option.value);
  
    // If the "*" (All) option is selected, include all options in the payload
    if (event.action === "select-option" && event.option.value === "*") {
        if (!tripTransportersListSelectAll) {
        selectedTransporterIds = tripTransportersList.map(sp => sp.provider_id);
        selectedOptions=tripTransportersList.map(sp => {
          return {
            label: sp.name,
            value: sp.provider_id,
          };
        })
        }else{
          //deselect all
          selectedTransporterIds=[];
          selectedOptions=[];
        }

    }
  
    this.setState(prevState => ({
      tripTransportersListSelectAll:!tripTransportersListSelectAll,
      multiSelectTransportersName: selectedOptions,
      provider_ids: selectedTransporterIds.join(','), 
    }), () => {
      this.searchTrips();
    });
  }

  render() {
    const {
      trips,
      tripsCount,
      inboundCount,
      outboundCount,
      isTripsLoaded,
      ftlCount,
      lclCount,
      courierCount,
      containerCount,
      filterCount,
      transporterSearchItems,
      plantSearchItems,
      itemsSearchItems,
      customerSearchItems,
      isTransporterDetailsSubmitting,
      isDetailedLogLoaded,
      detailedLog,
      vehicleServiceTypes,
      vehicleTypes,
      vehicleBodyTypes,
      operatorTypes,
      tripStatusCount,
      tripModeCount,
      transportTypeCount,
      boundTypeCount,
      serviceProviders,
      tripConsigneeList,
      tripPlantsandDepotsList,
      tripTransportersList
    } = this.props;

    this.setCounts(trackingMode, tripModeCount);
    this.setCounts(BOUND_TYPE_TABS, boundTypeCount);
    this.setCounts(TRANSPORT_TYPE, transportTypeCount);
    this.setCounts(trackingModeAWL, tripModeCount);
    const tripSubType = ((tripStatusCount || []).find(tSCount => tSCount.location_type_status && (tSCount.location_type_status === this.state.location_type_status) || tSCount.type === this.state.truck_location_status));
    return (
      <Sticky activeLink={'trips'} onSearch={this.genericSearch} initialSearchValue={this.getQueryStringValue('search')} isSearch={true}>
        <div className="p5 pr-30">
        <ResponsiveTable>
          <section className="pl-15 mb-20 flex flex-between">
            {isSeeker && <Fragment>
              <div className="flex-1 mr-20">
                {/* <Tabs tabs={[
                  {
                    id:0,
                    value: 'All',
                    count: tripsCount
                  },{
                    id:1,
                    value: 'FTL',
                    count: ftlCount
                  },{
                    id:2,
                    value: 'LCL',
                    count: lclCount
                  },{
                    id:3,
                    value: 'Courier',
                    count: courierCount
                  },{
                    id:4,
                    value: 'Container',
                    count: containerCount
                  },
                ]}
                activeTabId={this.state.serviceType}
                selectTab = {this.selectServiceType}
                /> */}
                {AWL.includes(getUser().company_id) ?
                  <Tabs
                    tabs={trackingModeAWL}
                    activeTabId={this.state.trip_mode}
                    selectTab = {this.selectTrackingMode}
                    // isAlternateName ={TTBIL.includes(userDetails.company_id)}
                  />
                :
                <Tabs
                  tabs={trackingMode}
                  activeTabId={this.state.trip_mode}
                  selectTab = {this.selectTrackingMode}
                  isAlternateName ={TTBIL.includes(userDetails.company_id)}
                />}
              </div></Fragment>}
              <div className="flex-1 mr-20">
                <Tabs tabs={BOUND_TYPE_TABS}
                activeTabId= {this.state.boundType}
                selectTab = {this.selectBoundType}
                />
              </div>
              <div className="flex-1">
                <Tabs
                  tabs={TRANSPORT_TYPE}
                  activeTabId={this.state.transport_type}
                  selectTab = {this.selectTransportType}
                />
              </div>
          </section>
        </ResponsiveTable>
          {isSeeker && <ResponsiveTable>
            <section className="pl-15 mb-30 flex flex-between">
                {!(PANTALOONS.includes(seekerId)||
                   CARGILL.includes(seekerId)) &&
                <Dropdown
                  onChange={this.searchTransporters}
                  containerClassName="flex-1 mr-20"
                  items={transporterSearchItems}
                  value={this.state.transporterSearch}
                  label={'Select Transporters'}
                  onSelect={this.selectTransporter}
                  hasClearFilter={() => this.clearTransporterFilter()}
                />}

               {PANTALOONS.includes(seekerId)&&
                <Select
                  options={serviceProviders ? serviceProviders.map((val) => {
                      return { label: val.vendor_name, value: val.provider_id}
                  }): []}
                  className={'wt-250 flex-3 mr-20'}
                  placeholder={'Select Transporter'}
                  onChange={this.selectTransporterOptions}
                  // onInputChange={this.searchPlants}
                />}
                {CARGILL.includes(seekerId) &&
                  <ReactMultiSelectCheckboxes
                    options={[
                      { label: "Select All", value: "*", selectAll: true },
                      ...(tripTransportersList || []).map((sp) => {
        
                                return {
                                  label: sp.name,
                                  value: sp.provider_id,
                                };
                        
                          })]}
                    className="ml--35 bg-white"
                    placeholderButtonLabel="Select Tranporters"
                    getDropdownButtonLabel={this.getDropdownButtonLabel}
                    value={this.state.multiSelectTransportersName || []}
                    onChange={this.onChangeMultiselectTransportersList}
                    setState={(val) => this.setState({ multiSelectTransportersName: [...val, ...multiSelectTransportersName] })}
                  />}
                {!CARGILL.includes(seekerId) &&
                <Dropdown
                  onChange={this.searchPlants}
                  containerClassName="flex-1 mr-20"
                  items={plantSearchItems}
                  isList={true}
                  displayKey={'city'}
                  label={`Select Plant/Depot${TTBIL.includes(userDetails.company_id) ? '/Consignee' : ''}`}
                  value={this.state.plantSearch}
                  onSelect={this.selectPlant}
                  hasClearFilter={() => this.clearPlantFilter()}
                /> }
                {CARGILL.includes(seekerId) &&
                  <ReactMultiSelectCheckboxes
                    options={[
                      { label: "Select All", value: "*", selectAll: true },
                      ...(tripPlantsandDepotsList || []).map((sp) => {
        
                                return {
                                  label: sp.display_name,
                                  value: sp.branch_id,
                                };
                        
                          })]}
                    className="ml--35 bg-white"
                    placeholderButtonLabel="Select Plants and Depots"
                    getDropdownButtonLabel={this.getDropdownButtonLabel}
                    value={this.state.plantsAndDepotsName || []}
                    onChange={this.onChangePlantsandDepotsList}
                    setState={(val) => this.setState({ plantsAndDepotsName: [...val, ...plantsAndDepotsName] })}
                  />}
                {GALAXY.includes(seekerId) &&
                <Dropdown
                  onChange={this.searchCustomers}
                  containerClassName="flex-1"
                  items={customerSearchItems}
                  label={'Select Customers'}
                  value={this.state.customerSearch}
                  onSelect={this.selectCustomer}
                  hasClearFilter={() => this.clearCustomerFilter()}
                />}
                {!CARGILL.includes(seekerId) &&
                <Dropdown
                  onChange={this.searchItems}
                  containerClassName="flex-1"
                  items={itemsSearchItems}
                  label={'Select Items'}
                  value={this.state.itemsSearch}
                  onSelect={this.selectItem}
                  hasClearFilter={() => this.clearItemFilter()}
                />}
                {CARGILL.includes(seekerId) &&
                  <ReactMultiSelectCheckboxes
                options={[
                  { label: "Select All", value: "*", selectAll: true },
                  ...(tripConsigneeList || []).map((sp) => {
     
                            return {
                              label: sp.display_name,
                              value: sp.branch_id,
                            };
                    
                      })]}
                className="ml--35 bg-white"
                placeholderButtonLabel="Select Consignee"
                getDropdownButtonLabel={this.getDropdownButtonLabel}
                value={this.state.transporter_name || []}
                onChange={this.onChangeConsigneeList}
                setState={(val) => this.setState({ transporter_name: [...val, ...transporter_name] })}
              />}
                
            </section>
          </ResponsiveTable>}
          <ResponsiveTable>
            <section className="pl-15 mb-30 flex flex-between">
              <div className="mr-10 wt-240">
                <SelectDropdown
                  selectedClassName={"dropdown-select"}
                  items={TRACKING_TYPES_MAP}
                  label={'Select Tracking Type'}
                  value={this.state.selectedTrackingType}
                  onSelect={this.selectTrackingType}
                  hasClearFilter={() => this.clearTrackingType()}
                />
              </div>
              {this.state.selectedTrackingType && this.state.selectedTrackingType.id === 3 &&
              <div className="mr-20 wt-240">
                <SelectDropdown
                  selectedClassName={'col-1'}
                  items={this.props.gpsVendorType}
                  label={'Select Vendor Type'}
                  value={this.state.selectedGPSType}
                  onSelect={this.selectVendorType.bind(this)}
                  hasClearFilter={() => this.clearVendorType()}
                />
              </div>}
              {this.state.selectedTrackingType && this.state.selectedTrackingType.id === 2 &&
              <div className="mr-10 wt-240">
                <SelectDropdown
                  selectedClassName={"col-1"}
                  items={this.props.operatorTypes}
                  label={'Select Operator Type'}
                  value={this.state.selectedOperatorType}
                  onSelect={this.selectOperatorType}
                  hasClearFilter={() => this.clearOperatorType()}
                />
              </div>}
              <div className="mr-20 wt-200"></div>
            </section>
          </ResponsiveTable>
          {/* {
            isSeeker && */}
            <ResponsiveTable>
              <section className="pl-15 mb-20 flex sub_type col-1">
              {/* { this.state.truck_type.map((truck, index)=> {
                return (
                  <Checkbox onCheck={this.tripStatus.bind(this, truck.id, index)} key={index} className="mr-70" checked={truck.id == this.state.truck_location_status} label={truck.value}/>
                )
              })
              } */}
                {/* <Tabs tabs={tripStatusCount}
                    activeTabId={this.state.truck_location_status}
                    selectTab = {this.tripStatus}
                    tripSubStatus= {true}
                  /> */}
                <DashboardCardContainer onSelect={this.tripStatus} statuses={tripStatusCount} tripSubStatus= {true} activeType={(this.state.truck_location_status || this.state.location_type_status)}/>
              </section>
              </ResponsiveTable>
          {/* } */}

          <section className="flex flex-between mb-30">
            <header className="flex-1 pl-15 fs-24 theme-color fBolder">Trips</header>
            {tripSubType && tripSubType.sub_types && tripSubType.sub_types.length > 0 && <div className="mr-10">
              <SelectDropdown
                onSelect={this.selectSubType}
                customClass="flex-1 mr-20"
                items={tripSubType.sub_types}
                value={tripSubType.sub_types.filter(val => {return val.type === this.state.sub_type })[0]}
                label={'Select Sub Type'}
                displayKey="status"
                selectedClassName={'wt-150'}
              />
            </div>}
            {filterCount > 0 && <div className="wt-200">
              <PaginationDropdown onSelect={this.changeOffset} offset={this.state.offset} limit={10} totalCount={filterCount}/>
            </div>}
            { <div className="wt-150 ml-5">
                <Button value="Download" onClick={() => this.searchTrips(false, '', true)}/>
              </div>
            }
            {userCanEdit && this.state.trip_mode === 1 && <Link className="wt-200 ml-5" to ={'/add-trip/'}>
              <Button value="+  Add new trip"/>
            </Link>}
            {userCanEdit && isSeeker && this.state.trip_mode === 2 && <Link className="wt-200 ml-5" to ={'/secondary-add-trip/'}>
              <Button value="+  Add new trip"/>
            </Link>}
          </section>

          {
            isTripsLoaded && trips &&
            <TripTable
              numbersOperatorData={this.props.numbersOperatorData}
              getNumbersOperator={this.getNumbersOperator}
              isTransporterDetailsSubmitting={isTransporterDetailsSubmitting}
              submitTransporterDetails={this.submitTransporterDetails}
              trips={trips}
              truck_status={this.state.truck_location_status}
              location_type_status={this.state.location_type_status}
              gpsVendorType={this.props.gpsVendorType}
              tripData={this.props.tripData}
              confirmAtOrigin={this.confirmAtOrigin}
              showlogDetails={this.showlogDetails}
              onChangeVehicleDetails={this.onChangeVehicleDetails}
              selectVehicleServiceType={this.selectVehicleServiceType}
              vehicleServiceTypes={vehicleServiceTypes}
              vehicleTypes={vehicleTypes}
              vehicleBodyTypes={vehicleBodyTypes}
              vehicleData={this.props.vehicleData}
              operatorTypes={operatorTypes}
              searchTerm={this.state.search}
              getVehicleRemarks={this.getVehicleRemarks}
              vehicleRemarks={this.props.vehicleRemarks}
              rejectVehicle={this.rejectVehicle}
            />
          }

          {trips && trips.length > 0 &&
            <Fragment>
              {this.state.offset !== 0 && <div className="wt-150 ml-5 mt-20 fLeft inBlock"><Button value="Previous" onClick={() => this.onClickPagination('previous')}/></div>}
              <div className="wt-150 ml-5 mt-20 fRight inBlock"><Button value="Next" onClick={() => this.onClickPagination('next')}/></div>
            </Fragment>
          }
          {this.state.showLog && <Overlay show={this.state.showLog} hideOverlay={this.showlogDetails} >
              <DetailedLog
                  isDetailedLogLoaded={isDetailedLogLoaded}
                  search={this.searchDetailedRouteLog}
                  locations={detailedLog}
                  trackingType={this.state.trackingType}
                  tripId={this.state.tripId}
              />
          </Overlay>}

          {!isTripsLoaded  && <ActionLoader fixed={true} /> }
          {isTransporterDetailsSubmitting && <ActionLoader fixed={true}/>}
        </div>
      </Sticky>
    );
  }

  componentDidMount() {
    const search = this.getQueryStringValue('search');
    const { dispatch , match } = this.props;
    let getDetails = getPagesOffset();
    getDetails = JSON.parse(getDetails);
    const tripMode = getDetails ? getDetails.trip_mode : this.state.trip_mode;
    const  truck_type = tripMode === 1 ? truckType : [...secondary_truck_type];
    const transport_type = getDetails && getDetails.transport_type ? getDetails.transport_type : this.state.transport_type;
    this.setState({ truck_type, transport_type })
    // const truck_location_status  = this.props.location.search.indexOf('type') > -1 ? this.props.location.search.split('=')[1] : this.state.truck_location_status;
    //   this.setState({ truck_location_status },
    //      () => this.searchTrips(false, search));
    this.searchTrips(false, search)
    dispatch({ type: GET_GPS_VENDORS, seekerId: seekerId });
    dispatch({ type: GET_VEHICLE_DETAIL_OPTIONS, params: {seeker_id: seekerId} });
    dispatch({ type: GET_OPERATOR_TYPE });
    this.fetchServiceProvider();
    this.fetchTripConsignees();
    this.fetchTripPlantsAndDepots();
    this.fetchTripTransportersForMultiSelect();
  }
}

const mapStateToProps = ({trips}) => {
  return {
    trips: trips.trips.data,
    isTripsLoaded: trips.trips.isDataLoaded,
    containerCount : trips.trips.containerCount,
    courierCount :trips.trips.courierCount,
    ftlCount : trips.trips.ftlCount,
    lclCount : trips.trips.lclCount,
    tripsCount : trips.trips.tripsCount,
    inboundCount : trips.trips.inboundCount,
    outboundCount : trips.trips.outboundCount,
    filterCount: trips.trips.filterCount,
    transporterSearchItems: trips.trips.transporterSearch || [],
    plantSearchItems: trips.trips.plantSearch || [],
    itemsSearchItems: trips.trips.itemsSearch || [],
    customerSearchItems: trips.trips.customerSearch || [],
    isTransporterDetailsSubmitting: trips.transporterDetails.dataSubmitting,
    gpsVendorType: trips.gpsVendors || [],
    tripData : trips.trips.actualData,
    isDetailedLogLoaded: trips.detailedLog.isDataLoaded,
    detailedLog: trips.detailedLog.data || [],
    vehicleServiceTypes: trips.vehicleServiceTypes || [],
    vehicleTypes: trips.vehicleTypes,
    vehicleBodyTypes: trips.vehicleBodyTypes,
    vehicleData: trips.vehicleData || {},
    operatorTypes: trips.operatorTypes || [],
    numbersOperatorData: trips.numbersOperatorData || {},
    tripStatusCount: trips.tripStatusCount || [],
    tripModeCount: trips.tripModeCount || {},
    transportTypeCount: trips.transportTypeCount || {},
    boundTypeCount: trips.boundTypeCount || {},
    vehicleRemarks: trips.vehicleRemarks || [],
    serviceProviders: trips.serviceProviders || [],
    tripConsigneeList:trips.tripConsigneeList||[],
    tripPlantsandDepotsList:trips.tripPlantsandDepotsList||[],
    tripTransportersList:trips.tripTransportersList||[],
  }
};


export default connect(
  mapStateToProps
)(TripsListing);
