import React from 'react';
import classNames from 'classnames';

import {ReactComponent as Oval} from './icons/oval.svg';
import Button from 'Components/Button';
import { getTrackingLabel } from 'Utils/common';
import { getUser } from 'Utils/storage';
import { AWL } from 'Constants/commonConstants';

const TripBanner = (props)=> {
    const {trip, gpsVendors, operatorTypes, preference, seekerName } = props;
    let driverDetails = {
        name: '-',
        number: '-',
        'consentStatus': 'n/a'
    };
    const trackingPreference = trip.trackingPreferences.length > 0 && trip.trackingPreferences.filter(preference => preference.enabled).length > 0 ? trip.trackingPreferences.filter(preference => preference.enabled)[0].tracking_type : '';
    // const modifiedtrackingPreference = preference && preference.length > 0 && preference.filter(item => item.enabled).length > 0 ? preference.filter(item => item.enabled)[0].tracking_type : '';
    if (trip.driverDetails && trip.driverDetails.length > 0){
        let activeDriverFound = false;
        for (let i=0; i<(trip.driverDetails || []).length; i++) {
            if (trip.driverDetails[i].isTracked) {
                driverDetails = trip.driverDetails[i];
                activeDriverFound = true;
                break;
            }
        }
        if (!activeDriverFound) {
            driverDetails = trip.driverDetails[0];
        }
    };
    const isDriverDetailsEmpty =
    !driverDetails.firstName ||
    !driverDetails.lastName ||
    !driverDetails.number ||
    !driverDetails.operatorType;

    const getTrackingType = () => {
        const C1 = trip.trackedLogs && trip.trackedLogs.tracking_type === 3
        if (C1) {
            return "GPS Tracking"
        }
        // return "SIM TRACKING"
    }
    const getVendorName = () => {
        const C1 = trip.trackedLogs && trip.trackedLogs.vendor_name || ""
        return C1
    }

    const renderPreviousTracking = () => {
        const C1 = trip.type === 7
        const C2 = trip.trackedLogs && Object.keys(trip.trackedLogs).length > 0;
        
        if (C1 && C2) {
            return true
        }
        return false;
    }
    
    return (
        <div className="trip-banner flex flex-between flex-middle">
            <div className="banner-item col-5 pl-15 pb-10 pr">
                <div className="pa l--8">
                    <Oval />
                </div>
                <div className="theme-color fBolder fs-10 mb-10 mt-5">ROUTE</div>
                <div className="table-text-color fs-18 fBolder mb-5 ">{trip.routeDetails.source} - {trip.routeDetails.destination}</div>
                <div className="table-text-color fs-14">{trip.routeDetails.via || "-"}</div>
                <div>{trip.seekerName}</div> 
            </div>
            <div className="banner-item col-5 pl-15 pb-10 pr">
                <div className="pa l--8">
                    <Oval />
                </div>
                <div className="theme-color fBolder fs-10 mb-10 mt-5">TRANSPORTER & SERVICE</div>
                <div className="table-text-color fs-18 fBolder mb-5 ">{trip.transporterDetails.transporterName}</div>
                <div className="table-text-color fs-14">{(trip.transporterDetails.number || "-")}</div>
            </div>
            <div className="banner-item col-5 pl-15 pb-10 pr">
                <div className="pa l--8">
                        <Oval />
                </div>
                <div className="theme-color fBolder fs-10 mb-10 mt-5">DATES & TAT</div>
                {trip.dateTat.createdOn && <div className="table-text-color fs-14 fBolder mb-5 "><span>{'Created On: '}</span>{trip.dateTat.createdOn ? trip.dateTat.createdOn.format('DD MMM, YYYY HH:mm') : ''}</div>}
                {trip.dateTat.startedDate && <div className="table-text-color fs-14 mb-5 "><span>{'Start Date: '}</span>{trip.dateTat.startedDate ? trip.dateTat.startedDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                {trip.dateTat.gateInDate && <div className="table-text-color fs-14 mb-5 "><span>{'Source Gate In: '}</span>{trip.dateTat.gateInDate ? trip.dateTat.gateInDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                {trip.dateTat.gateOutDate && <div className="table-text-color fs-14 mb-5 "><span>{'Source Gate Out: '}</span>{trip.dateTat.gateOutDate ? trip.dateTat.gateOutDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                {trip.dateTat.destinationGateInTime && <div className="table-text-color fs-14 mb-5 "><span>{'Destination Gate In: '}</span>{trip.dateTat.destinationGateInTime ? trip.dateTat.destinationGateInTime.format('DD MMM, YYYY HH:mm') : ''}</div>}
                {trip.dateTat.destinationGateOutTime && <div className="table-text-color fs-14 mb-5 "><span>{'Destination Gate Out: '}</span>{trip.dateTat.destinationGateOutTime ? trip.dateTat.destinationGateOutTime.format('DD MMM, YYYY HH:mm') : ''}</div>}
                {trip.dateTat.completedOn && <div className="table-text-color fs-14 mb-5 "><span>{'Completed On: '}</span>{trip.dateTat.completedOn ? trip.dateTat.completedOn.format('DD MMM, YYYY HH:mm') : ''}</div>  }
                <div className="table-text-color fs-14">{`${trip.dateTat.tat ? `${trip.dateTat.tat} Days` : ''}`}</div>
            </div>
            { driverDetails &&
                <div className="banner-item col-5 pl-15 pb-10 pr">
                    <div className="pa l--8">
                        <Oval />
                    </div>
                    <div className="theme-color fBolder fs-10 mb-10 mt-5">DRIVER DETAILS</div>
                    
                     {isDriverDetailsEmpty &&
                        <div className={isDriverDetailsEmpty ? 'blinking-text' : ''} style={{ color: 'red' }}>
                        <b>DRIVER DETAILS ARE MISSING</b>
                        </div>}

                    <div className="table-text-color fs-18 fBolder mb-5 ">
                        {(driverDetails.firstName || driverDetails.lastName) ? `${driverDetails.firstName} ${driverDetails.lastName}` : driverDetails.name}
                    </div>
                    <div className="table-text-color fs-14">{driverDetails.number}</div>
                    <div
                        className={classNames('fs-12 fBolder mt-5',{
                            'green-color': driverDetails.consentStatus == 'allowed',
                            'orange-color': driverDetails.consentStatus == 'pending',
                            'error-color': driverDetails.consentStatus == 'not_added',
                            'consent-error-color': driverDetails.consentStatus == 'consent_check_error'
                        })}
                        style={{textTransform: 'uppercase'}}
                    >
                        <span className={'theme-color fs-10'}>{getTrackingLabel(+trackingPreference)}</span>
                        {driverDetails.consentStatus.toLowerCase() != 'n/a' && +trackingPreference === 2 ? `${driverDetails.consentStatus} ${driverDetails.operatorType ? `(${operatorTypes.filter(operator => operator.id === driverDetails.operatorType)[0].name})` : ''}` : +trackingPreference === 3 && Object.keys(trip.gpsDetails).length > 0 ? trip.gpsDetails && trip.gpsDetails.name : ''}
                    </div>
                    {(renderPreviousTracking() &&
                        <div>
                            This vehicle was previously tracked with
                            "<span style={{ color: "#E4003A" }}>
                                {getTrackingType()}
                            </span>"
                            by the vendor
                            "<span style={{ color: "#E4003A" }}>{getVendorName()}</span>".
                        </div>
                    )}
                    {
                        driverDetails.userResponse &&
                        <div className="fs-12 table-text-color fBolder">
                            <span className={'theme-color fs-10'} style={{textTransform: 'uppercase'}}>IVR/SMS Response: </span>
                            {driverDetails.userResponse}
                        </div>
                    }
                    {
                        driverDetails.responseTime &&
                        <div className="fs-12 table-text-color mb-5 fBolder">
                            <span className={'theme-color fs-10'} style={{textTransform: 'uppercase'}}>IVR Time: </span>
                            {driverDetails.responseTime}
                        </div>
                    }
                    {/* { <div className="mt-10 wt-150">
                        <Button value={'Resend Consent'} onClick={props.onClickResend}></Button>
                    </div>}  */}
                </div>
            }
            <div className="banner-item col-5 pl-15 pb-10 pr">
                <div className="pa l--8">
                    <Oval />
                </div>
                <div className="theme-color fBolder fs-10 mb-10 mt-5">VEHICLE DETAILS</div>
                <div className="table-text-color fs-18 fBolder mb-5 ">{trip.vehicleDetails.vehicleNo}</div>
                {
                    trip.vehicleDetails.loadingCapacity &&
                    <div className="fs-14 place-date-time pt-4">
                        {`${trip.vehicleDetails.vehicleServiceType}: ${trip.vehicleDetails.loadingCapacity}L`}
                    </div>
                }
                <div className="fs-14 place-date-time pt-4">
                    {trip.vehicleDetails.isoNo}
                </div>
                <div className="table-text-color fs-14">{trip.vehicleDetails.truckType}</div>
                <div className="table-text-color fs-14">{trip.vehicleDetails.bodyType}</div>
            </div>
            {AWL.includes(getUser().company_id) &&
            <div className="banner-item col-5 pl-15 pb-10 pr">
                <div className="pa l--8">
                    <Oval />
                </div>
                <div className="theme-color fBolder fs-10 mb-10 mt-5">RFQ DETAILS</div>
                <div className="table-text-color fs-18 fBolder mb-5 ">{trip.rfqNo}</div>
                <div className="table-text-color fs-14">{trip.contractTenureFrom ? `Contract From: ${trip.contractTenureFrom.format('DD MMM, YYYY HH:mm')}` : ''}</div>
                <div className="table-text-color fs-14">{trip.contractTenureTo ? `Contract To: ${trip.contractTenureTo.format('DD MMM, YYYY HH:mm')}` : ''}</div>
            </div>}
        </div>
    );
}

export default TripBanner;
