import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';

import {
  GET_TRIP_DETAIL, UPDATE_TRACKING_PREFERENCES, POST_MANUAL_TRACKING, POST_TRANSPORTER_DETAILS,
  TOAST_ERROR, GET_PROVIDERS, CANCEL_TRIP, GET_GPS_VENDORS, GET_OPERATOR_TYPE,
  GET_VEHICLE_DETAIL_OPTIONS, GET_NUMBERS_OPERATOR_TYPE, POST_VEHICLE_DATA,
  GET_VEHICLE_DATA, GET_VEHICLE_REMARKS
} from 'Constants/actionConstants';

import Sticky from 'Components/Sticky';
import TripBanner from 'Components/TripBanner';
import TabbedSections from 'Components/TabbedSections';
import ResponsiveTable from 'Components/ResponsiveTable';

import RouteDetails from 'Components/RouteDetails';
import TrackingSettings from 'Components/TrackingSettings';
import DetentionDetails from 'Components/DetentionDetails';
import ManualTracking from 'Components/ManualTracking';
import TransporterDetails from 'Components/TransporterDetails';
import GPSVendors from 'Components/GPSVendors';
import Comments from 'Components/Comments';
import ShipmentDetails from 'Components/ShipmentDetails';

import Status from 'Components/Status';
import Button from 'Components/Button';
import ActionLoader from 'Components/ActionLoader';

import {ReactComponent as BackIcon} from './icons/back.svg';
import { ReactComponent as EsclamationIcon } from 'Components/TripRow/icons/esclamation.svg';

import { getUser, getCompanyType, canUserEdit } from 'Utils/storage';
import { SEEKER, PROVIDER, truck_location_tab} from 'Constants/commonConstants';
import Confirmation from './Confirmation';
import Overlay from 'Components/Overlay';


const userDetails = getUser();
const userCanEdit = canUserEdit();
const isSeeker =  getCompanyType() === SEEKER;
const seekerId = isSeeker ? userDetails.company_id : (userDetails.seeker_ids && userDetails.seeker_ids[0]);


class TripDetail extends Component {
  constructor() {
    super();
    this.state = {
      isConfirm: false,
      ccType: 0
    }
  }

  saveTrackingPreferences = (trackingPreferences, notificationSettings) => {
    const { dispatch, trip } = this.props;
    dispatch({
      type: UPDATE_TRACKING_PREFERENCES,
      tripId: trip.tripId,
      trackingPreferences: trackingPreferences.map((trackingPreference) => {
        return {
          tracking_type: trackingPreference.trackingType || trackingPreference.tracking_type,
          tracking_name: trackingPreference.trackingName || trackingPreference.tracking_name,
          enabled: trackingPreference.enabled,
        }
      }),
      notificationSettings
    })
  }

  onSearch = (value) => {
    localStorage.setItem('teg-component', true);
    location.href="/trips?search=" + value;
  }

  submitManualTracking = (trackingDetails) => {
    const { dispatch } = this.props;
    dispatch({
      type: POST_MANUAL_TRACKING,
      data: trackingDetails
    })
  }

  getProviders = (search) => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_PROVIDERS,
      search
    })
  }

  submitTransporterDetails = (transporterDetails) => {
    const { dispatch, trip } = this.props;
    dispatch({
      type: POST_TRANSPORTER_DETAILS,
      data: transporterDetails
    })
    if (transporterDetails.vehicle_details) {
      const payload = {...transporterDetails.vehicle_details};
      payload.seeker_id = trip.seeker_id;
      dispatch({type: POST_VEHICLE_DATA, payload});
    }
  }

  componentDidCatch(e) {
    console.log('DID CATCH', e);
  }

  throwError = ({message}) => {
    const { dispatch } = this.props;
    dispatch({
      type: TOAST_ERROR,
      message
    })
  }

  cancelCloseTrip = (remarks) => {
    const { dispatch, trip, history } = this.props;
    dispatch({
      type: CANCEL_TRIP,
      data: {
        trip_id: trip.tripId,
        type: this.state.ccType,
        remarks
      },
      onSuccess: (data) => {
        this.hideOverlay();
        history.push('/trips');
      }
    });
  }

  handleTripCancellation = (ccType) => {
    const { isConfirm } = this.state;
    this.setState({
      isConfirm: !isConfirm,
      ccType
    })
  }

  hideOverlay = () => {
    this.setState({
      isConfirm: false,
      ccType: 0
    })
  }

  getNumbersOperator = (data) => {
    this.props.dispatch({
      type: GET_NUMBERS_OPERATOR_TYPE,
      params: data
    })
  }

  selectVehicleServiceType = (serviceType) => {
    const { dispatch } = this.props;
    const params = {
      seeker_id: seekerId,
      service_type: serviceType.id
    }
    dispatch({ type: GET_VEHICLE_DETAIL_OPTIONS, params });
  }

  onChangeVehicleDetails = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: GET_VEHICLE_DATA, params: data });
  }

  sendPreferenceToBanner = (preference) => { this.setState({ preference }); }

  getVehicleRemarks = (type) => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_VEHICLE_REMARKS,
      is_type_reject: type
    })
  }

  render() {
    const {
      trip, isTripLoaded, manualTracking, isTransporterDetailsSubmitting,
      providers, gpsVendors, tripData, vehicleTypes, vehicleBodyTypes,
      vehicleServiceTypes, operatorTypes, vehicleData
    } = this.props;
    let tripDetails = tripData.filter((trip) => { return (trip.trip_id == this.props.match.params.id || trip.tripId == this.props.match.params.id) });
    let tripStatusTypeText = truck_location_tab.find(val => { return trip.type == val.id });
    tripStatusTypeText = tripStatusTypeText ? ` (${tripStatusTypeText && tripStatusTypeText.value})` : '';
    return (
      <Sticky activeLink={'trips'} onSearch = {this.onSearch}>
        {trip && isTripLoaded && <section className="trip-detail-container pr-30 pl-10">
          <section className="trip-title-container flex flex-between mb-10">
            <div className="flex flex-middle flex-1">
              <div className="curP" onClick={()=>{history.back()}}>
                <BackIcon/>
              </div>
              <span className="theme-color fs-24 fBolder mr-30 ml-15">Trip - {trip.doNo}<span className={'fs-14'}>{tripStatusTypeText}</span></span>
              {/* <Status status={trip.status.text} background={trip.status.code}/> */}
              {/* {isSeeker && <span className="table-text-color fs-14 ml-20 flex flex-middle">Value:
                <span className="ht-25 bg-theme theme-color br-15 fBolder flex flex-middle pr-10 pl-10 ml-5">{trip.value}</span>
              </span>} */}
            </div>
            { [2].indexOf(trip.type) == -1 && <Link className="wt-200 mr-20" to = {'/tracking/' + trip.tripId}>
              <Button value="Track Trip"/>
            </Link>}

            {
              userCanEdit && isSeeker &&
              <div className="wt-200 mr-20">
                <Button value="Cancel Trip" onClick={() => this.handleTripCancellation(9)} />
              </div>
            }

            {
              userCanEdit &&
              <div className="wt-200">
                <Button value="Close Trip" onClick={() => this.handleTripCancellation(8)} />
              </div>
            }
          </section>
          <section className="flex flex-middle tag-text-color fs-12 mb-10 flex-wrap">
            {trip.isAutoCreated && <div className="pl-15 pr-15 flex flex-middle ht-25 br-15 mr-10 mb-5 orange-bg border-orange-1">Auto Created</div>}
            {trip.serviceType && <div className="pl-15 pr-15 flex flex-middle ht-25 br-15 mr-10 mb-5 border-gray-1">{trip.serviceType}</div>}
            {trip.boundType && <div className="pl-15 pr-15 flex flex-middle ht-25 br-15 mr-10 mb-5 border-gray-1">{trip.boundType}</div>}
            {trip.runType && <div className="pl-15 pr-15 flex flex-middle ht-25 br-15 mr-10 mb-5 border-gray-1">{trip.runType}</div>}
            {trip.modeType && <div className="pl-15 pr-15 flex flex-middle ht-25 br-15 mr-10 mb-5 border-gray-1">{trip.modeType}</div>}
          </section>
          <section className="flex flex-middle tag-text-color fs-12 mb-10 flex-wrap">
          {trip.closedBy &&
            <div className="pl-15 pr-15 flex flex-middle ht-25 br-15 mr-10 mb-5 orange-bg border-orange-1">
              <EsclamationIcon style={{ width: '15px', height: '15px' }} className="mr-5"/>
              <span>{`${trip.closedBy} : ${trip.closureRemarks}`}</span>
            </div>}
          </section>
            <section className="mb-40">
              <ResponsiveTable>
                  {isTripLoaded && trip && <TripBanner trip={trip} operatorTypes={operatorTypes} gpsVendors={gpsVendors} preference={this.state.preference} />}
              </ResponsiveTable>
            </section>
          {
            // (isSeeker) &&
            <TabbedSections
              sections = {[
                {title:'Route Details', component:<RouteDetails trip={trip} submitRouteDetails={this.submitTransporterDetails}/>},
                // {title:'Notification Settings', component:<TrackingSettings saveTrackingPreferences={this.saveTrackingPreferences} trip={trip} />},
                {title:'Shipment Details', component:<ShipmentDetails trip={trip} submitShipmentDetails={this.submitTransporterDetails}/>},
                // {title:'Detention Details', component:<DetentionDetails trip={trip} />},
                {
                  title: 'Manual Tracking',
                  component: <ManualTracking
                    tripId={trip.tripId}
                    submitManualTracking={this.submitManualTracking}
                    isDataSubmitting={manualTracking.dataSubmitting}
                    dataSubmittedSuccessfully={manualTracking.dataSubmittedSuccessfully}
                  />
                },
                {
                  title: 'Transporter Details',
                  component: <TransporterDetails
                    numbersOperatorData={this.props.numbersOperatorData}
                    throwError={this.throwError}
                    getNumbersOperator={this.getNumbersOperator}
                    submitTransporterDetails={this.submitTransporterDetails}
                    trip={trip}
                    sendPreferenceToBanner={this.sendPreferenceToBanner}
                    isDataSubmitting={isTransporterDetailsSubmitting}
                    providers= {providers.map((provider)=> {
                      return {
                        name: provider.name,
                        value: provider.name,
                        email: provider.email,
                        panNo: provider.pan_no,
                        id: provider.provider_id
                      }
                    })}
                    getProviders={this.getProviders}
                    isSeeker={isSeeker}
                    tripData={tripDetails[0]}
                    gpsVendorType={gpsVendors}
                    onChangeVehicleDetails={this.onChangeVehicleDetails}
                    selectVehicleServiceType={this.selectVehicleServiceType}
                    vehicleServiceTypes={vehicleServiceTypes}
                    vehicleTypes={vehicleTypes}
                    vehicleBodyTypes={vehicleBodyTypes}
                    vehicleData={vehicleData}
                    operatorTypes={operatorTypes}
                    getVehicleRemarks={this.getVehicleRemarks}
                    vehicleRemarks={this.props.vehicleRemarks}
                  />
                },
                // {title: 'GPS Vendors', component: <GPSVendors gpsVendors={gpsVendors} />}
                {title: 'Comments', component: <Comments trip={trip} submitComments={this.submitTransporterDetails}/>}
              ]}
            />
          }
          {
            // !isSeeker && <TabbedSections sections = {[
            // {title: 'Transporter Details', component: <TransporterDetails throwError={this.throwError} submitTransporterDetails={this.submitTransporterDetails} trip={trip} isDataSubmitting={isTransporterDetailsSubmitting} providers= {providers.map((provider)=> {
            //   return {
            //     name: provider.name,
            //     value: provider.name,
            //     email: provider.email,
            //     panNo: provider.pan_no,
            //     id: provider.provider_id
            //   }
            // })}
            // getProviders={this.getProviders}/>},
            // {title:'Route Details', component:<RouteDetails trip={trip} />},
            // {title:'Shipment Details', component:<ShipmentDetails trip={trip} />},
            // {title: 'Manual Tracking', component: <ManualTracking tripId={trip.tripId} submitManualTracking={this.submitManualTracking} isDataSubmitting={manualTracking.dataSubmitting} dataSubmittedSuccessfully={manualTracking.dataSubmittedSuccessfully}/>},
            // {title: 'Comments', component: <Comments trip={trip} submitComments={this.submitTransporterDetails}/>}
          // {title: 'GPS Vendors', component: <GPSVendors gpsVendors={gpsVendors} />}
          // ]}/>
          }
        </section>
      }
      {
        !isTripLoaded && <ActionLoader fixed={true}/>
      }
      {
        this.state.isConfirm &&
        <Overlay show={this.state.isConfirm} hideOverlay={this.hideOverlay} containerClass={'confirm-details'}>
          <Confirmation
            hideOverlay={this.hideOverlay}
            cancelCloseTrip={this.cancelCloseTrip}
            type={this.state.ccType}
          />
        </Overlay>
      }
      </Sticky>
    );
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch({ type: GET_TRIP_DETAIL, tripId: match.params.id });
    dispatch({ type: GET_GPS_VENDORS, seekerId: seekerId});
    dispatch({ type: GET_OPERATOR_TYPE });
    dispatch({ type: GET_VEHICLE_DETAIL_OPTIONS, params: {seeker_id: seekerId} });
  }
}

const mapStateToProps = ({trips}) => {
  return {
    trip: trips.tripDetail.data,
    isTripLoaded: trips.tripDetail.isDataLoaded,
    manualTracking: trips.manualTracking,
    isTransporterDetailsSubmitting: trips.transporterDetails.dataSubmitting,
    providers: trips.providers.data || [],
    gpsVendors: trips.gpsVendors,
    tripData : trips.trips.actualData,
    vehicleServiceTypes: trips.vehicleServiceTypes || [],
    vehicleTypes: trips.vehicleTypes,
    vehicleBodyTypes: trips.vehicleBodyTypes,
    vehicleData: trips.vehicleData || {},
    operatorTypes: trips.operatorTypes,
    numbersOperatorData: trips.numbersOperatorData,
    vehicleRemarks: trips.vehicleRemarks || []
  }
};


export default connect(mapStateToProps)(withRouter(TripDetail));
