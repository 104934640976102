import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Sticky from 'Components/Sticky';
import Tabs from 'Components/Tabs';
import SearchDropdown from 'Components/SearchDropdown';
import ActionLoader from 'Components/ActionLoader';
import { EAGLE_EYE_TIMER, SEEKER, trackingMode, TTBIL, AWL, PANTALOONS } from 'Constants/commonConstants';
import { MAP_BUTTONS, TRANSPORT_TYPE } from 'Constants/commonConstants';
import Dropdown from 'Components/Dropdown';

import TrackingMap from 'Components/TrackingMap';
import { getCompanyType, getUser } from 'Utils/storage';

import {
  GET_MULTIPLE_TRIPS,
  GET_TRANSPORTER_SEACH,
  GET_PLANT_SEACH,
  GET_ITEMS_SEACH,
  GET_TRIPS,
  GET_LOCATION_TYPE_FILTER,
  GET_SERVICE_PROVIDERS
} from 'Constants/actionConstants';

import { ReactComponent as Replay } from './icons/replay.svg';
import Select from 'react-select';

// const flattenDeep = (arr1)=> {
//     return arr1.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
//  }

class Analytics extends React.Component {
  constructor() {
    super();
    this.state = {
      serviceType: 0,
      boundType: null,
      limit: 10,
      offset: 0,
      transporterSearch: '',
      plantSearch: '',
      itemsSearch: '',
      search: '',
      activeStatusCode: '#3f51b5',
      activeStatusText: 'IN-TRANSIT',
      autoScrolling: true,
      isDirection: false,
      mapButtons: MAP_BUTTONS,
      directionData: [],
      dropdownOpen: false,
      subType: '',
      subTypeColor: '',
      trip_mode: TTBIL.includes(getUser().company_id) ? 2 : 1,
      zone: {},
      stateName: {},
      clusterName: {},
      showDestinationLocations: false,
      transportType: TRANSPORT_TYPE[0].id,
    }
  }
  timer = null;
  setTimer = (statusType)=> {
    if(this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(()=> {
      const { tripTypes } = this.props;
      const currentTypeIndex = (tripTypes || []).findIndex((tripType)=> {
        return tripType.type === statusType;
      })
      const nextStatusType = (currentTypeIndex >= tripTypes.length - 1)?tripTypes[0]:tripTypes[currentTypeIndex + 1];
      // this.getMultipleTrips(nextStatusType.type, nextStatusType.status);
    }, EAGLE_EYE_TIMER * 1000);
  }
  getMultipleTrips = (statusType=6, statusText="IN-TRANSIT", location_type_status, autoScrolling, subType='', subTypeColor='')=> {
    const { dispatch } = this.props;
    const { boundType, serviceType, transporterSearch, plantSearch, itemsSearch, trip_mode, locationType, transportType, zone, stateName, clusterName, provider_id } = this.state;
    console.log('provider_id:', provider_id)
    const isAdani = AWL.includes(getUser().company_id);
    this.setState({
        activeStatusText: statusText,
        statusType,
        autoScrolling,
        subType,
        subTypeColor,
        trip_mode,
        location_type_status
    }, ()=> {
        dispatch({
            type: GET_MULTIPLE_TRIPS,
            params: {
                boundType: isAdani ? boundType && Object.keys(boundType).length && boundType.id || '' : boundType > 0?boundType:"",
                serviceType: serviceType > 0?serviceType: "",
                transporterSearch,
                provider_id,
                plantSearch,
                itemsSearch,
                statusType,
                subType,
                trip_mode,
                clusters: clusterName && clusterName.name ? clusterName.name : '',
                zone: zone && zone.name ? zone.name : '',
                state: stateName && stateName.name ? stateName.name : '',
                location_type: locationType && Object.keys(locationType).length && locationType.id || '',
                transport_type: transportType || undefined,
                location_type_status
            }
        });
        // if(!autoScrolling) {
        //   this.setTimer(statusType);
        // }else {
        //   clearTimeout(this.timer);
        // }
    });
  }
  searchTransporters = (value)=> {
    this.setState({
      transporterSearch: value
    }, ()=> {
      if(!value) {
        this.getMultipleTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_TRANSPORTER_SEACH,
        transporterSearch: value
      })
    });
  }
  selectTransporter = (value)=> {
    this.setState({
      transporterSearch: value.name,
      provider_id: value.provider_id
    },this.getMultipleTrips);
  }

  selectTransporterOptions = (value)=> {
    console.log('value:', value)
    this.setState({
      // transporterSearch: value.name,
      provider_id: value.value
    },this.getMultipleTrips);
  }
  searchPlants = (value)=> {
    const { boundType, locationType, zone, stateName, provider_id } = this.state;
    const params = {
      boundType,
      locationType,
      zone,
      stateName,
      provider_id
    }
    this.setState({
      plantSearch: value
    }, ()=> {
      if(!value) {
        this.getMultipleTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_PLANT_SEACH,
        plantSearch: value,
        params,
        includeConsignees: 1,
        isAnalytics: true
      })
    });
  }
  selectPlant = (value)=> {
    this.setState({
      plantSearch: value
    },this.getMultipleTrips);
  }

  selectZone = (value) => {
    if (value) {
      this.setState({ stateName: {} });
    }
    this.setState({
      zone: value
    },this.getMultipleTrips)
  }

  selectCluster = (value) => {
    this.setState({
      clusterName: value
    },this.selectZone({}))
  }

  selectState = (value) => {
    this.setState({
      stateName: value
    },this.getMultipleTrips)
  }

  selectLocationType = (value = '') => {
    this.setState({
      locationType: value
    },this.getMultipleTrips);
  }
  searchItems = (value)=> {
    this.setState({
      itemsSearch: value
    }, ()=> {
      if(!value) {
        this.getMultipleTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_ITEMS_SEACH,
        itemsSearch: value
      })
    });
  }
  selectItem = (value)=> {
    this.setState({
      itemsSearch: value
    },this.getMultipleTrips);
  }
  selectServiceType = (serviceType)=> {
    this.setState({
      serviceType,
      boundType: serviceType===0?0:this.state.boundType
    }, this.getMultipleTrips);
  }

  selectTrackingMode = (trip_mode) => {
    this.setState({
      trip_mode,
      boundType: this.state.boundType
    }, this.getMultipleTrips);
  }

  selectBoundType = (boundType)=> {
    this.setState({
        boundType
      }, this.getMultipleTrips);
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }))
  }

  toggleCheckbox = () => {
    this.setState(prevState => ({
      showDestinationLocations: !prevState.showDestinationLocations
    }))
  }

  selectDirections = (e) => {
    let initialStatus = this.state.mapButtons;
    let tabVal = e.target.name.split('-');
    initialStatus.map((val) => {
      if(val.value == tabVal[0]){
        val.to[tabVal[2]].isChecked = !val.to[tabVal[2]].isChecked;
      }
    });
    this.setState({ mapButtons: initialStatus });
  }

  genericSearch = () => {

  }

  selectTransportType = (transportType) => {
    this.setState({
      transportType,
    }, this.getMultipleTrips);
  }

  fetchServiceProvider = () => {
    const { dispatch } = this.props;
    const { boundType } = this.state
    dispatch({ 
      type: GET_SERVICE_PROVIDERS,
      params: {
        'customer_id' : getUser().company_id,
        'bound_type' : boundType
      },
    });
  };

  render() {
    const {
      tripsCount,
      ftlCount,
      lclCount,
      courierCount,
      containerCount,
      transporterSearchItems,
      plantSearchItems,
      itemsSearchItems,
      tripTypes,
      inboundCount,
      outboundCount,
      isMultipleTripsLoaded,
      locationTypeFilter,
      source_data,
      destination_data,
      serviceProviders
    } = this.props;
    const multipleTrips = this.state.isDirection ? this.state.directionData : this.props.multipleTrips;
    const companyType = getCompanyType();
    const hasFilter = locationTypeFilter && Object.keys(locationTypeFilter).length > 0;
      return (
      <Sticky activeLink={'analytics'} onSearch={this.genericSearch} initialSearchValue={''} isSearch={false}>
        <div className="p5 pr-30">
          <section className="pl-15 mb-20 flex flex-between">
          {companyType === SEEKER && <Fragment>
            <div className="flex-1 mr-20">
              <Tabs
                tabs={trackingMode}
                activeTabId={this.state.trip_mode}
                selectTab = {this.selectTrackingMode}
                isAlternateName= {TTBIL.includes(getUser().company_id)}
              />
            </div>
            {!AWL.includes(getUser().company_id) &&
            <div className="flex-1 mr-20">
              <Tabs tabs={[
                {
                  id:1,
                  value: 'Inbound',
                  count: inboundCount
                }, {
                  id:2,
                  value: 'Outbound',
                  count: outboundCount
                }
              ]}
              activeTabId= {this.state.boundType}
              selectTab = {this.selectBoundType}
              />
            </div>}
          </Fragment>}
          {!AWL.includes(getUser().company_id) &&
            <div className="flex-1">
              <Tabs
                tabs={TRANSPORT_TYPE}
                activeTabId={this.state.transportType}
                selectTab = {this.selectTransportType}
              />
            </div>}
          </section>
          <section className="flex-1 mr-20 ht-33 mb-10">
            <div className="flex">
            { companyType === SEEKER && !PANTALOONS.includes(getUser().company_id) &&
              <SearchDropdown
                  onChange={this.searchTransporters}
                  containerClassName="flex-1 mt-25 mr-20 ht-33"
                  items={transporterSearchItems}
                  value={this.state.transporterSearch}
                  label={'Select Transporters'}
                  onSelect={this.selectTransporter}
                  hasClearFilter={() => this.searchTransporters('')}
              />}
              {companyType === SEEKER && PANTALOONS.includes(getUser().company_id) &&
                <Select
                  options={serviceProviders ? serviceProviders.map((val) => {
                      return { label: val.vendor_name, value: val.provider_id}
                  }): []}
                  className={'wt-250 flex-3 mt-25 mr-20 ht-33'}
                  placeholder={'Select Transporter'}
                  onChange={this.selectTransporterOptions}
                  // onInputChange={this.searchPlants}
                />}
              
              {AWL.includes(getUser().company_id) &&
              <div className="wt-250 pl-15 mt-25 mr-20">
                <Dropdown
                  onSelect={this.selectBoundType}
                  customClass={'col-1'}
                  items={[{
                    id:1,
                    value: 'Inbound',
                    count: inboundCount
                  }, {
                    id:2,
                    value: 'Outbound',
                    count: outboundCount
                  }]}
                  value={this.state.boundType}
                  label={'— Select Bound Type — '}
                  selectedClassName={'col-1'}
                  hasClearFilter={() => this.selectBoundType({})}
                />
              </div>}
              {/* {AWL.includes(getUser().company_id) ? 
              <Fragment>
                <div className="wt-250 pl-15 mt-25 mr-20">
                <Dropdown
                  onSelect={this.selectCluster}
                  customClass={'col-1'}
                  items={hasFilter && locationTypeFilter.clusters ? (locationTypeFilter.clusters || []).map((cluster, index) => { return { id: index+1, name: cluster } }) : [] }
                  value={this.state.clusterName}
                  label={'— Select Cluster — '}
                  selectedClassName={'col-1'}
                  hasClearFilter={() => this.selectCluster({})}
                />
              </div>
              {Object.keys(this.state.clusterName).length > 0 &&
              <div className="wt-250 pl-15 mt-25 mr-20">
                <Dropdown
                  onSelect={this.selectZone}
                  customClass={'col-1'}
                  items={hasFilter && locationTypeFilter.cluster_mapping ? ((this.state.clusterName.name === 'Cluster1' ? locationTypeFilter.cluster_mapping.Cluster1 : locationTypeFilter.cluster_mapping.Cluster2) || []).map((cluster, index) => { return { id: index+1, name: cluster } }) : [] }
                  value={this.state.zone}
                  label={'— Select Zone — '}
                  selectedClassName={'col-1'}
                  hasClearFilter={() => this.selectZone({})}
                />
              </div>}
              </Fragment>: <div></div>} */}
               
              <div className="wt-250 pl-15 mt-25 mr-20">
                <Dropdown
                  onSelect={this.selectZone}
                  customClass={'col-1'}
                  items={hasFilter && locationTypeFilter.zones ? (locationTypeFilter.zones || []).map((zone, index) => { return { id: index+1, name: zone } }) : [] }
                  value={this.state.zone}
                  label={'— Select Zone — '}
                  selectedClassName={'col-1'}
                  hasClearFilter={() => this.selectZone({})}
                />
              </div>
              {
                Object.keys(this.state.zone).length > 0 ?
                <div className="wt-250 pl-15 mt-25 mr-20">
                  <Dropdown
                    onSelect={this.selectState}
                    customClass={'col-1'}
                    items={hasFilter && Object.keys(locationTypeFilter.zone_mapping || {}).length > 0 && locationTypeFilter.zone_mapping[this.state.zone.name] ? (locationTypeFilter.zone_mapping[this.state.zone.name] || []).map((st, index) => { return { id: index+1, name: st } }) : []}
                    value={this.state.stateName}
                    label={'— Select State — '}
                    selectedClassName={'col-1'}
                    hasClearFilter={() => this.selectState({})}
                  />
                </div>
                :
                <div className="wt-250 pl-15 mt-25 mr-20">
                  <Dropdown
                    onSelect={this.selectState}
                    customClass={'col-1'}
                    items={hasFilter && (locationTypeFilter.states || []).length > 0 ? (locationTypeFilter.states || []).map((st, index) => { return { id: index+1, name: st } }) : []}
                    value={this.state.stateName}
                    label={'— Select State — '}
                    selectedClassName={'col-1'}
                    hasClearFilter={() => this.selectState({})}
                  />
                </div>
              }
              <div className="wt-250 pl-15 mt-25 mr-20">
                <Dropdown
                  onSelect={this.selectLocationType}
                  customClass={'col-1'}
                  items={hasFilter && locationTypeFilter.location_types || []}
                  value={this.state.locationType}
                  label={'— Select Location Type — '}
                  selectedClassName={'col-1'}
                  hasClearFilter={() => this.selectLocationType({})}
                />
              </div>
              {
              // getUser().branch_type === 20 && 
              companyType === SEEKER &&
                  <SearchDropdown
                  onChange={this.searchPlants}
                  containerClassName="flex-1 mt-25 mr-20 ht-33"
                  items={plantSearchItems}
                  label={'Plant/Depot/Consignee'}
                  isList={true}
                  displayKey={'city'}
                  value={this.state.plantSearch}
                  onSelect={this.selectPlant}
                  hasClearFilter={() => this.searchPlants('')}
              />}
            </div>
          </section>
          <div className="pr">
            <header className="flex mb-30 pl-15 fs-18 theme-color fBolder col-1">
              <div className='wt-90'>
               <span className="pr-10">Now Showing - {this.state.activeStatusText }</span>
              {/* {this.state.autoScrolling && <span onClick={()=>{this.getMultipleTrips(this.state.statusType, this.state.activeStatusText, false)}}><Replay /></span>} */}
              </div>
            </header>
            <section className="pl-15 mb-50">
                <div  className="flex-1">
                    <div className="map-container-absolute">
                      <TrackingMap
                        colorCode={this.state.activeStatusCode || '#3f51b5'}
                        trips={multipleTrips}
                        source_data={source_data}
                        destination_data={destination_data}
                        getMultipleTrips={this.getMultipleTrips}
                        onCheckEvent={this.toggleCheckbox}
                        showDestinationLocations={this.state.showDestinationLocations}
                        statuses={tripTypes}
                        activeStatus={this.state.activeStatusText}
                        subTypeColor={this.state.subTypeColor}
                        locationTypeFilter={locationTypeFilter}
                        selectLocationType={this.selectLocationType}
                        />
                    </div>
                </div>
            </section>
            {!isMultipleTripsLoaded && <ActionLoader fixed={true}/>}
          </div>
        </div>
      </Sticky>
    );
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({
      type: GET_LOCATION_TYPE_FILTER,
      onSuccess: ((data) => {
        const zones = data && Object.keys(data).length > 0 && data.zones ? (data.zones || []).map((zone, index) => { return { id: index+1, name: zone } }) : [];
        if (zones.length === 1) {
          this.setState({
            zone: zones[0]
          })
        }
      })
    })
    this.getMultipleTrips(6);
    this.fetchServiceProvider();
  }
}

const mapStateToProps = ({trips}) => {
  return {
    trips: trips.trips.data,
    isTripsLoaded: trips.trips.isDataLoaded,
    containerCount : trips.trips.containerCount,
    courierCount :trips.trips.courierCount,
    ftlCount : trips.trips.ftlCount,
    lclCount : trips.trips.lclCount,
    tripsCount : trips.trips.tripsCount,
    inboundCount : trips.trips.inboundCount,
    outboundCount : trips.trips.outboundCount,
    filterCount: trips.trips.filterCount,
    transporterSearchItems: trips.trips.transporterSearch || [],
    plantSearchItems: trips.trips.plantSearch || [],
    itemsSearchItems: trips.trips.itemsSearch || [],
    serviceProviders: trips.serviceProviders || [],
    multipleTrips: trips.multipleTrips.data || [],
    source_data: trips.multipleTrips.source_data || [],
    destination_data: trips.multipleTrips.destination_data || [],
    tripTypes: trips.multipleTrips.types || [],
    isMultipleTripsLoaded: trips.multipleTrips.isDataLoaded,
    locationTypeFilter: trips.locationTypeFilter || {}
  }
};


export default connect(
  mapStateToProps
)(Analytics);
