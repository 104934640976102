import React, { Component, Fragment } from 'react';

import Status from 'Components/Status';
import { Link } from 'react-router-dom';
import ConsigneeDetailTooltip from 'Components/TripRow/ConsigneeDetailsTooltip';
import DriverTooltip from 'Components/TripRow/DriverTooltip';
import TransporterDetails from 'Components/TransporterDetails';
import ActionLoader from 'Components/ActionLoader';
import Button from 'Components/Button';

import { getCompanyType, canUserEdit } from 'Utils/storage';
import { SEEKER, PROVIDER, truck_location_tab } from 'Constants/commonConstants';
import classNames from 'classnames';
import { getTrackingType, getTrackingTypeText } from 'Utils/common';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as EsclamationIcon } from './icons/esclamation.svg';
import { ReactComponent as Refresh } from './icons/refresh.svg';
import { SingleDatePicker } from 'react-dates';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import Moment from 'moment';
import Dropdown from 'Components/Dropdown';
import Input from 'Components/Input';

const statusTypes = [...truck_location_tab];

const userCanEdit = canUserEdit();


class TripRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTransporterDetailsShown: false,
            isConfirmPopup: false,
            gateTime: new Date().getTime()
        };
    }
    toggleTransporterDetails = ()=> {
        this.setState({
            isTransporterDetailsShown : !this.state.isTransporterDetailsShown
        })
    }

    onConfirmAtOrigin = (e, trip_id) => {
        e.preventDefault();
        const data = {
            trip_id,
            type: 4,
            sub_type: 41,
            timestamp: this.state.gateTime,
        }
        this.setState({ isConfirmPopup: false });
        this.props.confirmAtOrigin(data);
    }

    onConfirmAtGateOut = (e, trip_id) => {
        e.preventDefault();
        const data = {
            trip_id,
            type: 6,
            sub_type: 61,
            timestamp: this.state.gateTime,
        }
        this.setState({ isConfirmPopup: false });
        this.props.confirmAtOrigin(data);
    }

    showlogDetails = (e, tripId, trackingType) => {
        e.preventDefault();
        const trackingTypeIndex = trackingType.findIndex(val => val.enabled);
        this.props.showlogDetails(tripId, trackingTypeIndex + 1);
    }

    sendPreferenceToBanner = (preference) => { this.setState({ preference }); }

    onRefresh = (e, trip) => {
        e.preventDefault();
        const data = {
            trip_id: trip.tripId,
            driver_details: trip.driverDetails && trip.driverDetails.length > 0 ? trip.driverDetails.map(driver => {
                return {
                    consent_status: driver.consentStatus || '',
                    name: driver.name || '',
                    first_name: driver.firstName || '',
                    last_name: driver.lastName || '',
                    operator_type: driver.operatorType || 0,
                    operator_name: driver.operatorName || '',
                    number: driver.number || '',
                    from: driver.from || {},
                    to: driver.to || {},
                    is_tracked: driver.is_tracked || false,
                }
            }) : [],
            tracking_details: {
                tracking_preference : trip.trackingPreferences
            }
        }
        this.props.submitTransporterDetails(data);
    }

    sortByBool = (items) => {
        const sortedList = items.sort((a,b) => { return (b.isTracked - a.isTracked) });
        return sortedList;
    }

    selectReason = (reason, type) => {
        if(type === '0'){
            this.setState({rejectReason: reason})
        } else {
            this.setState({acceptReason: reason})
        }
        
    }

    onRejectTrip = (e,trip,type) => {
        e.preventDefault();
        const data = {
            trip_id: trip.tripId,
            type: type === 'reject' ? 11 : 3,
            sub_type: 0,
            remarks: type === 'reject' ? ((this.state.rejectReason || {}).value) : ((this.state.acceptReason || {}).value)
        }
        if(this.state.rejectReason && this.state.rejectReason.value === "Others"){
            data.remarks = this.state.otherReason;
        }
        this.props.rejectVehicle(data);
        if(type === 'reject'){
            this.setState({isRejectPopup: false})
        } else {
            this.setState({isAcceptPopup: false})
        }
    }

    render() {
        const companyType = getCompanyType();
        const { trip, isTransporterDetailsSubmitting, truck_status, tripData, gpsVendorType, operatorTypes, searchTerm } = this.props;
        const defaultDriverDetails = {
            name: '-',
            number: '-',
            'consentStatus': 'n/a'
        };
        const sortedList = this.sortByBool([...trip.driverDetails]);
        let trackingPreference = 0;
        trip.trackingPreferences && trip.trackingPreferences.map(preference => {
            if (preference.enabled) {
                trackingPreference = +preference.tracking_type;
                return;
            }
        });
        const trackingTypeText = getTrackingTypeText(trip.trackingPreferences);
        const driverDetails = (trip.driverDetails && trip.driverDetails.length > 0) ? sortedList[0] : defaultDriverDetails;
        // const isShowConfirmatGate = isSimTracking ? isConsentAllowed : true ;
        const isSimTracking = trackingPreference === 2;
        let isConsentAllowed = false;
        const isGpsTracking = trackingPreference === 3;
        let vendorName = 'n/a';
        let defaultErrorMessage = '';
        if (isSimTracking) {
            defaultErrorMessage = 'Tracking consent not received';
            if (driverDetails.operatorName) {
                vendorName = driverDetails.operatorName;
            } else if (driverDetails.operatorType) {
                operatorTypes.map(operator => {
                    if (operator.id === driverDetails.operatorType) {
                        vendorName = operator.name;
                        return;
                    }
                })
            } else {
                vendorName = 'Not Added';
            }
        } else if (isGpsTracking) {
            trip.gpsDetails && trip.gpsDetails.id && gpsVendorType.map(gps => {
                if (gps.id === trip.gpsDetails.id) {
                    vendorName = gps.name;
                    return;
                }
            })
        }
        let error_message = driverDetails.message;
        if (driverDetails.consentStatus !== 'allowed') {
            error_message = error_message || defaultErrorMessage;
        } else {
            isConsentAllowed = true;
            if (trip.type !== 7) {
                error_message = '';
            }
        }
        const toolTipId = `error-tool-${trip.tripId}`;
        let vehicleRemarksList = this.props.vehicleRemarks.map((remarks) => ({
                value: remarks.remark_name,
                label: remarks.remark_type
            }))
        return (
            <Fragment>
                {companyType === SEEKER && <Link to={'/trip/' + trip.tripId} className="pr flex pt-15 pb-15 flex-middle trip-row br-5">
                    <div className="wt-150 pl-15">
                        <div className="fBolder fs-16 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>{trip.doNo}</div>
                        {trip.poNo && <div className="fBolder fs-10 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>PO No: {trip.poNo}</div>}
                        <div className="fBolder fs-10 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>LR No: {trip.lrNo}</div>
                        <div className="fBolder fs-10 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>Invoice No: {trip.invoiceNo}</div>
                        <div className="fBolder fs-10 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>Item(s): {trip.itemName}</div>
                        {/* { (trip.type == 5 || trip.type == 6 || trip.type == 8) && <Status status={trip.statusText} background= {trip.statusCode}/>} */}
                    </div>
                    <div className="wt-150 pl-15">
                        <div className="fs-16 table-text-color mb-5">{trip.routeDetails.source}-{trip.routeDetails.destination}</div>
                        <div className="fs-12 table-text-color">{trip.routeDetails.via ? `via: ${trip.routeDetails.via}`:''}</div>
                        {
                            trip.customerName &&
                            <div className="fs-12 table-text-color">{`Customer: ${trip.customerName}`}</div>
                        }
                        <section className="flex flex-middle tag-text-color fs-10 flex-wrap">
                          {trip.isAutoCreated && <div className="pl-10 pr-10 flex flex-middle ht-20 br-15 mr-10 mb-5 orange-bg border-orange-1">Auto Created</div>}
                        </section>
                        <section className="flex flex-middle tag-text-color fs-10 flex-wrap">
                            {
                                trip &&
                                <div className="pl-10 pr-10 flex flex-middle ht-20 br-15 mr-10 mb-5 bg-theme theme-color border-theme">
                                    {
                                        statusTypes.map((obj)=> {
                                            if (obj.id == trip.type) {
                                                return obj.value;
                                            }
                                        })
                                    }
                                </div>
                            }
                        </section>
                        {
                            trip.routeDetails && trip.routeDetails.inBetween &&
                            <section className="flex flex-middle tag-text-color fs-10 flex-wrap">
                                <span className={'fs-12 fBolder'}>Between: </span>
                                {trip.routeDetails.inBetween}
                            </section>
                        }
                        {
                            trip.routeDetails && trip.routeDetails.currentlyAt &&
                            <section className="flex flex-middle tag-text-color fs-10 flex-wrap">
                                <span className={'fs-12 fBolder'}>Currently at: </span>
                                {trip.routeDetails.currentlyAt}
                            </section>
                        }
                    </div>
                    {
                        companyType === SEEKER &&
                        <div className="wt-150 pl-15">
                            <div className="fs-16 table-text-color mb-5">{trip.transporterDetails.transporterName}</div>
                            <div className="fs-12 table-text-color">{trip.transporterDetails.serviceType}</div>
                        </div>
                    }
                    <div className="wt-250 pl-15">
                        {trip.dateTat.createdOn && <div className="fs-12 table-text-color mb-5"><span className={'fs-12 fBolder'}>{'Created On: '}</span>{trip.dateTat.createdOn.format('DD MMM, YYYY HH:mm')}</div>}
                        {trip.dateTat.startedDate && <div className="fs-12 table-text-color mb-5"><span className={'fs-12 fBolder'}>{'Start Date: '}</span>{trip.dateTat.startedDate ? trip.dateTat.startedDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                        {trip.dateTat.timeVehiclePlaced && 
                        //trip.type === 3 && 
                        <div className="fs-12 table-text-color mb-5"><span span className={'fs-12 fBolder'}>{'Vehicle Placed: '}</span>{trip.dateTat.timeVehiclePlaced ? trip.dateTat.timeVehiclePlaced.format('DD MMM, YYYY HH:mm') : ''}</div>}
                        {trip.dateTat.gateInDate && <div className="fs-12 table-text-color mb-5"><span className={'fs-12 fBolder'}>{'Gate In: '}</span>{trip.dateTat.gateInDate ? trip.dateTat.gateInDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                        {trip.dateTat.gateOutDate && <div className="fs-12 table-text-color mb-5"><span span className={'fs-12 fBolder'}>{'Gate Out: '}</span>{trip.dateTat.gateOutDate ? trip.dateTat.gateOutDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                        {trip.dateTat.completedOn && <div className="fs-12 table-text-color mb-5"><span span className={'fs-12 fBolder'}>{'Completed On: '}</span>{trip.dateTat.completedOn ? trip.dateTat.completedOn.format('DD MMM, YYYY HH:mm') : ''}</div>}
                        <div className="fs-12 table-text-color"><span span className={'fs-12 fBolder'}>{'TAT: '}</span>{`${trip.dateTat.tat ? `${trip.dateTat.tat} Days` : ''}`}</div>
                    </div>
                    {
                        companyType === SEEKER && <div className="wt-150 pl-15 pr driver-details">
                            <div className="fs-16 table-text-color mb-5">
                                {(driverDetails.firstName || driverDetails.lastName) ? `${driverDetails.firstName} ${driverDetails.lastName}` : driverDetails.name}
                            </div>
                            <div className="fs-12 table-text-color">
                                {driverDetails.number}
                                <div>
                                    {trackingTypeText}
                                    <div className={classNames('consent-status', {
                                        'bg-orange': driverDetails.consentStatus == 'pending',
                                        'bg-green': driverDetails.consentStatus == 'allowed',
                                        'bgc-error': driverDetails.consentStatus == 'not_added',
                                        'bg-check-consent-error': driverDetails.consentStatus == 'consent_check_error'
                                    })}>
                                    </div>
                                    {vendorName}
                                    {
                                        userCanEdit && [2, 8, 9].indexOf(trip.type) === -1 && isSimTracking && !isConsentAllowed &&
                                        <Refresh
                                            className={'ml-5 mt-5'}
                                            style={{ width: '15px', height: '15px', fill: '#3f51b5' }}
                                            onClick={(e) => this.onRefresh(e, trip)}
                                        />
                                    }
                                </div>
                            </div>
                            {
                                driverDetails.userResponse &&
                                <div className="fs-12 table-text-color">
                                    IVR/SMS Response: <span className="fBolder">{driverDetails.userResponse}</span>
                                </div>
                            }
                            {
                                driverDetails.responseTime &&
                                <div className="fs-12 table-text-color mb-5 fBolder">
                                    {driverDetails.responseTime}
                                </div>
                            }
                            <div className="driver-tooltip pa t-5 l-5">
                                <DriverTooltip
                                    driverDetails={trip.driverDetails}
                                    trackingPreferences={trip.trackingPreferences}
                                    gpsVendorType={gpsVendorType}
                                    operatorTypes={operatorTypes}
                                    trackingPreference={trackingPreference}
                                />
                            </div>
                        </div>
                    }
                    {
                        companyType === SEEKER &&
                        <div className="wt-150 pl-15">
                            <div className="fs-16 table-text-color mb-5">{trip.vehicleDetails.vehicleNo}</div>
                            {
                                trip.vehicleDetails.loadingCapacity &&
                                <div className="fs-12 table-text-color">
                                    {`${trip.vehicleDetails.vehicleServiceType}: ${trip.vehicleDetails.loadingCapacity}L`}
                                </div>
                            }
                            <div className="fs-12 table-text-color">{trip.vehicleDetails.isoNo}</div>
                            <div className="fs-12 table-text-color">{trip.vehicleDetails.truckType}</div>
                            <div className="fs-12 table-text-color">{trip.vehicleDetails.bodyType}</div>
                        </div>
                    }
                    {
                        /* <div className="wt-150 pl-15">
                            <div className="fs-16 table-text-color mb-5">{(trip.consigneeDetails && trip.consigneeDetails.length > 0)?trip.consigneeDetails[0].name:'-'}</div>
                            <div className="fs-12 table-text-color">{(trip.consigneeDetails && trip.consigneeDetails.length > 0)?trip.consigneeDetails[0].number:'-'}</div>
                            <div className="consignee-tooltip pa t-5 l-5">
                                <ConsigneeDetailTooltip consigneeDetails={trip.consigneeDetails}/>
                            </div>
                        </div> */
                    }
                    
                                
                   {
                        trip.isTracked && companyType === SEEKER && ([2, 3, 4, 7].indexOf(trip.type) == -1) &&
                        <div className={'wt-150 pl-50'}>
                            {[3, 4, 5, 6, 7, 8].indexOf(trip.type) > -1 &&
                            <div className="r-10 bg-theme border-theme br-5 wt-70 ht-25 tCenter">
                                <Link to={'/tracking/' + trip.tripId} className="fs-13 fBolder theme-color">Track</Link>
                            </div>}
                            {[5, 6, 8, 9].indexOf(trip.type) > -1 &&
                                <div className="r-10 bg-theme border-theme br-5 wt-70 ht-25 tCenter mt-5" onClick={(e) => this.showlogDetails(e, trip.tripId, trip.trackingPreferences)}>
                                    <span className="fs-13 fBolder theme-color confirm-button">{'Logs'}</span>
                                </div>
                            }
                            {(error_message && [6].indexOf(trip.type) > -1) &&
                            <div className={'error-color wt-150 mt-10'}>
                                {error_message}
                            </div>
                            }
                        </div>
                    }

                    <div>
                        {
                            (error_message && [3, 4, 7].indexOf(trip.type) > -1) &&
                            <div className={'error-color ml-50 wt-150'}>
                                {error_message}
                            </div>
                        }
                        {
                            /* companyType === SEEKER &&
                            ((trip.type === 3 && !trip.confirmGateIn) || trip.type === 7) &&
                            <React.Fragment>
                                <a data-tip data-for={toolTipId} className={'ml-50'}><EsclamationIcon style={{ width: '25px', height: '21px' }}/></a>
                                <ReactTooltip id={toolTipId} type="info" effect="solid" border={true}>
                                    {error_message}
                                </ReactTooltip>
                            </React.Fragment>*/
                        }

                        {
                            companyType === SEEKER && trip.type == 4 && trip.confirmGateOut &&
                            <Fragment>
                                <div className="trip-track pa r-20 bg-theme border-theme br-5 wt-130 flex flex-center flex-middle ht-25" onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: true }) }}>
                                    <span className="fs-13 fBolder theme-color confirm-button">{'Confirm Gate Out'}</span>
                                </div>
                                {
                                    this.state.isConfirmPopup &&
                                    <div className="overlay" onClick={(e) => {e.preventDefault()}}>
                                        <div className="overlay-container change-vehicle-popup">
                                            <span className="overlay-close-button fs-20" onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: false }) }}> X </span>
                                            <div className="overlay-body">
                                                <header className="fs-14 theme-color fBolder mb-20 tCenter">
                                                    Are you sure you want to Gate Out?
                                                </header>
                                                <div className="flex flex-center gateOutDateTime mb-80">
                                                    <div className="mb-20 mr-10">
                                                        <div className="fs-12 communication-color fBold mb-5">Select Date </div>
                                                        <SingleDatePicker
                                                            noBorder={true}
                                                            displayFormat={"Do, MMM YYYY"}
                                                            // isOutsideRange={(day) => day.isBefore(Moment(trip.dateTat.gateInDate || trip.dateTat.startedDate).subtract(1, 'day'))}
                                                            isOutsideRange={(day) => day.isBefore(Moment(new Date()).subtract(10, 'day'))}
                                                            hideKeyboardShortcutsPanel={true}
                                                            placeholder={"Date"}
                                                            numberOfMonths={1}
                                                            date={Moment(this.state.gateTime)}
                                                            onDateChange={(date) => {
                                                                // e.preventDefault();
                                                                this.setState({
                                                                    gateTime: new Date(Moment(date).format('YYYY-MM-DD') + ' ' + Moment(this.state.gateTime).format('HH:mm')).getTime()
                                                                })
                                                            }}
                                                            focused={this.state.focusedStart}
                                                            onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                            id="gateTime"
                                                        />
                                                    </div>
                                                    <div className="mb-20 ml-10 pick-time">
                                                        <div className="fs-12 communication-color fBold mb-5">Select Time</div>
                                                        <TimePicker
                                                            showSecond={false}
                                                            value={Moment(this.state.gateTime)}
                                                            className="ht-40 wt-280"
                                                            onChange={(time) => {
                                                                const { gateTime } = this.state;
                                                                this.setState({
                                                                    gateTime: new Date(Moment(gateTime).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                                });
                                                            }}
                                                            format={'h:mm a'}
                                                            use12Hours
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tCenter">
                                                    <div className="wt-200 inBlock mr-30"><Button value={'Confirm'} onClick={(e) =>  { e.preventDefault(); this.onConfirmAtGateOut(e, trip.tripId) }}>Confirm</Button></div>
                                                    <div className="wt-200 inBlock"><Button value={'Cancel'} onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: false, gateTime: new Date().getTime() }) }}>Cancel</Button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        }
                        {
                            companyType === SEEKER && trip.type == 3 && trip.confirmGateIn &&
                            <Fragment>
                                <div
                                    className="trip-track pa r-90 bg-theme border-theme br-5 wt-80 flex flex-center flex-middle ht-30 tCenter"
                                    onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: true }) }}
                                >
                                    <span className="fs-13 fBolder theme-color confirm-button">{'Confirm at Gate'}</span>
                                </div>
                                {
                                    this.state.isConfirmPopup &&
                                    <div className="overlay" onClick={(e) => {e.preventDefault()}}>
                                        <div className="overlay-container change-vehicle-popup">
                                            <span className="overlay-close-button fs-20" onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: false }) }}>
                                                X
                                            </span>
                                            <div className="overlay-body">
                                                <header className="fs-14 theme-color fBolder mb-20 tCenter">
                                                    Are you sure you want to perform Gate-In?
                                                </header>
                                                <div className="flex flex-center gateOutDateTime mb-80">
                                                    <div className="mb-20 mr-10">
                                                        <div className="fs-12 communication-color fBold mb-5">Select Date</div>
                                                        <SingleDatePicker
                                                            noBorder={true}
                                                            displayFormat={"Do, MMM YYYY"}
                                                            // isOutsideRange={(day) => day.isBefore(Moment(trip.dateTat.startedDate).subtract(2, 'day'))}
                                                            isOutsideRange={(day) => day.isBefore(Moment(new Date()).subtract(10, 'day'))}
                                                            hideKeyboardShortcutsPanel={true}
                                                            placeholder={"Date"}
                                                            numberOfMonths={1}
                                                            date={Moment(this.state.gateTime)}
                                                            onDateChange={(date) => {
                                                                this.setState({
                                                                    gateTime: new Date(Moment(date).format('YYYY-MM-DD') + ' ' + Moment(this.state.gateTime).format('HH:mm')).getTime()
                                                                })
                                                            }}
                                                            focused={this.state.focusedStart}
                                                            onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                            className="ht-40 wt-200"
                                                            id="gateTime"
                                                        />
                                                    </div>
                                                    <div className="mb-20 ml-10 pick-time">
                                                        <div className="fs-12 communication-color fBold mb-5">Select Time</div>
                                                        <TimePicker
                                                            showSecond={false}
                                                            value={Moment(this.state.gateTime)}
                                                            className="ht-40 wt-280"
                                                            onChange={(time) => {
                                                                const { gateTime } = this.state;
                                                                this.setState({
                                                                    gateTime: new Date(Moment(gateTime).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                                });
                                                            }}
                                                            format={'h:mm a'}
                                                            use12Hours
                                                        />
                                                    </div>
                                                    
                                                </div>
                                                <div className="tCenter">
                                                    <div className="wt-200 inBlock mr-30"><Button value={'Confirm'} onClick={(e) =>  { e.preventDefault(); this.onConfirmAtOrigin(e, trip.tripId) }}>Confirm</Button></div>
                                                    <div className="wt-200 inBlock"><Button value={'Cancel'} onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: false, gateTime: new Date().getTime() }) }}>Cancel</Button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        }
                        {
                        companyType === SEEKER && [3,4].includes(trip.type) && 
                        <Fragment>
                            <div
                                className={trip.type === 3 ? "trip-track pa r-2 bg-theme border-theme br-5 wt-80 flex flex-center flex-middle ht-30" : "trip-track pa mt-30 r-20 bg-theme border-theme br-5 wt-130 flex flex-center flex-middle ht-25"}
                                onClick={(e) => { e.preventDefault(); this.setState({ isRejectPopup: true },this.props.getVehicleRemarks("1")) }}
                            >
                                <span className="fs-13 fBolder theme-color confirm-button">{'Reject Trip'}</span>
                            </div>
                            {
                                this.state.isRejectPopup &&
                                <div className="overlay" onClick={(e) => {e.preventDefault()}}>
                                    <div className="overlay-container change-vehicle-popup">
                                        <span className="overlay-close-button fs-20" onClick={(e) => { e.preventDefault(); this.setState({ isRejectPopup: false }) }}>
                                            X
                                        </span>
                                        <div className="overlay-body" style={{height: '500px'}}>
                                            <header className="fs-14 theme-color fBolder mb-20 tCenter">
                                                Are you sure you want to Reject this Trip?
                                            </header>
                                        <div className={"flex"}>
                                        <div className={'mt-10 ml-25 wt-300'}>
                                            <label>Reason</label>
                                            <Dropdown
                                                onSelect={(value) =>  { this.selectReason(value, "0") }}
                                                customClass="flex-1 mr-20"
                                                items={vehicleRemarksList}
                                                value={this.state.rejectReason}
                                                label={'Reject Reason'}
                                                selectedClassName={'wt-300'}
                                            />
                                        </div>
                                        {this.state.rejectReason && this.state.rejectReason.value === 'Others' &&
                                        <div className={'mt-10 ml-25 wt-300'}>
                                        <label>Other Reason</label>
                                            <Input containerClassName="mb-20" name="other" type="text" value={this.state.otherReason} placeholder="Other Reason" onChange={(e) => {this.setState({otherReason: e.target.value})}}/>
                                        </div>}
                                        </div>
                                        <div className="tCenter" style={{marginTop: '200px'}}>
                                            <div className="wt-200 inBlock mr-30"><Button value={'Confirm'} onClick={(e) =>  { e.preventDefault(); this.onRejectTrip(e,trip,'reject') }}>Confirm</Button></div>
                                            <div className="wt-200 inBlock"><Button value={'Cancel'} onClick={(e) => { e.preventDefault(); this.setState({ isRejectPopup: false }) }}>Cancel</Button></div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                }
                            </Fragment>
                        }
                        {
                        companyType === SEEKER && [11].includes(trip.type) && 
                        <Fragment>
                            <div
                                className={"trip-track pa r-20 bg-theme border-theme br-5 wt-130 flex flex-center flex-middle ht-25"}
                                onClick={(e) => { e.preventDefault(); this.setState({ isAcceptPopup: true },this.props.getVehicleRemarks("0")) }}
                            >
                                <span className="fs-13 fBolder theme-color confirm-button">{'Accept Trip'}</span>
                            </div>
                            {
                                this.state.isAcceptPopup &&
                                <div className="overlay" onClick={(e) => {e.preventDefault()}}>
                                    <div className="overlay-container change-vehicle-popup">
                                        <span className="overlay-close-button fs-20" onClick={(e) => { e.preventDefault(); this.setState({ isRejectPopup: false }) }}>
                                            X
                                        </span>
                                        <div className="overlay-body">
                                            <header className="fs-14 theme-color fBolder mb-20 tCenter">
                                                Are you sure you want to Accept this Trip?
                                            </header>
                                        <div className={'mt-10 ml-25 wt-300'}>
                                            <label>Reason</label>
                                            <Dropdown
                                                onSelect={(value) =>  { this.selectReason(value, "1") }}
                                                customClass="flex-1 mr-20"
                                                items={vehicleRemarksList}
                                                value={this.state.acceptReason}
                                                label={'Accept Reason'}
                                                selectedClassName={'wt-300'}
                                            />
                                        </div>
                                        <div className="tCenter mt-20">
                                            <div className="wt-200 inBlock mr-30"><Button value={'Confirm'} onClick={(e) =>  { e.preventDefault(); this.onRejectTrip(e,trip, 'accept') }}>Confirm</Button></div>
                                            <div className="wt-200 inBlock"><Button value={'Cancel'} onClick={(e) => { e.preventDefault(); this.setState({ isAcceptPopup: false }) }}>Cancel</Button></div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                }
                            </Fragment>
                        }
                    </div>
                    
                </Link>}
                {
                    companyType === PROVIDER &&
                    <div onClick={this.toggleTransporterDetails}>
                        <div to={'/trip/' + trip.tripId} className="pr flex pt-15 pb-15 flex-middle trip-row br-5">
                            <div className="wt-250 pl-15">
                                <div className="fBolder fs-16 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>{trip.doNo}</div>
                                <div className="fBolder fs-10 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>LR No: {trip.lrNo}</div>
                                <div className="fBolder fs-10 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>Invoice No: {trip.invoiceNo}</div>
                                <div className="fBolder fs-10 table-text-color mb-5" style={{'wordWrap': 'break-word'}}>Item(s): {trip.itemName}</div>
                                {/* <Status status={trip.statusText} background= {trip.statusCode}/> */}
                            </div>
                            <div className="wt-250 pl-15">
                                <div className="fs-16 table-text-color mb-5">{trip.routeDetails.source}-{trip.routeDetails.destination}</div>
                                <div className="fs-12 table-text-color">{trip.routeDetails.via?`via: ${trip.routeDetails.via}`:''}</div>
                                {
                                    trip.customerName &&
                                    <div className="fs-12 table-text-color">{`Customer: ${trip.customerName}`}</div>
                                }
                                <section className="flex flex-middle tag-text-color fs-10 flex-wrap">
                                  {trip.isAutoCreated && <div className="pl-10 pr-10 flex flex-middle ht-20 br-15 mr-10 mb-5 orange-bg border-orange-1">Auto Created</div>}
                                </section>
                                <section className="flex flex-middle tag-text-color fs-10 flex-wrap">
                                {
                                    trip &&
                                    <div className="pl-10 pr-10 flex flex-middle ht-20 br-15 mr-10 mb-5 bg-theme theme-color border-theme">
                                        {
                                            statusTypes.map((obj)=> {
                                                if (obj.id == trip.type) {
                                                    return obj.value;
                                                }
                                            })
                                        }
                                    </div>
                                }
                                </section>
                            </div>
                            {
                                companyType === SEEKER &&
                                <div className="wt-150 pl-15">
                                    <div className="fs-16 table-text-color mb-5">{trip.transporterDetails.transporterName}</div>
                                    <div className="fs-12 table-text-color">{trip.transporterDetails.serviceType}</div>
                                </div>
                            }
                            <div className="wt-250 pl-15">
                                {trip.dateTat.createdOn && <div className="fs-12 table-text-color mb-5"><span className={'fs-12 fBolder'}>{'Created On: '}</span>{trip.dateTat.createdOn.format('DD MMM, YYYY HH:mm')}</div>}
                                {trip.dateTat.startedDate && <div className="fs-12 table-text-color mb-5"><span className={'fs-12 fBolder'}>{'Start Date: '}</span>{trip.dateTat.startedDate ? trip.dateTat.startedDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                                {trip.dateTat.timeVehiclePlaced && trip.type === 3 && <div className="fs-12 table-text-color mb-5"><span span className={'fs-12 fBolder'}>{'Vehicle Placed: '}</span>{trip.dateTat.timeVehiclePlaced ? trip.dateTat.timeVehiclePlaced.format('DD MMM, YYYY HH:mm') : ''}</div>}
                                {trip.dateTat.gateInDate && <div className="fs-12 table-text-color mb-5"><span className={'fs-12 fBolder'}>{'Gate In: '}</span>{trip.dateTat.gateInDate ? trip.dateTat.gateInDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                                {trip.dateTat.gateOutDate && <div className="fs-12 table-text-color mb-5"><span span className={'fs-12 fBolder'}>{'Gate Out: '}</span>{trip.dateTat.gateOutDate ? trip.dateTat.gateOutDate.format('DD MMM, YYYY HH:mm') : ''}</div>}
                                {trip.dateTat.completedOn && <div className="fs-12 table-text-color mb-5"><span span className={'fs-12 fBolder'}>{'Completed On: '}</span>{trip.dateTat.completedOn ? trip.dateTat.completedOn.format('DD MMM, YYYY HH:mm') : ''}</div>}
                                {trip.dateTat.tat && <div className="fs-13 table-text-color mb-5"><span span className={'fs-12 fBolder'}>{'TAT: '}</span>{`${trip.dateTat.tat ? `${trip.dateTat.tat} Days` : ''}`}</div>}
                                {/*<div className="fs-12 table-text-color"><span span className={'fs-12 fBolder'}>{'TAT: '}</span>{`${trip.dateTat.tat ? `${trip.dateTat.tat} Days` : ''}`}</div>
                                <div className="fs-16 table-text-color mb-5"><span className={'fs-14 fBolder'}>{'Created On: '}</span>{trip.dateTat.startedDate.format('DD MMM, YYYY')}</div>
                                <div className="fs-16 table-text-color mb-5"><span span className={'fs-14 fBolder'}>{'Start Date: '}</span>{trip.dateTat.gateOutDate ? trip.dateTat.gateOutDate.format('DD MMM, YYYY') : ''}</div>*/}
                            </div>
                            <div className="wt-150 pl-15 pr driver-details">
                                <div className="fs-16 table-text-color mb-5">
                                    {(driverDetails.firstName || driverDetails.lastName) ? `${driverDetails.firstName} ${driverDetails.lastName}` : driverDetails.name}
                                </div>
                                <div className="fs-12 table-text-color">
                                    {driverDetails.number}
                                    <div>
                                        {trackingTypeText}
                                        <div className={classNames('consent-status', {
                                            'bg-orange': driverDetails.consentStatus == 'pending',
                                            'bg-green': driverDetails.consentStatus == 'allowed',
                                            'bgc-error': driverDetails.consentStatus == 'not_added',
                                            'bg-check-consent-error': driverDetails.consentStatus == 'consent_check_error'
                                        })}>
                                        </div>
                                        {vendorName}
                                        {
                                            userCanEdit && [2, 8, 9].indexOf(trip.type) === -1 && isSimTracking && !isConsentAllowed &&
                                            <Refresh
                                                className={'ml-5 mt-5'}
                                                style={{ width: '15px', height: '15px', fill: '#3f51b5' }}
                                                onClick={(e) => this.onRefresh(e, trip)}
                                            />
                                        }
                                    </div>
                                </div>
                                {
                                    driverDetails.userResponse &&
                                    <div className="fs-12 table-text-color">
                                        IVR/SMS Response: <span className="fBolder">{driverDetails.userResponse}</span>
                                    </div>
                                }
                                {
                                    driverDetails.responseTime &&
                                    <div className="fs-12 table-text-color mb-5 fBolder">
                                        {driverDetails.responseTime}
                                    </div>
                                }
                                <div className="driver-tooltip pa t-5 l-5">
                                    <DriverTooltip
                                        driverDetails={trip.driverDetails}
                                        trackingPreferences={trip.trackingPreferences}
                                        gpsVendorType={gpsVendorType}
                                        operatorTypes={operatorTypes}
                                        trackingPreference={trackingPreference}
                                    />
                                </div>
                            </div>
                            <div className="wt-150 pl-15">
                                <div className="fs-16 table-text-color mb-5">{trip.vehicleDetails.vehicleNo}</div>
                                <div className="fs-12 table-text-color">{trip.vehicleDetails.truckType}</div>
                            </div>
                            <div>
                                {
                                    (error_message && [3, 4, 7].indexOf(trip.type) > -1) &&
                                    <div className={'error-color ml-50 wt-150'}>
                                        {error_message}
                                    </div>
                                }

                                {
                                    // companyType === SEEKER &&
                                    trip.isTracked && (trip.type === 6 || trip.type === 5) &&
                                    <div className="mr-10 r-10 bg-theme border-theme br-5 wt-70 ht-25 tCenter">
                                        <Link to={'/tracking/' + trip.tripId} className="fs-13 fBolder theme-color">Track</Link>
                                    </div>
                                }
                                {
                                    trip.isTracked && [5, 6, 8, 9].indexOf(trip.type) > -1 &&
                                    <div className="r-10 bg-theme border-theme br-5 wt-70 ht-25 tCenter mt-5" onClick={(e) => this.showlogDetails(e, trip.tripId, trip.trackingPreferences)}>
                                        <span className="fs-13 fBolder theme-color confirm-button">{'Logs'}</span>
                                    </div>
                                }
                                {
                                    //companyType === SEEKER &&
                                    trip.type == 3 && trip.confirmGateIn &&
                                    <Fragment>
                                        <div className="trip-track pa r-10 bg-theme border-theme br-5 wt-130 flex flex-center flex-middle ht-25" onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: true }) }}>
                                            <span className="fs-12 fBolder theme-color confirm-button">{'Confirm at Gate'}</span>
                                        </div>
                                        {
                                            this.state.isConfirmPopup &&
                                            <div className="overlay" onClick={(e) => {e.preventDefault()}}>
                                                <div className="overlay-container change-vehicle-popup">
                                                    <span className="overlay-close-button fs-20" onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: false }) }}>
                                                        X
                                                    </span>
                                                    <div className="overlay-body">
                                                        <header className="fs-14 theme-color fBolder mb-20 tCenter">
                                                            Are you sure you want to perform Gate-In?
                                                        </header>
                                                        <div className="flex flex-center gateOutDateTime mb-80">
                                                            <div className="mb-20 mr-10">
                                                                <div className="fs-12 communication-color fBold mb-5">Select Date</div>
                                                                <SingleDatePicker
                                                                    noBorder={true}
                                                                    displayFormat={"Do, MMM YYYY"}
                                                                    // isOutsideRange={(day) => day.isBefore(Moment(trip.dateTat.startedDate).subtract(2, 'day'))}
                                                                    isOutsideRange={(day) => day.isBefore(Moment(new Date()).subtract(10, 'day'))}
                                                                    hideKeyboardShortcutsPanel={true}
                                                                    placeholder={"Date"}
                                                                    numberOfMonths={1}
                                                                    date={Moment(this.state.gateTime)}
                                                                    onDateChange={(date) => {
                                                                        this.setState({
                                                                            gateTime: new Date(Moment(date).format('YYYY-MM-DD') + ' ' + Moment(this.state.gateTime).format('HH:mm')).getTime()
                                                                        })
                                                                    }}
                                                                    focused={this.state.focusedStart}
                                                                    onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                                    className="ht-40 wt-200"
                                                                    id="gateTime"
                                                                />
                                                            </div>
                                                            <div className="mb-20 ml-10 pick-time">
                                                                <div className="fs-12 communication-color fBold mb-5">Select Time</div>
                                                                <TimePicker
                                                                    showSecond={false}
                                                                    value={Moment(this.state.gateTime)}
                                                                    className="ht-40 wt-280"
                                                                    onChange={(time) => {
                                                                        const { gateTime } = this.state;
                                                                        this.setState({
                                                                            gateTime: new Date(Moment(gateTime).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                                        });
                                                                    }}
                                                                    format={'h:mm a'}
                                                                    use12Hours
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="tCenter">
                                                            <div className="wt-200 inBlock mr-30"><Button value={'Confirm'} onClick={(e) =>  { e.preventDefault(); this.onConfirmAtOrigin(e, trip.tripId) }}>Confirm</Button></div>
                                                            <div className="wt-200 inBlock"><Button value={'Cancel'} onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: false, gateTime: new Date().getTime() }) }}>Cancel</Button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                }

                                {
                                    //companyType === SEEKER &&
                                    trip.type == 4 && trip.confirmGateOut &&
                                    <Fragment>
                                        <div className="trip-track pa r-10 bg-theme border-theme br-5 wt-130 flex flex-center flex-middle ht-25" onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: true }) }}>
                                            <span className="fs-13 fBolder theme-color confirm-button">{'Confirm Gate Out'}</span>
                                        </div>
                                        {
                                            this.state.isConfirmPopup &&
                                            <div className="overlay" onClick={(e) => {e.preventDefault()}}>
                                                <div className="overlay-container change-vehicle-popup">
                                                    <span className="overlay-close-button fs-20" onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: false }) }}> X </span>
                                                    <div className="overlay-body">
                                                        <header className="fs-14 theme-color fBolder mb-20 tCenter">
                                                            Are you sure you want to Gate Out?
                                                        </header>
                                                        <div className="flex flex-center gateOutDateTime mb-80">
                                                            <div className="mb-20 mr-10">
                                                                <div className="fs-12 communication-color fBold mb-5">Select Date </div>
                                                                <SingleDatePicker
                                                                    noBorder={true}
                                                                    displayFormat={"Do, MMM YYYY"}
                                                                    // isOutsideRange={(day) => day.isBefore(Moment(trip.dateTat.gateInDate || trip.dateTat.startedDate).subtract(1, 'day'))}
                                                                    isOutsideRange={(day) => day.isBefore(Moment(new Date()).subtract(10, 'day'))}
                                                                    hideKeyboardShortcutsPanel={true}
                                                                    placeholder={"Date"}
                                                                    numberOfMonths={1}
                                                                    date={Moment(this.state.gateTime)}
                                                                    onDateChange={(date) => {
                                                                        // e.preventDefault();
                                                                        this.setState({
                                                                            gateTime: new Date(Moment(date).format('YYYY-MM-DD') + ' ' + Moment(this.state.gateTime).format('HH:mm')).getTime()
                                                                        })
                                                                    }}
                                                                    focused={this.state.focusedStart}
                                                                    onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                                    id="gateTime"
                                                                />
                                                            </div>
                                                            <div className="mb-20 ml-10 pick-time">
                                                                <div className="fs-12 communication-color fBold mb-5">Select Time</div>
                                                                <TimePicker
                                                                    showSecond={false}
                                                                    value={Moment(this.state.gateTime)}
                                                                    className="ht-40 wt-280"
                                                                    onChange={(time) => {
                                                                        e.preventDefault();
                                                                        const { gateTime } = this.state;
                                                                        this.setState({
                                                                            gateTime: new Date(Moment(gateTime).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                                        });
                                                                    }}
                                                                    format={'h:mm a'}
                                                                    use12Hours
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="tCenter">
                                                            <div className="wt-200 inBlock mr-30"><Button value={'Confirm'} onClick={(e) =>  { e.preventDefault(); this.onConfirmAtGateOut(e, trip.tripId) }}>Confirm</Button></div>
                                                            <div className="wt-200 inBlock"><Button value={'Cancel'} onClick={(e) => { e.preventDefault(); this.setState({ isConfirmPopup: false, gateTime: new Date().getTime() }) }}>Cancel</Button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div style={{
                    maxHeight: this.state.isTransporterDetailsShown?'1000px':0,
                    overflow: 'auto',
                    transition: '0.6s max-height'
                }}>
                {this.state.isTransporterDetailsShown && <div className="mt-20" style={{width: '99%'}}>
                    <Link to={'/trip/' + trip.tripId} className="fs-12 theme-color curP mb-15 flex fBolder tUnderline">Click to see detailed view</Link>
                    <TransporterDetails
                        numbersOperatorData={this.props.numbersOperatorData}
                        getNumbersOperator={this.props.getNumbersOperator}
                        submitTransporterDetails={this.props.submitTransporterDetails}
                        providerMode={false}
                        trip={trip}
                        sendPreferenceToBanner={this.sendPreferenceToBanner}
                        isSeeker={companyType === SEEKER}
                        gpsVendorType={this.props.gpsVendorType}
                        tripData={tripData}
                        onChangeVehicleDetails={this.props.onChangeVehicleDetails}
                        selectVehicleServiceType={this.props.selectVehicleServiceType}
                        vehicleServiceTypes={this.props.vehicleServiceTypes}
                        vehicleTypes={this.props.vehicleTypes}
                        vehicleBodyTypes={this.props.vehicleBodyTypes}
                        vehicleData={this.props.vehicleData}
                        operatorTypes={operatorTypes}
                        getVehicleRemarks={this.props.getVehicleRemarks}
                        vehicleRemarks={this.props.vehicleRemarks}
                    />
                </div>}
                </div>
            </Fragment>
        );
    }
}

export default TripRow;
