import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import Button from '../Button';
import DetailedLog from '../DetailedLog';
import { LocationRoute } from '../LocationRoute';
import Overlay from '../Overlay';
import Pill from 'Components/Pill';
import Status  from 'Components/Status';
import classNames from 'classnames';
import { getTrackingType } from 'Utils/common';
import { truck_location_tab, TTBIL, AWL } from 'Constants/commonConstants';
import { ReactComponent as ShareIcon } from './icons/share.svg';
import { ReactComponent as EsclamationIcon } from 'Components/TripRow/icons/esclamation.svg';
import Share from './Share';
import { getUser } from 'Utils/storage';
import RetriggerEmail from './RetriggerEmail';

export default class TripTrackingSideBar extends Component {

    constructor() {
        super();
        this.state = {
            show: false,
            isShare: false
        }
    }

    showOverlay = ()=> {
        this.setState({show: true});
    }

    hideOverlay = ()=> {
        this.setState({show: false});
    }

    hideShareOverlay = () => {
        this.setState({isShare: false });
    }

    onShareClick = () => {
        this.setState({isShare: true }, () => {
            this.props.getSharedDetails();
        });
    }

    downloadFasttag = () => {
        this.props.getFastTagLogs();
    }

    showReminder = () => {
        this.setState({isReminder: true});
    }

    hideReminderOverlay = () => {
        this.setState({isReminder: false});
    }

    componentDidUpdate() {
        let firstNotReached = document.querySelectorAll(".reached");
        try {
            if(firstNotReached.length > 0){
                firstNotReached[firstNotReached.length - 1].className = firstNotReached[firstNotReached.length - 1].className + " last-reached "
            }
        } catch (err) {
            console.log("suspense!", err);
        }
    }
    goBack = ()=> {
        history.back();
    }

    // diff_hours = (dt2, dt1) => {
    //     var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    //     diff /= (60 * 60);
    //     return Math.abs(Math.round(diff));
    // }
    renderDriverMobileNo=()=>{
        const {publicUrl}=this.props;
        if(!!publicUrl){
            return false;
        }
        return true;
    }

    render() {
        const {
            tripForTracking,
            detailedLog,
            searchDetailedRouteLog,
            isDetailedLogLoaded,
            fetchNextDetailedLog,
            fetchPreviousDetailedLog,
            shareDetails,
            onSaveSharedDetails,
            isShare,
            operatorTypes,
            // gpsVendorType,
            reminderEmailDetails,
            urlBranchId
        } = this.props;
        const pillsNormal = [
            tripForTracking.serviceType,
            tripForTracking.boundType,
            tripForTracking.runType,
            tripForTracking.modeType,
        ];

        let tripNotFound = true;
        if (tripForTracking && typeof(tripForTracking.tripId) != "undefined") {
            tripNotFound = false;
        }
        const getSubType = truck_location_tab.find(val => tripForTracking.type === val.id);
        const queryParams = new URLSearchParams(window.location.search);
        const url_branchId = queryParams.get('branch_id') || null;
        const currentPath = window.location.pathname;
        const showDiv = currentPath.includes("tracking-public");
        return (
             <div className="trip-tracking-leftpane">
             { !tripNotFound &&
                <div>
                    <div className="trip-indent-header flex">
                        {!TTBIL.includes(getUser().company_id) && isShare && <span className="back-icon flex curP" onClick={this.goBack}>
                            <img src="/images/back.svg" />
                        </span>}
                        <header className="ml-10 fs-24 theme-color fBolder ">
                            <Link to={'/trip/' + tripForTracking.tripId}>
                                {"Trip - " + tripForTracking.doNo}
                            </Link>
                            <section className="flex flex-middle tag-text-color fs-12 flex-wrap">
                              {tripForTracking.isAutoCreated && <div className="pl-15 pr-15 flex flex-middle ht-25 br-15 mr-10 mb-5 orange-bg border-orange-1">Auto Created</div>}
                            </section>
                        </header>
                        {/* <div>
                            <Pill type={tripForTracking.status.text == "on-time" ? "success" : "danger"} text={tripForTracking.status.text} />
                            <Status status={tripForTracking.status.text} background={tripForTracking.status.code}/>
                        </div> */}
                    </div>
                    {/* <section className="flex flex-wrap">
                        {
                            tripForTracking && pillsNormal.map( (pillData, index) => {
                                return (
                                    <Pill type={""} text={pillData} key={index}/>)
                            })
                        }
                    </section> */}
                    {/* <section className="flex value-container mt-15">
                        <span className="value-text fs-14 fMedium">Value : </span>  <Pill type={"value"} text={tripForTracking.value} />
                    </section> */}
                    {getSubType && <div className={'fs-14 theme-color fBolder ml-35 flex'}>
                        <div>{`(${getSubType.value})`}</div>
                        {tripForTracking.type && tripForTracking.type === 6 && tripForTracking.status && Object.keys(tripForTracking.status).length > 0 &&
                         <div className={"pl-15 pr-15 flex flex-middle ht-20 br-15 mr-10 mb-5 ml-5 white-color"} style={{background: tripForTracking.status.code }}>{tripForTracking.status.text}</div>}
                    </div>}

                    {tripForTracking.lastKnownLocation && tripForTracking.lastKnownLocation.location && <section className="last-known-location mt-25">
                        <div className="fs-16 theme-color fBolder">
                            Last known location
                        </div>
                        <div className="flex mt-8">
                            <div className="flex locationIcon pr-16">
                                <img src="/images/maps-and-flags.svg" width="15px" height="21"/>
                            </div>
                            <div className="place-details">
                                <div className="fs-16 theme-color fBolder place-name col-1-1">
                                    { tripForTracking.lastKnownLocation.location }
                                </div>
                                <div className="fs-14 place-date-time pt-4">
                                { tripForTracking.lastKnownLocation.date.format('DD MMM, YYYY') } | { tripForTracking.lastKnownLocation.time }
                                </div>
                                <div className="fs-14 place-date-time pt-4">
                                    <React.Fragment>
                                        <a data-tip data-for="distance" className={'ml-50'}>
                                            <div className="pb-5 fBold mt--15">
                                                Distance<EsclamationIcon style={{ width: '15px', height: '11px' }}/> :
                                                <span className={'fs-14k fBolder'}>
                                                    {` ${parseInt(tripForTracking.lastKnownLocation.distance_covered || 0)}/${parseInt(tripForTracking.distance)}km`}
                                                </span>
                                            </div>
                                        </a>
                                        <ReactTooltip id="distance" type="info" effect="solid" border={true}>
                                            Distance covered by truck / Approx. distance of the route
                                        </ReactTooltip>
                                    </React.Fragment>
                                </div>
                                {
                                    tripForTracking.lastKnownLocation.eta && ((getUser().company_text_id === 'ADANI') ? tripForTracking.type !== 5 : true) &&
                                    <div className="fs-14 place-date-time pt-4">
                                        {'ETA : '}{tripForTracking.lastKnownLocation.eta.format('DD MMM, YYYY HH:mm')}
                                    </div>
                                }
                                {((getUser().company_text_id === 'ADANI') && (tripForTracking.dateTat || {}).destinationGateInTime) && 
                                    <div className="fs-14 place-date-time pt-4">
                                        {'Reporting Date & Time : '}{(tripForTracking.dateTat || {}).destinationGateInTime.format('DD MMM, YYYY HH:mm')}
                                    </div>
                                }
                                {
                                    (!!!tripForTracking.is_completed) && tripForTracking.at_destination_duration &&
                                    <div className="fs-14 place-date-time pt-4">
                                        {`Truck Standing At Destination Since ${tripForTracking.at_destination_duration} ${tripForTracking.at_destination_duration > 1 ? 'hours' : 'hour'}`}
                                    </div> || ''
                                }
                            </div>
                        </div>
                    </section>}

                    <section className="last-known-location mt-25">
                        <div className="fs-16 theme-color fBolder"> Shipment Details </div>
                        <div className="ml-30">
                            {tripForTracking.poNo && <div className="fs-14 place-date-time pt-4"> PO No: {tripForTracking.poNo}</div>}
                            {tripForTracking.lrNo && <div className="fs-14 place-date-time pt-4"> LR No: {tripForTracking.lrNo}</div>}
                            {tripForTracking.invoiceNo && <div className="fs-14 place-date-time pt-4"> Invoice No: {tripForTracking.invoiceNo}</div>}
                            {tripForTracking.customerName && <div className="fs-14 place-date-time pt-4"> Customer: {tripForTracking.customerName}</div>}
                            {tripForTracking.itemName && <div className="fs-14 place-date-time pt-4"> Item(s): {tripForTracking.itemName}</div>}
                        </div>
                    </section>
                    {/* {!showDiv &&
                    <> */}
                    {(tripForTracking.driverDetails && tripForTracking.driverDetails.length > 0) && <section className="last-known-location mt-25">
                        <div className="fs-16 theme-color fBolder">
                            Driver Details
                        </div>

                        {
                            tripForTracking.driverDetails.map((driver, index)=> {
                                return (
                                    <div key={index}>
                                        <div className="flex mt-8 mb-25 ">
                                            <div className="wt-30 locationIcon pr-16 tracking-indicator-circle">
                                            {driver.isTracked ? <div class="pulsating-circle ml--10 mt--25"></div> : <img src="/images/person.svg" width="18px" height="18"/>}
                                            </div>
                                            <div className="place-details">
                                                <div className="fs-18 theme-color fBolder success-color place-name">
                                                    {(driver.firstName || driver.lastName) ? `${driver.firstName} ${driver.lastName}` : (driver.name || '-')}
                                                </div>
                                                {this.renderDriverMobileNo() &&
                                                    <div className="fs-14 place-date-time success-color pt-4">
                                                        {driver.number || '-'}
                                                    </div>}
                                                <div className="fs-14 place-date-time pt-4">
                                                    {getTrackingType(tripForTracking.trackingType)}
                                                    <div className={classNames('consent-status', {
                                                        'bg-orange': driver.consentStatus == 'pending',
                                                        'bg-green': driver.consentStatus == 'allowed',
                                                        'bgc-error': driver.consentStatus == 'not_added',
                                                        'bg-check-consent-error': driver.consentStatus == 'consent_check_error'
                                                        })}>
                                                    </div>
                                                    {
                                                        tripForTracking.trackingType === 2 && driver.operatorType && operatorTypes.filter(operator => operator.id === driver.operatorType).length > 0
                                                        ? operatorTypes.filter(operator => operator.id === driver.operatorType)[0].name
                                                        : (
                                                            tripForTracking.trackingType === 3 && tripForTracking.gpsDetails && tripForTracking.gpsDetails.name
                                                            ? tripForTracking.gpsDetails.name
                                                            : ''
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="fs-16 theme-color fBolder">
                                            Consent Status
                                        </div>
                                        <div className={classNames('fs-14 theme-color fBolder place-name ml-10',{
                                            'green-color': driver.consentStatus == 'allowed',
                                            'orange-color': driver.consentStatus == 'pending'
                                        })} style={{'textTransform': 'capitalize'}}>
                                            {driver.consentStatus}
                                        </div> */}
                                    </div>
                                )
                            })
                        }
                    </section>} 
                    {(tripForTracking.transporter_details && Object.keys(tripForTracking.transporter_details).length > 0) && <section className="last-known-location mt-25">
                        <div className="fs-16 theme-color fBolder">
                           Transporter Details
                        </div>
                        <div>
                            <div className="flex mt-8 mb-25">
                                <div className="flex locationIcon pr-16">
                                    <img src="/images/truck.svg" width="18px" height="18"/>
                                </div>
                                <div className="place-details">
                                    <div className="fs-18 theme-color fBolder place-name">
                                        {tripForTracking.transporter_details.name || '-'}
                                    </div>
                                    <div className="fs-14 place-date-time pt-4">
                                        {tripForTracking.transporter_details.email || '-'}
                                    </div>
                                    {!showDiv &&
                                    <div className="fs-14 place-date-time pt-4">
                                        {tripForTracking.transporter_details.number || '-'}
                                    </div>
                                    }
                                    {/* <div className="fs-14 place-date-time pt-4">
                                        {tripForTracking.transporter_details.pan_no || '-'}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>} 
                    {/* </>
                    } */}
                    {(tripForTracking.vehicleDetails && Object.keys(tripForTracking.vehicleDetails).length > 0) && <section className="last-known-location mt-25">
                        <div className="fs-16 theme-color fBolder">
                           Vehicle Details
                        </div>
                        <div>
                            <div className="flex mt-8 mb-25 ml-15">
                                <div className="place-details">
                                    <div className="fs-18 theme-color fBolder place-name">
                                        {tripForTracking.vehicleDetails.vehicleNo || '-'}
                                    </div>
                                    {
                                        tripForTracking.vehicleDetails.loadingCapacity &&
                                        <div className="fs-14 place-date-time pt-4">
                                            {`${tripForTracking.vehicleDetails.vehicleServiceType}: ${tripForTracking.vehicleDetails.loadingCapacity}L`}
                                        </div>
                                    }
                                    <div className="fs-14 place-date-time pt-4">
                                        {tripForTracking.vehicleDetails.isoNo}
                                    </div>
                                    <div className="fs-14 place-date-time pt-4">
                                        {tripForTracking.vehicleDetails.vehicleType}
                                    </div>
                                    <div className="fs-14 place-date-time pt-4">
                                        {tripForTracking.vehicleDetails.bodyType}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>}

                    <section className="last-known-location mt-25">
                        <div className="fs-16 theme-color fBolder">
                            Comments/Additional Information
                        </div>
                        <div className={'ml-15 fs-14 wt-250'}>
                            {tripForTracking.comments}
                        </div>
                    </section>

                    <section className="last-known-location mt-25">
                        <div className="fs-16 theme-color fBolder">
                            Route Map
                        </div>
                            {url_branchId !== null ? (
                                tripForTracking.routeMap && tripForTracking.routeMap.filter(obj => obj.branch_id === url_branchId || obj.source).map((loc, index) => {
                                    return <LocationRoute loc={loc} first={index == 0} last={index == tripForTracking.routeMap.length - 1} key={index} urlBranchId={urlBranchId} />
                                })
                            ) : (
                            tripForTracking.routeMap && tripForTracking.routeMap.map((loc, index) => {
                                return <LocationRoute loc={loc} first={index == 0} last={index == tripForTracking.routeMap.length -1} key={index} urlBranchId={urlBranchId} />
                            })
                        )
                            
                        }
                    </section>

                    {isShare && <section className={`mt-25 mb-30 flex ${isShare ? '' : 'col-2'}`}>
                        <Button className="button-outlined" style={{width:"fit-content"}} value="View detailed log" onClick={this.showOverlay}></Button>
                        <div className={'share-button button-outlined ml-10 flex pl-50 pt-10 mr-10'} onClick={this.onShareClick}>
                            <div className={'mr-5'}><ShareIcon width={'20px'} height={'20px'}/></div>
                            <span>{'Share'}</span>
                        </div>
                        <Button className="button-outlined" style={{width:"fit-content"}} value="Download FastTag Logs" onClick={this.downloadFasttag}></Button>
                        {AWL.includes(getUser().company_id) && <Button className="button-outlined ml-10" style={{width:"fit-content"}} value="Reminder Email" onClick={this.showReminder}></Button>}
                    </section>}
                    {this.state.show && <Overlay show={this.state.show}  hideOverlay={this.hideOverlay} >
                        <DetailedLog
                            fetchNext={fetchNextDetailedLog}
                            fetchPrevious={fetchPreviousDetailedLog}
                            isDetailedLogLoaded={isDetailedLogLoaded}
                            search={searchDetailedRouteLog}
                            locations={detailedLog}
                            trackingType={tripForTracking.trackingType}
                            tripId={tripForTracking.tripId}
                        />
                    </Overlay>}
                    {this.state.isShare && <Overlay show={this.state.isShare}  hideOverlay={this.hideShareOverlay} containerClass={'share-details'}>
                            <Share hideOverlay={this.hideShareOverlay} shareDetails={shareDetails} onSaveSharedDetails={onSaveSharedDetails} />
                    </Overlay>}
                    {this.state.isReminder && <Overlay show={this.state.isReminder}  hideOverlay={this.hideShareOverlay} containerClass={'share-details'}>
                            <RetriggerEmail hideOverlay={this.hideReminderOverlay} reminderEmailDetails={reminderEmailDetails} onSaveSharedDetails={this.props.postReminderEmail} />
                    </Overlay>}
                </div>
             }

             {
                 tripNotFound && <div className="" style={{
                     marginTop: `50%`
                 }}>
                        <div className="fs-24 theme-color tCenter mb-10">
                            Not trip found for tracking!  &#x263A;
                        </div>
                    </div>
             }
            </div>
        )
    }
}
