import React from 'react';

import Navbar from 'Containers/Navbar';
import Sidebar from 'Components/Sidebar';

const Sticky = ({children, activeLink, onSearch, initialSearchValue, isSearch})=> {
    return (
        <div className="sticky">
        <Navbar onSearch={onSearch} initialSearchValue={initialSearchValue} isSearch={isSearch} />
            <div className="m-hide"><Sidebar activeLink={activeLink}/></div>
            <div className="main-page">
                {children}
            </div>
        </div>
    )
}

export default Sticky;