import React from "react";

const ResponsiveTable = ({children, className}) => {
    return (
        <div className={"responsive-table " + className}>
            <div className="inner-container">
                {children}
            </div>
        </div>
    );
};

export default ResponsiveTable;