import React, { Component } from "react";

class Button extends Component {
  render() {
    const { Name, className, onClick, disabled } = this.props;
    return (
      <button className={`${className}`} onClick={onClick} disabled={disabled}>
        {Name}
      </button>
    );
  }
}
export default Button;
