import React, { Component } from 'react'
import "./ChangePassword.scss"
import Logo from "Components/Logo/index";
import Tabs from "Components/Tabs/index";
import { ReactComponent as PasswordVisible } from "./passwordvisible.svg";
import { ReactComponent as PasswordNotVisible } from "./passwordNotVisible.svg";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
import { getCompanyType, getUser } from 'Utils/storage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Recaptcha from 'react-recaptcha';
import { CHANGE_PASSWORD } from 'Constants/actionConstants';
import { getSecureRandomKey } from 'Utils/index';
import { logOutWithToken } from 'Utils/api';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                current_password: '',
                new_password: '',
                confirm_new_password: '',
                companyType: getCompanyType(),
                errorMessage: "",
                actualCaptcha: '',
                captchaEntered: '',
                isVerified: false,
                showTag: false,
                reCaptchaKey: 1,
                captcha_value: '',
                isOldPasswordVisible:false,
                isNewPasswordVisible:false,
                isConfirmPasswordVisible:false,
                isOldPasswordFocused: false,
                isNewPasswordFocused: false,
                isConfirmPasswordFocused: false,
            }
        }
    }


    componentDidMount() {
        setTimeout(function () {
            this.setState({ showTag: true })
        }.bind(this), 1000)
    }

    onChange = (e) => {
        const { data } = this.state;
        data[e.target.name] = e.target.value
        this.setState({ data })
    }

    changePassword = (e) => {
        const { dispatch, history } = this.props;
        const { data,
            captcha_value
        } = this.state;
        e.preventDefault();
        dispatch({
            type: CHANGE_PASSWORD,
            data: {
                current_password: data.current_password,
                new_password: data.new_password,
                confirm_new_password: data.confirm_new_password,
                company_type: getCompanyType(),
                email: getUser().email,
                module_id: 3,
                captcha_value
            },
            onSuccess: ((data) => {
                const path = (data.status === 200) ? '/' : '/forgot-password';
                if (data.status === 200) {
                    logOutWithToken();
                    history.push(path);
                }
            }),
            // onSuccess: () => {
            //     history.push('/');
            //     deleteToken();
            //     clearStorage();
            // },
            onError: (errorData) => {
                if (errorData.data && errorData.data.validation_errors) {
                    this.setState({ errorMessage: errorData.data.validation_errors })
                }
            }
        })

        this.setState((prevState) => {
            const updatedState = { ...prevState };
            updatedState.isVerified = false
            updatedState.reCaptchaKey = getSecureRandomKey()
            return updatedState
        })

    }

    verifyCallback = (response) => {
        const { dispatch } = this.props;
        // dispatch({
        //   type: VALIDATE_RECAPTCHA,
        //   data: {
        //     captcha_value: response
        //   },
        //   onSuccess: (successData) => {
        //     if(successData && successData.success){
        //       this.setState({
        //         isVerified:true
        //       });
        //       this.setState({
        //         errorMessage:""
        //       });
        //     }
        //   }
        // })

        this.setState((prevState) => {
            const updatedState = { ...prevState };
            updatedState.captcha_value = response
            updatedState.isVerified = true
            return updatedState
        })
    }

    callback = () => {
        console.log('Done!!!!');
    };

    onExpire = () => {
        this.setState({
            isVerified: false
        });
    };

    toggleisOldPasswordVisibility = () => {
        this.setState((prevState) => ({
            isOldPasswordVisible: !prevState.isOldPasswordVisible,
        }));
    };
    toggleisNewPasswordVisibility = () => {
        this.setState((prevState) => ({
            isNewPasswordVisible: !prevState.isNewPasswordVisible,
        }));
    };
    toggleisConfirmPasswordVisibility = () => {
        this.setState((prevState) => ({
            isConfirmPasswordVisible: !prevState.isConfirmPasswordVisible,
        }));
    };



  render() {

      const { 
        isVerified, 
        newPassword, 
        confirmPassword, 
        data,
        reCaptchaKey,
        isPasswordVisible,
        isOldPasswordVisible,
        isNewPasswordVisible,
        isConfirmPasswordVisible,
      } = this.state;
      //   const isDisabled = !(isVerified && data.newPassword && data.confirmPassword)
      const isDisabled = !(data.current_password && data.new_password && data.confirm_new_password)
    return (
        <div className='changePasswordMain'>
            <form onSubmit={this.changePassword}>
            {/* <Rectangle className={"changePasswordRectangleTopL"} /> */}
            {/* <Rectangle className={"changePasswordRectangleTopS"} /> */}
            <div className="changePasswordCardMain">
                <div className="changePasswordCard">
                    <div className="changePasswordLogo">
                        <Logo className="changePasswordLogoImage" />
                    </div>
                    <div className="changePasswordPasswordMain">
                        <p className="changePasswordPasswordLable">Old Password</p>
                        <div className={`changePasswordPasswordBox ${this.state.isOldPasswordFocused ? 'box-focused' : ''}`}>
                            <input
                                type={isOldPasswordVisible ? "text" : "password"}
                                value={this.state.current_password}
                                onChange={this.onChange}
                                className="changePasswordPasswordInput"
                                name="current_password"
                                maxLength={16}
                                onFocus={() => this.setState({ isOldPasswordFocused: true })}
                                onBlur={() => this.setState({ isOldPasswordFocused: false })}
                            ></input>
                            <div
                                className="changePasswordPasswordIcon"
                                // onClick={this.toggleisOldPasswordVisibility}
                                onClick={() => {
                                    this.toggleisOldPasswordVisibility();
                                    this.setState({ isOldPasswordFocused: true })
                                }}
                            >
                                {isOldPasswordVisible ? (
                                        <PasswordVisible />
                                    ) : (
                                        <PasswordNotVisible />
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="changePasswordPasswordMain">
                        <p className="changePasswordPasswordLable">New Password</p>
                        <div className={`changePasswordPasswordBox ${this.state.isNewPasswordFocused ? 'box-focused' : ''}`}>
                            <input
                                type={isNewPasswordVisible ? "text" : "password"}
                                value={this.state.new_password}
                                onChange={this.onChange}
                                className="changePasswordPasswordInput"
                                name="new_password"
                                maxLength={16}
                                onFocus={() => this.setState({ isNewPasswordFocused: true })}
                                onBlur={() => this.setState({ isNewPasswordFocused: false })}
                            ></input>
                            <div
                                className="changePasswordPasswordIcon"
                                // onClick={this.toggleisNewPasswordVisibility}
                                onClick={() => {
                                    this.toggleisNewPasswordVisibility();
                                    this.setState({ isNewPasswordFocused: true })
                                }}
                            >
                                {isNewPasswordVisible ? (
                                        <PasswordVisible />
                                    ) : (
                                        <PasswordNotVisible />
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="changePasswordPasswordMain">
                        <p className="changePasswordPasswordLable">Confirm New Password</p>
                        <div className={`changePasswordPasswordBox ${this.state.isConfirmPasswordFocused ? 'box-focused' : ''}`}>
                            <input
                                type={isConfirmPasswordVisible ? "text" : "password"}
                                value={this.state.confirm_new_password}
                                onChange={this.onChange}
                                className="changePasswordPasswordInput"
                                name="confirm_new_password"
                                maxLength={16}
                                onFocus={() => this.setState({ isConfirmPasswordFocused: true })}
                                onBlur={() => this.setState({ isConfirmPasswordFocused: false })}
                            ></input>
                            <div
                                className="changePasswordPasswordIcon"
                                // onClick={this.toggleisConfirmPasswordVisibility}
                                onClick={() => {
                                    this.toggleisConfirmPasswordVisibility();
                                    this.setState({ isConfirmPasswordFocused: true })
                                }}
                            >
                                {isConfirmPasswordVisible ? (
                                        <PasswordVisible />
                                    ) : (
                                        <PasswordNotVisible />
                                    )}
                            </div>
                        </div>
                    </div>
                        {/* {this.state.showTag && <Recaptcha
                            sitekey="6LcHtbwUAAAAAKkjbo7nNh9ajzF5GEHk9uC5skGG"
                            render="explicit"
                            verifyCallback={this.verifyCallback}
                            onloadCallback={this.callback}
                            expiredCallback={this.onExpire}
                            key={reCaptchaKey}
                        />}  */}
                        <br></br>
                        {this.state.errorMessage && this.state.errorMessage.length > 0 &&
                            (this.state.errorMessage || []).map((message) => {
                                return (
                                    <div className="changePasswordErrorBox">{message}</div>
                                )
                            })
                        }
                    <div className="changePasswordButtonBox">
                        <Button 
                        Name={"Change Password"} 
                        type="submit"
                        disabled={isDisabled}
                        value="Change Password"
                        // className={"changePasswordButton"} 
                        className={`changePasswordButton ${!isDisabled ? 'isnotDisable' : ''}`}
                        />
                    </div>
                </div>
            </div>
            {/* <Rectangle className={"changePasswordRectangleBottomL"} /> */}
            {/* <Rectangle className={"changePasswordRectangleBottomS"} /> */}
            </form>
        </div>
    )
  }
}
export default connect()(withRouter(ChangePassword))