import React, { Component } from 'react';
import Button from 'Components/Button';
import classNames from 'classnames';
import { canUserEdit } from 'Utils/storage';

const userCanEdit = canUserEdit();


class Comments extends Component {
    constructor(props){
        super(props);
        this.state = {
            comments: this.props.trip.comments || '',
            trip_id: this.props.trip.tripId
        }
    }

    onChangeHandler = (e) => {
        this.setState({ comments : e.target.value });
    }

    submitComments = () => {
        this.props.submitComments(this.state);
    }

    render() {
        const isEnable = [4, 5, 6, 7, 8].indexOf(this.props.trip.type) > -1 ? true : false;

        return(
            <div className={classNames("pb-150 pr manual-tracking")}>
            <header className="fs-18 theme-color fBolder mb-30 pb-5 pt-5">Comments/Additional information</header>
                <div className={'mb-10'}>
                    <textarea className={'col-1 br-5 fs-16'} value={this.state.comments} onChange={this.onChangeHandler}/>
                </div>
                {
                    userCanEdit &&
                    <Button value='Save' onClick={this.submitComments}/>
                }
            </div>
        )
    }
}

export default Comments;
