import React, { Component, Fragment } from "react";
import "./LoginWithSso.scss";
import Logo from "Components/Logo/index";
import Tabs from "Components/Tabs/index";
import { ReactComponent as PasswordVisible } from "./passwordvisible.svg";
import { ReactComponent as PasswordNotVisible } from "./passwordNotVisible.svg";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
class LoginWithSso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailFocused: false,
    };
  }
  render() {
    const {
      companyTypeTabButton,
      companyType,
      selectCompanyType,
      toggleLoginWithSso,
      handleVerifyOtpClick,
      email,
      handleEmailChange,
      onSubmit,
      ssoLogin
    } = this.props;
    return (
      <Fragment>
        {/* <form onSubmit={onSubmit}> */}
        {/* <Rectangle className={"otpRectangleTopL"} /> */}
        {/* <Rectangle className={"otpRectangleTopS"} /> */}
        <div className="loginWithSsoFormCardMain">
          <div className="loginWithSsoFormCard">
            <div className="loginWithSsoFormLogo">
              <Logo className="loginWithSsoLogoImage" />
            </div>
            <div className="companyTypeButton">
              <Tabs
                tabs={companyTypeTabButton}
                activeTabId={companyType}
                selectTab={selectCompanyType}
              />
            </div>
            <div className="loginWithSsoFormEmailMain">
              <p className="loginWithSsoFormEmailLable">Email</p>
              <div className={`loginWithSsoFormEmailBox ${this.state.isEmailFocused ? 'box-focused' : ''}`}>
                <input
                  type="text"
                  name="email"
                  className="loginWithSsoFormEmailInput"
                  value={email}
                  onChange={handleEmailChange}
                  onFocus={() => this.setState({ isEmailFocused: true })}
                  onBlur={() => this.setState({ isEmailFocused: false })}
                ></input>
              </div>
            </div>

            <div className="loginWithSsoButtonBox">
              <Button
                Name={"Login with SSO"}
                className={"loginWithSsoButton"}
                onClick={ssoLogin}
                // type="submit"
              />
            </div>
            <div className="loginWithSsoBackToSignInLableBox">
              <p
                className="loginWithSsoBackToSignInLable"
                onClick={toggleLoginWithSso}
              >
                Back To SignIn
              </p>
            </div>
          </div>
        </div>
        {/* <Rectangle className={"otpRectangleBottomL"} /> */}
        {/* <Rectangle className={"otpRectangleBottomS"} /> */}
        {/* </form> */}
      </Fragment>
    );
  }
}
export default LoginWithSso;
