import React from 'react';
import Tab from 'Components/Tabs/Tab';

const Tabs = ({ tabs, activeTabId, selectTab, isAlternateName, tabStyle, company })=> {

    return (
        <div className="tabs flex flex-between flex-middle">
            {
                (tabs || []).map((tab, index)=> {
                    return (
                        <Tab active={tab.id === activeTabId} count={tab.count} tab={tab} key={index} index={index} isAlternateName={isAlternateName} tabStyle={tabStyle} selectTab={()=> {
                            selectTab(tab.id);
                        }} company={company}/>
                    )
                })
            }
        </div>
    )
}

export default Tabs;
