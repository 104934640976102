import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

export const LocationRoute = ({loc, first, last,urlBranchId}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const url_branchId = queryParams.get('branch_id') || null;
    return (
        <div className={classNames("pt-25 place-detail-container col-1-1", {
            "first": first,
            "not-reached": !loc.reached,
            "reached": loc.reached,
            "last": last
        })}>
            <div className="place-details">
                <div className={classNames("theme-color fBolder place-name", {
                    'fs-18': first || last,
                    'fs-14': !first && !last,
                })}>
                      {/* { loc.display_name || loc.displayName || `${ loc.city }, ${loc.state}`} */}
                    {
                        loc.source === false && url_branchId !==null? (
                            <>
                                {url_branchId === loc.branch_id ? loc.display_name || loc.displayName || `${loc.city}, ${loc.state}` : loc.city}
                            </>
                        ) : (
                            <>
                                {loc.display_name || loc.displayName || `${loc.city}, ${loc.state}`}
                            </>
                        )
                    }
                    <span className={classNames("blue-dot", {
                        'blue-dot-md': first || last,
                        'blue-dot-sm': !first && !last && loc.reached,
                        'blue-dot-sm hole': !last && !loc.reached,
                        'blue-dot-md hole': last && !loc.reached
                    })}>
                    </span>
                </div>
                <div className={classNames("theme-color place-name", {
                    'fs-18': first || last,
                    'fs-14': !first && !last,
                })}>
                    {loc.customer_name || ''}
                </div>
                <div className={classNames("theme-color place-name", {
                    'fs-18': first || last,
                    'fs-14': !first && !last,
                })}>
                    {loc.lr_no || ''}
                </div>
                <div className={classNames("fs-14 place-date-time pt-4", {
                    'light-font': !first && !last
                })}>
                    {'Gate In: ' + (loc.is_in ? moment(loc.is_in).format('DD MMM, YYYY | HH:mm') : ' - ') }
                </div>
                <div className={classNames("fs-14 place-date-time pt-4", {
                    'light-font': !first && !last
                })}>
                    {'Gate Out: ' + (loc.is_out ? moment(loc.is_out).format('DD MMM, YYYY | HH:mm') : ' - ') }
                </div>
            </div>
        </div>
    )
};
