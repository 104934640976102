import React, { Fragment } from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {ReactComponent as DashboardIcon} from './icons/dashboard.svg';
import {ReactComponent as TripsIcon} from './icons/delivery.svg';
import {ReactComponent as TrackingIcon} from './icons/tracking.svg';
import {ReactComponent as TransportersIcon} from './icons/transporters.svg';
import {ReactComponent as AnalyticsIcon} from './icons/analytics.svg';
import {ReactComponent as SettingsIcon} from './icons/settings.svg';
import {ReactComponent as EagleIcon} from './icons/eagle.svg';
import {ReactComponent as IndentingIcon} from './icons/indenting.svg';
import {ReactComponent as MileStone} from './icons/milestone.svg';
import {ReactComponent as Audit} from './icons/audit.svg';

import { getCompanyType, getUserType, getUser } from 'Utils/storage';
import { PROVIDER, SEEKER, USER_TYPE, LOREAL, PANTALOONS, CARGILL, AWL, ABFRL } from 'Constants/commonConstants';
import { INDENTING_URL} from 'Utils/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const isAuditVisible = () => {
    const company_id = getUser().company_id
    const isSalesUser = getUser().user_type
    const C1 = LOREAL.includes(company_id) && isSalesUser == 11
    if (C1) {
        return true
    } else {
        return false
    }
}

const Sidebar = ({activeLink,...restProps})=> {
    const companyType = getCompanyType();
    const userType = getUserType();
    const userDetails = getUser(); 
    const isLorealProvider = (userDetails.seeker_ids || []).filter(ids => LOREAL.includes(ids));
    const isPantaloosProvider = (userDetails.seeker_ids || []).filter(ids => PANTALOONS.includes(ids));
    const isCargillProvider = (userDetails.seeker_ids || []).filter(ids => CARGILL.includes(ids));
    const isProvider = (companyType === PROVIDER);
    return (
        <div className={"sidebar flex flex-column flex-middle"}>
            <div className="mt-70"></div>
            {
                ((isProvider && userDetails.vims_tabs) ? (userDetails.vims_tabs).includes('dashboard') : true) && ([USER_TYPE.AUDIT].indexOf(userType) <= -1) && !LOREAL.includes(userDetails.company_id) && 
                <Link
                    to="/dashboard"
                    className={
                        classNames(
                            "col-1 flex flex-column flex-center flex-middle icon-container ht-70",
                            {active: activeLink === 'dashboard'}
                        )
                    }
                >
                    <DashboardIcon />
                    <div className="fs-10 mt-5 white-color">Dashboard</div>
                </Link>
            }
            {
                ((isProvider && userDetails.vims_tabs) ? (userDetails.vims_tabs).includes('trips') : true) && ([USER_TYPE.AUDIT].indexOf(userType) <= -1) && !LOREAL.includes(userDetails.company_id) && 
                <Link
                    to="/trips"
                    className={
                        classNames(
                            "col-1 flex flex-column flex-center flex-middle icon-container ht-70",
                            {active: activeLink === 'trips'}
                        )
                    }
                >
                    <TripsIcon />
                    <div className="fs-10 mt-5 white-color">Trips</div>
                </Link>
            }
            <Fragment>
                {
                    /* {companyType === SEEKER && <Fragment>
                        <Link to="/tracking/1" className={classNames("col-1 flex flex-column flex-center flex-middle icon-container ht-70", {
                            active: activeLink==='tracking'
                        })}>
                            <TrackingIcon />
                            <div className="fs-10 mt-5 white-color">Tracking</div>
                        </Link>
                        {/* <Link to="/transporters"  className={classNames("col-1 flex flex-column flex-center flex-middle icon-container ht-70", {
                            active: activeLink==='transporters'
                        })}>
                            <TransportersIcon />
                            <div className="fs-10 mt-5 white-color">Transporter</div>
                        </Link>
                    </Fragment>} */
                }
                {
                    ((isProvider && userDetails.vims_tabs) ? (userDetails.vims_tabs).includes('eagle_eye') : true) && ([USER_TYPE.AUDIT].indexOf(userType) <= -1) && !LOREAL.includes(userDetails.company_id) && 
                    <Fragment>
                        <Link
                            to="/eagle"
                            className={
                                classNames(
                                    "eagle-eye col-1 flex flex-column flex-center flex-middle icon-container ht-70",
                                    {active: activeLink === 'analytics'}
                                )
                            }
                        >
                            <EagleIcon />
                            <div className="fs-10 mt-5 white-color">Eagle Eye</div>
                        </Link>
                    </Fragment>
                }
                {/* {
                    ([USER_TYPE.AUDIT].indexOf(userType) <= -1) &&
                    <Fragment>
                        <Link
                            to="/settings"
                            className={
                                classNames(
                                    "eagle-eye col-1 flex flex-column flex-center flex-middle icon-container ht-70",
                                    {active: activeLink === 'settings'}
                                )
                            }
                        >
                            <SettingsIcon />
                            <div className="fs-10 mt-5 white-color">Configuration</div>
                        </Link>
                    </Fragment>
                } */}
                {
                    /* {companyType === SEEKER && <Link to="/settings" className={classNames("col-1 flex flex-column flex-center flex-middle icon-container ht-70 align-self-end flex-1", {
                        active: activeLink==='settings'
                    })}>
                        <SettingsIcon />
                        <div className="fs-10 mt-5 white-color">Settings</div>
                    </Link>} */
                }
                {((isProvider && userDetails.vims_tabs) ? (userDetails.vims_tabs).includes('mis') : true) && 
                    <Fragment>
                        <Link
                            to="/holistics"
                            className={
                                classNames(
                                    "eagle-eye col-1 flex flex-column flex-center flex-middle icon-container ht-70",
                                    {active: activeLink === 'mis'}
                                )
                            }
                        >
                            <AnalyticsIcon />
                            <div className="fs-10 mt-5 white-color">MIS</div>
                        </Link>
                    </Fragment>
                }
                {((((CARGILL.includes(userDetails.company_id) && userDetails.user_type == 1) || isCargillProvider.length > 0 )) || (AWL.includes(userDetails.company_id) && userDetails.user_type == 1) || ((PANTALOONS.includes(userDetails.company_id) || PANTALOONS.includes(userDetails.companyId) || isPantaloosProvider.length > 0)) || (LOREAL.includes(userDetails.company_id) || isLorealProvider.length > 0) || ABFRL.includes(userDetails.company_id)) &&
                    <Fragment>
                        {((isProvider && userDetails.vims_tabs) ? (userDetails.vims_tabs).includes('milestone') : true) && 
                        <Link
                            to="/milestone"
                            className={
                                classNames(
                                    "eagle-eye col-1 flex flex-column flex-center flex-middle icon-container ht-70",
                                    {active: activeLink === 'milestone'}
                                )
                            }
                        >
                            <MileStone className="ht-35"/>
                            <div className="fs-10 mt-5 white-color">Milestone</div>
                        </Link>}
                        {((isProvider && userDetails.vims_tabs) ?
                            (userDetails.vims_tabs).includes('audit') : true)
                            &&
                            !isAuditVisible() &&
                        <Link
                            to="/audit"
                            className={
                                classNames(
                                    "eagle-eye col-1 flex flex-column flex-center flex-middle icon-container ht-70",
                                    { active: activeLink === 'audit' }
                                )
                            }
                        >
                            <Audit className="ht-35" />
                            <div className="fs-10 mt-5 white-color">Audit</div>
                        </Link>}
                    </Fragment>
                }
            </Fragment>
            {
                (companyType === SEEKER) && ([USER_TYPE.AUDIT, USER_TYPE.ZONE].indexOf(userType) <= -1) && !LOREAL.includes(userDetails.company_id) && 
                <Fragment>
                    <a href={INDENTING_URL} onClick={() => localStorage.setItem('teg-component', true)} className={classNames("eagle-eye col-1 flex flex-column flex-center flex-middle icon-container ht-70", {
                        active: activeLink==='indenting'
                    })}>
                        <IndentingIcon />
                        <div className="fs-10 mt-5 white-color">Indenting</div>
                    </a>
                </Fragment>
            }
            {
                [USER_TYPE.SUPER_ADMIN].includes(userType) && !LOREAL.includes(userDetails.company_id) &&
                <Fragment>
                    <Link
                        to="/map-display"
                        className={
                            classNames(
                                "eagle-eye col-1 flex flex-column flex-center flex-middle icon-container ht-70",
                                {active: activeLink === 'tracking'}
                            )
                        }
                    >
                        <TrackingIcon />
                        <div className="fs-10 mt-5 white-color">Track</div>
                    </Link>
                </Fragment>
            }
        </div>
    )
}

export default connect(
    null,null
  )(withRouter(Sidebar));
