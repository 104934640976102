import React, {Component} from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';

import Input from 'Components/Input';
import { SingleDatePicker } from 'react-dates';
import Button from 'Components/Button';
import { canUserEdit } from 'Utils/storage';
import TimePicker from 'rc-time-picker';
import Dropdown from 'Components/Dropdown';

import ActionLoader from 'Components/ActionLoader';

// import 'rc-time-picker/assets/index.css';

import { STATUS_TYPES, MANUAL_LOCATION_TYPES, GOOGLE_API_KEY } from 'Constants/commonConstants';

import Moment from 'moment';

const userCanEdit = canUserEdit();


class ManualTracking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location: '',
            geoCodedAddress:null,
            time: Moment().hour(0).minute(0),
            date: null,
            locationType: "",
            statusType: "",
            timeSpent:""
        }
    }
    locationDetails = {
        city: '',
        state: '',
        country: 'India',
        location: '',
        latitude: 0,
        longitude: 0

    }
    submitManualTracking = ()=> {
        try {
            this.props.submitManualTracking({
                trip_id: this.props.tripId,
                latitude: this.locationDetails.latitude,
                longitude: this.locationDetails.longitude,
                location: this.locationDetails.location,
                city: this.locationDetails.city,
                state: this.locationDetails.state,
                country: this.locationDetails.country,
                date: this.state.date?this.state.date.toDate().getTime():null,
                time: this.state.time.format('h:mm A'),
                status: this.state.statusType.id,
                location_type: this.state.locationType.id,
                time_spent: this.state.timeSpent
            });
        } catch(e) {
            console.log('ERROR',e);
        }
    }
    setLocationDetails = ()=> {
        const {geoCodedAddress} = this.state;
        try {
            geoCodedAddress.address_components.forEach((addressComponent)=> {
                if(addressComponent.types.indexOf("administrative_area_level_1") > -1) {
                    this.locationDetails.state = addressComponent.long_name
                }
                else if(addressComponent.types.indexOf("locality") > -1) {
                    this.locationDetails.city = addressComponent.long_name
                }
                else if(addressComponent.types.indexOf("administrative_area_level_2") > -1) {
                    this.locationDetails.city = addressComponent.long_name
                }
                else if(addressComponent.types.indexOf("country") > -1) {
                    this.locationDetails.country = addressComponent.long_name
                }
            });
            getLatLng(geoCodedAddress).then((data)=> {
                this.locationDetails.latitude = data.lat;
                this.locationDetails.longitude = data.lng;
            });
            this.locationDetails.location = geoCodedAddress.formatted_address;
        } catch(e) {

        }
    }
    selectLocationType = (locationType)=> {
        this.setState({
            locationType
        })
    }
    selectStatusType = (statusType)=> {
        this.setState({
            statusType
        })
    }
    fetchLocations = (value)=> {
        this.setState({
            location:value
        })
    }
    handleLocationSelect = (address)=> {
        this.setState({
            location: address
        })
        geocodeByAddress(address)
          .then(results => {
              this.setState({
                geoCodedAddress: results[0]
              }, this.setLocationDetails);
        });
    }
    setTime = (time)=> {
        this.setState({
            time
        })
    }
    changeTimeSpent = (e)=> {
        this.setState({
            timeSpent: e.target.value
        });
    }
    render() {
        const { location } = this.state;
        const { isDataSubmitting } = this.props;
        return (
            <div className="pb-150 pr manual-tracking">
                <header className="fs-18 theme-color fBolder mb-30">Manual Tracking</header>
                <div className="flex flex-middle">
                    <div className="">
                        <div className="fs-12 communication-color fBold mb-5">Location</div>
                        <PlacesAutocomplete
                            onChange={(value) => {this.fetchLocations(value)}}
                            onSelect={(value) => { this.handleLocationSelect(value)}}
                            value={location}
                            searchOptions={{type:['cities']}}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                <div className="dropdown-container mb-20 wt-300 mr-20">
                                    <div className="dropdown-container 2 ht-40 pr">
                                        <input
                                            {...getInputProps({
                                            placeholder: 'Add location',
                                            className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-280',
                                        })} />
                                    </div>
                                    <div className="row-100">
                                        <ul className="dropdown-items flex flex-column" style={{'width': `800px`}}>
                                            {
                                                suggestions.map((item, i) => {
                                                    const className = "dropdown-item pl-16 ht-40";
                                                    return (
                                                        <li
                                                            {...getSuggestionItemProps(item, {
                                                                className,
                                                            })}
                                                        >
                                                           <abbr title={item.description}>
                                                                {item.description.substr(0, 100)}
                                                                {item.description.length > 100?'...':''}
                                                            </abbr>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>

                    <div className="mb-20 wt-300 mr-20">
                        <div className="fs-12 communication-color fBold mb-5">Status: </div>
                        <Dropdown value={this.state.statusType} onSelect={this.selectStatusType} items = {[...STATUS_TYPES]} label="Select Status type" selectedClassName={'col-1'}/>
                    </div>
                    <div className="mb-20 wt-300 mr-20">
                        <div className="fs-12 communication-color fBold mb-5">Location Type: </div>
                        <Dropdown value={this.state.locationType} onSelect={this.selectLocationType} items = {[...MANUAL_LOCATION_TYPES]} label="Select Location type" selectedClassName={'col-1'}/>
                    </div>
                </div>
                <div className="flex">
                    <div className="mb-20 mr-40">
                        <div className="fs-12 communication-color fBold mb-5">Select Date</div>
                        <SingleDatePicker
                            noBorder={true}
                            displayFormat={"DD/MMM/YYYY"}
                            isOutsideRange={() => false}
                            hideKeyboardShortcutsPanel={true}
                            placeholder={"Date"}
                            numberOfMonths={1}
                            date={this.state.date}
                            onDateChange={date => this.setState({ date }, this.search)}
                            focused={this.state.focusedStart}
                            onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                            id="manualDate"
                        />
                    </div>
                    <div className="mb-20 mr-40">
                        <div className="fs-12 communication-color fBold mb-5">Select Time</div>
                        <TimePicker
                            showSecond={false}
                            defaultValue={Moment().hour(0).minute(0)}
                            className="ht-40 wt-280"
                            onChange={this.setTime}
                            format={'h:mm a'}
                            use12Hours
                            inputReadOnly
                        />
                    </div>
                        <Input placeholder="Time Spent" value={this.state.timeSpent} onChange={this.changeTimeSpent}/>
                </div>
                {
                    userCanEdit &&
                    <div className="flex flex-middle">
                        <div className="wt-200">
                            <Button value="Save" onClick={this.submitManualTracking}/>
                        </div>
                    </div>
                }
                {isDataSubmitting && <ActionLoader />}
            </div>
        );
    }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ["places"]
})(ManualTracking);
