import React, { Component } from 'react'
import { className } from 'classnames';

export default class Overlay extends Component {
    render() {
        const { show , hideOverlay, children, containerClass } = this.props;

        return ( 
            show && 
            <div className={`overlay ${containerClass ? containerClass : ''}`}>
                <div className="overlay-container">
                    <span className="overlay-close-button fs-20" onClick={hideOverlay}>
                        X
                    </span>
                    <div className="overlay-body flex">
                         { children }
                    </div>
                </div>
            </div>
        )
    }
}
