import React, { Component, Fragment } from 'react'
import { Marker, InfoWindow } from 'react-google-maps';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import Moment from 'moment';
import { LocationRoute } from 'Components/LocationRoute';
import ActionLoader from 'Components/ActionLoader';
import { GET_TRIP_DETAIL, CLEAR_TRIP_DETAIL } from 'Constants/actionConstants';


class TruckMarker extends Component {

    constructor() {
        super();
        this.state = {
            isInfoBoxShown: false,
            showTruck: ''
        }
    }

    showInfoBox = (e) => {
        this.setState({isInfoBoxShown: true});
    }

    hideInfoBox = () => {
        setTimeout(() => {
            this.setState({isInfoBoxShown: false});
        }, 500);
    }

    onClickTruck = (tripId) => {
        const { dispatch } = this.props;
        dispatch({ type: GET_TRIP_DETAIL, tripId: tripId });

        // this.props.history.push(`/tracking/${tripId}`);
        // if (this.state.showTruck === tripId) {
        //     this.setState({ showTruck : '' });
        // } else {
        //     this.setState({ showTruck : tripId });
        // }
    }

    navToTrip = (tripId) => {
        this.props.history.push(`/tracking/${tripId}`);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: CLEAR_TRIP_DETAIL });
    }

    render() {
        const { trip, type, isTripLoaded, tripDetails } = this.props;
        // const source = trip.source_place;
        // const destination = trip.destination_place;
        // const currentPlace = trip.trip_place;
        // const departureTime = trip.source_time;
        // const arrivalTime = trip.destination_time;
        // const detentionTime = trip.time;
        const last_known_location = (isTripLoaded ? tripDetails.trackingDetails.last_location : {}) || {};
        const infoWindowLatLng = () => {
            if (type === 'source') {
                return new google.maps.LatLng(trip.source_lat, trip.source_long);
            } else if (type === 'destination') {
                return new google.maps.LatLng(trip.destination_lat, trip.destination_long);
            } else {
                return new google.maps.LatLng(trip[1], trip[2]);
            }
        }

        return (
            <Fragment>
                <Marker {...this.props} onClick={() => this.onClickTruck(trip[0])} />
                {
                    // this.state.showTruck == trip[0] &&
                    isTripLoaded && tripDetails.tripId === trip[0] &&
                    <InfoWindow
                        disableAutoPan={true}
                        defaultOptions={{ disableAutoPan: true }}
                        defaultPosition={infoWindowLatLng()}
                        options={{ closeBoxURL: ``, enableEventPropagation: true }}
                    >
                        <Fragment>
                            <div>
                                <span>{'DO No.: '}</span>
                                <a className={'fBolder theme-color'} onClick={() => this.navToTrip(trip[0])}>{tripDetails.doNo}</a>
                                <section className="flex flex-middle tag-text-color fs-10 flex-wrap">
                                    {
                                        tripDetails.isAutoCreated &&
                                        <div className="pl-10 pr-10 flex flex-middle ht-20 br-15 mr-10 mb-5 orange-bg border-orange-1">
                                            Auto Created
                                        </div>
                                    }
                                </section>
                            </div>
                            <div className={'mt-5'}>{'Route'}</div>
                            <div className={"last-known-location action-trip mt-10 ml-5"}>
                                {
                                    tripDetails.tripRouteDetails.map((loc, index) => {
                                        loc.date = Moment(loc.date);
                                        return <LocationRoute loc={loc} first={index == 0} last={index == tripDetails.tripRouteDetails.length -1} key={index} />
                                    })
                                }
                            </div>
                            <div className={'mt-10'}>
                                {'Driver Details'}
                                {
                                    tripDetails.driverDetails.map((driver, index) => {
                                        return (
                                            <div className={'mt-5 fs-12 fBolder ml-10'} key={index}>
                                                <span>
                                                    {(driver.firstName || driver.lastName) ? `${driver.firstName} ${driver.lastName}` : driver.name}
                                                </span>
                                                <br/>
                                                <span>{driver.number}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={'mt-10'}>
                                {'Shipment Details'}
                                {tripDetails.lrNo && <div className={'mt-5 fs-12 fBolder ml-10'}>LR No: {tripDetails.lrNo}</div>}
                                {tripDetails.invoiceNo && <div className={'mt-5 fs-12 fBolder ml-10'}>Invoice No: {tripDetails.invoiceNo}</div>}
                                {tripDetails.itemName && <div className={'mt-5 fs-12 fBolder ml-10'}>Item(s): {tripDetails.itemName}</div>}
                            </div>
                            <div className={'mt-10'}>
                                {'Vehicle Details'}
                                <div className={'mt-5 fs-12 fBolder ml-10'}>
                                    <span>{tripDetails.vehicleDetails.vehicleNo}<br/></span>
                                    {   tripDetails.vehicleDetails.loadingCapacity &&
                                        <span>{tripDetails.vehicleDetails.vehicleServiceType}: {tripDetails.vehicleDetails.loadingCapacity}L<br/></span>
                                    }
                                    {
                                        tripDetails.vehicleDetails.isoNo &&
                                        <span>{tripDetails.vehicleDetails.isoNo}<br/></span>
                                    }
                                    {
                                        tripDetails.vehicleDetails.vehicleType &&
                                        <span>{tripDetails.vehicleDetails.vehicleType}<br/></span>
                                    }
                                    <span>{tripDetails.vehicleDetails.bodyType}</span>
                                </div>
                            </div>
                            {
                                last_known_location.eta &&
                                <div className={'mt-10'}>
                                    {'ETA : '}
                                    <span className={'fs-12 fBolder'}>
                                        {Moment(last_known_location.eta).format('DD MMM, YYYY HH:mm')}
                                    </span>
                                </div>
                            }
                            {
                                last_known_location.distance_covered >= 0 && last_known_location.distance_remaining >= 0 &&
                                <div className={'mt-10'}>
                                    {'Distance : '}
                                    <span className={'fs-12 fBolder'}>
                                        {`${parseInt(last_known_location.distance_covered)}/${parseInt(tripDetails.distance)}km`}
                                    </span>
                                </div>
                            }
                            {
                                last_known_location.location &&
                                <div className={'mt-10'}>
                                    {'Last Known Location & Date : '}
                                    <br/>
                                    <span className={'fs-12 fBolder'}>{last_known_location.location}</span>
                                    <br/>
                                    <span className={'fs-12 fBolder'}>
                                        {last_known_location.date ? Moment(last_known_location.date).format('DD MMM, YYYY HH:mm') : ''}
                                    </span>
                                </div>
                            }
                        </Fragment>
                    </InfoWindow>
                }

                {/*
                    <div onMouseOver={this.showInfoBox} onMouseOut={this.hideInfoBox}>
                        <Marker {...this.props} onMouseOver={this.showInfoBox} onMouseOut={this.hideInfoBox} />
                        {
                            this.state.isInfoBoxShown &&
                            <InfoWindow
                                disableAutoPan={true}
                                defaultOptions={{ disableAutoPan: true }}
                                defaultPosition={infoWindowLatLng()}
                                options={{ closeBoxURL: ``, enableEventPropagation: true }}
                            >
                                <Fragment>
                                    <div style={{ backgroundColor: `#fff`, padding: `8px`, borderRadius: `5px`, zIndex: `999` }} onMouseOver={this.showInfoBox} onMouseOut={this.hideInfoBox}>
                                        <div style={{ fontSize: `14px`, fontColor: `#08233B` }}>
                                            <span className="p15">Route: <b>{source} from {destination}</b> </span><br />
                                            <span className="p15">Current Location: <b>{currentPlace}</b> </span><br />
                                            <span className="p15">Departure Time: <b>{departureTime}</b> </span><br />
                                            <span className="p15">Arrival Time: <b>{departureTime}</b></span><br />
                                            {
                                                detentionTime &&
                                                <Fragment>
                                                    <span className="p15">Detention Time: <b>{detentionTime}</b></span>
                                                    <br />
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                                    <div className="tooltip">
                                        <div className="flex flex-column p15 tLeft">
                                            <div className="fs-12 mb-2 table-text-color">Route</div>
                                            <div className="theme-color fs-14 fBolder">{source} - {destination}</div>
                                        </div>
                                        <div className="flex flex-column p15 tLeft">
                                            <div className="fs-12 mb-2 table-text-color">Current Location</div>
                                            <div className="theme-color fs-14 fBolder">{currentPlace}</div>
                                        </div>
                                        <div className="flex flex-column p15 tLeft">
                                            <div className="fs-12 mb-2 table-text-color">Departure Time</div>
                                            <div className="theme-color fs-14 fBolder">{departureTime}</div>
                                        </div>
                                        <div className="flex flex-column p15 tLeft">
                                            <div className="fs-12 mb-2 table-text-color">Arrival Time</div>
                                            <div className="theme-color fs-14 fBolder">{arrivalTime}</div>
                                        </div>
                                        {
                                            detentionTime &&
                                            <div className="flex flex-column p15 tLeft">
                                                <div className="fs-12 mb-2 table-text-color">Detention Time</div>
                                                <div className="theme-color fs-14 fBolder">{detentionTime}</div>
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            </InfoWindow>
                        }
                    </div>
                */}
            </Fragment>

        )
    }
}

const mapStateToProps = ({trips}) => {
    return {
        tripDetails: trips.tripDetail.data,
        isTripLoaded: trips.tripDetail.isDataLoaded,
    }
};


export default connect(mapStateToProps)(withRouter(TruckMarker));
