import { call, put } from 'redux-saga/effects';
import {
    getDataWithToken,
    GET_BROADCAST_MSG_URL,
    ssoGetDataWithToken,
    SSO_LOGIN_RESPONSE_URL,
    SSO_BASE_URL
} from 'Utils/api';
import {
    GENERIC_TOKEN
} from 'Constants/commonConstants';
import {
    GET_BROADCAST_MSG,
    STORE_BROADCAST_MSG,
    CLEAR_BROADCAST_MSG,
} from 'Constants/actionConstants';
import axios from 'axios'
import { getCompanySeekerId, getToken, storeCompanyType, storeToken, storeUser, storeUserType } from 'Utils/storage';


export function* getBroadcastMsg(action) {
    try {
        // yield put({ type: DATA_LOADING });
        const data = yield call(ssoGetDataWithToken, GET_BROADCAST_MSG_URL, action.params, GENERIC_TOKEN);
        if (data && data.data && data.data.messages) {
            yield put({
                type: STORE_BROADCAST_MSG,
                broadcastMsgs: data.data.messages
            });
        }
        // yield put({
        //     type: TOAST_SUCCESS,
        //     message: data.message || "Success"
        // })
        // yield put({ type: CLEAR_DATA_LOAD });
    } catch(e) {
        // yield put({ type: CLEAR_DATA_LOAD });
        // yield put({
        //     type: TOAST_ERROR,
        //     message: e && e.message ? e.message : "Oops! Something went wrong"
        // })
        console.log('ERROR', e);
    }
}

export function* getUserSession(action) {
    try {
        const { data } = yield call(axios.get, SSO_LOGIN_RESPONSE_URL,
            {
                baseURL: SSO_BASE_URL,
                headers: {
                    token: getToken(),
                    'seeker-id': getCompanySeekerId()
                },
               
            })
 
        storeToken(data.data.token);
        storeUser(data.data);
        storeCompanyType(data.data.company_type);
        storeUserType(data.data.user_type);
        if (action.onSuccess) {
            action.onSuccess();
        }
        // window.location.href = "/dashboard";
    } catch (e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}
