import React, { Fragment }  from "react";
import { GoogleMap, withGoogleMap, withScriptjs, withStateHandlers, DirectionsRenderer } from "react-google-maps";
import { compose, lifecycle, withProps, withState, withHandlers } from "recompose";
import { Marker , InfoWindow } from 'react-google-maps';
import { MapStyles } from 'Components/Map/MapStyles';
import Checkbox from 'Components/Checkbox';
import CustomMarker from "Components/MapMarkers/CutomMarker";

import TruckMarker from './TruckMarker';
import MapControl from 'Components/MapControl';
import AnalyticsCardContainer from 'Components/DashboardCard/AnalyticsCardContainer';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { GOOGLE_API_KEY } from 'Constants/commonConstants';

// const getLatLangGoogleObj = (obj) => new google.maps.LatLng(obj.latitude, obj.longitude);
const img = "M21.474,377.522V117.138c0-14.469,11.729-26.199,26.199-26.199h260.25c14.469,0,26.198,11.73,26.198,26.199v260.385 c0,4.823-3.909,8.733-8.733,8.733H30.207C25.383,386.256,21.474,382.346,21.474,377.522z M231.634,466.724 c0,30.01-24.329,54.338-54.338,54.338c-30.009,0-54.338-24.328-54.338-54.338c0-30.011,24.329-54.338,54.338-54.338 C207.305,412.386,231.634,436.713,231.634,466.724z M204.464,466.724c0-15.005-12.164-27.169-27.169-27.169 s-27.17,12.164-27.17,27.169s12.165,27.17,27.17,27.17S204.464,481.729,204.464,466.724z M130.495,412.385H8.733 c-4.823,0-8.733,3.91-8.733,8.733v26.495c0,4.823,3.91,8.733,8.733,8.733h97.598C108.879,438.862,117.704,423.418,130.495,412.385z  M515.938,466.724c0,30.01-24.329,54.338-54.338,54.338c-30.01,0-54.338-24.328-54.338-54.338 c0-30.011,24.328-54.338,54.338-54.338C491.609,412.385,515.938,436.713,515.938,466.724z M488.77,466.724 c0-15.005-12.165-27.169-27.17-27.169c-15.006,0-27.169,12.164-27.169,27.169s12.164,27.17,27.169,27.17 S488.77,481.729,488.77,466.724z M612,421.118v26.495c0,4.823-3.91,8.733-8.733,8.733h-70.704 c-5.057-34.683-34.906-61.427-70.961-61.427c-36.062,0-65.912,26.745-70.969,61.427H248.261 c-2.549-17.483-11.373-32.928-24.164-43.961h134.994V162.594c0-9.646,7.82-17.466,17.466-17.466h82.445 c23.214,0,44.911,11.531,57.9,30.77l53.15,78.721c7.796,11.547,11.962,25.161,11.962,39.094v118.672h21.253 C608.09,412.385,612,416.295,612,421.118z M523.408,256.635l-42.501-60.393c-1.636-2.324-4.3-3.707-7.142-3.707H407.47 c-4.822,0-8.733,3.91-8.733,8.733v60.393c0,4.824,3.91,8.733,8.733,8.733h108.798C523.342,270.394,527.48,262.421,523.408,256.635z"
const RED = "#DB0000",
    ORANGE = "#FFA500",
    GREY = "#A5A5A5",
    BLACK = "#000000",
    GREEN = "#228B22";
const redCircleImageUrl = "/images/redCircle.svg",
    orangeCircleImageUrl = "/images/orangeCircle.svg",
    greyCircleImageUrl = "/images/greyCircle.svg",
    blackCircleImageUrl = "/images/blackCircle.svg",
    greenCircleImageUrl = "/images/greenCircle.svg",
    blueCircleImageUrl = "/images/blueCircle.svg";

const getDirectionPoints = (direction) => {
    try {
        let markers = [];
        direction.routes.map((route) => {
            const noOfPaths = route.overview_path.length;
            const interval = Math.floor(noOfPaths / 10) || 1;
            for (let i = interval; i < noOfPaths; i += interval) {
                markers.push({
                    latitude: route.overview_path[i].lat(),
                    longitude: route.overview_path[i].lng()
                });
            }
        });
        return markers;
    } catch(e) {
        return [];
    }
};

{/*
    const blueWrapper = {
        path: window.google?google.maps.SymbolPath.CIRCLE:'',
        scale: 9,
        fillColor: "#fff",
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: "#3f51b5"
    };

    const redWrapper = {
        path: window.google?google.maps.SymbolPath.CIRCLE:'',
        scale: 9,
        fillColor: "#fff",
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: "#d0021b"
    };
*/}

const TrackingMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div className="map-loading-element" style={{ height: `100%` }} />,
        containerElement: <div className="map-container-element" style={{height: `calc(100vh - 70px)`, width: `calc(100vw - 120px)`}} />,
        mapElement: <div className="map-element"  style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    withState('hoverState', 'setHoverState', null),
    lifecycle({
        state: { directions: [], directionPoints: [], mapProjection: {}, locationsData: [] },
        componentWillMount() {
            const refs = {};

            this.setState({
                mapProjection: {},
                onMapMounted: ref => {
                    refs.map = ref;
                    const bounds = new window.google.maps.LatLngBounds();
                    refs.map.fitBounds(bounds);
                },
                projectionChanged: () => {
                    this.setState({
                        mapProjection: refs.map.getProjection()
                    });
                },
                onZoomChanged: () => {},
                mapCenter: new google.maps.LatLng(23.23, 69.79),
                activePoint: null,
            });
        },
        componentWillReceiveProps(nextProps) {
            let locationsData = [...nextProps.source_data];
            if (nextProps.showDestinationLocations) {
                locationsData = locationsData.concat([...nextProps.destination_data]);
            }
            this.setState({ locationsData });
        }
    }),
    withHandlers({
        handleHoverState: (props) => (val) => {
            if (val === 'clear') {
                props.setHoverState(null);
            } else {
                props.setHoverState(val);
            }
        },
    })
)(props => (
    <GoogleMap
        defaultZoom={4.75}
        defaultCenter={new google.maps.LatLng(22.9734, 78.6569)}
        defaultOptions={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: true,
            styles: MapStyles
        }}
    >
        <MarkerClusterer
            averageCenter
            styles={[
                {
                    width: 32,
                    height: 33,
                    url: props.subTypeColor === GREEN ? greenCircleImageUrl :
                    (
                        props.subTypeColor === GREY ? greyCircleImageUrl :
                        (
                            props.subTypeColor === BLACK ? blackCircleImageUrl :
                            (
                                props.subTypeColor === ORANGE ? orangeCircleImageUrl :
                                (
                                    props.subTypeColor === RED ? redCircleImageUrl : blueCircleImageUrl
                                )
                            )
                        )
                    ),
                    textColor: 'white',
                    // anchor:[2,22]
                }
            ]}
            // enableRetinaIcons
        >
            {
                props.trips && props.trips.length > 0 &&
                props.trips.map((trip, index) => {
                    if (trip[1] && trip[2]) {
                        return (
                            <TruckMarker
                                openInfoWindow={true}
                                position={new google.maps.LatLng(trip[1], trip[2])}
                                icon={{
                                    path: img,
                                    height: 2.5,
                                    width: 0.5,
                                    fillColor: props.subTypeColor ? props.subTypeColor : props.activeStatus === 'IN-TRANSIT' ? props.colorCode : 'black',
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: (props.subTypeColor === 'black' || props.subTypeColor === '#fff') ? 'transparent' : 'transparent',
                                    scale: 0.04
                                }}
                                zIndex={0}
                                key={'marker-currentLoc- ' + index}
                                trip={trip}
                            />
                        );
                    }
                })
            }
        </MarkerClusterer>
        {
            props.locationsData && props.locationsData.length > 0 &&
            props.locationsData.map((location, index)=> {
                return (
                    <Fragment key={`{location-${index}}`}>
                        <CustomMarker
                            openInfoWindow={false}
                            position={new google.maps.LatLng(location[2], location[3])}
                            icon={{
                                path: window.google ? google.maps.SymbolPath.CIRCLE : '',
                                scale: 6,
                                fillColor: "#fff",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                //strokeColor: "#d0021b"
                                strokeColor: location[1]
                            }}
                            zIndex={0}
                            key={'marker-redWrapper- ' + index}
                        />
                        <CustomMarker
                            openInfoWindow={false}
                            position={new google.maps.LatLng(location[2], location[3])}
                            icon={{
                                path: window.google ? google.maps.SymbolPath.CIRCLE : '',
                                scale: 4,
                                fillColor: location[1],
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: location[1]
                            }}
                            location = {{location: location[4], latitude: location[2], longitude: location[3]}}
                            zIndex={0}
                            key={'marker-redfillWrapper- ' + index}
                        />
                    </Fragment>
                )
            })
        }

        <MapControl position={google.maps.ControlPosition.TOP_LEFT}>
            {/* <Select className={'select fs-16 mt-10 mb-10 ml-10'} placeholder={'Select Plant'} isMulti /> */}
            <div className={'flex flex-between fs-14 mt-10 fBolder bg-white ml-10'}>
                {
                    props.locationTypeFilter && Object.keys(props.locationTypeFilter).length > 0 &&
                    props.locationTypeFilter.location_types.map((location, index) => {
                        return (
                            <div className={'flex flex-between ml-10 mt-5 mb-5 mr-10'} key={index}>
                                <div className={'fBolder curP'} onClick={() => { props.selectLocationType(location) }}>
                                    <span className={'legend-status'} style={{borderColor: location.code}}>
                                        <span className={'inner-circle'} style={{backgroundColor: location.code}}></span>
                                    </span>
                                    <span>{location.name}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </MapControl>
        <MapControl position={google.maps.ControlPosition.LEFT_TOP}>
            <AnalyticsCardContainer
              onSelect={props.getMultipleTrips}
              statuses={props.statuses}
              activeStatus={props.activeStatus}
            />
        </MapControl>
        <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
            <div className={`pr flex flex-wrap mt-5`}>
                <Checkbox
                    onCheck={props.onCheckEvent}
                    id={'show_destinations'}
                    className={'mr-10 map-checkbox'}
                    checked={props.showDestinationLocations}
                    label={"Show Destinations"}
                />
            </div>
        </MapControl>
    </GoogleMap>
));

export default TrackingMap;
