import React, {Component} from 'react';
import classNames from 'classnames';

class TabbedSections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSection: 0
        }
    }
    setActiveSection = (e)=> {
        this.setState({
            activeSection: Number(e.target.getAttribute('name'))
        })
    }
    render() {
        const { sections } = this.props;
        return (
            <div className="tabbed-sections">
                <div className="flex secion-header-container mb-50 bb-light">
                    {
                        sections.map((section, index)=> {
                            return (
                                <div name={index} key={index} className={classNames("section-tab-title fs-16 fMedium mr-30 pb-10 pl-5 pr-5",{
                                    'theme-color bb-theme':index === this.state.activeSection,
                                    'header-light-color': index!==this.state.activeSection
                                } )} onClick={this.setActiveSection}>{section.title}</div>
                            )
                        })
                    }
                </div>
                {sections[this.state.activeSection].component}
            </div>
        )
    }
}

export default TabbedSections;
