import Sticky from 'Components/Sticky';
import React from 'react';
import { connect } from 'react-redux';

class Transporters extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
        <Sticky activeLink={'transporters'}>
            <div className="fixedCenter">
                <div className="fs-40 theme-color tCenter mb-10">
                    Nope! not so early.
                </div>
                <div className="fMedium fs-16 theme-color tCenter">
                    NB: Upcoming section
                </div>
            </div>
        </Sticky>
    );
  }
}

const mapStateToProps = ({trips}) => {
    return {
      }
};


export default connect(mapStateToProps)(Transporters);


