import { combineReducers } from 'redux';
import authentication from 'Reducers/authentication';
import trips from 'Reducers/trips';
import common from 'Reducers/common';

const rootReducer = combineReducers({
  authentication,
  trips,
  common
});

export default rootReducer;
