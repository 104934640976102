import React, { Component } from 'react';

import Input from 'Components/Input';
import Dropdown from 'Components/Dropdown';
import Button from 'Components/Button';
import classNames from 'classnames';
import { canUserEdit, getUser } from 'Utils/storage';
import { CARGO_UNITS, CPLY } from 'Constants/commonConstants';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

const userCanEdit = canUserEdit();


class ShipmentDetails extends Component {
    constructor(props) {
      super(props);
      this.state = {
          trip: this.props.trip,
          selectedDate: moment(this.props.trip.invoiceDate).format('DD/MMM/YYYY'),
       }
    }

    onSubmit = () => {
        const { trip } = this.state;
        const data = {
            trip_id: trip.tripId,
            cargo: trip.cargoWeight,
            do_no: trip.doNo,
            lr_no: trip.lrNo,
            invoice_no: trip.invoiceNo,
            item_name: trip.itemName,
            customer_name: trip.customer_name
        }
        this.props.submitShipmentDetails(data);
    }

   onChangeHandler = (e) => {
    const { name, value } = e.target;
    const { trip } = this.state;
    if(name === 'cargoWeight') {
        trip['cargoWeight']['text'] = value;
    } else{
        trip[name] = value;
    }
    this.setState({ trip });
   }

    render() {
        const { trip,selectedDate } = this.state;
        const isEnabled = [2,3,4].indexOf(trip.type) === -1;
        const isCPLY = CPLY.includes(getUser().company_id)
        return(
            <div className={classNames({
                'disabled': isEnabled
            })}>
                <section className="pl-15 mb-20 mt-40 flex ">
                    <div className="fs-18 theme-color fBolder"> Shipment Details </div>
                </section>
                <section className={'box-shadow pl-10 pr-10 pt-10 pb-10 br-5'}>
                    <section className="pl-15 mb-40 flex flex-between">
                        <Input disabled={isEnabled} required containerClassName="mb-10 mr-50 " name="doNo" type="text" value={trip.doNo} placeholder="DO No." onChange={this.onChangeHandler}/>
                        <Input disabled={isEnabled} containerClassName="mb-10 mr-50 " name="lrNo" type="text" value={trip.lrNo} placeholder="LR No." onChange={this.onChangeHandler}/>
                        <Input disabled={isEnabled} containerClassName="mb-10 mr-50 " name="invoiceNo" type="text" value={trip.invoiceNo} placeholder="Invoice No." onChange={this.onChangeHandler}/>
                        {isCPLY &&<div className="col-3">
                        <label className="fs-10">Invoice Date</label>
                        <SingleDatePicker
                            displayFormat={"DD/MMM/YYYY"}
                            isOutsideRange={() => false}
                            hideKeyboardShortcutsPanel={true}
                            date={selectedDate? moment(selectedDate) : null}
                            numberOfMonths={1}
                            readOnly={true}
                        />
                    </div>}
                        <div className="col-3">
                            <label className="fs-10">Item Name</label>
                            <textarea disabled={isEnabled} required name="itemName" className={'br-5 fs-13 col-1 textarea'} value={trip.itemName} placeholder="Item Name" onChange={this.onChangeHandler}/>
                        </div>
                        {/*<Input disabled={true} required containerClassName="mb-10 " name="itemName" type="text" value={trip.itemName} placeholder="Item Name" />*/}
                    </section>
                    <section className="pl-15 mb-30 flex flex-middle">
                        <Input disabled={isEnabled} name="cargoWeight" type="text" value={trip.cargoWeight && trip.cargoWeight.text ? trip.cargoWeight.text : ''} placeholder="Cargo Weight" onChange={this.onChangeHandler}/>
                        <div className="mr-135"> {trip.cargoWeight && trip.cargoWeight.type ? trip.cargoWeight.type : ''} </div>
                        {/*<div className="wt-250 mt-25">
                            <Dropdown
                                onSelect={(value)=>{this.setState({cargoUnit: value})}}
                                items={[...CARGO_UNITS]}
                                value={this.state.cargoUnit}
                                label={'Select Cargo Unit'}
                                selectedClassName={'col-1'}
                            />
                        </div>*/}
                        <Input disabled={isEnabled} name="customer_name" type="text" value={trip.customer_name} placeholder="Customer Name" onChange={this.onChangeHandler}/>
                    </section>
                    {
                        userCanEdit && !isEnabled &&
                        <div className="flex flex-middle">
                            <div className="wt-200">
                                <Button value="Save" onClick={this.onSubmit}/>
                            </div>
                        </div>
                    }
                 </section>
            </div>
        )
    }
}

export default ShipmentDetails;
