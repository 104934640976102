import {
    TOAST_ERROR,
    TOAST_SUCCESS ,
    HIDE_TOAST,
    STORE_BROADCAST_MSG,
    HIDE_BANNER,
    STORE_SSO_CONFIG_DATA
  } from 'Constants/actionConstants';
  const common = (state={
    toast: {
      message: '',
      type: ''
    },
    broadcastMsgs: [],
    ssoConfigData: {},
    multiroutes: []
  }, action)=> {
    let newState;
    if (action.type === TOAST_ERROR) {
      newState = {...state};
      newState.toast = {
          type: 'ERROR',
          message: action.message
      }
    } else if (action.type === TOAST_SUCCESS) {
      newState = {...state};
      newState.toast = {
        type: 'SUCCESS',
        message: action.message
      }
    } else if (action.type === HIDE_TOAST) {
      newState = {...state};
      newState.toast = {
        type: null,
        message: ''
      }
    } else if (action.type === STORE_BROADCAST_MSG) {
      newState = {...state};
      newState.broadcastMsgs = action.broadcastMsgs;
    } else if (action.type === STORE_SSO_CONFIG_DATA) {
      newState = {...state};
      newState.ssoConfigData = action.ssoConfigData;
    } else if (action.type === HIDE_BANNER) {
      newState = {...state};
      newState.broadcastMsgs = [];
    }
    return newState || state;
  }

  export default common;
