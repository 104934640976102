import React from 'react';
import Select from 'react-select';

const RouteRow = ({route, handleNotification, routeIndex})=> {
    return (
        <div className="flex flex-between flex-middle trip-row">
            <div className="col-7 p5">
                <div className="fBolder fs-16 table-text-color mb-5">{route.displayName || route.location || '-'}</div>
            </div>
            <div className="col-7 p5">
                <div className="fs-16 table-text-color mb-5">{route.locationType || '-'}</div>
            </div>
            <div className="col-7 p5">
                <div className="fs-16 table-text-color mb-5">{route.pincode ||'-'}</div>
            </div>
            <div className="col-7 p5">
                <div className="fs-16 table-text-color mb-5">{route.displayName || route.city || '-'}</div>
            </div>
            <div className="col-7 p5">
                <div className="fs-14 table-text-color mb-5">{route.name || '-'}</div>
                <div className="fs-12 theme-color mb-5 fBolder">{route.number || '-'}</div>
                <div className="fs-14 table-text-color mb-5">{route.customerName || '-'}</div>
                <div className="fs-12 table-text-color mb-5 fBolder">{route.lrNo || '-'}</div>
            </div>
            <div className="col-7 p5">
                <div className="fs-16 table-text-color mb-5">{route.tat || '-'}</div>
            </div>
            <div className="col-7 pr notification-email">
                <Select
                    className={'share route-details fs-16 wt-150'}
                    isMulti
                    value={(route.notificationEmails.length > 0) ? route.notificationEmails.map(val => { return { label : val, value: val } } ) : null}
                    onBlur={(e) => {
                        handleNotification("notificationEmails", e.target.value , routeIndex)
                    }}
                    isClearable={true}
                    onChange={(options) => {
                        const val = options.map(opt => opt.value).join(',');
                        handleNotification("notificationEmails", val, routeIndex, true)
                    }}
                    placeholder={'Notification Emails'}
                />
                {route.notificationEmails && route.notificationEmails.length > 0 &&
                    <div className="pa email-tooltip t-5 l-5">
                        <div className="detail-tooltip pa flex flex-column p5 tLeft wt-150">
                            {(route.notificationEmails|| []).map(val => <div className="table-text-color fs-12 tCenter">{val}</div>)}
                        </div>
                    </div>
                }
                {
                    route.notificationEmails && route.notificationEmails.length > 0 &&
                    route.notificationEmails.filter(notEmail => {
                        const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                        const res = !emailReg.test(notEmail);
                        return (notEmail && res);
                    }).length > 0 &&
                    <div className="error-color fs-12 tCenter">Invalid Email Address</div>
                }
            </div>
            <div className="col-7 pr notification-number">
                <Select
                    className={'share route-details fs-16 wt-150 p5'}
                    isMulti
                    value={(route.notificationNumbers.length > 0) ? route.notificationNumbers.map(val => { return { label : val, value: val } } ) : null}
                    onBlur={(e) => {
                        handleNotification("notificationNumbers", e.target.value , routeIndex)
                    }}
                    isClearable={true}
                    onChange={(options) => {
                        const val = options.map(opt => opt.value).join(',');
                        handleNotification("notificationNumbers", val, routeIndex, true)
                    }}
                    placeholder={"Notification No.'s"}
                />
                {route.notificationNumbers && route.notificationNumbers.length > 0 &&
                    <div className="pa number-tooltip t-5 l-5">
                        <div className="detail-tooltip pa flex flex-column p5 tCenter wt-150">
                            {(route.notificationNumbers|| []).map(val => <div className="table-text-color fs-12">{val}</div>)}
                        </div>
                    </div>
                }
                {route.notificationNumbers && route.notificationNumbers.length > 0 &&
                    route.notificationNumbers.filter(notNum =>{
                        return (notNum.length < 10 || notNum.length > 10 || (Number(notNum) === 'NaN'))
                    }).length > 0 &&
                    <div className="error-color fs-12 tCenter">Invalid Phone No.</div>}
            </div>
        </div>
    );
}

export default RouteRow;
