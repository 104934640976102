import { LOREAL, PANTALOONS } from "Constants/commonConstants";
import { getCompanyType, getGlobalSeeker, getUser } from "./storage";

export const scroll = (y= 2000, duration = 200) => {
    const initialY =
    document.documentElement.scrollTop || document.body.scrollTop;
    const baseY = (initialY + y) * 0.5;
    const difference = initialY - baseY;
    const startTime = performance.now();

function step() {
    let normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) normalizedTime = 1;

    window.scrollTo(0,baseY + difference * Math.cos(normalizedTime * Math.PI));
    if (normalizedTime < 1) window.requestAnimationFrame(step);
}
window.requestAnimationFrame(step);
};

export const getTrackingType = (type) => {
    let value = '';
    if(type == 1) {
        value = `App: `;
    } else if(type == 2) {
        value = `SIM: `;
    } else if(type == 3) {
        value = `GPS: `;
    } else if(type == 4) {
        value = `Manual: `;
    } else if(type == 5) {
        value = `None: `;
    }
    return value;
}

export const getTrackingTypeText = (value) => {
    let operatorText = '';
    for(let i in value ) {
        if(value[i].enabled){
            operatorText = getTrackingType(value[i].tracking_type);
        }
    }
    return operatorText;
}

export const getTrackingLabel = (type) => {
    let value = '';
    if(type == 1) {
        value = 'App';
    } else if(type == 2) {
        value = `CONSENT STATUS: `;
    } else if(type == 3) {
        value = `GPS: `;
    } else if(type == 4) {
        value = 'Manual';
    }
    return value;
}

export const getEmbedCode = (companyName) => {
    let embedCode = '6c2c40f580782d255265865d';
    if(companyName === 'ADANI') {
        embedCode = 'a2ed5546a1c709288b53d285'
    } else if(companyName === 'RAJ_PETRO') {
        embedCode = '9f33aafc89e7fcebb6048261'
    }
    else if(companyName === 'CENTURY_PLY') {
        embedCode = '355e95f2521ff6f3ab3bf631'
    }
    return embedCode;
}

export const checkSpecialCharacters = (event) => {
    const result = event.target.value.replace(/[^a-z0-9]/gi, '');
    return result
}

export function getCompanyName(seekerCompany) {
    if (isLoreal(seekerCompany)) {
      return 'loreal';
    }
    if (isPantloons(seekerCompany)) {
      return 'pantaloons';
    }
    return 'default';
  }
   
  export function isLoreal(seekerCompany) {
    let id = '';
    if (isValueThere(seekerCompany)) {
      id = seekerCompany;
    } else {
      id = getCompanyId();
    }
    return LOREAL.includes(id);
  }
   
  export function getCompanyId() {
    return getUser().company_id
  }
   
  export function isValueThere(data) {
    const C1 = data !== undefined && data !== null && data !== '';
   
    if (C1) {
      return true;
    }
   
    return false;
  }


//   PROVIDER ROUTERING FUNCTIONS
export function getProviderSideCompanyId() {
    return getGlobalSeeker().company_id
  }

  export function isProviderSideLoreal(seekerCompany) {
    let id = '';
    if (isValueThere(seekerCompany)) {
      id = seekerCompany;
    } else {
      id = getProviderSideCompanyId();
    }
    return LOREAL.includes(id);
  }

  export function getProviderSideCompanyName(seekerCompany) {
    if (isProviderSideLoreal(seekerCompany)) {
      return 'loreal';
    }
    if (isProviderSidePantloons(seekerCompany)) {
      return 'pantaloons';
    }
    return 'default';
  }

  export function isPantloons(seekerCompany) {
    let id = '';
    if (isValueThere(seekerCompany)) {
      id = seekerCompany;
    } else {
      id = getCompanyId();
    }
    return PANTALOONS.includes(id);
  }
  export function isProviderSidePantloons(seekerCompany) {
    let id = '';
    if (isValueThere(seekerCompany)) {
      id = seekerCompany;
    } else {
      id = getProviderSideCompanyId();
    }
    return PANTALOONS.includes(id);
  }

  export const getDefaultPath = (type,userName) => {
    const C1 = type == 1
    const C2 = getUser().user_type == 11
      if(userName == "Pantaloons"){
        return '/milestone'
      }

      if(userName == "Loreal" && C2){
        return '/milestone'
      }
      
      return '/dashboard'
  }