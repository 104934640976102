import React from 'react';
import classNames from 'classnames';
const Tab = ({ tab, active, selectTab, index, isAlternateName, tabStyle, company }) => {
    const getBGColor = (tabId,company) => {
        if (company === 'loreal'){
            switch (tabId) {
                case 2:
                    return '084990'
                case 6:
                    return 'CCA429'
                case 8:
                    return '82BD3E'
                case 62:
                    return 'E85C0D'
                case 55:
                    return '808080'
                case 9:
                    return 'C40C0C'
                default:
                    return '8D1722'
            }
        }
        else{
            switch (tabId) {
                case 2:
                    return '084990'
                case 6:
                    return 'CCA429'
                case 8:
                    return '82BD3E'
                case 62:
                    return '8D1722'
                default:
                    return '8D1722'
            }
        }
        
    }
    return (
        <button type="button" style={{
            minWidth: `${((company == 'loreal')) ? `28%` : '19%'}`, padding: `${((tabStyle == 'milestone') &&
                (active)) ? `20px` : ''}`, background: `${((tabStyle == 'milestone')) ?
                `#${getBGColor(tab.id, company)}` : ''}`,
            marginLeft: `${((index !== 0) && (tabStyle == 'milestone')) ? '10px' : ''}`
        }}
            onClick={selectTab}
            className={classNames((tab.value !== "User ID" &&
                tab.value !== "Mobile No.") ? (`${(tabStyle == 'milestone') ?
                    'milestoneTab flex-between' : `${(tabStyle == 'companyType') ?
                        'companyTab flex-center' : 'tab flex-center'}`} 
        flex flex-middle tCenter fSemibold flex-1 curP `) :
                ("subtab flex flex-middle tCenter flex-center fSemibold flex-1 curP"),
                {
                    active: active
                })}>
            {isAlternateName ? tab['name2'] : tab.value}
            {(tab.count || tab.count === 0 || tabStyle == 'milestone' ||
                tabStyle == 'companyType' || tabStyle == 'milestoneBound' ||
                tabStyle == 'auditBound') &&
                <span className={`${tabStyle == 'milestone' ? '' : 'fs-12'} pl-5`}>
                    {tabStyle == 'milestone' ? (tab.count || 0) : '(' + (tab.count || 0) + ')'}
                </span>}
        </button>
    )
}

export default Tab;
