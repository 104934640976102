export const MapStyles = [
    {
      "elementType": "labels.text",
      "stylers": [
        {
          "color": "#3f51b5"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#3f51b5"
        }
      ]
    },
    {
      "elementType": "geometry.fill",
      "stylers": [
        {
          // "color": "#c6dbf5",
        }
      ]
    }
]