import Moment from 'moment';

export default class Location {
    constructor(loc={}) {
        Object.assign(this, loc);
        this.date = Moment(this.date);
        this.system_time = this.system_time ? Moment(this.system_time) : null;
        this.eta = this.eta !== '--' ? Moment(this.eta) : '';
    }
}
