import React from 'react';
import classNames from 'classnames';
import { getTrackingTypeText } from 'Utils/common';

const DriverDetails = ({driverDetails, trackingPreferences, gpsVendorType, operatorTypes, trackingPreference })=> {
    return (
        <div className="detail-tooltip pa">
            {
                <div className="flex flex-column max-content">
                    {
                        (driverDetails || []).map((detail, index)=> {
                            return (
                                <div key={index} className="flex flex-column p10 tLeft">
                                    <div className="theme-color mb-5 fs-14 fBolder">
                                        {(detail.firstName || detail.lastName) ? `${detail.firstName} ${detail.lastName}` : detail.name}
                                    </div>
                                    <div className="fs-12 table-text-color">{detail.number}</div>
                                    <div className="fs-12 table-text-color">
                                        {getTrackingTypeText(trackingPreferences)}
                                        <div className={classNames('consent-status', {
                                        'bg-orange': detail.consentStatus == 'pending',
                                        'bg-green': detail.consentStatus == 'allowed',
                                        'bgc-error': detail.consentStatus == 'not_added',
                                        'bg-check-consent-error': detail.consentStatus == 'consent_check_error'
                                        })}></div>
                                        <span className="fs-12 table-text-color">{+trackingPreference === 2 && detail.operatorType &&  operatorTypes.filter(operator => operator.id === detail.operatorType).length > 0 ? operatorTypes.filter(operator => operator.id === detail.operatorType)[0].name : +trackingPreference === 3 && detail.gpsVendor && gpsVendorType.filter(gps => gps.id === detail.gpsVendor).length > 0 ? gpsVendorType.filter(gps => gps.id === detail.gpsVendor)[0].name : getTrackingTypeText(trackingPreferences) !== 'SIM: ' ? 'n/a' : 'Not Added' }</span>
                                    </div>
                                    {
                                        detail.userResponse &&
                                        <div className="fs-12 table-text-color">
                                            IVR/SMS Response: <span className="fBolder">{detail.userResponse}</span>
                                        </div>
                                    }
                                    {
                                        detail.responseTime &&
                                        <div className="fs-12 table-text-color mb-5 fBolder">
                                            {detail.responseTime}
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default DriverDetails;
