import React, { Component } from 'react';
import { ReactComponent as DropdownIcon } from 'Containers/Navbar/icons/dropdown.svg';
import ClassName from 'classnames';
import crossMark from 'Components/SearchDropdown/icons/cross-mark.svg';

class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDropdownShown: false,
            activePage: 1,
            searchText: ''
        }
    }
    showDropdown = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({
            isDropdownShown: !this.state.isDropdownShown
        });
    }

    hideDropdown = () => {
        setTimeout(() => {
            this.setState({
                isDropdownShown: false
            });
        }, 200)
    }

    onSelect = (value) => {
        this.setState({ searchText: '' }, () => {
            this.props.onSelect(value);
        })
    }

    onChange = (e) => {
        const{noNumericInput}=this.props;
        if(!!noNumericInput){
            // debugger
            const inputValue = e.target.value;
            const alphaOnly = inputValue.replace(/[^a-zA-Z]/g, '');
            this.setState({ searchText: alphaOnly });  
        }else{
            this.setState({ searchText : e.target.value });
        }
        
        // this.props.onSelect(e.target.value);
    }

    render() {
        const {
            label,
            items,
            value,
            customClass,
            selectedClassName,
            hasClearFilter,
            displayKey,
            auditSelect,
            isMilestoneFilter,
            isAuditFilter,
            isDisabled,
            isReason,
            isVendorType,
            isTransporterName,
            marginRightDisabled
        } = this.props;

        const { searchText } = this.state;

        return (
            <div
                onClick={isDisabled ? '' : this.showDropdown} style={{ cursor: 'pointer' }} className={`dropdown-container ${(isMilestoneFilter || isAuditFilter) ? 'ht-30' : 'ht-40'} ${(marginRightDisabled) ? '' : 'mr-20'} pr flex-1 ` + (customClass || "")}>
                <div className={`pa r-10 ${isReason ? 't-5' : 't-10'} `}>
                    <DropdownIcon />
                </div>
                <input
                    className={`curP mile-dropdown ${auditSelect ? 'row-32' : 'selected-item row-100'} flex flex-middle pl-10 pr-16` + selectedClassName}
                    style={{ width: `${isVendorType ? '150px' : isTransporterName ? '165px' : ''}` }}
                    tabIndex="1"
                    disabled={isDisabled}
                    onBlur={this.hideDropdown}
                    value={searchText ? searchText : (value === "0" || !value) ? '' : ((value || {})[displayKey || 'name'] || (value || {}).value) || ''}
                    onChange={(e) => this.onChange(e)}
                    placeholder={label}
                ></input>
                {/* <div onClick={this.showDropdown}
                    tabIndex="1"
                    onBlur={this.hideDropdown}
                    className={"curP selected-item row-100 flex flex-middle pl-10 pr-16 " + selectedClassName}> { (value === "0" || !value) ? label : ((value || {}).name || (value || {}).value) } </div> */}
                <div className="pa r-10 t-15 mr-15">
                    {
                        value && (value.length > 0 || Object.keys(value).length > 0) && hasClearFilter &&
                        <span className="cross-img" style={{ backgroundImage: `url(${crossMark})` }} onClick={hasClearFilter}></span>
                    }
                </div>
                {this.state.isDropdownShown && <ul className="dropdown-items flex flex-column" style={{ 'maxHeight': '300px', 'overflow': 'scroll' }}>
                    {items.filter((item) => {
                        if (!searchText) {
                            return true;
                        }
                        return item[displayKey || 'name'].toLowerCase().includes(searchText.toLowerCase());
                    }).map((item, index) => {
                        return (
                            index >= 0 && <li
                                key={index}
                                name={item[displayKey || 'name']}
                                className={ClassName("dropdown-item pl-16 min-ht40")}
                                onClick={this.onSelect.bind(this, item)}
                            >
                                {item[displayKey || 'name'] || item.value}
                            </li>
                        )
                    })
                    }
                </ul>}
            </div>
        );
    }
}

export default Dropdown;
