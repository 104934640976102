import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MapT1 from './MapT1';
import { GOOGLE_API_KEY } from 'Constants/commonConstants';
import { GET_MULTIMODAL_TRACKING_DETAILS } from 'Constants/actionConstants';
import Sticky from 'Components/Sticky/index';



export class MultiModelTracking extends Component {
    componentDidMount() {
        this.getTripDetails();
    }
    getTripDetails = () => {
        const { dispatch, match } = this.props;
        dispatch({
            type: GET_MULTIMODAL_TRACKING_DETAILS,
            //   fnr_no: "23102129194"
            fnr_no: match.params.fnrNo
        });
    }
    render() {
        const { tracking } = this.props
        console.log('tracking:', tracking)

        const mapCenter = { lat: 22.9734, lng: 78.6569 };
        const mapZoom = 6;

        const sourceMarker = {
            latitude: tracking && tracking.trackingDetails && tracking.trackingDetails.location && parseFloat(tracking.trackingDetails.location.station_from_lat),
            longitude: tracking && tracking.trackingDetails && tracking.trackingDetails.location && parseFloat(tracking.trackingDetails.location.station_from_long),
            sourceStation: tracking && tracking.trackingDetails && tracking.trackingDetails.location && tracking.trackingDetails.location.station_from_code,
        };

        const destinationMarker = {
            latitude: tracking && tracking.trackingDetails && tracking.trackingDetails.location && parseFloat(tracking.trackingDetails.location.station_to_lat),
            longitude: tracking && tracking.trackingDetails && tracking.trackingDetails.location && parseFloat(tracking.trackingDetails.location.station_to_long),
            destStation: tracking && tracking.trackingDetails && tracking.trackingDetails.location && tracking.trackingDetails.location.station_to_code,
        };

        const currentLocationMarker = {
            latitude: tracking && tracking.fois_tracking_route && parseFloat(tracking.fois_tracking_route[0].latitude),
            longitude: tracking && tracking.fois_tracking_route && parseFloat(tracking.fois_tracking_route[0].longitude),
            CurrentLocationstatus: tracking && tracking.fois_tracking_route && tracking.fois_tracking_route[0].last_reported_status,
            Currentstation: tracking && tracking.fois_tracking_route && tracking.fois_tracking_route[0].station_code,
            CurrentLocationCreatedOnTime: tracking && tracking.fois_tracking_route && tracking.fois_tracking_route[0].created_on,
        };


        const polyline1Path = ((tracking || {}).fois_tracking_route || [])
            .filter((item) =>
                item &&
                item.latitude !== null &&
                item.longitude !== null &&
                !isNaN(parseFloat(item.latitude)) &&
                !isNaN(parseFloat(item.longitude))
            )
            .map((item) => ({
                ...item,
                lat: parseFloat(item.latitude),
                lng: parseFloat(item.longitude),
            }));
        console.log("polyline1Path:", polyline1Path)
        const polyline2Path = ((tracking || {}).fois_railway_route || [])
            .filter((item) =>
                item &&
                item.latitude !== null &&
                item.longitude !== null &&
                !isNaN(parseFloat(item.latitude)) &&
                !isNaN(parseFloat(item.longitude))
            )
            .map((item) => ({
                ...item,
                lat: parseFloat(item.latitude),
                lng: parseFloat(item.longitude),
            }));
        console.log("polyline2Path:", polyline2Path)
        return (
            <Fragment>
                <div className='flex'>
                    <Sticky activeLink={'tracking'}></Sticky>
                    <div style={{ height: `90%`, width: `40%` }}>
                        <h1 className='mt-60 theme-color'><b>FOIS tracking details</b></h1>
                        <h2 className='mt-15'>FNR No. :{tracking && tracking.fnr_no} </h2>
                    </div>
                    <div style={{ height: `90%`, width: `60%` }} >

                        <MapT1
                            center={mapCenter}
                            zoom={mapZoom}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100vh`, width: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            polyline1Path={polyline1Path}
                            polyline2Path={polyline2Path}
                            sourceMarker={sourceMarker}
                            destinationMarker={destinationMarker}
                            currentLocationMarker={currentLocationMarker}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}



const mapStateToProps = ({ trips }) => {
    return {
        tracking: trips.multimodaltrackingDetail,
        isTrackingLoaded: trips.trackingDetail.isDataLoaded,
        detailedLog: trips.detailedLog.data || [],
        isDetailedLogLoaded: trips.detailedLog.isDataLoaded,
        mapLocation: trips.mapLocation || null,
        trips: trips.trips.data,
        tripData: trips.trips.actualData,
        currentLocation: trips.currentLocation,
        multipleTrips: trips.multipleTrips.data || [],
        shareDetails: trips.shareDetails,
        gpsVendorType: trips.gpsVendors || [],
        operatorTypes: trips.operatorTypes || [],
        reminderEmailDetails: trips.reminderEmailDetails || []
    }
};


export default connect(mapStateToProps)(withRouter(MultiModelTracking));
