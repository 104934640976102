import React, { Fragment } from 'react';
import { GoogleMap, withGoogleMap, withScriptjs, DirectionsRenderer } from "react-google-maps";
import { compose, lifecycle, withProps } from "recompose";
import MapControl from 'Components/MapControl';
import MapMarkers from 'Components/MapMarkers';
import CustomMarker from "Components/MapMarkers/CutomMarker";
import Tabs from 'Components/Tabs';
import { MapStyles } from 'Components/Map/MapStyles';
import SearchDropdown from 'Components/SearchDropdown';
import { Polyline } from "react-google-maps";
import { Polygon } from "react-google-maps";
import { IS_DIRECTION_ENABLED, GOOGLE_API_KEY } from 'Constants/commonConstants';

const getDirectionPoints = (direction) => {
    try {
        let markers = [];
        direction.routes.map((route) => {
            const noOfPaths = route.overview_path.length;
            const interval = Math.floor(noOfPaths / 10) || 1;
            for (let i = interval; i < noOfPaths; i += interval) {
                markers.push({
                    latitude: route.overview_path[i].lat(),
                    longitude: route.overview_path[i].lng()
                })
            }
        });
        return markers;
    } catch(e) {
        return [];
    }
};

const getLatLangGoogleObj = (obj) => new google.maps.LatLng(obj.latitude, obj.longitude);

export const Map = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div className="map-loading-element" style={{ height: `100%` }} />,
        containerElement: <div className="map-container-element" style={{ height: `calc(100vh - 70px)`, width: `calc(100vw - 480px)` }} />,
        mapElement: <div className="map-element"  style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentWillMount() {
            const refs = {};
            const DirectionsService = new google.maps.DirectionsService();
            // const directions = [];
            
            const waypts = [];
            let { routeMap } = this.props.tripForTracking;
            const totalDrops = routeMap && routeMap.length - 1 || 0;
            const isRoundTrip = routeMap ? routeMap[0].branch_id === routeMap[totalDrops].branch_id : false;
            if (totalDrops > 0) {
                routeMap.map((route, index) => {
                    if ((totalDrops !== index)) {
                        waypts.push({
                            location: new google.maps.LatLng(route.latitude, route.longitude),
                            stopover: true
                        });
                    }
                    
                    // else if (!isRoundTrip) {
                    //     waypts.push({
                    //         location: route.location,
                    //         stopover: true
                    //     });
                    // }
                })
            }
            const dest =  isRoundTrip && new google.maps.LatLng(routeMap[totalDrops - 1].latitude, routeMap[totalDrops - 1].longitude) || new google.maps.LatLng(routeMap[totalDrops].latitude, routeMap[totalDrops].longitude);
            DirectionsService.route({
                origin: new google.maps.LatLng(routeMap[0].latitude, routeMap[0].longitude),
                destination: dest,
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: waypts || [],
                optimizeWaypoints: true,
                provideRouteAlternatives: true,
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                        directionPoints: getDirectionPoints(result)
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });

            if (isRoundTrip) {
                DirectionsService.route({
                    origin: dest,
                    destination: new google.maps.LatLng(routeMap[0].latitude, routeMap[0].longitude),
                    travelMode: google.maps.TravelMode.DRIVING,
                    waypoints: [],
                    optimizeWaypoints: true,
                    // provideRouteAlternatives: true,
                }, (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        this.setState({
                            returnDirections: result,
                            returnDirectionPoints: getDirectionPoints(result)
                        });
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                });
            }

            this.setState({
                mapProjection: null,
                zoom: 7,
                onMapMounted: ref => {
                    refs.map = ref;
                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.extend(new window.google.maps.LatLng(routeMap[0].latitude, routeMap[0].longitude));
                    // bounds.extend(new window.google.maps.LatLng(this.props.tripForTracking.destination.latitude, this.props.tripForTracking.destination.longitude));
                    bounds.extend(isRoundTrip ? new google.maps.LatLng(routeMap[totalDrops - 1].latitude, routeMap[totalDrops - 1].longitude) : new google.maps.LatLng(routeMap[totalDrops].latitude, routeMap[totalDrops].longitude))
                    if (refs && refs.map) {
                        refs.map.fitBounds(bounds);
                    }
                },
                projectionChanged: () => {
                    this.setState({
                        mapProjection: refs.map.getProjection()
                    });
                },
                onZoomChanged: () => {
                    this.setState({
                        zoom: refs.map.getZoom()
                    });
                },
                mapCenter: new google.maps.LatLng(routeMap[0].latitude, routeMap[0].longitude),
                isRoundTrip: isRoundTrip
            });
        }
    })
)(props => {
    const queryParams = new URLSearchParams(window.location.search);
    const url_branchId = queryParams.get('branch_id') || null;
    // if(url_branchId !==null){
    //     props.tripForTracking.routeMap.filter(x=>x.branch_id===url_branchId || x.source)
    // }
    return(
    <GoogleMap
        defaultZoom={4}
        defaultCenter={props.mapCenter || new google.maps.LatLng(22.9734, 78.6569)}
        defaultOptions={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: true,
            styles: MapStyles
        }}
        ref={props.onMapMounted}
        onProjectionChanged={props.projectionChanged}
        zoom={props.zoom}
        onZoomChanged={props.onZoomChanged}
    >

        {
        props.tripForTracking.routeMap.map((route, index ) => {
        return (
            <Fragment>
            {route.geofence &&
            <Polygon
                path={(route.geofence || []).map(obj => { 
                    let coords =  {
                        lat: obj[1],
                        lng: obj[0]
                    }
                        return coords;
                })}
                key={`${index}_polygon`}
                options={{
                    fillColor: index === 0 ? "#e50f18" :  (index === 2 ? "purple" : "green"),
                    fillOpacity: 0.4,
                    strokeColor: index === 0 ? "#e50f18" :  (index === 2 ? "purple" : "green"),
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    strokeWidth: 5
                }}
            />}
            {route.parking_geofence &&
            <Polygon
                path={(route.parking_geofence || []).map(obj => { 
                    let coords =  {
                        lat: obj[1],
                        lng: obj[0]
                    }
                        return coords;
                })}
                key={`${index}_parkingPolygon`}
                options={{
                    fillColor: "yellow",
                    fillOpacity: 0.4,
                    strokeColor: "yellow",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    strokeWidth: 5
                }}
            />}
            {/* {route.geofence &&
                <CustomMarker
                    position={getLatLangGoogleObj(route.geofence)}
                    icon={{
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 9,
                        fillColor: "#fff",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "#e50f18"
                    }}
                    zIndex={19}
                    key={index}
                >
                </CustomMarker>} */}
        </Fragment>
        )})}
        
        
        {
            <div>
                
                {/* {props.directions && IS_DIRECTION_ENABLED && <DirectionsRenderer directions={props.directions} />} */}
                {
                    props.directions && IS_DIRECTION_ENABLED && (props.directions.routes || []).reverse().map((route, index) => {
                        // return (
                        //     <DirectionsRenderer
                        //         directions={props.directions}
                        //         options={{
                        //             suppressMarkers: true,
                        //             routeIndex: index,
                        //             polylineOptions: new google.maps.Polyline({
                        //                 strokeColor: index === 0 ? '#00b3fd' : '#000',
                        //                 strokeOpacity: 1.0,
                        //                 strokeWeight: 3
                        //             })
                        //         }}
                        //     />
                        // )
                        return (
                            <Polyline
                                path={props.directions.routes[index].overview_path}
                                geodesic={true}
                                options={{
                                    strokeColor: index === props.directions.routes.length - 1 ? "#00b3fd" : "#a0a0a0",
                                    strokeOpacity: 1,
                                    strokeWeight: 3,
                                }}
                                zIndex = {index===0 ? 20 : 0}
                                key={'poly' + index}
                            />
                        )
                    })
                }
                {
                    props.returnDirections && IS_DIRECTION_ENABLED && (props.returnDirections.routes || []).reverse().map((route, index) => {
                        return (
                            <Polyline
                                path={props.returnDirections.routes[index].overview_path}
                                geodesic={true}
                                options={{
                                    strokeColor: index === props.returnDirections.routes.length - 1 ? "#FFA500" : "#a0a0a0",
                                    strokeOpacity: 1,
                                    strokeWeight: 3,
                                }}
                                zIndex = {index === 0 ? 20 : 0}
                                key={'poly' + index}
                            />
                        )
                    })
                }
                {
                    props.tripForTracking && props.tripForTracking &&
                    <MapMarkers
                        mapProjection={props.mapProjection}
                        zoom={props.zoom}
                        source={props.tripForTracking.source}
                        destination={props.tripForTracking.destination}
                        locations={props.tripForTracking.locations || []}
                        wayPoints={props.tripForTracking.wayPoints || []}
                        lastKnownLocation={props.tripForTracking.lastKnownLocation}
                        vehicleDetails={props.tripForTracking.vehicleDetails}
                        markedLocation={props.markedLocation}
                        directionMarkers = {props.directionPoints || []}
                        returnDirectionPoints = {props.returnDirectionPoints || []}
                        currentLocation = {props.currentLocation}
                        activeTabId = {props.tripForTracking.trackingType}
                        subtype = {props.tripForTracking.sub_type}
                        routeMap = {props.tripForTracking.routeMap}
                        locationTypes = {props.tripForTracking.locationType}
                        fastTagDetails = {props.fastTagDetails && props.fastTagDetails.fastag_records}
                    />
                }

                {false && <MapControl position={google.maps.ControlPosition.TOP_LEFT}>
                    <div className={'flex flex-between fs-14 mt-10 fBolder bg-white ml-10'}>
                        {
                            props.tripForTracking.locationType && props.tripForTracking.locationType.length > 0 &&
                            props.tripForTracking.locationType.map((location, index) => {
                                return (
                                    <div className={'flex flex-between ml-10 mt-5 mb-5 mr-10'} key={index}>
                                        <div className={'fBolder'}>
                                            <span className={'legend-status'} style={{borderColor: location.code}}>
                                                <span className={'inner-circle'} style={{backgroundColor: location.code}}></span>
                                            </span>
                                            <span>{location.name}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="p10 tab-on-map">
                        <div className="col-4">
                            <div className="theme-color tCenter fBolder fs-14 ht-40 pt-10 pr-40 pl-40 br-5" style={{ backgroundColor: '#3f51b5',color: '#ffffff' }}>
                                {props.operatorType.value}
                            </div>
                            {/*
                                <Tabs
                                    selectTab={(trackingType) => {
                                        props.searchWithTrackingType(trackingType);
                                    }}
                                    activeTabId={props.tripForTracking.trackingType}
                                    tabs={props.tabs}
                                />
                            */}
                        </div>
                    </div>
                </MapControl>}
                {/*
                    <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
                        <div className="p10">
                            <div className="col25">
                                <SearchDropdown
                                    onKeyPress={(e) => {
                                        if (e.nativeEvent.which === 13) {
                                            props.searchMap(e.target.value);
                                        }
                                    }}
                                    onChange={() => {}}
                                    label="Search place, checkpost"
                                    items={[]}
                                />
                            </div>
                        </div>
                    </MapControl>
                */}
            </div>
        }
    </GoogleMap>
)});
