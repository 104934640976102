import React from 'react';
import { connect } from 'react-redux';

import TripRow from 'Components/TripRow';
import ResponsiveTable from 'Components/ResponsiveTable'
import { getCompanyType } from 'Utils/storage';
import { SEEKER } from 'Constants/commonConstants';
import classNames from 'classnames';

class TripTable extends React.Component {
  render() {
    const {trips, isTransporterDetailsSubmitting, confirmAtOrigin, showlogDetails, truck_status, operatorTypes} = this.props;
    const companyType = getCompanyType();
    return (
        <ResponsiveTable>
            <div className="trip-table-container">
                {trips && trips.length > 0 && <div className="trip-table">
                    <div className="flex mb-15">
                        <span className={classNames("fBolder fs-12 header-light-color pl-15",{
                            'wt-150' : companyType === SEEKER,
                            'wt-250' : companyType !== SEEKER
                        })}>Indent Details</span>
                        <span className={classNames("fBolder fs-12 header-light-color pl-15", {
                            'wt-150' : companyType === SEEKER,
                            'wt-250' : companyType !== SEEKER
                        })}>Route Details</span>
                        {companyType === SEEKER && <span className="fBolder fs-12 header-light-color wt-150 pl-15">Transporter and Service</span>}
                        <span className={classNames("fBolder fs-12 header-light-color pl-15 wt-250",{
                            // 'wt-250' : companyType === SEEKER,
                            // 'wt-250' : companyType !== SEEKER
                        })}>Dates</span>
                        <span className="fBolder fs-12 header-light-color wt-150 pl-15">Driver Details</span>
                        <span className="fBolder fs-12 header-light-color wt-150 pl-15">Vehicle Details</span>
                        {/* <span className={classNames("fBolder fs-12 header-light-color pl-15",{
                            'wt-150' : companyType === SEEKER,
                            'wt-250' : companyType !== SEEKER
                        })}>Consignee Details</span> */}
                    </div>
                    {
                        (trips || []).map((trip, index)=> {
                            return (
                                <TripRow
                                    numbersOperatorData={this.props.numbersOperatorData}
                                    getNumbersOperator={this.props.getNumbersOperator}
                                    isTransporterDetailsSubmitting={isTransporterDetailsSubmitting}
                                    submitTransporterDetails={this.props.submitTransporterDetails}
                                    trip={trip}
                                    key={index}
                                    truck_status={this.props.truck_status}
                                    gpsVendorType={this.props.gpsVendorType}
                                    tripData={this.props.tripData[index]}
                                    confirmAtOrigin={confirmAtOrigin}
                                    showlogDetails={showlogDetails}
                                    onChangeVehicleDetails={this.props.onChangeVehicleDetails}
                                    selectVehicleServiceType={this.props.selectVehicleServiceType}
                                    vehicleServiceTypes={this.props.vehicleServiceTypes}
                                    vehicleTypes={this.props.vehicleTypes}
                                    vehicleBodyTypes={this.props.vehicleBodyTypes}
                                    vehicleData={this.props.vehicleData}
                                    operatorTypes={operatorTypes}
                                    searchTerm={this.props.searchTerm}
                                    getVehicleRemarks={this.props.getVehicleRemarks}
                                    vehicleRemarks={this.props.vehicleRemarks}
                                    rejectVehicle={this.props.rejectVehicle}
                                />
                            )
                        })
                    }
                </div>
                }
            {!(trips && trips.length > 0) &&  <span className="pa contentCenter fBolder fs-14 header-light-color pl-15">No Trip is Currently Available</span>}

            </div>
        </ResponsiveTable>
    );
  }
}

const mapStateToProps = () => {
  return {

  }
};


export default connect(
)(TripTable);
