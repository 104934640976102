import Button from "Components/Button/index";
import React, { PureComponent } from "react";

class RequestLogPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      openClosePopup,
      requestLogLRnumber,
      onChangeRequestLogPopupLRnumber,
      onSubmit,
    } = this.props;
    return (
      <div className="overlay">
        <div className="overlay-container exception-modify-popup">
          <div
            style={{ height: "max-content" }}
            className="overlay-body create-location-popup "
          >
            {
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fs-18 mt-10 mb-10 tCenter">
                    <b>Pick up request Log</b>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="fs-18 mr-10 mb-10 tCenter"
                    onClick={openClosePopup}
                  >
                    X
                  </div>
                </div>

                <div className="fs-18 mt-30 mb-10">
                  Please enter the LR number:
                  <div className="fs-18 mt-30 mb-10">
                    <input
                      style={{
                        border: "solid 2px black",
                        height: "35px",
                        borderRadius: "4px",
                        width: "200px",
                      }}
                      input-container={false}
                      value={requestLogLRnumber}
                      onChange={(e) => {
                        onChangeRequestLogPopupLRnumber(e);
                      }}
                      placeholder="LR number"
                    />
                    <Button
                      value="Submit"
                      style={{ width: "60px", marginLeft: "10px" }}
                      onClick={() => {
                        onSubmit(requestLogLRnumber);
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default RequestLogPopup;
