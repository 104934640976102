import Sticky from 'Components/Sticky';
import React from 'react';
import { connect } from 'react-redux';

import { Map } from 'Components/Map';
import NoTripMap from 'Components/Map/NoTripMap';
import TripTrackingSideBar from 'Components/TripTrackingSideBar';
import ActionLoader from 'Components/ActionLoader'

import { GET_TRACKING_DETAIL, GET_DETAILED_LOG, SEARCH_ON_MAP, GET_CURRENT_LOCATION, GET_SHARE_DETAILS, SAVE_SHARE_DETAILS, GET_GPS_VENDORS, GET_OPERATOR_TYPE, REMINDER_EMAIL_DATA, GET_FAST_TAG_ROUTES, GET_FAST_TAG_LOGS } from 'Constants/actionConstants';
import Location from 'Models/Location';
import { TRACKING_TYPES_MAP } from 'Constants/commonConstants';

const mapTabFilters = [...TRACKING_TYPES_MAP];

class TripTracking extends React.Component {
  constructor() {
    super();
  }
  trackingType = -1;
  searchMap = (search)=> {

        const { dispatch, match } = this.props;
        dispatch({
            type: SEARCH_ON_MAP,
            search,
            trackingType: this.trackingType,
            tripId: match.params.id
        })
  }
  searchDetailedRouteLog = (search, startDate, endDate, trackingType, offset, limit, order_by)=> {
    const { dispatch, match, tripId, isTTBIL } = this.props;
    dispatch({
        type:GET_DETAILED_LOG,
        tripId: isTTBIL ? tripId : match.params.id,
        search,
        trackingType,
        startDate,
        endDate,
        offset,
        limit,
        order_by
    });
  }
  searchWithTrackingType = (trackingType)=> {
      const { dispatch, match, isPopup, tripId } = this.props;
      this.trackingType = trackingType;
    dispatch({
        type: GET_TRACKING_DETAIL,
        tripId: isPopup ? tripId : match.params.id,
        trackingType
    });
  }
  onSearch = (value)=>{
    localStorage.setItem('teg-component', true);
    location.href="/trips?search=" + value;
  }

  getSharedDetails = () => {
    const { dispatch, match, isTTBIL, tripId } = this.props;
    const data = {
      trip_id: isTTBIL ? tripId : match.params.id
    }
    dispatch({ type: GET_SHARE_DETAILS, data });
  }

  getFastTagLogs = () => {
    const { dispatch, match } = this.props;
    const data = {
      trip_id: match.params.id
    }
    dispatch({ type: GET_FAST_TAG_LOGS, data });
  }

  onSaveSharedDetails = (data) => {
    const { dispatch, match, isTTBIL, tripId  } = this.props;
    data.url = isTTBIL ? `${window.location.origin}/tracking-public/${tripId}` : `${window.location.origin}/tracking-public/${match.params.id}`;
    data.trip_id = isTTBIL ? tripId : match.params.id;
    dispatch({ type: SAVE_SHARE_DETAILS, data });
  }

  displayMap = () => {
    const { tracking, isTrackingLoaded, detailedLog, isDetailedLogLoaded,mapLocation, currentLocation, shareDetails, gpsVendorType, operatorTypes, isPopup, fastTagDetails } = this.props;
    if(this.trackingType === -1) {
        this.trackingType = (tracking && tracking.trackingType)?tracking.trackingType:-1;
    }

    const operatorType = mapTabFilters.find(val => {return val.id == tracking.trackingType});
    return(
      <>
      { isTrackingLoaded &&
      <div className="flex flex-between" style={{height: '100vh'}}>
          <div className="flex-basis-30p" style={{height: '1400px'}}>
              <TripTrackingSideBar
                  isDetailedLogLoaded={isDetailedLogLoaded}
                  searchDetailedRouteLog={this.searchDetailedRouteLog}
                  detailedLog={detailedLog || []}
                  tripForTracking={tracking}
                  getSharedDetails={this.getSharedDetails}
                  shareDetails={shareDetails}
                  onSaveSharedDetails={this.onSaveSharedDetails}
                  isShare={true}
                  gpsVendorType={gpsVendorType}
                  operatorTypes={operatorTypes}
                  reminderEmailDetails={this.props.reminderEmailDetails}
                  postReminderEmail={this.postReminderEmail}
                  getFastTagLogs={this.getFastTagLogs}
                  fastTagLogs={this.props.fastTagLogs}
                  />
          </div>
          <div  className="flex-1">
              <div className="map-container">
                  {tracking.tripId && <Map
                      searchWithTrackingType= {this.searchWithTrackingType}
                      searchMap={this.searchMap}
                      tabs={mapTabFilters}
                      tripForTracking={tracking}
                      markedLocation={mapLocation}
                      currentLocation={currentLocation}
                      operatorType={operatorType}
                      fastTagDetails={fastTagDetails}
                  />}
                  {!tracking.tripId &&
                  <NoTripMap
                      searchWithTrackingType= {this.searchWithTrackingType}
                      searchMap={this.searchMap}
                      tabs={mapTabFilters}
                      trackingType = {this.trackingType || -1}
                  />}
              </div>
          </div>
      </div>}
    {!isTrackingLoaded && <ActionLoader fixed={true}/>}
  </>
  )
} 

  render() {
    const { isPopup } = this.props;
    return (
      <>
      {isPopup ? <div className='tracking-container'>{this.displayMap()}</div> : <Sticky activeLink={'tracking'} onSearch = {this.onSearch}>{this.displayMap()}</Sticky>}
      </>
    );
  }

  componentDidCatch(e) {
    console.log(e);
  }
  componentDidMount() {
    const { dispatch, match, isPopup, tripId } = this.props;
    this.getTripDetails();
    this.intervalTripId = setInterval(() => this.getTripDetails(), 300000);
    
    dispatch({type: GET_GPS_VENDORS });
    dispatch({ type: GET_OPERATOR_TYPE });
    const tripDetails = this.props.multipleTrips.length ? this.props.multipleTrips : this.props.tripData;
    const trip =  tripDetails.filter((trip) => (trip.tripId || trip.trip_id) ==  (isPopup ? tripId : match.params.id));
    if( trip.length && trip[0].trackingType == 3 ) {
      this.intervalId = setInterval(() => this.getCurrentLocation(trip), 3600000);
      this.getCurrentLocation(trip);
    }
    this.getReminderEmail();
    this.getFastTag();
  }

  getTripDetails = () => {
    const { dispatch, match, tripId, isPopup } = this.props;
    dispatch({
      type: GET_TRACKING_DETAIL,
      tripId: isPopup ? tripId : match.params.id
    });
  }

  getFastTag = () => {
    const { dispatch, match ,tripId,isPopup} = this.props;
    dispatch({
      type: GET_FAST_TAG_ROUTES,
      tripId: isPopup ? tripId : match.params.id
    });
  }

  getReminderEmail = () => {
    const { dispatch, match, tripId, isTTBIL} = this.props;
    const data = {
      trip_id: isTTBIL ? tripId : match.params.id,
    }
    dispatch({
      type: REMINDER_EMAIL_DATA,
      data
    })
  }

  postReminderEmail = (emails) => {
    const { dispatch, match } = this.props;
    const data = {
      emails,
      trip_id: match.params.id,
    }
    dispatch({
      type: REMINDER_EMAIL_DATA,
      data,
      isSave: true,
      onSuccess: () => {this.getReminderEmail()}
    })
  }

  getCurrentLocation = (trip) => {
    const { dispatch, match } = this.props;
    dispatch({
      type: GET_CURRENT_LOCATION,
      data: {
        vehicle_no: Object.keys(trip[0].vehicleDetails).length ? trip[0].vehicleDetails.vehicleNo : '',
        vendor: this.getVendorDetails(trip[0].driver_details[0].gpsVendor)
      }
    });
  }

  getVendorDetails = (vendorId) => {
    let vendorType= '';
    if(vendorId == 1) {
      vendorType = 'omd';
    } else if(vendorId == 2) {
      vendorType = 'r_track';
    } else if(vendorId == 3) {
      vendorType = 'loconav';
    } else if(vendorId == 4) {
      vendorType = 'pro_track';
    }
    return vendorType;
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
    clearInterval(this.intervalTripId);
  }
}


const mapStateToProps = ({trips}) => {
    return {
        tracking: trips.trackingDetail.data,
        isTrackingLoaded: trips.trackingDetail.isDataLoaded,
        detailedLog: trips.detailedLog.data || [],
        isDetailedLogLoaded: trips.detailedLog.isDataLoaded,
        mapLocation: trips.mapLocation || null,
        trips: trips.trips.data,
        tripData : trips.trips.actualData,
        currentLocation: trips.currentLocation,
        multipleTrips: trips.multipleTrips.data || [],
        shareDetails: trips.shareDetails,
        gpsVendorType: trips.gpsVendors || [],
        operatorTypes: trips.operatorTypes || [],
        reminderEmailDetails: trips.reminderEmailDetails || [],
        fastTagDetails: trips.fastTagDetails || [],
        fastTagLogs: trips.fastTagLogs || []
      }
};


export default connect(mapStateToProps)(TripTracking);


