import React, { Component, Fragment, useCallback, useEffect } from 'react';
import Button from 'Components/Button';
import Dropdown from 'Components/Dropdown';
import { SingleDatePicker } from 'react-dates';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import SearchDropdown from 'Components/SearchDropdown';
import { SCHEDULER_STATUS } from 'Constants/commonConstants';
import PaginationDropdown from 'Components/PaginationDropdown';
import Input from 'Components/Input';
import TripTracking from 'Pages/Trips/TripTracking';
import { Link } from 'react-router-dom';
import { Container, Button as ActionButton, lightColors, darkColors } from 'react-floating-action-button';



class SchedulerDashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            schedulerList: props.schedulerList,
            dest_location: '',
            status_text: '',
            to_date_search: null,
            from_date_search: null,
            offset: 0,
            limit: 20,
            di_time: '',
            openTrackPopup: false,
            trip_id: null,
            branches: [],
            locationList: [],
            diTime: [],
            searchText: '',
            is_fullScreen: false
        }
    }
    componentDidMount() {
        this.setState({is_fullScreen: false})
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.fullScreenHandler, false)
    }

    fullScreenHandler = (event) => {
        if(event.key === "Escape" || event.key === "Meta"){
            this.closeFullscreen;
        }
    }

    trackTrip = (trackId) => {
       const { trip_id, openTrackPopup } = this.state;
       this.setState({openTrackPopup: !openTrackPopup, trip_id: trackId})
    }
    
    compareTime = (plan,actual) => {
        const diffMs = actual-plan;
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
        if(plan && actual){
            if(diffHrs === 0 && diffMins <= 15){
                return 'bg-pending'
            }else if(plan >= actual){
                return 'bg-success-status'
            } else return 'bg-error'
        } else return null;
    }

    getColor = (status) => {
        if(status === "SCHEDULED"){
            return "bg-white";
        } else if(status === "IN-TRANSIT"){
            return "bg-pending";
        } else if(status === "DELAYED" || status === "NOT-STARTED"){
            return "bg-error";
        } else if(status === "ON-TIME" || status === "COMPLETED"){
            return "bg-success-status"
        }
        return "bg-white";
    }

    getDITime = () => {
        const { diTimeList } = this.props;
        const { diTime } = this.state;
        diTimeList.map((data) => {
            let options = {value: 0, name: ''}
        
            options.value = data;
            options.name = data;
            diTime.push(options)
        })
        this.setState({diTime})
    }
    
    getBranches = () => {
        const { branchList } = this.props;
        const { branches } = this.state;
        branchList.map((data) => {
            let options = {value: 0, name: ''}
            options.value = data.branch_id;
            options.name = data.display_name;
            options.group_id = data.group_id;
            branches.push(options)
        })
        this.setState({branches})
    }

    getLocation = () => {
        const { locations } = this.props;
        const { locationList } = this.state;
        locations.map((data) => {
            let options = {value: 0, name: ''}
            options.value = data.branch_id;
            options.name = data.display_name;
            options.group_id = data.group_id;
            locationList.push(options)
        })
        this.setState({locationList})
    }

    fullScreen = () => {
        var elem = document.getElementById("scheduler_screen");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
          }
        this.setState({is_fullScreen: true});
    }

    closeFullscreen = () => {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
        this.setState({is_fullScreen: false});
      }

    
    render() {
        const { schedulerList, handleRouteDataChange, locations, schedulerCount, branchList, diTimeList } = this.props;
        const { dest_location, openTrackPopup, trip_id, branches, searchText, diTime, is_fullScreen, locationList } = this.state;
        const operatorType = schedulerList.find(val => {return val.do_no == trip_id});
        const count = 10;
        if(branchList.length > 0 && branchList.length !== branches.length){
            this.getBranches();
        }
        if(diTimeList.length > 0 && diTimeList.length !== diTime.length){
            this.getDITime();
        }
        if(locations.length > 0 && locations.length !== locationList.length){
            this.getLocation();
        }
        var elem = document.getElementById("scheduler_screen");
        // let screenSize = elem.requestFullscreen && (elem.requestFullscreen ? );
        return (
            
            <div>
            <section className="trip-detail-container pr-30 pl-10 add-trip-container">
                    {this.props.isFilter &&<section className={'box-shadow pl-10 pr-10 pt-10 pb-10 br-5 mb-10'}>
                        <section className="pl-15 mb-10 flex mt-10">
                            <div className="flex-1 mr-20 col-3">
                                <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Origin Location</div>
                                <Dropdown
                                    onSelect={(value) => {this.props.searchFields(value, 'source_location'), this.setState({source_location: value})}}
                                    // customClass="flex-1 mr-20"
                                    items={branches || []}
                                    value={this.state.source_location ? this.state.source_location : ''}
                                    label={'— Select — '}
                                    selectedClassName={'col-1'}
                                    hasClearFilter={() => {this.setState({source_location: ''}), this.props.searchFields('', 'source_location')}}
                                />
                            </div>
                            <div className="col-3 flex-1 mr-20">
                                <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Destination Location</div>
                                {/* <Dropdown
                                    hideSearch={true}
                                    onChange={(value) => {handleRouteDataChange(value), this.setState({dest_location: value})} }
                                    onSelect={(value) => {this.props.searchFields(value, 'dest_location'), this.setState({dest_location: value.plant_code})}}
                                    label={'— Select — '}
                                    items={locations || []}
                                    value={ dest_location ? dest_location : '' }
                                    selectedClassName={"route-detail-search"}
                                    // containerClassName={' wt-315'}
                                    hasClearFilter={() => {this.setState({dest_location: ''}), this.props.searchFields(dest_location, 'dest_location')}}
                                /> */}
                                <Dropdown
                                    onSelect={(value) => {this.props.searchFields(value, 'dest_location'), this.setState({dest_location: value})}}
                                    customClass="flex-1 mr-20"
                                    items={(locationList || [])}
                                    value={this.state.dest_location ? this.state.dest_location : ''}
                                    label={'— Select — '}
                                    selectedClassName={'col-1'}
                                    hasClearFilter={() => {this.setState({dest_location: ''}), this.props.searchFields('', 'dest_location')}}
                                />
                            </div>
                            <div className="flex-1 mr-20 col-3">
                                <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Search</div>
                                <div className="search-scheduler">
                                <input
                                    value={searchText}
                                    // onChange={this.onChange}
                                    onChange={(e) => this.setState({searchText: e.target.value}, this.props.searchFields(e.target.value, 'search'))}
                                    placeholder={"Do No., Vehicle No., Driver No."}
                                    // className={'wt-315'}
                                    // style={{borderBottom : 'none'}}
                                />
                                </div>
                                </div>
                            
                            
                    </section>
                    <section className="pl-15 mb-10 flex mt-10">
                    <div className="flex-1 col-3 mr-20">
                                <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Date</div>
                                <div className="flex date-select">
                                <DateRangePicker
                                    startDate={this.state.from_date_search ? moment(this.state.from_date_search) : null}
                                    startDateId="startDate"
                                    endDate={this.state.to_date_search ? moment(this.state.to_date_search) : null}
                                    endDateId="endDate"
                                    onDatesChange={({ startDate, endDate }) => {
                                    let { from_date_search, to_date_search } = this.state;
                                    from_date_search = startDate ? new Date(startDate).setHours(0, 0, 0) : new Date(this.state.from_date_search).setHours(0, 0, 0);
                                    to_date_search = endDate ? new Date(endDate).setHours(23, 59, 59) : to_date_search;
                                    this.setState({ from_date_search, to_date_search });
                                    this.props.searchFields(from_date_search, 'from_date_search')
                                    this.props.searchFields(to_date_search, 'to_date_search')
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    displayFormat={'DD MMM YYYY'}
                                    noBorder={true}
                                    minimumNights={0}
                                    isOutsideRange={() => false}
                                />
                                </div>
                            </div>
                            
                            <div className="mr-20 col-3 flex-1">
                                <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Status</div>
                                <Dropdown
                                    onSelect={(value)=>{this.setState({status_text: value}), this.props.searchFields((value.name).toLowerCase(), 'status_text')}}
                                    customClass="mr-0"
                                    items={SCHEDULER_STATUS}
                                    value={this.state.status_text}
                                    label={'— Select — '}
                                    selectedClassName={'col-1'}
                                    hasClearFilter={() => {this.setState({status_text: ''}), this.props.searchFields('', 'status_text')}}
                                />
                            </div>

                            <div className="col-6" style={{marginRight: "10px"}}>
                                <div className="fs-12 theme-color col-2 fBolder mb-10 add-trip-form-label">DI Time</div>
                                
                                <Dropdown
                                    onSelect={(value)=>{this.setState({di_time: value}), this.props.searchFields(value.value, 'di_time')}}
                                    customClass="flex-1 col-1 mr-20"
                                    items={diTime || []}
                                    value={this.state.di_time}
                                    label={'— Select — '}
                                    selectedClassName={'col-1'}
                                    hasClearFilter={() => {this.setState({di_time: ''}), this.props.searchFields('', 'di_time')}}
                                />
                                
                            </div>
                            <div className="wt-7p ml-5 mr-5 mt-25">
                                <Button value="Download" onClick={() => this.props.searchTrips(false, '', true)}/>
                            </div>
                            <div className="wt-7p ml-5 mr-5 mt-25">
                                <Button value="Full Screen" onClick={this.fullScreen}/>
                            </div>
                            
                    </section>
                </section>}
                </section>
                <div className={"flex flex-between flex-middle"} style={{width: "100%"}}>
                
                <table  id={"scheduler_screen"}>
                    <thead className="white-color bg-theme-color tCenter">
                        <tr>
                            {/* <th className="pl-4 pr-4 tCenter border-gray-1" rowSpan={3}>Sl No</th> */}
                            <th className="pl-20 pr-24 tCenter border-gray-1 " style={{width: '15%'}} rowSpan={3}>Status</th>
                            {/* <th className="pl-20 pr-24 tCenter border-gray-1 " rowSpan={3}>Track</th> */}
                            <th className="pl-10 pr-10 tCenter border-gray-1" rowSpan={3}>Trip Number</th>
                            {/* <th className="pl-10 pr-10 tCenter border-gray-1 " rowSpan={3}>Origin Location</th> */}
                            <th className="pl-10 pr-10 tCenter border-gray-1 " rowSpan={3}>DI Time</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " rowSpan={3}>Vehicle No</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " rowSpan={3}>Transporter</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " rowSpan={3}>Destination</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " colSpan={2}>Origin</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " colSpan={6}>Destination</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " colSpan={2}>Warehouse</th>
                            
                        </tr>
                        <tr style={{backgroundColor: "#528ae9"}}>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " colSpan={2}>Gate Out</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " colSpan={2}>Report Time</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " colSpan={2}>Gate In</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " colSpan={2}>Gate Out</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 " colSpan={2}>Gate In</th>
                        </tr>
                        <tr style={{backgroundColor: "#b1c7ec"}}>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Plan</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Actual</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Plan</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Actual</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Plan</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Actual</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Plan</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Actual</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Plan</th>
                            <th className="pl-10 pr-10 tCenter border-gray-1 ">Actual</th>
                        </tr>
                        
                    </thead>
                   <tbody className={"fs-80p fBold"}>
                        {(schedulerList || []).map((data, index) => {
                            const origin = data.trip_details.route_details[0] || {};
                            const destination = data.trip_details.route_details[1] || {};
                            const warehouse = data.trip_details.route_details[2] || {};
                            return(
                                <tr key={index}>
                                    {/* <td className="pl-2 pr-2 tCenter border-theme-light">{schedulerRouteDetails.sl_no}</td> */}
                                    <td className={`${this.getColor(data.status_text)}  p5-15 tCenter border-theme-light`} style={{textTransform: 'uppercase'}}>{`${data.status_text} `}<span className="curP" 
                                    style={{ 
                                        color: 'white', 
                                        fontSize: '10px',
                                        fontWeight: '900',
                                        padding: '3px 6px',
                                        borderRadius: '50%',
                                        backgroundColor: '#548bea',
                                        float: 'right'}} onClick={() => this.trackTrip(data.trip_id)}>T</span></td>
                                    {/* <td className=" p5 tCenter border-theme-light theme-color curP" style={{fontWeight: "600"}}onClick={() => this.trackTrip(data.trip_id)}>Track</td> */}
                                    <td className=" p5 tCenter border-theme-light"><Link to={'/trip/' + data.trip_id} className="pr tCenter br-5">{data.do_no}</Link></td>
                                    {/* <td className=" p5 tCenter border-theme-light">{origin.plant_code}</td> */}
                                    <td className=" p5 tCenter border-theme-light">{data.di_time}</td>
                                    <td className=" p5 tCenter border-theme-light">{data.vehicle_details.vehicle_no}</td>
                                    <td className=" p5 tCenter border-theme-light">{(data.transporter_details.name).slice(0, count) + (data.transporter_details.name.length > count ? "..." : "")}</td>
                                    <td className=" p5 tCenter border-theme-light">{destination.plant_code}</td>
                                    <td className=" p5 tCenter border-theme-light">{origin.gate_out_time && moment(origin.gate_out_time).format("HH:mm")}</td>
                                    <td className={`${this.compareTime(origin.gate_out_time, origin.is_out)}  p5 tCenter border-theme-light`}>{ origin.is_out && moment(origin.is_out).format("HH:mm")}</td>
                                    <td className=" p5 tCenter border-theme-light">{destination.reporting_time && moment(destination.reporting_time).format("HH:mm")}</td>
                                    <td className={`${this.compareTime(destination.reporting_time, destination.is_in)}  p5 tCenter border-theme-light`}>{ destination.is_in && moment(destination.is_in).format("HH:mm")}</td>
                                    <td className=" p5 tCenter border-theme-light">{destination.gate_in_time && moment(destination.gate_in_time).format("HH:mm")}</td>
                                    <td className={`${this.compareTime(destination.gate_in_time, destination.actual_gate_in)}  p5 tCenter border-theme-light`}>{destination.actual_gate_in && moment(destination.actual_gate_in).format("HH:mm")}</td>
                                    <td className=" p5 tCenter border-theme-light">{destination.gate_out_time && moment(destination.gate_out_time).format("HH:mm")}</td>
                                    <td className={`${this.compareTime(destination.gate_out_time, destination.is_out)}  p5 tCenter border-theme-light`}>{ destination.is_out && moment(destination.is_out).format("HH:mm")}</td>
                                    <td className=" p5 tCenter border-theme-light">{warehouse.gate_in_time && moment(warehouse.gate_in_time).format("HH:mm")}</td>
                                    <td className={`${this.compareTime(warehouse.gate_in_time, warehouse.is_in)}  p5 tCenter border-theme-light`}>{warehouse.is_in && moment(warehouse.is_in).format("HH:mm")}</td>
                                </tr>
                            )
                        })}
                        <div className="exitBotton">
                            <Container styles={{ right: '1vw', top: '1vw' }}>
                                <ActionButton 
                                    // tooltip="Download as CSV" icon="fas fa-plus"
                                    styles={{backgroundColor: darkColors.lighterRed, color: lightColors.white, width: '30px', height: '30px', marginRight: '-20px', marginTop: '-9px'}}
                                    onClick={this.closeFullscreen}
                                    className='fBolder'
                                >  X
                                </ActionButton>
                                
                            </Container>
                        </div>
                        <div className="exitBotton">
                            <Container styles={{ right: '5vw', bottom: '4vw' }}>
                                <ActionButton 
                                    // tooltip="Download as CSV" icon="fas fa-plus"
                                    styles={{backgroundColor: darkColors.blue, color: lightColors.white, width: '30px', height: '30px', marginRight: '-20px', marginTop: '-9px'}}
                                    onClick={() => this.props.onClickPagination('next')}
                                    className='fBolder'
                                >  <i className="arrow right"></i>
                                </ActionButton>
                                
                            </Container>
                        </div>
                        {this.props.offset !== 0 &&
                        <div className="exitBotton">
                            <Container styles={{ left: '-80vw', bottom: '4vw' }}>
                                <ActionButton 
                                    // tooltip="Download as CSV" icon="fas fa-plus"
                                    styles={{backgroundColor: darkColors.blue, color: lightColors.white, width: '30px', height: '30px', marginTop: '-9px'}}
                                    onClick={() => this.props.onClickPagination('previous')}
                                    className='fBolder'
                                >  <i className="arrow left"></i>
                                </ActionButton>
                                
                            </Container>
                        </div>}
                        
                    </tbody>
                </table>
                        
            </div>

            {schedulerList && schedulerList.length > 0 &&
                    <Fragment>
                    {this.props.offset !== 0 && <div className="wt-150 ml-5 mt-20 fLeft inBlock"><Button value="Previous" onClick={() => this.props.onClickPagination('previous')}/></div>}
                    <div className="wt-150 ml-5 mt-20 inBlock">
                            {schedulerCount > 0 && <div className="wt-210 ml-750">
                                <PaginationDropdown onSelect={this.props.changeOffset} offset={this.props.offset} limit={20} totalCount={schedulerCount}/>
                                </div>}
                            </div>
                    <div className="wt-150 ml-5 mt-20 ml-400 inBlock"><Button value="Next" onClick={() => this.props.onClickPagination('next')}/></div>
                    </Fragment>
                }

                {this.state.openTrackPopup && this.state.trip_id &&
                <Fragment>
                <div className="overlay">
                <div className="overlay-container map-popup">
                    <span className="overlay-close-button fs-20" onClick={this.trackTrip}>
                        X
                    </span>
                    <div className="overlay-body change-vehicle-body-popup">
                        <div className="flex flex-wrap flex-middle">
                            <div className="mb-20 wt-190 mr-20">
                                <TripTracking
                                    searchWithTrackingType= {this.props.searchWithTrackingType}
                                    tripId={this.state.trip_id}
                                    isPopup={true}
                                    tripForTracking={schedulerList}
                                    isTTBIL={true}
                                />
                                {/* <Map
                                    tripForTracking={schedulerList}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </Fragment>}
        </div>    
        )}
    }

export default SchedulerDashboard;
