import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';

import { SIGN_IN, REQUEST_OTP, LOGIN_WITH_OTP, VALIDATE_SSO_USER } from 'Constants/actionConstants';

import Logo from 'Components/Logo';
import Input from 'Components/Input';
import Button from 'Components/Button';
import Tabs from 'Components/Tabs';
import Link from 'Components/Link';
import Timer from 'Components/Timer';
import OtpInput from 'react-otp-input';
import Recaptcha from 'react-recaptcha';
import {SEEKER, PROVIDER, USER_ID, MOBILE_NO} from 'Constants/commonConstants';
import {SSO_BASE_URL, SSO_URL} from '../../utils/api';

class SigninForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focussed: false,
            email: "",
            password: "",
            companyType: SEEKER,
            tabType: USER_ID,
            contact_number: null,
            expire_time: null,
            start_time: null,
            hasExpired: false,
            invalidOTP: false,
            invalidContact: false,
            otp: '',
            errorMsg: '',
            isVerified: false,
            isSSOLogin: false
        }
    }
    tabs = [
        {
            id: SEEKER,
            value:'Seeker'
        }, {
            id: PROVIDER,
            value: 'Provider'
        }
    ];
    subtabs = [
        {
            id: USER_ID,
            value:'User ID'
        }, {
            id: MOBILE_NO,
            value: 'Mobile No.'
        }
    ];
    onChange = (e)=> {
        if(e.target.name == "contact_number"){
          e.target.value.length <= 10 ?
          this.setState({
            [e.target.name]: e.target.value
          }) : null
        } 
        else {
          this.setState({
            [e.target.name]: e.target.value
          })
        }
    }
    selectCompanyType = (companyType)=> {
        this.setState({
            companyType
        })
    }
    selectTabType = (tabType)=> {
        this.setState({
          tabType
        })
    }
    verifyCallback =  (response)=> {
       if(response){
        this.setState({
          isVerified:true
         });
         this.setState({
          errorMessage:""
         });
       }
    };
    formSubmit = (e, isDirectSSO=false) => {
        const { tabType, otp, isSSOLogin } = this.state;
        const isOTP = (tabType === 2);
        e.preventDefault();

        if (isDirectSSO) {
            localStorage.setItem('isSSOUser', true);
            window.location.replace(`${SSO_BASE_URL}${SSO_URL}/?sso&next=${window.location.origin}/dashboard&module=3`);
        } else if(isSSOLogin){
            this.ssoLogin();
        } else {
            isOTP ? (otp ? this.otpSignin(e) : this.sendOTP()) : this.signin(e);
        }
    }
    sendOTP = () => {
        const { dispatch } = this.props;
        const { contact_number, companyType } = this.state;
        dispatch({
          type: REQUEST_OTP,
          data: {
            contact_number,
            company_type: companyType
            },
            onSuccess: (data) => {
              this.setState({
                expire_time: data.expire_time, 
                start_time: data.created_on,
                hasExpired: false,
                invalidOTP: false,
                invalidContact: false,
                show_checkbox: false,
                otp: ''
              })
            },
            onError: (message) => {
              this.setState({
                invalidContact: true,
                show_checkbox: true,
                errorMessage: message
              })
            }
        })
    }
    otpSignin = () => {
        const { dispatch } = this.props;
        const { companyType, contact_number, otp } = this.state;
        dispatch({
            type: LOGIN_WITH_OTP,
            data: {
              contact_number,
              companyType,
              otp_number: otp,
              device_type: 3,
            },
            onError: (msg) => {
              this.setState({ invalidOTP: true, errorMsg: msg });
            }
        })
    }
    otpExpired = () => {
    const { expire_time, start_time} = this.state;
    const systemTime = new Date().getTime();
    if(expire_time && start_time && expire_time < systemTime){
        this.setState({hasExpired: true, otp: ''});
    }
    else {
        this.setState({hasExpired: false});
    }
    }
    signin = (e)=> {
        const { dispatch } = this.props;
        const { email, password, companyType } = this.state;
        e.preventDefault();
        dispatch({
            type: SIGN_IN,
            data: {
                email,
                password,
                device_type: 3,
                companyType
            }
        })
    }

    ssoLogin = () => {
        const { email } = this.state;
        const { dispatch } = this.props;
        const data = {
            email,
            module: 3
        }
        dispatch({
            type: VALIDATE_SSO_USER,
            data,
            onSuccess: () => {
                localStorage.setItem('isSSOUser', true);
                window.location.replace(`${SSO_BASE_URL}${SSO_URL}?sso&email=${email}&next=http://${window.location.host}/dashboard&module=3`);
            }
        })
        
    }

    handleChange = (otp) => this.setState({ otp });
    
    render() {
        const { error, showTag, ssoConfigData } = this.props;
        const { tabType, start_time, expire_time, hasExpired, invalidOTP, otp, contact_number, invalidContact, errorMessage, errorMsg, isVerified } = this.state;
        const isOTP = (tabType === 2);
        const systemTime = new Date().getTime();
        const disableLogin = (!otp ? (start_time && !hasExpired) : (isVerified ? otp.length != 6 : true));
        const disableTegLogin = ssoConfigData.disable_teg_login;
        const isCustomDomain = ssoConfigData.is_custom_domain;

        return (
            <div>
            <form className="form flex flex-column" onSubmit = {this.formSubmit}>
                <div className="tCenter wt-70 flex flex-center flex-self-center">
                    <Logo className="ht-130"/>
                </div>
                {error && <div className="error-color fs-14 fBolder mb-20">{error}</div>}
                {!disableTegLogin && !this.state.isSSOLogin &&
                <>
                <div className="fs-14 theme-color fBold mb-10">I am a</div>
                <div className="mb-10">
                    <Tabs selectTab={this.selectCompanyType} activeTabId={this.state.companyType} tabs={this.tabs}/>
                </div>
                <div className="mb-30">
                    <Tabs selectTab={this.selectTabType} activeTabId={this.state.tabType} tabs={this.subtabs}/>
                </div>
                </>}
                {
                    isOTP && !disableTegLogin &&
                    <Fragment>
                        <div className="flex">
                            <Input containerClassName="mb-20 wt-100p" name="contact_number" type="number" onWheel={(e) => e.currentTarget.blur()} value={this.state.contact_number} length={'10'} placeholder="Mobile No." onChange={this.onChange}/>
                        </div>
                        {
                            (expire_time > systemTime) &&
                            <OtpInput
                                value={this.state.otp}
                                onChange={this.handleChange}
                                numInputs={6}
                                separator={<span className="mr-10 ml-10">-</span>}
                                containerStyle="mb-20 wt-100p"
                                name="otp"
                                inputStyle="otp-box"
                            />
                        }
                    </Fragment>
                }
                {
                    !isOTP && !disableTegLogin &&
                    <Fragment>
                        <Input containerClassName="mb-20" name="email" type="text" value={this.state.email} placeholder="Username" onChange={this.onChange}/>
                        {!this.state.isSSOLogin && <Input containerClassName="mb-10" name="password" type="password" value={this.state.password} placeholder="Password" onChange={this.onChange}/>}
                        {!this.state.isSSOLogin && <Link className="tRight block mb-15 theme-color-light font-boldest" href={'/forgotPassword'} value={'Forgot Password?'} />}
                    </Fragment>
                }
                <div className="flex flex-right mb-30 mt--10">
                {isOTP && start_time && (!hasExpired ? <Timer from={start_time} to={expire_time} currentTime={systemTime} isOTP={true} otpExpired={this.otpExpired}/> : <span className="fs-14 lh-15p fBold error-color">OTP has expired</span>)}
                </div>
                <div className="flex flex-right mb-30 mt--20">
                    {isOTP && start_time && !hasExpired && invalidOTP && <span className="fs-14 lh-15p fBold error-color">{errorMsg}</span>}
                </div>
                {showTag && isOTP &&
                <Recaptcha
                    sitekey="6LcHtbwUAAAAAKkjbo7nNh9ajzF5GEHk9uC5skGG"
                    render="explicit"
                    verifyCallback={this.verifyCallback}
                />} 
                <br></br>
                            
                {!isOTP && !disableTegLogin && !this.state.isSSOLogin && <Button type="submit" className="mb-15 theme-bg-color-light" value={"Log In"}/>}
                {!isOTP && !disableTegLogin && this.state.isSSOLogin && <Button type="submit" className="mb-15 theme-bg-color-light" value={"Log with SSO"}/>}
                {!isOTP && (isCustomDomain || disableTegLogin) && <Button type="submit" onClick={(e) => this.formSubmit(e, true)} className="mb-15 theme-bg-color-light" value={"Login with SSO"}/>}
                {
                    isOTP &&
                    <Button
                        className={disableLogin  ? "mb-15 bg-button-gray disabled-button" : "mb-15 theme-bg-color-light"}
                        disabled={disableLogin}
                        value={isOTP && (otp && otp.length == 6) ? "Log In" : (hasExpired ? "Resend OTP" : "Send OTP")}
                        style={{"margin": "0.75rem", textAlign: 'center' }}
                        type={"submit"}
                    />
                }
                {!this.state.isSSOLogin && !isCustomDomain && !disableTegLogin && <div onClick={() => this.setState({isSSOLogin: true})} className="fs-13 theme-color curP tCenter block mb-15 theme-color-light font-boldest"> Login with SSO</div>}
                <div className="flex flex-between">
                    <span className="fs-13">Don't have an account?</span>
                    <Link className="theme-color-light font-boldest" value={"Contact Us"}/>
                </div>
                
            </form>
            </div>
        )
    }
}
const mapStateToProps = () => {
    return {

    }
};


  export default connect(mapStateToProps)(SigninForm);
