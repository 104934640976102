import React, { Component, Fragment } from 'react';
import moment from 'moment';
import classNames from 'classnames'; // Corrected typo: className to classNames
import Countdown from 'react-countdown';
import { ReactComponent as RedTimer } from 'Pages/Signin/icons/timer-red.svg';

 class NewTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: moment(props.from),
            to: moment(props.to),
            currentDate: props.currentTime ? moment(new Date(props.currentTime)) : moment(new Date()),
            currentDiff: '',
            intervalId: null,
        };
    }

    componentDidMount() {
        this.updateDiff();
        this.setupTimer();
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
    //         this.setInitialStateData();
    //     }
    // }

    componentWillUnmount() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }

    // setInitialStateData = () => {
    //     const { from, to } = this.props;
    //     this.setState(
    //         {
    //             from: moment(from),
    //             to: moment(to),
    //         },
    //         () => {
    //             this.updateDiff();
    //             this.setupTimer();
    //         }
    //     );
    // };

    setupTimer = () => {
        const intervalId = setInterval(() => {
            const { to, currentDate } = this.state;
            const duration = moment.duration(to.diff(currentDate));
            if (duration.asSeconds() <= 0) {
                clearInterval(intervalId);
                this.setState({ currentDiff: 'Expired' }, this.props.otpExpired());
                return;
            }
            this.setState(
                prevState => ({
                    currentDate: prevState.currentDate,
                }),
                this.updateDiff()
            );
        }, 1000); // Update every sec

        this.setState({ intervalId });
    };

    updateDiff = () => {
        const { showInfo } = this.props;
        const prefix = showInfo ? 'Ends in ' : '';
        const { to, currentDate } = this.state;

        const duration = moment.duration(to.diff(currentDate));
        if (duration.asSeconds() <= 0) {
            clearInterval(this.state.intervalId);
            this.setState({ currentDiff: 'Expired' });
            return;
        }

        const formattedDiff = `${duration.years() > 0 ? `${duration.years()} Year(s) ` : ''}${duration.months() > 0 ? `${duration.months()} Month(s) ` : ''
            }${duration.days() > 0 ? `${duration.days()} Day(s) ` : ''}${duration.hours() > 0 ? `${duration.hours()}h ` : ''
            }${duration.minutes() > 0 ? `${duration.minutes()}m ` : ''}${duration.seconds() > 0 ? `${duration.seconds()}s ` : ''
            }`;

        this.setState({
            currentDiff: prefix + formattedDiff,
        });
    };

    render() {
        const { isOTP } = this.props;
        const { from, to, currentDiff } = this.state;
        const duration = new Date(to);

        return (
            <Fragment>
                {from && to ? (
                    <div>
                        <RedTimer className="mt-5 mr-5" />
                        <span className={classNames("fs-14 lh-15p fBold")}>
                            {duration && (
                                <Countdown
                                    date={duration}
                                    renderer={({ minutes, seconds, completed }) => {
                                        if (!completed && isOTP) {
                                            return (
                                                <span style={{ color: "red" }}>
                                                    OTP expires in {minutes}m {seconds}s
                                                </span>
                                            );
                                        }
                                        if (completed && isOTP) {
                                            this.props.otpExpired();
                                        }
                                        return null;
                                    }}
                                />
                            )}
                        </span>
                    </div>
                ) : (
                    <div className="flex flex-middle">N/A</div>
                )}
            </Fragment>
        );
    }
}

export default NewTimer;
