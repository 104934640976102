import React, { Component, Fragment, useCallback, useEffect } from 'react';
import Button from 'Components/Button';
import Select from 'react-select';
import {ReactComponent as SearchIcon} from 'Components/SearchDropdown/icons/search.svg';
import UploadButton from 'Components/UploadButton';
import Input from 'Components/Input';
import  SearchDropdown  from 'Components/SearchDropdown';
import CheckBox from 'Components/Checkbox';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';
import DashboardCard from 'Components/DashboardCard';
import Dropdown from 'Components/Dropdown';
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import { ReactComponent as Delete } from 'Pages/Dashboard/icons/delete.svg';
import { ReactComponent as Edit } from 'Pages/Dashboard/icons/edit.svg';
import { ReactComponent as Save } from 'Pages/Dashboard/icons/save.svg';
import { ReactComponent as Cancel } from 'Pages/Dashboard/icons/cancel.svg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
class FOISDashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            fnrSearch: '',
            list: [],
            activeType: 6,
            from_date_search: null,
            to_date_search: null,
            stationData: [],
            editFNR: '',
            adhocFNR: {
                fnrMovementType: '',
                no_of_wagon: '',
                loaded_weight: '',
                adhoc_fnr_no: null
            },
            openForm: false,
            movementList: [],
            editData: {
                loading_start: null,
                loading_end: null,
                destination_place_date_time: null,
                unloading_start: null,
                unloading_end: null,
                // destination_release_time: null,
                fnr_no_scrape_time: null
            },
            multimodal_trackingPage:false,
            selectedFnrNo:"",
        }
    }

    setTime = (time, name)=> {
        const {editData} = this.state;
        editData[name] = time;
        this.setState({editData})
    }

    getDayHoursMin = (time) => {
        if(time){
            let seconds = Number(time);
            var days = Math.floor(seconds / (3600*24*1000));
            seconds  -= days*3600*24*1000;
            var hrs   = Math.floor(seconds / (3600*1000));
            seconds  -= hrs*3600*1000;
            var mnts = Math.floor(seconds / (60*1000));
            seconds  -= mnts*60*1000;

            var dDisplay = days > 0 ? days + (days == 1 ? " day, " : " days, ") : "";
            var hDisplay = hrs > 0 ? hrs + (hrs == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = mnts > 0 ? mnts + (mnts == 1 ? " minute " : " minutes ") : "";
            // var sDisplay = seconds > 0 ? seconds + (seconds == 1 ? " second" : " seconds") : "";
            return dDisplay + hDisplay + mDisplay;
        }
    }

    handleOnClickForLastReportItem = (fnr)=>{
    const {history} = this.props
    console.log('this.props:', this.props)
    console.log('history:', history)
    const fnrNo = fnr.fnr_no
    const navigateUrl  = `/multiModelTracking/${fnrNo}`
    history.push(navigateUrl)
    }

    render(){
        const { fnrData, station, isTracked, statuses, fnrMsg, foisMovementType  } = this.props;
        console.log('fnrData:', fnrData)
        const { fnrSearch, activeType, from_date_search, to_date_search, fromStation, toStation, adhocFNR, editFNR, editData, multimodal_trackingPage , selectedFnrNo} = this.state;
        return(
            <div className="box-shadow p-10 cargo-tracking" style={{width: '100%'}}>
                <div className="tCenter mt-10 fs-20 theme-color fBolder mb-10">Railway Tracking </div>
                <div className={'wt-300 ml-5 mt-10'}>
                    <div style={{display: 'inline-flex'}}>
                    {(statuses || []).map((status,index) => {
                        return (
                            <DashboardCard 
                            key={index} 
                            status={status} 
                            onClick={() => this.setState({activeType: status.type},() => this.props.getFOISData(this.state.activeType))}
                            // onClick={() => this.setState({activeType: status.type}, this.changeTab(index, fnrData.filter_count))}
                            tripSubStatus={null} 
                            activeType={this.state.activeType}/>
                        )
                    })}
                    </div>
                    
                </div>
                <div className="flex mt-10">
                    <div className="flex-1 col-5 mr-10">
                <div className="fs-12 theme-color fBolder mb-10 ml-10 add-trip-form-label">From Station</div>
                    <SearchDropdown
                            hideSearch={true}
                            onChange={(e) => {this.setState({fromStation: e}, this.props.getFOISStation(e))} }
                            onSelect={(value) => {this.setState({fromStation: value},this.props.getFOISData(activeType, fnrSearch, from_date_search, to_date_search, value, toStation))} }
                            label={'From Station'}
                            items={station || []}
                            value={this.state.fromStation}
                            hasClearFilter={() => {this.setState({fromStation: ''},this.props.getFOISData(activeType, fnrSearch, from_date_search, to_date_search, '', toStation))}}
                            className={"wt-100 border-theme-light br-5"}
                        /></div>
                        <div className="flex-1 col-5 mr-10">
                <div className="fs-12 theme-color fBolder mb-10 ml-10 add-trip-form-label">To Station</div>
                    <SearchDropdown
                            hideSearch={true}
                            onChange={(e) => {this.setState({toStation: e}, this.props.getFOISStation(e))} }
                            onSelect={(value) => {this.setState({toStation: value},this.props.getFOISData(activeType, fnrSearch, from_date_search, to_date_search, fromStation, value))} }
                            label={'To Station'}
                            items={station || []}
                            value={this.state.toStation}
                            hasClearFilter={() => {this.setState({toStation: ''},this.props.getFOISData(activeType, fnrSearch, from_date_search, to_date_search, fromStation, ''))}}
                            className={"wt-100 border-theme-light br-5"}
                        />
                    </div>
                    <div className="flex-1 col-3 mr-10">
                        <div className="fs-12 theme-color fBolder mb-10 ml-20 add-trip-form-label">Date</div>
                            <div className="flex date-select">
                            <DateRangePicker
                                startDate={this.state.from_date_search ? moment(this.state.from_date_search) : null}
                                startDateId="startDate"
                                endDate={this.state.to_date_search ? moment(this.state.to_date_search) : null}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => {
                                let { from_date_search, to_date_search } = this.state;
                                from_date_search = startDate ? new Date(startDate).setHours(0, 0, 0) : new Date(this.state.from_date_search).setHours(0, 0, 0);
                                to_date_search = endDate ? new Date(endDate).setHours(23, 59, 59) : to_date_search;
                                this.setState({ from_date_search, to_date_search });
                                this.props.getFOISData(activeType, fnrSearch, from_date_search, to_date_search, fromStation, toStation)
                                }}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                displayFormat={'DD MMM YYYY'}
                                noBorder={true}
                                minimumNights={0}
                                isOutsideRange={() => false}
                            />
                            </div>
                        </div>
                    <div className="flex-1 col-5">
                    <div className="fs-12 theme-color fBolder mb-10 ml-10 add-trip-form-label">Search FNR</div>
                        <SearchDropdown
                            hideSearch={true}
                            onChange={(e) => {this.setState({fnrSearch: e},this.props.getFOISData(activeType, e, from_date_search, to_date_search, fromStation, toStation))} }
                            onSelect={(value) => {this.setState({fnrSearch: value},this.props.getFOISData(activeType, value, from_date_search, to_date_search, fromStation, toStation))} }
                            label={'Search the FNR No.'}
                            items={fnrData.fnr_no_list || []}
                            value={this.state.fnrSearch}
                            hasClearFilter={() => {this.setState({fnrSearch: ''},this.props.getFOISData(activeType, '', from_date_search, to_date_search, fromStation, toStation))}}
                            className={"wt-100 border-theme-light br-5"}
                            
                        />
                        </div>
                    </div>
                    <div className="flex mt-10">
                    {activeType === 2 && <div className="ht-38 white-color bg-theme-color fs-16 fBold pl-20 br-5 pr-20 mr-10 wt-150">
                        <Button value="Adhoc FNR" onClick={() => this.setState({openForm: true})} />
                    </div>}
                    {activeType === 2 && <div className="ht-38 white-color bg-theme-color fs-16 fBold pl-20 br-5 pr-20 ml-475 wt-200 mr-10">
                        <Button value="Download Template" onClick={this.props.downloadFOISTemplate} />
                    </div>}
                    {activeType === 2 && <div className="ht-38 white-color bg-theme-color fs-16 fBold pl-30 br-5 pr-30 mr-10 wt-150">
                        <UploadButton value="Upload" onChange={(e) => this.props.uploadFOISData(e)} className={'wt-80'}/>
                    </div>}
                    <div className="ht-38 white-color bg-theme-color fs-16 fBold pl-20 br-5 pr-20 wt-150">
                        <Button value="Download Trips" onClick={() => this.props.getFOISData(activeType, fnrSearch, from_date_search, to_date_search, fromStation, toStation, true)} />
                    </div>
                </div>
                {!fnrMsg && fnrData && fnrData.fnr_trips && fnrData.fnr_trips.length > 0 ?
                    <div className={"flex flex-between flex-middle"} style={{marginTop: '20px', overflowX: 'scroll'}}>
                        <table id={"scheduler_screen"} style={{margin: '0px auto'}}>
                            <thead className="white-color bg-theme-color tCenter ">
                                <tr>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10"> FNR No. </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> FNR Date & Time </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10"> RR Number </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> RR Date </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 "> Movement Type </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-5"> No. of Wagons </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-5"> Loaded Weight (in MT) </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Origin </th>
                                    <th className="pl-40 pr-40 tCenter border-gray-1 wt-10p"> Via </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Destination </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Last Reported Status, Date & Time </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> ETA </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Last Updated Date & Time </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Railway Freight Amount </th>
                                    <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Railway Distance in KM </th>
                                    {activeType !== 2 &&
                                    <>
                                        <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Loading Start at Origin </th>
                                        <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Loading End at Origin </th>
                                        <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Total Loading Time </th>
                                        <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Destination Place Date & Time </th>
                                        <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Unloading Start </th>
                                        <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Unloading End </th>
                                        <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Total Unloading Time </th>
                                        {/* <th className="pl-10 pr-10 tCenter border-gray-1 wt-10p"> Destination Release time </th> */}
                                    </>}
                                    {activeType !== 8 && <th className="pl-10 pr-10 tCenter border-gray-1 wt-5"> Action </th>}
                                </tr>
                            </thead>
                            <tbody>
                            {(fnrData.fnr_trips || []).map((fnr, index) => {
                                return (
                                        <tr>
                                            <td className="p-10 tCenter border-gray-1 fs-14 wt-10">{fnr.fnr_no}</td>
                                            {!fnr.fnr_no_scrape_time && editFNR === index ? 
                                                    <>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">
                                                    <div className="foisDashboard ml-10">
                                                        <SingleDatePicker
                                                        displayFormat={"DD/MMM/YYYY"}
                                                        isOutsideRange={() => false}
                                                        hideKeyboardShortcutsPanel={true}
                                                        placeholder={"Loading Date"}
                                                        numberOfMonths={1}
                                                        date={editData.fnr_no_scrape_time ? moment(editData.fnr_no_scrape_time) : null}
                                                        onDateChange={date => {
                                                            const { editData } = this.state;
                                                            editData.fnr_no_scrape_time = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(editData.fnr_no_scrape_time).format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        focused={this.state.focusedDate}
                                                        onFocusChange={({ focused }) => this.setState({ focusedDate: focused })}
                                                        id="fnr_no_scrape_time"
                                                        readOnly={true}
                                                        /> 
                                                    </div>
                                                        <TimePicker
                                                        showSecond={false}
                                                        value={moment(editData.fnr_no_scrape_time)}
                                                        className="ht-40 mt-10 mb-10 pl-10 wt-100"
                                                        onChange={(time) => {
                                                            const { editData } = this.state;
                                                            editData.fnr_no_scrape_time = new Date(moment(editData.fnr_no_scrape_time).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        format={'HH:mm'}
                                                        inputReadOnly
                                                    /> 
                                                    </td>
                                                    </>:
                                                    <td className="p-10 tCenter border-gray-1 fs-14 wt-10">{fnr.fnr_no_scrape_time ? moment(fnr.fnr_no_scrape_time).format('DD/MMM/YYYY HH:mm') : 'TBD'}</td>}
                                            
                                            <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).rr_number || '-'}</td>
                                            <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).rr_date ? moment((fnr.freight_details || {}).rr_date).format('DD/MMM/YYYY HH:mm') : '-'}</td>
                                            <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).movement_type || '-'}</td>
                                            <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).no_of_wagons || '-'}</td>
                                            {!((fnr.freight_details || {}).loaded_weight) && editFNR === index ? 
                                                <input
                                                className={"wt-60 border-theme-light br-5 pl-10 ht-40 mt-10 ml-3"}
                                                tabIndex="1"
                                                value={editData.loaded_weight}
                                                type='number'
                                                onChange={(e) => {
                                                    const { editData } = this.state;
                                                    editData.loaded_weight = e.target.value;
                                                    this.setState({editData});
                                                }}
                                                placeholder={'Loaded Weight (in MT)'}
                                                /> :
                                            <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).loaded_weight || '-'}</td>}
                                            {fnr.tracking_details && fnr.type !== 'N/A' ?
                                            <Fragment>
                                                {(fnr.tracking_details || {}).is_fetched ?
                                                <Fragment>
                                                    <td className="p-5 tCenter border-gray-1 fs-12 text-uppercase">
                                                        {fnr.is_origin_update && editFNR === index && (
                                                            <input 
                                                                type="text"
                                                                className={"wt-100 border-theme-light br-5 pl-10 ht-40 mt-10 ml-3 tCenter"}
                                                                value={editData.origin}
                                                                placeholder='Origin'
                                                                onChange={(e) => {
                                                                    const { editData } = this.state;
                                                                    editData['origin'] = e.target.value;
                                                                    this.setState({
                                                                        editData
                                                                    });
                                                                }}
                                                                />)}
                                                            <>{((fnr.tracking_details || {}).last_location || {}).origin}</>
                                                    </td>
                                                    <td className="p-5 tCenter border-gray-1 fs-12">{`${((fnr.tracking_details || {}).last_location || {}).station_from} - ${((fnr.tracking_details || {}).last_location || {}).station_to}`}</td>
                                                    <td className="p-5 tCenter border-gray-1 fs-12 text-uppercase">{((fnr.tracking_details || {}).last_location || {}).destination}</td>
                                                    <td className="p-10 tCenter border-gray-1 fs-12" 
                                                     onClick={()=>{
                                                        this.handleOnClickForLastReportItem(fnr)
                                                     }}>
                                                     {((fnr.tracking_details || {}).last_location || {}).last_reported_status}
                                                    <div>{((fnr.tracking_details || {}).last_location || {}).last_reported_date !== ('NA' || 'N/A' || '') ? moment(fnr.tracking_details.last_location.last_reported_date).format('DD/MMM/YYYY HH:mm') : 'NA'}</div></td>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.tracking_details || {}).last_location.reached_time !== ('NA' || 'N/A' || '') ? moment(fnr.tracking_details.last_location.reached_time).format('DD/MMM/YYYY HH:mm') : 'NA'}</td>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{((fnr.tracking_details || {}).last_location || {}).updated_on && moment(fnr.tracking_details.last_location.updated_on).format('DD/MMM/YYYY HH:mm')}</td>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).freight_charges || 'TBD'}</td>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).distance || 'TBD'}</td>
                                                    {!((fnr.freight_details || {}).loading_start) && editFNR === index ? 
                                                    <>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">
                                                    <div className="foisDashboard ml-10">
                                                        <SingleDatePicker
                                                        displayFormat={"DD/MMM/YYYY"}
                                                        isOutsideRange={() => false}
                                                        hideKeyboardShortcutsPanel={true}
                                                        placeholder={"Loading Start"}
                                                        numberOfMonths={1}
                                                        date={editData.loading_start ? moment(editData.loading_start) : null}
                                                        onDateChange={date => {
                                                            const { editData } = this.state;
                                                            editData.loading_start = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(editData.loading_start).format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        focused={this.state.focusedStart}
                                                        onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                                        id="loading_start"
                                                        readOnly={true}
                                                        /> 
                                                    </div>
                                                        <TimePicker
                                                        showSecond={false}
                                                        value={moment(editData.loading_start)}
                                                        className="ht-40 mt-10 mb-10 pl-10 wt-100"
                                                        onChange={(time) => {
                                                            const { editData } = this.state;
                                                            editData.loading_start = new Date(moment(editData.loading_start).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        format={'HH:mm'}
                                                        inputReadOnly
                                                    /> 
                                                    </td>
                                                    </>:
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).loading_start ? moment((fnr.freight_details || {}).loading_start).format('DD/MMM/YYYY HH:mm') : 'TBD'}</td>}
                                                    {!((fnr.freight_details || {}).loading_end) && editFNR === index ? 
                                                    <>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">
                                                    <div className="foisDashboard ml-10">
                                                        <SingleDatePicker
                                                        displayFormat={"DD/MMM/YYYY"}
                                                        isOutsideRange={() => false}
                                                        hideKeyboardShortcutsPanel={true}
                                                        placeholder={"Loading End"}
                                                        numberOfMonths={1}
                                                        date={editData.loading_end ? moment(editData.loading_end) : null}
                                                        onDateChange={date => {
                                                            const { editData } = this.state;
                                                            editData.loading_end = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(editData.loading_end).format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        focused={this.state.focusedEnd}
                                                        onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                                                        id="loading_end"
                                                        readOnly={true}
                                                        /> 
                                                    </div>
                                                        <TimePicker
                                                        showSecond={false}
                                                        value={moment(editData.loading_end)}
                                                        className="ht-40 mt-10 mb-10 pl-10 wt-100"
                                                        onChange={(time) => {
                                                            const { editData } = this.state;
                                                            editData.loading_end = new Date(moment(editData.loading_end).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        format={'HH:mm'}
                                                        inputReadOnly
                                                    /> 
                                                    </td>
                                                    </>:
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).loading_end ? moment((fnr.freight_details || {}).loading_end).format('DD/MMM/YYYY HH:mm') : 'TBD'}</td>}
                                                    
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).total_loading_time ? this.getDayHoursMin((fnr.freight_details || {}).total_loading_time) : 'TBD'}</td>
                                                    {!((fnr.freight_details || {}).destination_place_date_time) && editFNR === index ? 
                                                    <>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">
                                                    <div className="foisDashboard ml-10">
                                                        <SingleDatePicker
                                                        displayFormat={"DD/MMM/YYYY"}
                                                        isOutsideRange={() => false}
                                                        hideKeyboardShortcutsPanel={true}
                                                        placeholder={"Destination Time"}
                                                        numberOfMonths={1}
                                                        date={editData.destination_place_date_time ? moment(editData.destination_place_date_time) : null}
                                                        onDateChange={date => {
                                                            const { editData } = this.state;
                                                            editData.destination_place_date_time = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(editData.destination_place_date_time).format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        focused={this.state.focusedOrgRelease}
                                                        onFocusChange={({ focused }) => this.setState({ focusedOrgRelease: focused })}
                                                        id="destination_place_date_time"
                                                        readOnly={true}
                                                        /> 
                                                    </div>
                                                        <TimePicker
                                                        showSecond={false}
                                                        value={moment(editData.destination_place_date_time)}
                                                        className="ht-40 mt-10 mb-10 pl-10 wt-100"
                                                        onChange={(time) => {
                                                            const { editData } = this.state;
                                                            editData.destination_place_date_time = new Date(moment(editData.destination_place_date_time).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        format={'HH:mm'}
                                                        inputReadOnly
                                                    /> 
                                                    </td>
                                                    </>:
                                                    (activeType === 6 && <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).destination_place_date_time ? moment((fnr.freight_details || {}).destination_place_date_time).format('DD/MMM/YYYY HH:mm') : 'TBD'}</td>)}
                                                    {activeType === 8 && <td className="p-10 tCenter border-gray-1 fs-14">{((fnr.tracking_details || {}).last_location || {}).last_reported_date !== ('NA' || 'N/A' || '') ? moment(fnr.tracking_details.last_location.last_reported_date).format('DD/MMM/YYYY HH:mm') : 'TBD'}</td>}
                                                    {!((fnr.freight_details || {}).unloading_start) && editFNR === index ? 
                                                    <>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">
                                                    <div className="foisDashboard ml-10">
                                                        <SingleDatePicker
                                                        displayFormat={"DD/MMM/YYYY"}
                                                        isOutsideRange={() => false}
                                                        hideKeyboardShortcutsPanel={true}
                                                        placeholder={"Unloading Start"}
                                                        numberOfMonths={1}
                                                        date={editData.unloading_start ? moment(editData.unloading_start) : null}
                                                        onDateChange={date => {
                                                            const { editData } = this.state;
                                                            editData.unloading_start = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(editData.unloading_start).format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        focused={this.state.focusedUnloadingStart}
                                                        onFocusChange={({ focused }) => this.setState({ focusedUnloadingStart: focused })}
                                                        id="unloading_start"
                                                        readOnly={true}
                                                        /> 
                                                    </div>
                                                        <TimePicker
                                                        showSecond={false}
                                                        value={moment(editData.unloading_start)}
                                                        className="ht-40 mt-10 mb-10 pl-10 wt-100"
                                                        onChange={(time) => {
                                                            const { editData } = this.state;
                                                            editData.unloading_start = new Date(moment(editData.unloading_start).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        format={'HH:mm'}
                                                        inputReadOnly
                                                    /> 
                                                    </td>
                                                    </>:
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).unloading_start ? moment((fnr.freight_details || {}).unloading_start).format('DD/MMM/YYYY HH:mm') : 'TBD'}</td>}
                                                    {!((fnr.freight_details || {}).unloading_end) && editFNR === index ? 
                                                    <>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">
                                                    <div className="foisDashboard ml-10">
                                                        <SingleDatePicker
                                                        displayFormat={"DD/MMM/YYYY"}
                                                        isOutsideRange={() => false}
                                                        hideKeyboardShortcutsPanel={true}
                                                        placeholder={"Unloading End"}
                                                        numberOfMonths={1}
                                                        date={editData.unloading_end ? moment(editData.unloading_end) : null}
                                                        onDateChange={date => {
                                                            const { editData } = this.state;
                                                            editData.unloading_end = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(editData.unloading_end).format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        focused={this.state.focusedUnloadingEnd}
                                                        onFocusChange={({ focused }) => this.setState({ focusedUnloadingEnd: focused })}
                                                        id="unloading_end"
                                                        readOnly={true}
                                                        /> 
                                                    </div>
                                                        <TimePicker
                                                        showSecond={false}
                                                        value={moment(editData.unloading_end)}
                                                        className="ht-40 mt-10 mb-10 pl-10 wt-100"
                                                        onChange={(time) => {
                                                            const { editData } = this.state;
                                                            editData.unloading_end = new Date(moment(editData.unloading_end).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        format={'HH:mm'}
                                                        inputReadOnly
                                                    /> 
                                                    </td>
                                                    </>:
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).unloading_end ? moment((fnr.freight_details || {}).unloading_end).format('DD/MMM/YYYY HH:mm') : 'TBD'}</td>}
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).total_unloading_time ? this.getDayHoursMin((fnr.freight_details || {}).total_unloading_time) : 'TBD'}</td>
                                                    {/* {!((fnr.freight_details || {}).destination_release_time) && editFNR === index ? 
                                                    <>
                                                    <td className="p-10 tCenter border-gray-1 fs-14">
                                                    <div className="foisDashboard ml-10">
                                                        <SingleDatePicker
                                                        displayFormat={"DD/MMM/YYYY"}
                                                        isOutsideRange={() => false}
                                                        hideKeyboardShortcutsPanel={true}
                                                        placeholder={"Dest. Release Time"}
                                                        numberOfMonths={1}
                                                        date={editData.destination_release_time ? moment(editData.destination_release_time) : null}
                                                        onDateChange={date => {
                                                            const { editData } = this.state;
                                                            editData.destination_release_time = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(editData.destination_release_time).format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        focused={this.state.focusedDestReleaseTime}
                                                        onFocusChange={({ focused }) => this.setState({ focusedDestReleaseTime: focused })}
                                                        id="destination_release_time"
                                                        readOnly={true}
                                                        /> 
                                                    </div>
                                                        <TimePicker
                                                        showSecond={false}
                                                        value={moment(editData.destination_release_time)}
                                                        className="ht-40 mt-10 mb-10 pl-10 wt-100"
                                                        onChange={(time) => {
                                                            const { editData } = this.state;
                                                            editData.destination_release_time = new Date(moment(editData.destination_release_time).format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                            this.setState({editData});
                                                        }}
                                                        format={'HH:mm'}
                                                        inputReadOnly
                                                    /> 
                                                    </td>
                                                    </>:
                                                    <td className="p-10 tCenter border-gray-1 fs-14">{(fnr.freight_details || {}).destination_release_time ? moment((fnr.freight_details || {}).destination_release_time).format('DD/MMM/YYYY HH:mm') : 'TBD'}</td>} */}
                                                </Fragment> :
                                                <td className="p-10 tCenter border-gray-1 fs-14 error-color fBolder" colSpan={8}>{'Invalid FNR'}</td>}
                                            </Fragment> :
                                                <td className="p-10 tCenter border-gray-1 fs-14 error-color fBolder" colSpan={8}>{'Tracking is enabled for the FNR number. Please check the status after some time'}</td>}
                                            {activeType === 2 && <td className="p-10 tCenter border-gray-1 fs-14"><Delete className={'curP'} style={{width: '20px', height: '20px'}} onClick={() => {this.props.deleteFNR(fnr.fnr_no)}}></Delete></td>}
                                            {activeType === 6 && (editFNR !== index ? 
                                            <td className="p-10 tCenter border-gray-1 fs-14"><Edit className={'curP'} style={{width: '20px', height: '20px'}} onClick={() => {this.setState({editFNR: index, editData: JSON.parse(JSON.stringify(fnr.freight_details))})}}></Edit></td>
                                            : 
                                            <td className="p-10 tCenter border-gray-1 fs-14">
                                                <Save className={'curP'} style={{width: '20px', height: '20px'}} onClick={() => {this.setState({editFNR: '', editData: {}},this.props.editFNRData(fnr,editData))}}></Save>
                                                <Cancel className={'curP'} style={{width: '20px', height: '20px'}} onClick={() => {this.setState({editFNR: '', editData: {}})}}></Cancel>
                                            </td>)}
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div> :
                    <div className={fnrMsg ? 'mt-10 success-color fBolder' : 'mt-10 error-color fBolder'}>{isTracked && this.state.fnrSearch ? 'No Data Found' : (fnrMsg ? fnrMsg : '')}</div>}
            {this.state.openForm &&
            <Fragment>
                <div className="overlay">
                    <div className="overlay-container change-vehicle-popup">
                        <span className="overlay-close-button fs-20" onClick={() => this.setState({openForm: false})}>
                            X
                        </span>
                        <div className="overlay-body change-vehicle-body-popup" style={{height: '450px'}}>
                            <header className="fs-18 theme-color fBolder mb-20">Adhoc FNR</header>
                            <div className="flex flex-wrap flex-middle mt-30">
                                <div className="mb-20 wt-190 mr-20">
                                    <div className="fs-14 communication-color fBold mb-5">FNR Number</div>
                                    <input
                                        className={"wt-180 border-theme-light br-5 pl-10 ht-40"}
                                        tabIndex="1"
                                        value={adhocFNR.adhoc_fnr_no || ''}
                                        onChange={(e) => {
                                            const { adhocFNR } = this.state;
                                            adhocFNR.adhoc_fnr_no = e.target.value;
                                            this.setState({adhocFNR});
                                        }}
                                        placeholder={'Track FNR'}
                                        maxLength={11}
                                        >
                                    </input>
                                    {adhocFNR.adhoc_fnr_no && adhocFNR.adhoc_fnr_no.length !== 11 && <div className="error-color fs-10">Enter valid FNR No.</div>}
                                </div>
                                <div className="mb-20 mr-20 wt-190">
                                    <div className="fs-14 communication-color fBold mb-5">Movement Type</div>
                                    <Dropdown
                                        value={{value: adhocFNR.fnrMovementType, label: adhocFNR.fnrMovementType}}
                                        items={(foisMovementType || []).map((el)=> { return { value: el, name: el }})}
                                        label="Movement type"
                                        selectedClassName={'col-1'}
                                        onSelect={(value) => {
                                            const { adhocFNR } = this.state;
                                            adhocFNR.fnrMovementType = value.value;
                                            this.setState({adhocFNR});
                                        }}
                                    />
                                </div>
                                <div className="mb-20 wt-190 mr-20 ml-40">
                                    <div className="fs-14 communication-color fBold mb-5">No. of Wagons</div>
                                    <input
                                        className={"wt-180 border-theme-light br-5 pl-10 ht-40"}
                                        tabIndex="1"
                                        value={adhocFNR.no_of_wagon}
                                        onChange={(e) => {
                                            const { adhocFNR } = this.state;
                                            adhocFNR.no_of_wagon = e.target.value;
                                            this.setState({adhocFNR});
                                        }}
                                        placeholder={'No. of Wagon'}
                                        type='number'
                                        >
                                    </input>
                                </div>
                                <div className="mb-20 wt-190 mr-20">
                                    <div className="fs-14 communication-color fBold mb-5">Loaded Weight (in MT)</div>
                                    <input
                                        className={"wt-180 border-theme-light br-5 pl-10 ht-40"}
                                        tabIndex="1"
                                        value={adhocFNR.loaded_weight}
                                        type='number'
                                        onChange={(e) => {
                                            const { adhocFNR } = this.state;
                                            adhocFNR.loaded_weight = e.target.value;
                                            this.setState({adhocFNR});
                                        }}
                                        placeholder={'Loaded Weight (in MT)'}
                                        >
                                    </input>
                                </div>
                                <div className="ht-38 white-color bg-theme-color fs-16 fBold pl-30 br-5 pr-30 ml--2">
                                    <Button value="Track" onClick={() => adhocFNR.adhoc_fnr_no && adhocFNR.adhoc_fnr_no.length >= 11 && this.setState({openForm: false}, this.props.createFNR(adhocFNR))} />
                                </div>
                                <SearchIcon className="wt-20 ht-20 mr-10 mt-8 serach-icon" style={{'marginLeft':"-93px"}} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            }
            </div>
            
        )
    }
}

export default withRouter(FOISDashboard);
