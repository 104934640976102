import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import SigninForm from 'Containers/SigninForm';

import ActionLoader from 'Components/ActionLoader';
import { getToken, getCompanyType, setCookie, getUserType, getUser } from 'Utils/storage';
import { ReactComponent as AlertIcon } from './icons/esclamation.svg';
import { GET_BROADCAST_MSG, SIGN_IN } from 'Constants/actionConstants';
import { SEEKER, USER_TYPE, PANTALOONS } from 'Constants/commonConstants';
import { sha256, sha224 } from 'js-sha256';
// import { getItemFromLocal } from 'Utils/localStorage';
import cx from 'classnames';
import LoginForm from 'Components/LoginForm/Index';

class Signin extends React.Component {
  constructor() {
    super();
    this.state = {
      email1: '',
      password1: '',
      username: '',
      showTag: false,
      force_logout: false,
      show_checkbox: false,
      captcha_value: '',
      isUserAlreadyLoggedin: false,
      hideLoginBtn: false,
    };
  }

  componentDidMount() {
    const userDetails = getUser();
    const token = getToken();
    const isSSOLogin = getUser().isSSOLogin;
    setTimeout(function () { //Start the timer
      this.setState({ showTag: true }) //After 1 second, set render to true
    }.bind(this), 1000)
    if (window.name && window.name.length > 0 && (!token || (JSON.parse(window.name))['tracking-session-token'] !== token) && !(JSON.parse(window.name))['sso-logout-duration']) {
      Object.entries(JSON.parse(window.name)).map(([key, value]) => {
        setCookie(key, value, 200);
      });
      location.reload();
    }

    if (!token) {
      return;
    } else {
      if (isSSOLogin) {
        localStorage.setItem('teg-component', false);
        localStorage.setItem('sso-session', true);
        localStorage.setItem('isSSOUser', true);
        localStorage.setItem('teg-sso-session', true);
      }
      this.props.history.push(getCompanyType() === SEEKER ? ((PANTALOONS.includes(userDetails.company_id) || PANTALOONS.includes(userDetails.companyId)) ? "/milestone" : "/dashboard") : '/trips')
      // this.props.history.push('/transporters')
    }
  }

  getEncryptedPassword = (password) => {
    const hash = sha256(password)
    return hash
  }

  signin = (e, email, password, companyType) => {
    const { dispatch } = this.props;
    const {force_logout,show_checkbox,
      captcha_value,
      signInforceOtpLogin
     } = this.state;
    let enCryPsw = this.getEncryptedPassword(password)
    e.preventDefault();
    dispatch({
        type: SIGN_IN,
        data: {
            email:email,
            // password: enCryPsw, *** Uncomment once BE changes are pushed ***
            password:password,
            device_type: 3,
            companyType:companyType,
            module_id: 3,
            force_logout: false,      
            captcha_value
        },
        onError: (data) => {
          const isUserAlreadyLoggedin = data == 1
          const hideLoginBtn = data == 1
          const isForceLogout = data == 1
          this.setState({ 
            email1:email , 
            password1:password, 
            companyType1:companyType, 
            E:e,
            isUserAlreadyLoggedin,
            hideLoginBtn,
            signInforceOtpLogin: isForceLogout,
            show_checkbox: isForceLogout ? true : undefined,
          });
        }
    })
}

  forceSignin = (e, email, password, companyType) => {
    const { dispatch } = this.props;
    const { force_logout, show_checkbox,
      captcha_value,
      signInforceOtpLogin
    } = this.state;
    let enCryPsw = this.getEncryptedPassword(password)
    e.preventDefault();
    dispatch({
      type: SIGN_IN,
      data: {
        email: email,
        // password: enCryPsw, *** Uncomment once BE changes are pushed ***
        password: password,
        device_type: 3,
        companyType: companyType,
        module_id: 3,
        force_logout: signInforceOtpLogin ? true : false,
        captcha_value
      },
      onError: (data) => {
        const isUserAlreadyLoggedin = data == 1
        const hideLoginBtn = data == 1
        const isForceLogout = data == 1
        this.setState({
          email1: email,
          password1: password,
          companyType1: companyType,
          E: e,
          isUserAlreadyLoggedin,
          hideLoginBtn,
          // signInforceOtpLogin: isForceLogout,
          // show_checkbox: isForceLogout ? true : undefined,
        });
      }
    })
  }


  onGenerateAnotherSession = () => {
    const { show_checkbox, invalidContact, invalidOTP,email1,password1,E,companyType1,force_logout } = this.state;
    this.setState({
        show_checkbox: !show_checkbox,
        // invalidOTP: !invalidOTP,
        // invalidContact: !invalidContact,
        force_logout: true
      }, () => {
        this.forceSignin(E,email1,password1,companyType1);
        })
  }


  handleSetCaptchaValue = (captcha_value = '') => {
    this.setState((prevState) => {
      const updatedState = { ...prevState };
      updatedState.captcha_value = captcha_value
      updatedState.isUserAlreadyLoggedin = false;
      return updatedState
    })
  }

  shouldEnableRecaptcha = () => {
    return false; // Disable Recaptcha
    // if(getItemFromLocal("rc") === false){
    //   return false;
    // }

    // if(['UAT','QA','dev'].includes(process.env.DEPLOYED_ENV)){
    //   return true;
    // }

    // return false;
  }

  render() {
    const { signinError, isSubmitting, ssoConfigData } = this.props;
    const {
      isUserAlreadyLoggedin,
      hideLoginBtn
    } = this.state
    return (
      <Fragment>
        {/* <div className="center pr" style={{width: "40rem", padding: "3rem", transform: "translate(0px, 3rem)", borderRadius: "10px", "box-shadow": "0 0 20px 0px black", background:"#fff"}}>
          <SigninForm
          error={signinError} showTag={this.state.showTag} 
          ssoConfigData={ssoConfigData}
          signin={this.signin}
          handleSetCaptchaValue={this.handleSetCaptchaValue}
          isUserAlreadyLoggedin = {isUserAlreadyLoggedin}
          hideLoginBtn = {hideLoginBtn}
          shouldEnableRecaptcha={this.shouldEnableRecaptcha}
          onGenerateAnotherSession={this.signin}
          />
          {isSubmitting && <ActionLoader fixed={true} />}
        </div> */}
        {/* {this.state.show_checkbox === true && 
            <Fragment>
              <div className="fs-14 flex flex-center col-1" style={{ marginTop: "2rem" }}>
                    <div className="theme-color fBold flex flex-center pt-5 mb-40 tCenter " style={{ background:"wheat", padding: "1rem", "border-radius": "5px", "margin-top": "1rem" }}>
                    <AlertIcon className="wt-20 flex ht-20 alert-icon"/>
                      <div style={{ "padding-left":"5rem" }}>
                      
                        {
                        <Fragment>
                            <span className="error-color flex flex-center" style={{ fontSize:"16px" }}>
                            Your Previous Login / Session is still active and not closed properly.
                            To Logout previous sessions,
                           {<Fragment>    
                          <a
                            onClick={this.onGenerateAnotherSession}
                            style={{
                              color: "white",
                              "box-shadow": "0 0 5px 0 black",
                              padding: "0.25rem",
                              "margin-left": " 0.5rem",
                              "border-radius": "5px",
                              background: "green"
                            }}
                            className={cx('btnT2',{
                              disabledT1: this.shouldEnableRecaptcha() ? isUserAlreadyLoggedin : false
                            })}
                            >
                            Click Here...
                          </a>
                           </Fragment>}
                          </span>
                        </Fragment>}
                        { <div className="error-color flex flex-center fBold mt-5" style={{ fontSize:"16px" }}>Note: Any unsaved data in previous sessions will be lost</div>}
                      </div>
                      
                    </div>
              </div>
            </Fragment>
          } */}
          <Fragment>
          <LoginForm
          error={signinError} 
          showTag={this.state.showTag}
          ssoConfigData={ssoConfigData}
          signin={this.signin}
          handleSetCaptchaValue={this.handleSetCaptchaValue}
          isUserAlreadyLoggedin={isUserAlreadyLoggedin}
          hideLoginBtn={hideLoginBtn}
          shouldEnableRecaptcha={this.shouldEnableRecaptcha}
          onGenerateAnotherSession={this.onGenerateAnotherSession}
          show_checkbox={this.state.show_checkbox}
          />
        {isSubmitting && <ActionLoader fixed={true} />}
        </Fragment>

        {
        this.state.show_checkbox === true && 
            <Fragment>
              <div 
                   className="fs-14 flex flex-center col-1" 
                   style={{ 
                    background: "linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%)", 
                    marginTop: "-200px" }}
                    >
                    <div 
                    className="theme-color fBold flex flex-center pt-5 mb-40 tCenter " 
                    style={{ 
                      background:"wheat", 
                      padding: "1rem", 
                      "border-radius": "5px", 
                      "margin-top": "1rem" , 
                      zIndex:4}}
                    >
                    <AlertIcon className="wt-20 flex ht-20 alert-icon"/>
                      <div style={{ "padding-left":"5rem" }}>
                      
                        {
                        <Fragment>
                            <span className="error-color flex flex-center" style={{ fontSize:"16px" }}>
                            Your Previous Login / Session is still active and not closed properly.
                            To Logout previous sessions,
                           {<Fragment>    
                          <a
                            onClick={this.onGenerateAnotherSession}
                            style={{
                              color: "white",
                              "box-shadow": "0 0 5px 0 black",
                              padding: "0.25rem",
                              "margin-left": " 0.5rem",
                              "border-radius": "5px",
                              background: "green"
                            }}
                            className={cx('btnT2',{
                              disabledT1: this.shouldEnableRecaptcha() ? isUserAlreadyLoggedin : false
                            })}
                            >
                            Click Here...
                          </a>
                           </Fragment>}
                          </span>
                        </Fragment>}
                        { <div className="error-color flex flex-center fBold mt-5" style={{ fontSize:"16px" }}>Note: Any unsaved data in previous sessions will be lost</div>}
                      </div>
                      
                    </div>
              </div>
            </Fragment>
          }
      </Fragment>
    );
  }
}

const mapStateToProps = ({authentication, common}) => {
  return {
    signinError: authentication.signin.error,
    isSubmitting: authentication.signin.isSubmitting,
    broadcastMsgs: common.broadcastMsgs,
    ssoConfigData: common.ssoConfigData || {}
  }
};


export default connect(mapStateToProps)(Signin);
