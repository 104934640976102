import React, { Component, Fragment } from "react";
import "./OtpVerifyFormCard.scss";
import Logo from "Components/Logo/index";
import Tabs from "Components/Tabs/index";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
import OtpInput from "react-otp-input";
import Timer from "Components/Timer/index";
import { ReactComponent as AlertIcon } from './esclamation.svg';
import cx from 'classnames';
import NewTimer from "Components/NewTimer/index";
class OtpVerifyFormCard extends Component {
  render() {
    const {
      companyTypeTabButton,
      companyType,
      selectCompanyType,
      toggleVerifyOtp,
      handleChangeOtp,
      otp,
      backtoSendOtp,
      maskInput,
      hasExpired,
      start_time,
      expire_time,
      systemTime,
      otpExpired,
      otpSignin,
      onSubmit,
      onClickReSendOtp
    } = this.props;
    // console.log("mask",this.props)

    const inputStyle = {
      width: "30px",
      height: "36px",
      flexShrink: "0",
      borderRadius: "4px",
      border: "1px solid  #DAE0F5",
      background: "#FFF",
      color: "#00000",
    };

    const focusStyle = {
      border: "1px solid #007bff",
      outline: "none",
    };

    const containerStyle = {
      display: "flex",
    };

    return (
      <Fragment>
        {/* <Rectangle className={"rectangleTopL"} /> */}
        {/* <Rectangle className={"rectangleTopS"} /> */}
        <div className="otpVerifyFormCardMain">
          <div className="otpVerifyFormCard">
            <div className="otpVerifyFormLogo">
              <Logo className="otpVerifyLogoImage" />
            </div>
            <div className="companyTypeButton">
              <Tabs
                tabs={companyTypeTabButton}
                activeTabId={companyType}
                selectTab={selectCompanyType}
              />
            </div>
            <div className="otpVerifySendCodeBox">
              <p className="otpVerifySendCodeLable">
                We’ve sent OTP on {maskInput}
              </p>
            </div>
            <div className="otpVerifyEnterOtpLableBox">
              <p className="otpVerifyEnterOtpLable">Enter OTP</p>
            </div>
            <div className="otpVerifyInputBox">
              <OtpInput
                numInputs={6}
                value={otp}
                separator={<span className="otpVerifySeprator">-</span>}
                containerStyle={containerStyle}
                name="otp"
                inputStyle={inputStyle}
                focusStyle={focusStyle}
                onChange={handleChangeOtp}
                isInputNum={true} 
              />
            </div>
            <div className="optVerifyTimerBox">
              <div className="optVerifyTimer">
                {!hasExpired ?
                // <Timer
                //   from={start_time} 
                //   to={expire_time} 
                //   currentTime={systemTime} 
                //   isOTP={true} 
                //   otpExpired={otpExpired}
                // />
                <NewTimer
                  from={start_time} 
                  to={expire_time}
                  currentTime={systemTime}
                  isOTP={true}
                  otpExpired={otpExpired}
                />
                : <span className="fs-14 lh-15p fBold error-color">OTP has expired</span>
                }
                {/* OTP expires in {5}m {0}s */}
              </div>
            </div>
            <div className="otpVerifyButtonBox">
              <Button 
              Name={"Verify OTP"} 
              // className={"otpVerifyButton"} 
              className={`otpVerifyButton ${otp.length === 6 ? 'verifyOtp' : ''}`}
              onClick={otpSignin}
              disabled={otp.length!=6}
              />
            </div>
            <div className="otpvVerifyHorizonatl">
              <hr></hr>
            </div>
            <div className="otpVerifyLableBox">
              <p className="otpVerifyLable">Did not receive the OTP?</p>
            </div>

            <div className="otpVerifyResendOtpButtonBox">
              <Button
                Name={"Resend OTP"}
                // className={"otpVerifyResendOtpButton"}
                className={`otpVerifyResendOtpButton ${hasExpired ? 'verifyOtp' : ''}`}
                disabled={!hasExpired}
                onClick={onClickReSendOtp}
              />
            </div>
            <div className="optVerfiyBackToSignInLableBox">
              <p
                className="optVerfiyBackToSignInLable"
                onClick={backtoSendOtp}
              >
                Back To SignIn
              </p>
            </div>
          </div>
        </div>
        {/* <Rectangle className={"rectangleBottomL"} /> */}
        {/* <Rectangle className={"rectangleBottomS"} /> */}
      </Fragment>
    );
  }
}
export default OtpVerifyFormCard;
