import React, { Component, Fragment } from 'react';
import Overlay from 'Components/Overlay';
import Checkbox from 'Components/Checkbox';
import Button from 'Components/Button';

class ActionDetail extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { trip } = this.props;
        return (
            <Overlay show={this.props.show}  hideOverlay={this.props.onAction}>
                <div className={'flex-1'}>
                    <header className={'pl-15 fs-24 theme-color fBolder  mr-40 text-center mb-10'}>Trip Details</header>
                    <section className={'ml-10 mr-10'}>
                        <div className={'fs-16 fBold'}>{`DO No. : ${trip.do_no}`}</div>
                    </section>
                    <div className={'flex flex-between fBolder'}>
                        <section className={'box-shadow pb-10 pt-10 pl-10 pr-10 br-5 mt-5 mb-5 wt-350'}>
                            <header className="fs-18 theme-color fBolder mb-5">{'Driver Details'}</header>
                            {trip.driver_details.map((driver, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div className={'fBold'}>
                                            {`Driver: ${(driver.firstName || driver.lastName) ? `${driver.firstName} ${driver.lastName}` : driver.name}`}
                                        </div>
                                        <div className={'fBold'}>
                                            {`Contact: ${driver.number}`}
                                        </div>
                                    </Fragment>
                                )
                            })}
                        </section>
                        <section className={'box-shadow pb-10 pt-10 pl-10 pr-10 br-5 mt-5 mb-5 wt-350'}>
                            <header className="fs-18 theme-color fBolder mb-5">{'Transporter Details'}</header>
                            <div>
                                {`Transporter: ${trip.transporter_details.name}`}
                            </div>
                            <div>
                                {`Contact: ${trip.transporter_details.number}`}
                            </div>
                            <div>
                                {`Email: ${trip.transporter_details.email}`}
                            </div>
                        </section>
                        <section className={'box-shadow pb-10 pt-10 pl-10 pr-10 br-5 mt-5 mb-5 wt-350'}>
                            <header className="fs-18 theme-color fBolder mb-5">{'Vehicle Details'}</header>
                                <div>
                                    {`Vehicle Type: ${trip.vehicle_details.vehicle_type}`}
                                </div>
                                <div>
                                    {`Vehicle No: ${trip.vehicle_details.vehicle_no}`}
                                </div>
                                <div>
                                    {`Type: ${trip.vehicle_details.body_type}`}
                                </div>
                        </section>
                    </div>
                    <section className={'box-shadow pb-10 pt-10 pl-10 pr-10 br-5 mt-5 mb-5'}>
                        <header className="fs-18 theme-color fBolder mb-10">{'Route Details'}</header>
                        <div className="route-details-table">
                            <div className="flex flex-between mb-15">
                                <span className="p5 fBolder fs-12 header-light-color col-7">Location</span>
                                <span className="p5 fBolder fs-12 header-light-color col-7">Location type</span>
                                <span className="p5 fBolder fs-12 header-light-color col-7">PIN code</span>
                                <span className="p5 fBolder fs-12 header-light-color col-7">Weekly off</span>
                                <span className="p5 fBolder fs-12 header-light-color col-7">Stopping point</span>
                                <span className="p5 fBolder fs-12 header-light-color col-7">Daily cutoff time</span>
                                <span className="p5 fBolder fs-12 header-light-color col-7">Consignee Details</span>
                            </div>
                            {trip.route_details.map((route,ind) => {
                                return(
                                    <div className="flex ht-70 flex-between flex-middle trip-row" key={ind}>
                                        <div className="col-7">
                                            <div className="fBolder fs-16 table-text-color mb-5">{route.location || '-'}</div>
                                        </div>
                                        <div className="col-7">
                                            <div className="fs-16 table-text-color mb-5">{route.locationType || '-'}</div>
                                        </div>
                                        <div className="col-7">
                                            <div className="fs-16 table-text-color mb-5">{route.pincode ||'-'}</div>
                                        </div>
                                        <div className="col-7">
                                            <div className="fs-16 table-text-color mb-5">{route.weeklyOff || '-'}</div>
                                        </div>
                                        <div className="col-7">
                                            <div className="fs-16 table-text-color mb-5">{route.city || '-'}</div>
                                        </div>
                                        <div className="col-7">
                                            <div className="fs-16 table-text-color mb-5">{route.cutoffTime || '-'}</div>
                                        </div>
                                        <div className="col-7">
                                            <div className="fs-14 table-text-color mb-5">{route.name || '-'}</div>
                                            <div className="fs-12 theme-color mb-5 fBolder">{route.number || '-'}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                    <section className={'box-shadow pb-10 pt-10 pl-10 pr-10 br-5 mt-5 mb-5'}>
                        <header className="fs-18 theme-color fBolder mb-10">{'Actions'}</header>
                        <Checkbox  label={'Spoke to driver'} checked={true}/>
                    </section>
                    <section className={'box-shadow pb-10 pt-10 pl-10 pr-10 br-5 mt-5 mb-5'}>
                        <header className="fs-18 theme-color fBolder mb-10">{'Remarks'}</header>
                        <div className={'mb-10'}>
                            <textarea className={'col-1 br-5 fs-14'} />
                        </div>
                    </section>
                    <div className={'mt-5'}>
                        <Button value='Save'/>
                    </div>
                </div>
            </Overlay>
        )
    }
}

export default ActionDetail;
