import React, {Component} from 'react';
import classNames from 'classnames';

import Checkbox from 'Components/Checkbox';
import Button from 'Components/Button';

import { ReactComponent as CloseIcon } from './icons/close-icon.svg';


export default class TrackingSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trip: props.trip,
            saved: true
        }
    }
    changePreference = (preferenceIndex)=> {
        const newState = {...this.state};
        const trackingPreferences = newState.trip.trackingPreferences;
        // const currentTrackingPreference = trackingPreferences[preferenceIndex];
        // currentTrackingPreference.enabled = !currentTrackingPreference.enabled;
        // trackingPreferences.forEach((preference)=> {
        //     if(currentTrackingPreference.trackingType === 5 && currentTrackingPreference.enabled && preference.trackingType !== 5) {
        //         preference.enabled = false;
        //     } else if(currentTrackingPreference.trackingType !== 5 && currentTrackingPreference.enabled && preference.trackingType == 5) {
        //         preference.enabled = false;
        //     }
        // })
        for(var i in trackingPreferences){
            if(i == preferenceIndex){
                trackingPreferences[i].enabled = true;
            } else {
                trackingPreferences[i].enabled = false;
            }
        }

        newState.saved = false;
        this.setState(newState);
    }
    saveTrackingPreferences = ()=> {
        this.props.saveTrackingPreferences(this.state.trip.trackingPreferences, this.state.trip.notificationSettings);
        this.setState({
            saved: true
        })
    }
    addCommunicationAccount = (index)=> {
        const newState = {...this.state};
        newState.trip.notificationSettings[index].accounts.push({
            email: '',
            sms: ''
        });
        newState.saved = false;
        this.setState(newState);
    }
    changeEmail = (index, accountIndex, e)=> {
        const newState = {...this.state};
        newState.trip.notificationSettings[index].accounts[accountIndex].email = e.target.value;
        newState.saved = false;
        this.setState(newState);
    }
    changeSMS = (index, accountIndex, e)=> {
        const newState = {...this.state};
        newState.trip.notificationSettings[index].accounts[accountIndex].sms = e.target.value;
        newState.saved = false;
        this.setState(newState);
    }
    removeAccount = (index, accountIndex)=> {
        const newState = {...this.state};
        newState.trip.notificationSettings[index].accounts.splice(accountIndex, 1);
        newState.saved = false;
        this.setState(newState);
    }
    render(){
        const { trip, saved } = this.state
        const { trackingPreferences, notificationSettings } = trip;
        const isEnable = [4, 5, 6, 7, 8].indexOf(trip.type) > -1 ? true : false;

        return (
            <div className={classNames("tracking-settings")}>
                <header className="fs-18 theme-color fBolder mb-30">Notification Settings</header>
                <div className="flex pr-50">
                    {
                        (notificationSettings || []).map((setting, index)=> {
                            return (
                                <div name={index} key={index} className="flex-flex-column mr-70">
                                    <div className="table-text-color fs-16 fBolder mb-20">{setting.plant_name}</div>
                                    {
                                        (setting.accounts || []).map((account, accountIndex)=> {
                                            return (
                                                <div key={accountIndex} className="wt-300 border-theme-light p10 br-5 mb-20 pr">
                                                    {(setting.accounts || []).length > 1 && <span
                                                       className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--15 r--15"
                                                       onClick={this.removeAccount.bind(this, index, accountIndex)}>
                                                       <CloseIcon />
                                                    </span>}
                                                    <div className="ht-70 bb-theme-light flex flex-column flex-center">
                                                        <div className="fs-10 communication-color fMedium mb-10">@ EMAIL ID</div>
                                                        <input onChange={this.changeEmail.bind(this,index, accountIndex)} className="communication-value-color fs-13 fMedium br-none" value={account.email}/>
                                                    </div>
                                                    <div className="ht-70 bb-theme-light flex flex-column flex-center">
                                                        <div className="fs-10 communication-color fMedium mb-10">@ SMS</div>
                                                        <input onChange={this.changeSMS.bind(this,index, accountIndex)} className="communication-value-color fs-13 fMedium br-none" value={account.sms}/>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div onClick={this.addCommunicationAccount.bind(this, index)} className="curP bg-theme ht-40 flex flex-middle flex-center mt-10 fs-13 fMedium theme-color">
                                        + Add New Communication Account
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="wt-250 mt-50">
                    <Button value="Save" className={classNames("ht-40", {
                        'op-1':  saved
                    })} onClick={this.saveTrackingPreferences}/>
                </div>

            </div>
        );
    }
};

