import React, { Component, Fragment } from "react";
import "./LoginFormCard.scss";
import Logo from "Components/Logo/index";
import Tabs from "Components/Tabs/index";
import { ReactComponent as PasswordVisible } from "./passwordvisible.svg";
import { ReactComponent as PasswordNotVisible } from "./passwordNotVisible.svg";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
import { ToastContainer, toast } from "react-toastify";

class LoginFormCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailFocused: false,
      isPasswordFocused: false
    };
  }

  render() {
    const {
      companyTypeTabButton,
      companyType,
      selectCompanyType,
      isPasswordVisible,
      togglePasswordVisibility,
      handlePasswordChange,
      password,
      toggleOtpLogin,
      toggleForgotPassword,
      toggleLoginWithSso,
      email,
      handleEmailChange,
      onSubmit,
    } = this.props;
    return (
      <div>
        <form onSubmit={onSubmit}>
        {/* <Rectangle className={"rectangleTopL"} /> */}
        {/* <Rectangle className={"rectangleTopS"} /> */}
        <div className="loginFormCardMain">
            <ToastContainer />
          <div className="loginFormCard">
            <div className="loginFormLogo">
              <Logo className="loginFormLogoImage"/>
            </div>
            <div className="companyTypeButton">
              <Tabs
                tabs={companyTypeTabButton}
                  activeTabId={companyType}
                selectTab={selectCompanyType}
              />
            </div>
            <div className="loginFormEmailMain">
              <p className="loginFormEmailLable">Email</p>
              <div className={`loginFormPasswordBox ${this.state.isEmailFocused ? 'box-focused' : ''}`}>
                <input 
                type="text" 
                name="email" 
                className="loginFormEmailInput"
                value={email}
                onChange={handleEmailChange}
                onFocus={() => this.setState({ isEmailFocused: true })}
                onBlur={() => this.setState({ isEmailFocused: false })}
                >

                </input>
              </div>
            </div>
            <div className="loginFormPasswordMain">
              <p className="loginFormPasswordLable">Password</p>
              <div className={`loginFormPasswordBox ${this.state.isPasswordFocused ? 'box-focused' : ''}`}>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  className="loginFormPasswordInput"
                  name="password"
                  maxLength={16}
                  onFocus={() => this.setState({ isPasswordFocused: true })}
                  onBlur={() => this.setState({ isPasswordFocused: false })}
                ></input>
                <div
                  className="loginFormPasswordIcon"
                  // onClick={togglePasswordVisibility}
                  onClick={() => {
                      togglePasswordVisibility();
                      this.setState({ isPasswordFocused: true })
                  }}
                >
                  {isPasswordVisible ? (
                    <PasswordVisible />
                  ) : (
                    <PasswordNotVisible />
                  )}
                </div>
              </div>
            </div>
            <div className="forgotPasswordLableBox">
              <p className="forgotPasswordLable" onClick={toggleForgotPassword}>
                Forgot Password?
              </p>
            </div>
            <div className="loginButtonBox">
              <Button Name={"Login"} className={"loginButton"} />
            </div>
            <div className="loginWithOtpButtonBox">
              <Button
                Name={"Login with OTP"}
                className={"loginWithOtpButton"}
                onClick={toggleOtpLogin}
              />
            </div>
            <div className="orLableBox">
              <p className="orLable">Or</p>
            </div>
            <div className="loginWithSsoLableBox">
              <p className="loginWithSso" onClick={toggleLoginWithSso}>
                Login with SSO
              </p>
            </div>
          </div>
        </div>
        {/* <Rectangle className={"rectangleBottomL"} /> */}
        {/* <Rectangle className={"rectangleBottomS"} /> */}
        </form>
      </div>
    );
  }
}
export default LoginFormCard;
