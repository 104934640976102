import React from 'react';
import { connect } from 'react-redux';
import Dropdown from 'Components/Dropdown';
import Logo from 'Components/Logo';
import Select from 'react-select';
import {ReactComponent as SearchIcon} from 'Components/SearchDropdown/icons/search.svg';
import {ReactComponent as DropdownIcon} from './icons/dropdown.svg';
import crossMark from './icons/cross-mark.svg';
import LogoutIcon from './icons/logout1.png';
import GREENPLY from './icons/Greenply-logo.png';
import { getCompanyList, getCompanySeekerId, getCompanyType, getGlobalSeeker, getUser, setCompanySeekerId } from 'Utils/storage';
import { GET_SEEKERS, SIGN_OUT } from 'Constants/actionConstants';
import { GREENPLY_IDs, PROVIDER } from 'Constants/commonConstants';
import { SSO_BASE_URL, SSO_URL, logOutWithToken } from 'Utils/api';
import { deleteToken, clearStorage } from 'Utils/storage';
import OutsideClickHandler from 'react-outside-click-handler';
import { withRouter } from 'react-router-dom';

const searchFields = [
  { label: 'Invoice No.', value: 'invoice_no'}
]

class Navbar extends React.Component {
  constructor(props) {
    super();
    this.state = {
        searchText: props.initialSearchValue || "",
        isNavbarDropdownShown: false,
        type: 'Primary',
        isDropDown: false,
        search_field: '',
        seekerList : [],
        selectedSeeker : null,
        selected_seeker: getCompanySeekerId() || null,
        list: getCompanyList()
    }
  }

  componentDidMount = () => {
    const {match} = this.props
    const companyType = getCompanyType();
    const routerPath = match && match.path
    if(companyType === PROVIDER && routerPath == '/milestone'){
      this.getSeekers()
    }
  }

  showNavbarDropdown = (e)=> {
      e.stopPropagation();
      this.setState({
        isNavbarDropdownShown: true
      })
  }
  hideNavbarDropdown = ()=> {
      this.setState({
        isNavbarDropdownShown: false
      })
  }
  changeSearchText = (e)=> {
      this.setState({
          searchText: e.target.value
      }, () => {
        this.props.onSearch(this.state.searchText, this.state.search_field);
      });
  }
  onEnter = (e)=> {
    if(e.nativeEvent.which === 13) {
        this.props.onSearch(e.target.value);
    }
  }

  onClearFilter = () => {
      this.setState({ searchText : '' }, () => {
        this.props.onSearch()
      })
  }


  logout = (e)=> {
    logOutWithToken();
    setTimeout(() => location.href = "/", 2000);
  }

  SSOLogout = () => {
    location.replace(`${SSO_BASE_URL}${SSO_URL}/?slo&module=3`);
    setTimeout(() => {deleteToken(), localStorage.clear()}, 2000);
  }

  changePassword = () => {
    const { history } = this.props;
    history.push('/changePassword');
  }

  onShowDropDown = () => {
    const { isDropDown } = this.state;
    this.setState({ isDropDown: !isDropDown });
  }

  onSearchFieldSelect = (option) => {
    this.setState({
      search_field: (option || {}).value 
    }, () => {
      if(this.state.searchText) {
        this.props.onSearch(this.state.searchText, this.state.search_field);
      }
    })
  }

  onSeekerFieldSelect = (option) => {
    setCompanySeekerId((option || {}).id || 'none')
    this.setState({
      selected_seeker: (option || {}).id 
    }, () => {
      this.props.onSearch()
    })
  }

  getSeekers = () => {
    const { dispatch} = this.props;
    dispatch({
      type: GET_SEEKERS,
      search :'',
      onSuccess: (data) => {
        const tempData = Array.isArray(data) && data.map(el => ({
          label: el.name,
          value: el.company_id,
          ...el
        }));
        const defaultSelect = tempData.length > 0 ? tempData[0] : {}
          dispatch({
            type:"PAGE_RELOAD",
            payload:tempData[0]
          })
          const tempSeeker = getGlobalSeeker()
          if(!!!tempSeeker.company_id){
            localStorage.setItem('selectedGlobalSeeker', JSON.stringify(tempData[0]));
          }
        // if (tempSeeker )
        this.setState({seekerList : tempData, selectedSeeker:tempSeeker.company_id? tempSeeker:defaultSelect})
    }
    })
}

  onSeekerChange = (selected) => {
    const {history,dispatch} = this.props
    this.setState({selectedSeeker : selected})
    localStorage.setItem('selectedGlobalSeeker', JSON.stringify(selected));
    // history.push('/milestone')
    dispatch({
      type:"PAGE_RELOAD",

    })
  }

  render() {
    const isSSO = localStorage.getItem('isSSOUser');
    const {match} = this.props
    const {seekerList , selectedSeeker, list} = this.state
    const companyType = getCompanyType();
    const routerPath = match && match.path;
    return (
        <div className="flex navbar-wrap col-1 pr">
            <div className="wt-70 flex flex-center flex-middle">
                <Logo className="wt-80 ht-40"/>
            </div>
            <div tabIndex="1" onClick={this.showNavbarDropdown} onBlur={this.hideNavbarDropdown} className="m-hide pr wt-200 flex flex-middle pl-40 fs-20 table-text-color bb-light br-light l-5">
                Tracking
                {/* <span className="pa r-10">
                    <DropdownIcon />
                </span>
                {this.state.isNavbarDropdownShown && <div className="navbar-dropdown-items bg-white box-shadow pa t-100p l-0 col-1">
                    <div className="dropdown-item dropdown-item fs-20 bb-light flex flex-middle pl-40 curP ht-50">Contract</div>
                    <div className="dropdown-item dropdown-item fs-20 bb-light flex flex-middle pl-40 curP ht-50">Indent</div>
                </div>} */}
            </div>
            {this.props.isSearch && <div className='pr ml-5'>
              <Dropdown
                 onSelect={(value)=> this.onSearchFieldSelect(value)}
                 items={searchFields}
                 value={this.state.search_field && searchFields.find(el => el.value === this.state.search_field)}
                 label="Select.."
                 displayKey={'label'}
                 selectedClassName={`wt-150`}
                 customClass={'wt-150 ht-45'}
                 hasClearFilter={() => this.onSearchFieldSelect() }
              />
            </div>}
            {this.props.isSearch && <div className="pr m-hide">
               <input
                    onChange={this.changeSearchText}
                    value={this.state.searchText}
                    onKeyPress={this.onEnter}
                    className="ht-44 pl-50 pr-16 fs-14 pr wt-400 l-5"
                    placeholder="Search by Docket No., Driver No., Vehicle No."
                />
                <div className="pa r-10 t-15">
                    {
                       this.state.searchText && <span className="cross-img" style={{backgroundImage: `url(${crossMark})`}} onClick={this.onClearFilter}></span>
                    }
                </div>
                <div className="pa l-15 t-13">
                    <SearchIcon />
                </div>
                  </div> }

            {this.props.isSearch && <div className="pr d-hide">
                <input
                    onChange={this.changeSearchText}
                    value={this.state.searchText}
                    onKeyPress={this.onEnter}
                    className="ht-70 pl-30 pr-16 fs-14 pr l-5"
                    placeholder="Search"
                />
                <div className="pa l-10 t-27">
                    <SearchIcon />
                </div>
            </div>}

            <OutsideClickHandler
                  onOutsideClick={() => {
                      this.setState({isDropDown: false});
              }}>
          <div className="pa r-25 flex">
            {companyType === PROVIDER && routerPath == '/milestone' &&
              <div className="r-50">
                <Select
                  options={seekerList}
                  className={'wt-200 flex-3 mr-20 pdd-top'}
                  placeholder={'Seeker Name'}
                  value = {selectedSeeker}
                // isClearable={true}
                  onChange={this.onSeekerChange}
                />
              </div>
            }
            {companyType === PROVIDER && routerPath !== '/milestone' && list.length > 1 &&
              <div className="r-50 mr-40">
                <Dropdown
                 onSelect={(value)=> this.onSeekerFieldSelect(value)}
                 items={list || []}
                 value={this.state.selected_seeker && (list || []).find(el => el.id === this.state.selected_seeker)}
                 label="Select.."
                 displayKey={'name'}
                 selectedClassName={`wt-180`}
                 customClass={'wt-180 ht-45'}
              />
              </div>
            }
            <div className="flex flex-self-center mt-5">
              <div className={'flex mt-5 mr-10 theme-color'} onClick={this.onShowDropDown}>
                    {getUser().user_name}<DropdownIcon className={'mt-5 ml-5'}/>
                    {GREENPLY_IDs.includes(getUser().company_id) && <img className="teg-logo nav-bar-logo wt-80 ht-50 mt--15" src={GREENPLY} alt="teg logo" />}
              </div>
              <img onClick={isSSO ? this.SSOLogout : this.logout} src={LogoutIcon} alt="logout" className="ht-30 wt-30 curP"/>
            </div>
                </div>
                  
            {this.state.isDropDown && <div className="box-shadow bg-white br-5 z-100 pa t-45 wt-150 r-40">
              <div className="pl-8 pt-8 pr-8 pb-8 pl-16 ht-40 fs-14 label-color bg-hover-theme curP" onClick={this.changePassword}>Change Password </div>
            </div>}
            </OutsideClickHandler>
        </div>
    );
  }
}

const mapStateToProps = () => {
  return {

  }
};


export default connect(mapStateToProps)(withRouter(Navbar));
