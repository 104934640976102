import React, { Component } from 'react';
import Moment from 'moment';

import Input from 'Components/Input';
import Dropdown from 'Components/Dropdown';

import Button from 'Components/Button';
import SearchDropdown from 'Components/SearchDropdown';
import ActionLoader from 'Components/ActionLoader';
import Checkbox from 'Components/Checkbox';
import Overlay from 'Components/Overlay';
import { ReactComponent as CloseIcon } from 'Components/TrackingSettings/icons/close-icon.svg';
import { SingleDatePicker } from 'react-dates';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import uuid4 from 'uuid';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';

import ChangeDriver from 'Components/TransporterDetails/ChangeDriver';
import { GOOGLE_API_KEY } from 'Constants/commonConstants';

import { scroll } from '../../utils/common';
import classNames from 'classnames';

const emptyGeoFenceLocation = {
    location: "",
}

class ChangeVehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleNo: null,
            vehicleServiceType: {},
            isoNo: '',
            loadingCapacity: '',
            vehicleType: {},
            bodyType: {},
            vehicleRemarks: (props.trip && props.trip.vehicleDetails && props.trip.vehicleDetails.rejectRemarks) || '',
            vehicleHistory: props.vehicleHistory || [],
            error: false,
            time: new Date().getTime(),
            date: new Date().getTime(),
            open: false,
            showChangeVehiclePopUp: false,
            newgeofenceLocations: [{...emptyGeoFenceLocation, id: uuid4()}],
            washingStatus: '',
            noOfWashes: 0,
            rejectRemarks: ''
        }
    }

    componentDidMount() {
        this.setState({
            vehicleNo: null,
            vehicleServiceType: {},
            isoNo: '',
            loadingCapacity: '',
            vehicleType: {},
            bodyType: {},
            vehicleRemarks: (this.props.trip && this.props.trip.vehicleDetails && this.props.trip.vehicleDetails.rejectRemarks) || '',
            vehicleHistory: this.props.vehicleHistory || [],
            error: false,
            time: new Date().getTime(),
            date: new Date().getTime(),
            open: false,
            showChangeVehiclePopUp: false,
            washingStatus: '',
            noOfWashes: 0
        },() => this.props.getVehicleRemarks("1"));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.vehicleData !== this.props.vehicleData) {
            const { vehicleTypes, vehicleBodyTypes } = this.props;
            const { loadingCapacity, vehicleType, bodyType, vehicleServiceType, washingStatus, noOfWashes } = this.state;

            const updatedData = {};
            const isRoadTanker = (vehicleServiceType.name || '').toLowerCase().includes('road');
            const isIsoTanker = (vehicleServiceType.name || '').toLowerCase().includes('iso');

            if (isRoadTanker || isIsoTanker) {
                updatedData.loadingCapacity = nextProps.vehicleData.loading_capacity || loadingCapacity;
                updatedData.washingStatus = nextProps.vehicleData.washing_status || washingStatus;
                updatedData.noOfWashes = nextProps.vehicleData.no_of_washes || noOfWashes;
            }

            if (nextProps.vehicleData.vehicle_type) {
                const vehicleTypeSelected = vehicleTypes.filter((item) => { return item.id === nextProps.vehicleData.vehicle_type });
                updatedData.vehicleType = (vehicleTypeSelected && vehicleTypeSelected.length > 0) ? vehicleTypeSelected[0] : vehicleType;
            }

            if (nextProps.vehicleData.body_type) {
                const bodyTypeSelected = vehicleBodyTypes.filter((item) => { return item.id === nextProps.vehicleData.body_type });
                updatedData.bodyType = (bodyTypeSelected && bodyTypeSelected.length > 0) ? bodyTypeSelected[0] : bodyType;
            }
            this.setState(updatedData);
        }
    }

    setTime = (time) => {
        this.setState({
            time
        });
    };


    onChangeVehicle = () => {
        this.setState({
            showChangeVehiclePopUp: !this.state.showChangeVehiclePopUp
        });
    }

    selectVehicleServiceType = (vehicleServiceType) => {
        const isRoadTanker = (vehicleServiceType.name || '').toLowerCase().includes('road');
        const isIsoTanker = (vehicleServiceType.name || '').toLowerCase().includes('iso');
        const data = {
            vehicleServiceType
        };
        if (isRoadTanker) {
            data.isoNo = '';
        } else if (!isRoadTanker && !isIsoTanker) {
            data.isoNo = '';
            data.loadingCapacity = '';
        }
        this.setState(data);
        this.props.selectVehicleServiceType(vehicleServiceType)
    }

    selectVehicleType = (vehicleType) => {
        this.setState({
            vehicleType
        });
    }

    selectBodyType = (bodyType) => {
        this.setState({
            bodyType
        });
    }

    onChangeVehicleDetails = (field, value) => {
        const { trip, vehicleTypes, vehicleBodyTypes } = this.props;
        const data = {seeker_id: trip.seekerId};
        let callApi = false;
        value = value.toUpperCase();
        if (field === 'vehicleNo') {
            if ((value.match(/^\w+$/) || value === '') && value.length <= 11) {
                callApi = value.length >= 7;
            } else {
                return;
            }
            data.vehicle_no = value || '';
        } else {
            callApi = true;
            data.iso_no = value || '';
        }
        if(callApi) {
            this.props.onChangeVehicleDetails(data);
        }
        this.setState({
            [field]: value,
            error: false
        });
    }

    onChange = (e) => {
        const reg = new RegExp('^[^<>!%$]*[0-9a-zA-Z-]+$');
        if(e.target && e.target.name == "vehicleNo" && e.target.value && !reg.test(e.target.value)) return;
        if(e.target && e.target.name == "vehicleNo" && e.target.value && e.target.value.length > 11) {
            return;
        }
        // if (e.target.name == 'transporterNumber' && e.target.value && !reg.test(e.target.value)) {
        //     return;
        // }
        // if (e.target.name == 'transporterNumber' && e.target.value.length > 10) {
        //     return;
        // }
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSubmit = (e) => {
        const d = Moment(this.state.date).format("YYYY-MM-DD") + ' ' + Moment(this.state.time).format("HH:mm");
        const currDate = Moment(d.toString());
        const {
            date, time, vehicleNo, bodyType, vehicleType, vehicleRemarks, newgeofenceLocations,
            vehicleServiceType, isoNo, loadingCapacity, washingStatus, noOfWashes, rejectRemarks
        } = this.state;
        const {
            vehicleHistory, tripId, oldVehicleNo, oldVehicleType, oldBodyType,
            oldVehicleServiceType, oldIsoNo, oldLoadingCapacity, oldWashingType, oldWashingCount
        } = this.props;
        if(date === null || time === null || !(vehicleNo && vehicleNo.length >= 5 && vehicleNo.length <= 11) || vehicleRemarks === "") {
            // Object.keys(vehicleType).length === 0 , Object.keys(bodyType).length === 0);
            this.setState({
                error: true
            });
        } else {
            const vehicleHistories = vehicleHistory === undefined ? [] : vehicleHistory;
            vehicleHistories.push({
                vehicle_no: oldVehicleNo,
                service_type: (oldVehicleServiceType && oldVehicleServiceType.name) | '',
                iso_no: oldIsoNo,
                loading_capacity: parseInt(oldLoadingCapacity || 0),
                vehicle_type: (oldVehicleType && oldVehicleType.name) || '',
                body_type: (oldBodyType && oldBodyType.name) || '',
                remarks: vehicleRemarks,
                changed_on: currDate.utc().valueOf(),
                change_location: newgeofenceLocations && newgeofenceLocations.length > 0 ? newgeofenceLocations[0].location : '',
            });

            const dataToBeSubmitted = {
                trip_id: tripId,
                vehicle_details: {
                    vehicle_no: vehicleNo || "",
                    service_type: vehicleServiceType.name || "",
                    iso_no: isoNo || "",
                    loading_capacity: parseInt(loadingCapacity || 0),
                    vehicle_type: vehicleType.name || "",
                    body_type: bodyType.name || "",
                    vehicle_history: vehicleHistories,
                    washing_status: washingStatus,
                    no_of_washes: noOfWashes,
                    rejected_remarks: rejectRemarks ? rejectRemarks.value : ''
                }
            };
            this.props.onChangeVehicle();
            this.props.submitTransporterDetails(dataToBeSubmitted);
            this.setState({
                vehicleNo: null,
                vehicleServiceType: {},
                isoNo: '',
                loadingCapacity: '',
                vehicleType: {},
                bodyType: {},
                vehicleRemarks: "",
                vehicleHistory: this.props.vehicleHistory || [],
                error: false,
                time: null,
                date: null,
                open: false,
                showChangeVehiclePopUp: false,
                washingStatus: '',
                noOfWashes: 0,
                rejectRemarks: ''
            });
        }
    }

    handleNewGeofenseLocationChange = (value, id) => {
        if (typeof value === "string") {
            const newLocs = this.state.newgeofenceLocations.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocations: newLocs });
        } else {
            const newLocs = this.state.newgeofenceLocations.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocations: newLocs });
        }
    }

    selectRejectRemarks = (value) => {
        this.setState({rejectRemarks: value})
    }

    render () {
        const {vehicleServiceTypes, vehicleTypes, vehicleBodyTypes} = this.props;
        const isIsoTanker = (this.state.vehicleServiceType.name || '').toLowerCase().includes('iso');
        const isRoadTanker = (this.state.vehicleServiceType.name || '').toLowerCase().includes('road');
        return (
            <div className="overlay">
                <div className="overlay-container change-vehicle-popup">
                    <span className="overlay-close-button fs-20" onClick={this.props.onChangeVehicle}>
                        X
                    </span>
                    <div className="overlay-body change-vehicle-body-popup">
                        <header className="fs-14 theme-color fBolder mb-20">New Vehicle Details</header>
                        <div className="flex flex-wrap flex-middle">
                            <div className="mb-20 wt-190 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Vehicle Service Type:</div>
                                <Dropdown
                                    value={this.state.vehicleServiceType}
                                    onSelect={this.selectVehicleServiceType}
                                    items={vehicleServiceTypes}
                                    label="Select Service type"
                                    selectedClassName={'col-1'}
                                />
                            </div>
                            <div className="mb-20 wt-150 mr-20">
                                <div className="fs-12 communication-color fBold">Vehicle No. <span className={'error-color'}>*</span></div>
                                <Input
                                    value={this.state.vehicleNo}
                                    // onChange={this.onChange}
                                    onChange={(e) => this.onChangeVehicleDetails('vehicleNo', e.target.value)}
                                    name="vehicleNo"
                                    placeholder="Vehicle No."
                                    selectedClassName={'col-1'}
                                />
                                {this.state.vehicleNo && (this.state.vehicleNo.length < 5 || this.state.vehicleNo.length > 11) && <span className={'fs-12 error-color'}>{'Invalid Vehicle Number'}</span>}
                            </div>
                            <div className="mb-20 wt-250 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Vehicle Type:</div>
                                <Dropdown
                                    value={this.state.vehicleType}
                                    onSelect={this.selectVehicleType}
                                    items={vehicleTypes}
                                    label="Select Vehicle type"
                                    selectedClassName={'col-1'}
                                />
                            </div>
                            <div className="mb-20 wt-200 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Body Type:</div>
                                <Dropdown
                                    value={this.state.bodyType}
                                    onSelect={this.selectBodyType}
                                    items={vehicleBodyTypes}
                                    label="Select Body type"
                                    selectedClassName={'col-1'}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-middle">
                            {
                                isIsoTanker &&
                                <div className="mb-20 wt-150 mr-20">
                                    <div className="fs-12 communication-color fBold">ISO No. </div>
                                    <Input
                                        value={this.state.isoNo}
                                        // onChange={this.onChange}
                                        onChange={(e) => this.onChangeVehicleDetails('isoNo', e.target.value)}
                                        name="isoNo"
                                        placeholder="ISO No."
                                        selectedClassName={'col-1'}
                                    />
                                </div>
                            }
                            {
                                (isIsoTanker || isRoadTanker) &&
                                <div className="mb-20 wt-200 mr-20">
                                    <div className="fs-12 communication-color fBold">Water Loading Capacity (litres)</div>
                                    <Input
                                        type="number"
                                        value={this.state.loadingCapacity}
                                        onChange={this.onChange}
                                        name="loadingCapacity"
                                        placeholder="Loading Capacity"
                                        selectedClassName={'col-1'}
                                    />
                                </div>
                            }
                        </div>
                        <header className="fs-14 theme-color fBolder mb-20">Old Vehicle Details</header>
                        <div className="">
                            <header className="fs-12 fBolder mb-20">Changed On</header>
                            <div className="flex">
                                <div className="mb-20 mr-40">
                                    <div className="fs-12 communication-color fBold mb-5">Select Date <span className={'error-color'}>*</span></div>

                                    <SingleDatePicker
                                        noBorder={true}
                                        displayFormat={"DD/MMM/YYYY"}
                                        isOutsideRange={() => false}
                                        hideKeyboardShortcutsPanel={true}
                                        placeholder={"Date"}
                                        numberOfMonths={1}
                                        date={Moment(this.state.date)}
                                        onDateChange={date => this.setState({ date })}
                                        focused={this.state.focusedStart}
                                        onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                        id="manualDate"
                                    />
                                </div>
                                <div className="mb-20 ml-50 wt-450 pick-time">
                                    <div className="fs-12 communication-color fBold mb-5">Select Time<span className={'error-color'}>*</span></div>

                                    <TimePicker
                                        showSecond={false}
                                        defaultValue={''}
                                        value={Moment(this.state.time)}
                                        className="ht-40 wt-250"
                                        onChange={this.setTime}
                                        format={'h:mm a'}
                                        use12Hours
                                    />
                                </div>
                                <div className="mb-20 wt-300 mr-20">
                                    {
                                        this.state.newgeofenceLocations.map((loc, index) => {
                                            return <div className="mb-5 mr-10" key={index}>
                                                <div className="fs-12 communication-color fBold mb-5">Change Location</div>
                                                <span className={classNames("location-side-marker", {
                                                    'last-in-geofence': index === this.state.newgeofenceLocations.length - 1
                                                })}>
                                                    <span className="blue"> </span>
                                                </span>
                                                <span className={classNames("remove-btn-row", {
                                                    'first-in-geofence': index === 0
                                                })}>
                                                    <span className="location-side-marker right ">
                                                    </span>

                                                </span>

                                                <PlacesAutocomplete
                                                    key={index}
                                                    value={loc.location}
                                                    onChange={(value) => { this.handleNewGeofenseLocationChange(value, loc.id) }}
                                                    onSelect={(value) => { this.handleNewGeofenseLocationChange(value, loc.id) }}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div className="dropdown-container">
                                                            <div className="dropdown-container 2 ht-40 pr">
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'Change location',
                                                                        className: 'selected-item row-100 pl-40 pr-16  route-detail-search wt-200',
                                                                    })} />
                                                            </div>
                                                            <div className="row-100">
                                                                <ul className="dropdown-items flex flex-column" style={{ 'width': `600px` }}>
                                                                    {
                                                                        suggestions.map((item, i) => {
                                                                            const className = "dropdown-item pl-16 ht-40";
                                                                            return (
                                                                                <li
                                                                                    {...getSuggestionItemProps(item, {
                                                                                        className,
                                                                                    })}
                                                                                >
                                                                                    <abbr title={item.description}>
                                                                                        {item.description.substr(0, 75)}
                                                                                        {item.description.length > 75 ? '...' : ''}
                                                                                    </abbr>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mb-20 wt-300 mr-20 ">
                                <div className="fs-12 communication-color fBold mb-5">Reason for change:<span className={'error-color'}>*</span></div>
                                <Input value={this.state.vehicleRemarks} name="vehicleRemarks" onChange={this.onChange} placeholder="Enter Remarks" selectedClassName={'col-1'} />
                            </div>
                            {/* <div className="mb-20 wt-250 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Reject Remarks:</div>
                                <Dropdown
                                    value={this.state.rejectRemarks}
                                    onSelect={this.selectRejectRemarks}
                                    items={rejectRemarksList}
                                    label="Select Remarks"
                                    selectedClassName={'col-1'}
                                />
                            </div> */}
                        </div>
                        
                        <div className="">
                            {
                                this.state.error && <span className={'error-color'}>Please fill all the mandatory fields.</span>
                            }
                        </div>
                        <div className="submit-vehicle-change">
                            <Button className="submit-vehicle-change-button" value="Submit" onClick={this.onSubmit} />
                        </div>
                    </div>

                </div>
            </div>

        )
        }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ["places"]
})(ChangeVehicle);
