import { SEEKER, PROVIDER, USER_TYPE } from 'Constants/commonConstants';


const getAllCookies = () => {
    const pairs = document.cookie.split(";");
    let cookies = {};
    for (let i=0; i<pairs.length; i++){
        let pair = pairs[i].split("=");
        cookies[(pair[0]+'').trim()] = unescape(pair[1]);
    }
    return cookies;
};

export const setCookie = (cname, cvalue, exdays)=> {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    window.name = JSON.stringify(getAllCookies());
};

export const getCookie = (cname)=> {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const deleteCookie = (name)=> {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const storeToken = (token)=> {
    setCookie('tracking-session-token', token, 7);
    setCookie('teg-session-token', token, 7);
    setCookie('teg-accounts-session-token', token, 7);
}

export const getToken = ()=> {
    return getCookie('tracking-session-token');
}

export const getUserType = () => {
    return parseInt(getCookie('user-type'));
}

export const canUserEdit = () => {
    return [USER_TYPE.ZONE, USER_TYPE.AUDIT].indexOf(getUserType()) <= -1;
}

export const storeCompanyType = (companyType)=> {
    setCookie('tracking-company-type', companyType, 7);
}

export const storeCompanyId = (companyId) => {
    setCookie('tracking-companyId', companyId, 200);
}

export const storeUserType =  (userType) => {
    setCookie('user-type', userType, 7);
}

export const storeUser = (user) => {
    setCookie('user', JSON.stringify(user), 7);
}

export const getUser = () => {
    try {
        return JSON.parse(getCookie('user'));
    } catch(e) {
        return ""
    }
}
export const setCompanySeekerId = (id) => {
    localStorage.setItem('seeker_id', JSON.stringify(id));
}

export const getCompanySeekerId = () => {
    return localStorage.getItem('seeker_id') ? JSON.parse(localStorage.getItem('seeker_id')) : 
    ((getCompanyList().length && getCompanyList()[0].id) || '');
}

export const getCompanyList = () => {
    const companyList = getUser().company_list;
    let list  = [{
        name: 'Others',
        id: 'none',
        company_text_id: 'none'
    }];
    if(companyList){
        (companyList || []).map((company) => {
            if(company.company_text_id == 'ADANI'){
                list.unshift(company)
            }
        })
    }
    return list;
}

export const getCompanyId = () => {
    return getCookie('teg-accounts-companyId') || '';
}

export const getMandatoryFeilds = (selectedCompanyId) => {
    const userDetails = getUser();
    const mandatory = {
        'po_no': false,  // currently only po_no comes from backend
        'do_no': true,
        'item_name': true,
    };

    let tripCompanyId = null;
    if (userDetails.company_type == SEEKER) {
        tripCompanyId = userDetails.company_id;
    } else if (userDetails.company_type == PROVIDER) {
        tripCompanyId = selectedCompanyId;
    }

    if (tripCompanyId && userDetails.mandatory_fields && userDetails.mandatory_fields[tripCompanyId]) {
        userDetails.mandatory_fields[tripCompanyId].map((field, index) => {
            mandatory[field] = true;
        })
    }
    return mandatory;
}

export const getCompanyType = ()=> {
    try {
        return parseInt(getCookie('tracking-company-type'));
    } catch(e) {
        return ""
    }
}

export const deleteToken = ()=> {
    deleteCookie('tracking-session-token');
    deleteCookie('teg-session-token');
    deleteCookie('tracking-company-type');
    deleteCookie('sso-logout-duration');
    window.name = '';
    localStorage.setItem('isSSOUser', false);
    localStorage.setItem('teg-sso-session', false);
    localStorage.setItem('sso-session', false);
    clearStorage();
}

export const setPageOffset = (offset) => {
    localStorage.setItem('offset', JSON.stringify(offset));
}

export const getPagesOffset = () => {
    return localStorage.getItem('offset');
}

export const setBoundType = (boundType) => {
    localStorage.setItem('boundType', JSON.stringify(boundType));
}

export const getBoundType = () => {
    return localStorage.getItem('boundType');
}

export const clearStorage = () => {
    localStorage.clear();
}

export const storeLogoutDuration = (time)=> {
    setCookie('sso-logout-duration', time);
}

export const getLogoutTimeDuration = ()=> {
    try {
        return parseInt(getCookie('sso-logout-duration'));
    } catch(e) {
        return ""
    }
}

export const getGlobalSeeker = () => {
    const seeker = localStorage.getItem('selectedGlobalSeeker') 
    ?JSON.parse(localStorage.getItem('selectedGlobalSeeker'))
    :{}
    return seeker || {}
}