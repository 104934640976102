import React, {Component} from 'react';

import {ReactComponent as SearchIcon} from './icons/search.svg';
import {ReactComponent as DropdownIcon} from 'Containers/Navbar/icons/dropdown.svg';

class PaginationDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownShown: false,
            activePage: 1
        }
    }
    showDropdown = (e)=> {
        if(e) {
            e.stopPropagation();
        }
        this.setState({
            isDropdownShown:  true
        });
    }
    hideDropdown = ()=> {
        setTimeout(()=> {
            this.setState({
                isDropdownShown:  false
            });
        }, 200)
    }
    onSelect = (offset)=> {
        this.props.onSelect(offset);
    }
    onChange=(e)=> {
       // this.props.onChange(e.target.value);
    }
    formatList = (totalCount, limit)=> {
        const elements = [];
        for(let i=0; i < totalCount; i+=limit) {
            elements.push(
                <li 
                    name={i} 
                    key={i} 
                    className={"dropdown-item pl-16 ht-40"}
                    onClick={this.onSelect}>
                    {(i+1) +' to ' + ((i+limit < totalCount)?(i+limit):totalCount)}
                </li>
            )
        }
        return elements
    }
    render() {
        const {
            totalCount,
            offset,
            limit,
            containerClassName, 
            selectedClassName
        } = this.props;
        let paginationDropdownItems = [];
        for(let i=0; i < totalCount; i+=limit) {
            paginationDropdownItems.push({
                offset: i,
                limit: (i+limit < totalCount)?i+limit:totalCount
            });
        }
        return (
            <div className={"dropdown-container ht-40 pr " + (containerClassName || "")}>
                <div className="pa r-10 t-10">
                    <DropdownIcon />
                </div>
                <input 
                    className={"curP selected-item row-100 flex flex-middle pl-20 pr-16 col-1 " + (selectedClassName || "")}
                    onFocus={this.showDropdown} 
                    tabIndex="1" 
                    onBlur={this.hideDropdown}
                    onChange={this.onChange}
                    value={(offset + 1) + ' - ' + ((offset + limit)<totalCount?(offset+limit):totalCount) +  ' of ' + totalCount}
                />
                {this.state.isDropdownShown && <ul className="dropdown-items flex flex-column" style={{overflow: 'scroll', maxHeight: '300px' }}>
                    {
                        paginationDropdownItems.map((item, index)=> {
                            return (
                                <li 
                                    key={index}
                                    name={offset} 
                                    className={"dropdown-item pl-16 ht-40"}
                                    onClick={this.onSelect.bind(this, item.offset)}
                                >
                                    {(item.offset + 1) + ' - ' + (item.limit)}
                                </li>
                            )
                        })
                    }
                </ul>}
            </div>
        );
    }
}

export default PaginationDropdown;