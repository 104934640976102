import Location from "./Location";
import Place from './Place';
import Moment from 'moment';

export default class Tracking {
    constructor(tracking={}) {
        try {
            this.tripId = tracking.trip_id;
            this.seekerId = tracking.seeker_id;
            this.providerId = tracking.provider_id;
            this.trackingName = tracking.tracking_name;
            this.doNo = tracking.do_no;
            this.poNo = tracking.po_no;
            this.customerName = tracking.customer_name;
            this.itemName = tracking.item_name;
            this.status = tracking.status;
            this.value = tracking.value;
            this.serviceType = tracking.service_type;
            this.isAutoCreated = tracking.is_auto_created;
            this.boundType = tracking.bound_type;
            this.runType = tracking.run_type;
            this.modeType = tracking.mode_type;
            this.lrNo = tracking.lr_no;
            this.trackingType = tracking.tracking_type;
            this.invoiceNo = tracking.invoice_no;
            this.sub_type = tracking.sub_type;
            this.comments = tracking.comments;
            this.type = tracking.type;
            this.distance = tracking.distance || 0;
            this.locationType = tracking.location_types || []
            this.gpsDetails = tracking.gps_details || {}
            this.trackingDetails = tracking.tracking_details || {};
            this.at_destination_duration = tracking.at_destination_duration;
            this.fois_tracking_route = tracking.fois_tracking_route||[];
            this.fois_railway_route = tracking.fois_railway_route||[];
            this.fnr_no = tracking.fnr_no||"";
            this.is_completed = tracking.is_completed || null;
            if(tracking.last_known_location) {
                this.lastKnownLocation = new Location(tracking.last_known_location);
            }
            if(tracking.driver_details) {
                this.driverDetails = tracking.driver_details.map((driver)=> {
                    return {
                        driverName: driver.name,
                        driverNo: driver.number,
                        name: driver.name || '',
                        firstName: driver.first_name || '',
                        lastName: driver.last_name || '',
                        number: driver.number,
                        operatorType: driver.operator_type,
                        operatorName: driver.operator_name,
                        consentStatus: driver.consent_status,
                        message: driver.message,
                        isTracked: driver.is_tracked || false
                    }
                });
            }

            if(tracking.vehicle_details) {
                const vehicleDetails = tracking.vehicle_details;
                this.vehicleDetails = {
                    vehicleNo: vehicleDetails.vehicle_no,
                    vehicleServiceType: vehicleDetails.service_type || '',
                    isoNo: vehicleDetails.iso_no || '',
                    loadingCapacity: vehicleDetails.loading_capacity || '',
                    vehicleType: vehicleDetails.vehicle_type,
                    bodyType: vehicleDetails.body_type,
                    washingStatus: vehicleDetails.service_type_option,
                    noOfWashes: vehicleDetails.no_of_times_sealed
                }
            }
            if (tracking.trip_dates) {
                const dateTat = tracking.trip_dates;
                this.dateTat = {
                    destinationGateInTime: dateTat.destination_gate_in_time ? Moment(dateTat.destination_gate_in_time) : '',
                }
            }

            this.source = new Location(tracking.source);
            this.destination = new Location(tracking.destination);
            if(tracking.locations) {
                this.locations = tracking.locations.map((loc) => new Location(loc));
            }
            this.routeMap = tracking.route_map ? tracking.route_map.map((place) => new Place(place)) : [];
            this.wayPoints = tracking.way_points ? tracking.way_points.map((place) => new Place(place)) : [];
            if(tracking.transporter_details) {
                this.transporter_details = tracking.transporter_details;
            }
        } catch(e) {
            console.log('Error in tracking models: ', e)
        }
    }
}
