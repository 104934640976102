import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import {
  TOAST_HIDE_TIME
} from 'Constants/commonConstants';
import {
  HIDE_TOAST,
  HIDE_BANNER,
  GET_BROADCAST_MSG,
  SSO_DELETE_TOKEN,
  GET_SSO_CONFIG
} from 'Constants/actionConstants';
import Toast from 'Components/Toast';
import Banner from 'Components/Banner';

import 'Styles/app.scss';

import 'react-dates/lib/css/_datepicker.css';
import { SSO_BASE_URL, SSO_URL, logOutWithToken } from 'Utils/api';
import { deleteToken, getUser, storeLogoutDuration, getLogoutTimeDuration } from 'Utils/storage';
import { APP_VERSION } from 'Utils/version';

class App extends Component {

  closeBanner =  () => {
      const { dispatch } = this.porps;
      dispatch({ type: HIDE_BANNER });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const isSSOUser = localStorage.getItem('isSSOUser');
    const isSessionActive = localStorage.getItem('sso-session');
    const user = getUser();
    let params = {
      module: 3,
      force_auth: user.force_auth || false
    }
    dispatch({ 
      type: GET_BROADCAST_MSG,
      params: {module_id: 3} 
    });

    dispatch({
      type: GET_SSO_CONFIG,
      params: {}
    });

    if(isSSOUser === 'true' && isSessionActive === 'true'){
      window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      const isTEGComponent = localStorage.getItem('teg-component');
      if(isTEGComponent !== 'true'){
        localStorage.setItem('sso-session', false);
        dispatch({
          type: SSO_DELETE_TOKEN,
          params
        })
        storeLogoutDuration(new Date().valueOf());
      }  
      localStorage.setItem('teg-component', false);
    });
  }
  }

  onIdle = () => {
    logOutWithToken();
    // deleteToken();
    if(location.pathname !== '/') {
      this.idleTimer.reset();
      // location.href = '/';
    }
  }

  onSSOIdle = () => {
    // location.replace(`${SSO_BASE_URL}${SSO_URL}/?slo`);
    // setTimeout(() => {deleteToken(), localStorage.clear()}, 2000);
    deleteToken();
    localStorage.clear();
    this.idleTimer.reset();
    }
  
  endSession = () => {
    deleteToken();
    localStorage.clear();
    location.href = '/';
    // location.replace(`${SSO_BASE_URL}${SSO_URL}/?slo`);
  }

  render() {
    console.log('APP_VERSION',APP_VERSION)
    const { dispatch, toast, broadcastMsgs } = this.props;
    this.timer = null;
    const isSSOUser = localStorage.getItem('isSSOUser');
    const isSessionActive = localStorage.getItem('sso-session');
    const lastLogoutTimeDuration = getLogoutTimeDuration();
    let currrentTime = new Date();
    const logoutTime = getUser().logout_delay_duration || (60*60);
    const diffMins = (Math.abs((currrentTime.getTime() - (new Date(lastLogoutTimeDuration).getTime())) / 1000)) > logoutTime;

    if(toast.type && toast.message) {
      if(this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(()=> {
        dispatch({
          type: HIDE_TOAST
        });
      }, TOAST_HIDE_TIME*1000);
    }

    if(isSSOUser && isSessionActive === 'false' && diffMins){
      this.endSession();
    }

    if(isSSOUser){
      return(
          <div className="ct-app pr">
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              element={document}
              onIdle={this.onSSOIdle}
              debounce={250}
              timeout={1000 * (getUser().idle_timeout_duration || (60*5))}
              // timeout={1000 * 60 * 5}
            >
              <Toast type={toast.type} message={toast.message}/>
              {this.props.children}
            </IdleTimer>
          </div>
        )
    }

    return (
      <div className="ct-app pr">
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onIdle={this.onIdle}
          // timeout={1000 * 60 * 15}
          timeout={1000 * (getUser().idle_timeout_duration || (60*15))}
        >
          {broadcastMsgs && broadcastMsgs.length > 0 && <Banner data={broadcastMsgs} close={this.closeBanner} islogin={location.pathname==='/'} />}
          <Toast type={toast.type} message={toast.message}/>
          {this.props.children}
        </IdleTimer>
      </div>
    );
  }
}

const mapStateToProps = ({common}) => {
  return {
    toast: common.toast,
    broadcastMsgs: common.broadcastMsgs,
  }
};

export default connect(mapStateToProps)(App);


// export default App;
