import React, {Component} from 'react';
import className from 'classnames';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focussed: false
        }
    }
    render() {
        const { placeholder, value, onChange, type, name, containerClassName, required, error, errorMessage, errorField, disabled,pattern,title } = this.props;
        let isFocussed = false;
        if (type === 'number') {
            isFocussed = (value !== null && value !== undefined && value !== '') || this.state.focussed;
        } else {
            isFocussed = (value && value.length > 0) || this.state.focussed;
        }
        return (
            <div className={"input-container " + (containerClassName || "")}>
                <label
                    className={
                        className(
                            "label communication-color ",
                            {"input-focussed": isFocussed}
                        )
                    }
                >
                    {placeholder}
                    <span className="error-color">{required ? "*" : ""}</span>
                </label>
                <input
                    type={type || "text"}
                    value={value}
                    onChange={onChange}
                    onFocus={()=> {this.setState({focussed:true})}}
                    onBlur={()=> {this.setState({focussed: false})}}
                    name= {name || ""}
                    className="pb-10"
                    disabled={disabled}
                    pattern={pattern}
                    title={title}
                />
                {error && errorMessage && errorField == name && <div className={'error-color fs-12'}>{this.props.errorMessage}</div>}
            </div>
        )
    }
}

export default Input;
