import Button from "Components/Button/index";
import Select from "react-select";
import React, { PureComponent } from "react";
import copy from "clipboard-copy";
import { ReactComponent as CopySvg } from "../../../components/DashboardCard/icons/copy-svgrepo-com.svg";
import Moment from "moment";

class SeeAllLogsPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      optionSelected: false,
    };
  }
  copyToClipboard = (data) => {
    copy(data);
  };

  render() {
    const { optionSelected } = this.state;
    const {
      openClosePopup,
      requestLogLRnumber,
      onChangeRequestLogPopupLRnumber,
      onSubmit,
      apiLogTypes,
      fetchApiLogData,
      apiLogData,
      fetchApiLogDataLoading,
    } = this.props;

    const options = Object.entries(apiLogTypes[0]).map(([value, label]) => ({
      value: parseInt(value),
      label,
    }));
    return (
      <div className="overlay">
        <div className="overlay-container exception-modify-popup">
          <div
            style={{
              maxHeight: "fit-content",
              width: "120%",
              marginLeft: "-80px",
            }}
            className="overlay-body create-location-popup"
          >
            {
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fs-18 tCenter">
                    <b>See All Logs</b>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="fs-18 mr-10 mb-10 tCenter"
                    onClick={openClosePopup}
                  >
                    X
                  </div>
                </div>

                <div className="fs-18">
                  Please select the option:
                  <div className="fs-18 mb-20">
                    <Select
                      options={options}
                      className={"wt-250 flex-3 mr-20 ht-33"}
                      placeholder={"To"}
                      onChange={(selectedOption) => {
                        fetchApiLogData(selectedOption.value);
                        this.setState({ optionSelected: true });
                      }}
                    />
                  </div>
                </div>
                {!!optionSelected && !!fetchApiLogDataLoading && (
                  <>Loading...</>
                )}
                {apiLogData.map((logData, index) => (
                  <div key={index}>
                    {!!optionSelected &&
                      !!logData &&
                      !!!fetchApiLogDataLoading && (
                        <div>
                          <b>
                            {index + 1}
                            {") Created on:"}
                          </b>
                          <span>
                            {Moment(logData.created_on).format(
                              "DD MMM, YYYY HH:mm"
                            )}
                          </span>
                          <div className="flex flex-around">
                            <div>
                              <div
                                style={{ height: "max-content" }}
                                className="fs-18 mt-10 mb-10 fBold flex flex-middle"
                              >
                                Request Body:
                                <div className="ml-10 mt-10">
                                  <CopySvg
                                    value={"Copy to Clipboard"}
                                    style={{ height: "25px", width: "25px" }}
                                    onClick={() =>
                                      this.copyToClipboard(
                                        JSON.stringify(
                                          logData.request_body,
                                          null,
                                          2
                                        )
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div>
                                <textarea
                                  rows={10}
                                  cols={40}
                                  value={JSON.stringify(
                                    logData.request_body,
                                    null,
                                    2
                                  )}
                                  style={{
                                    resize: "none",
                                  }}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div>
                              <div
                                style={{ height: "max-content" }}
                                className="fs-18 mt-10 mb-10 fBold flex flex-middle"
                              >
                                Response Body:
                                <div className="ml-10 mt-10">
                                  <CopySvg
                                    value={"Copy to Clipboard"}
                                    style={{ height: "25px", width: "25px" }}
                                    onClick={() =>
                                      this.copyToClipboard(
                                        JSON.stringify(
                                          logData.response_body,
                                          null,
                                          2
                                        )
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <textarea
                                  rows={10}
                                  cols={40}
                                  value={JSON.stringify(
                                    logData.response_body,
                                    null,
                                    2
                                  )}
                                  readOnly
                                  style={{
                                    resize: "none",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default SeeAllLogsPopup;
