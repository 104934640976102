import React from 'react';
import Status from '../Status';

const DetailedLogRow = ({loc})=> {
    return (
        <div className="flex pb-10 pt-10 flex-between flex-middle detailed-view-row br-5">
            <div className="pl-15 wt-150">
                <div className="fs-16 table-text-color mb-5 fBolder text-break">{loc.city}</div>
                <div className="fs-12 table-text-color text-break">{loc.state}</div>
            </div>
            <div className="pl-15 wt-250">
                <div className="fs-16 table-text-color mb-5 fBolder text-break">{loc.location}</div>
            </div>
            <div className="pl-20 wt-200">
                <div className="fs-16 table-text-color mb-5 text-break">{loc.location_type}</div>
            </div>
            <div className="pl-15 wt-150">
                <div className="fs-16 table-text-color mb-2">{loc.date.format('DD MMM, YYYY')}</div>
                <div className="table-text-color fs-12">{loc.time}</div>
            </div>
            <div className="pl-15 wt-150">
                <div className="fs-16 table-text-color mb-2">{loc.system_time && loc.system_time.format('DD MMM, YYYY') || '--'}</div>
                <div className="table-text-color fs-12">{loc.system_time && loc.system_time.format('HH:mm')}</div>
            </div>
            {/* <div className="col-6 pl-15">
                <div className="fs-16 table-text-color mb-5 text-uppercase">{loc.time_spent}</div>
            </div> */}
            <div className="pl-15 wt-100">
                <div className="fs-16 table-text-color mb-5 fBolder">{loc.distance_covered ? `${loc.distance_covered}km` : '0km'}</div>
            </div>
            <div className="pl-15 wt-100">
                <div className="fs-16 table-text-color mb-5 fBolder">{loc.distance_remaining ? `${loc.distance_remaining}km` : '0km' }</div>
            </div>
            {/* <div className="pl-15 wt-100">
                <div className="fs-16 table-text-color mb-5">
                    <Status status={loc.status.text} background = {loc.status.code}/>
                </div>
            </div> */}
            {/* <div className="col-6 pl-15">
                <div className="fs-16 table-text-color mb-5">{loc.eta}</div>
            </div> */}
        </div>
    );
}

export default DetailedLogRow;
