import Sticky from "Components/Sticky";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {ReactComponent as DropupArrow} from './icons/Up.svg'
import { ReactComponent as LostIcon } from './icons/warning.svg'
import { ReactComponent as DropdownArrow } from './icons/Down.svg'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { withRouter } from 'react-router';
import './index.scss'
// import  SearchDropdown  from 'Components/SearchDropdown';
import {
  trackingModeLoreal,
  trackingModePantaloons,
  ModifyExceptions,
  pantaloonsException,
  pantaloonsDelay,
  PANTALOONS,
  SEEKER,
  PROVIDER,
  BOUND_TYPE_TABS,
  COMPANY_TYPE_TABS,
  CARGILL,
  LOREAL,
  AWL,
  BOUND_TYPE_TABS_LOREAL
} from "Constants/commonConstants";
import { getCompanyType, getGlobalSeeker, getUser, getUserType } from "Utils/storage";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Dropdown from "Components/Dropdown";
import Input from "Components/Input";
import Button from "Components/Button";
import { ReactComponent as DownloadIcon } from "./icons/download.svg";
import { ReactComponent as UploadIcon } from "./icons/upload.svg";
import { ReactComponent as LeftArrow } from "./icons/left.svg";
import { ReactComponent as RightArrow } from "./icons/right.svg";
import { ReactComponent as SideLine } from "./icons/line.svg";
import { ReactComponent as CancelIcon } from "./icons/cancel.svg";
import { ReactComponent as EditIcon } from "./icons/edit.svg";
import { ReactComponent as Warning } from "./icons/warning.svg";
import { ReactComponent as Move } from "./icons/next-arrow.svg";
import { ReactComponent as Reason_select } from "./icons/reason_select.svg";
import moment from "moment";
import "react-dates/initialize";
import UploadButton from "Components/UploadButton";
import {
  DOWNLOAD_MILESTONE_TEMP,
  UPLOAD_ORDERS,
  GET_MILESTONE_OVERVIEW_DETAILS,
  GET_MILESTONE_ORDER,
  GET_MILESTONE_DOCKET_LIST,
  DOWNLOAD_LSP_TEMPLATE,
  UPLOAD_LSP_DETAILS,
  UPLOAD_POD_DETAILS,
  GET_SERVICE_PROVIDERS,
  GET_MILESTONE_RESERVED_LR_LIST,
  CANCEL_EXCEPTION,
  UPDATE_EXCEPTION_MILESTONE,
  GET_SERVICE_PROVIDER_SUCCESS,
  MOVE_TO_EXCEPTION_MILESTONE_UPDATE,
  GET_REASONS_SUCCESS,
  GET_REASONS,
  GET_SEEKERS,
  GET_MOVEMENT_TYPE,
  GENERATE_DELAY_REASON_TEMPLATE,
  UPLOAD_DELAY_REASONS,
  DOWNLOAD_POD_UPLOADED_FILES,
  GET_MILESTONE_LOCATIONS_DROPDOWN,
  GET_BOOKING_ORDER_LOGS,
  GET_API_LOG_TYPES,
  GET_LOG_DETAILS,
  GET_MILESTONE_LR_COUNT_DETAILS
} from "Constants/actionConstants";
import ActionLoader from "Components/ActionLoader";
import SearchDropdown from "Components/SearchDropdown";
import Autosuggest from "react-autosuggest";
import { checkSpecialCharacters } from "Utils/common";
import RequestLogPopup from "./RequestLogPopup";
import SeeAllLogsPopup from "./SeeAllLogsPopup";
import Tabs from "Components/Tabs/index";

const SERVICE_PROVIDERS = [
  { name: "Mahindra Logistics Lmt", value: 1 },
  { name: "SAFE EXPRESS", value: 2 },
];

const serviceTypes = [
  { name: "FTL", value: 1 },
  { name: "LCL", value: 2 },
  { name: "COURIER", value: 3 },
  { name: "CONTAINER", value: 4 },
  { name: "MILKRUN", value: 5 },
  { name: "DEDICATED", value: 6 },
]

const defaultColumns = LOREAL.includes(getUser().company_id) || CARGILL.includes(getUser().company_id) || AWL.includes(getUser().company_id)

const lorealDefaultColumns = CARGILL.includes(getUser().company_id) || AWL.includes(getUser().company_id)


class MileStone extends React.Component {
  constructor() {
    super();
    this.state = {
      from_date_search: null,
      to_date_search: null,
      offset: 0,
      limit: 50,
      count: 200,
      milestoneData: [],
      transporter_name: [],
      seeker_name: "",
      movement_type: "",
      consignor_name: "",
      milestonePopup: false,
      modifyPopup: false,
      delaymodifyPopup: false,
      showAlert: false,
      showAlert1:false,
      markAsLostAlert: false,
      orderDetails: [],
      docketList: [],
      type: 2,
      alias: "",
      reserved_lrs: [],
      value: "",
      reservedLrData: [],
      tempValue: "",
      lrSuggestions: [],
      lrnumbersDb: "",
      lsp_details: [],
      order: null,
      milestone_counts: [],
      // exc_type: 1,
      modify_type: 0,
      lr_modify_date: "",
      manual_lr:'',
      booking_date:'',
      lspNameList:[],
      manual_lr_input:'',
      sub_type: 3,
      resonsList:'',
      milestone_sub_counts:[],
      pickup_delay_remarks:'',
      delayRemarksList:'',
      delayremark:'',
      bound_type: defaultColumns ? 2 : 1,
      company_type: 1,
      sort_by: null,
      sort_type: null,
      openRequestLogPopup:false,
      requestLogLRnumber:"",
      SeeAllLogsPopup:false,
      lrId:"",
      fetchApiLogDataLoading:true,
      countData : null,
      is_pod_uploaded : undefined
    };
  }

  componentDidMount() {
    const companyType = getCompanyType();
    const globalSeeker = getGlobalSeeker()
    this.setState({seeker_name : globalSeeker},()=>this.milestoneOverview())
    this.fetchServiceProvider();
    this.fetchFromAndToLocations();
    this.getMovementType();
    if(companyType === PROVIDER){
      this.fetchSeeker();
    }
    if(LOREAL.includes(getUser().company_id)){
      this.setState({type:6,sub_type:2})
    }
    // this.fetchReasonsName();
  }

  fetchApiLogTypes = (lr_id) => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_API_LOG_TYPES,
      params: {},
      onSuccess: () => {
        this.toggleSeeAllLogsPopup(lr_id)
      },
    });
  }
  fetchApiLogData = (api_type) => {
    const { dispatch } = this.props;
    const {lrId}=this.state;
    this.setState({fetchApiLogDataLoading:true});
    dispatch({
      type: GET_LOG_DETAILS,
      params: {
        api_type: api_type,
        lr_id:lrId, 
      },
      onSuccess: () => {
        this.setState({fetchApiLogDataLoading:false})
      },
    });
  }

  fetchServiceProvider = () => {
    const { dispatch } = this.props;
    const { bound_type } = this.state
    dispatch({
      type: GET_SERVICE_PROVIDERS,
      params: {
        'customer_id' : getUser().company_id,
        'bound_type' : bound_type
      },
    });
  };
  fetchFromAndToLocations = () => {
    const params = {
      bound_type: this.state.bound_type
    };
    const { dispatch } = this.props;
    dispatch({
      type: GET_MILESTONE_LOCATIONS_DROPDOWN,
      params: params,
    });
  };
  // fetchReasonsName = () => {
  //   const { dispatch } = this.props;
  //   dispatch({
  //     type: GET_REASONS,
  //     params: {},
  //   });
  // };

  fetchSeeker = (search) => {
    const { dispatch } = this.props;
    dispatch({
        type: GET_SEEKERS,
        search
    });
  }

  fetchDelayNameList =(event)=>{
    const params = {
      delay_type: this.state.sub_type,
      // delay_sub_type:value.value,
      bound_type: this.state.bound_type
    };
    const { dispatch } = this.props;
    dispatch({
      type: GET_REASONS,
      params: params,
      onSuccess: (data) => {
        if (data) {
          this.setState({ delayremark: data });
        }
      },

    });
  }
  milestoneLrlist = (value) => {
    const { dispatch } = this.props;
    const params = {
      alias: value.alias,
    };
    dispatch({
      type: GET_MILESTONE_RESERVED_LR_LIST,
      params,
      onSuccess: (data) => {
        this.setState({
          reserved_lrs: data.reserved_lrs,
          lsp_details: data.lsp_details,
        });
      },
    });
  };
  selectTrackingMode = (type) => {
    this.setState({
      type, 
      offset: 0, 
      sort_by: null, 
      sort_type: null, 
      search: this.state.search,
    }, this.tempMilestoneOverview);
  };
  selectBound = (bound_type) => {
    this.setState({
      bound_type,
      offset: 0,
      sort_by: null,
      sort_type: null,
      search: "",
    }, ()=>{
      this.milestoneOverview()
      this.fetchServiceProvider()
    });
  };
  selectCompany = (company_type) => {
    this.setState({
      company_type,
      offset: 0,
      sort_by: null,
      sort_type: null,
      search: "",
    }, this.milestoneOverview);
  };
  selectDelayTypeMode = (sub_type) => {
    this.setState({
      sub_type,
      offset: 0,
      sort_by: null,
      sort_type: null,
      search: "",
    }, this.milestoneOverview);
  };
  selectModifyExceptionTypeMode = (modify_type) => {
    this.setState({
      modify_type,
      offset: 0,
      sort_by: null,
      sort_type: null,
      search: "",
    }, this.milestoneOverview);
  };
  getColor = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === "delivered") {
      return "delivered-color";
    } else if (status === "delayed") {
      return "delayed-color";
    } else {
      return "awaiting-color";
    }
  };

  downloadTemplate = () => {
    const { dispatch } = this.props;
    dispatch({
      type: DOWNLOAD_MILESTONE_TEMP,
      params: {
        bound_type: this.state.bound_type
      }
    });
  };
  generateDelayReasonTemplate = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GENERATE_DELAY_REASON_TEMPLATE,
      params: {
        bound_type: this.state.bound_type,
        type:this.state.type,
        sub_type:this.state.sub_type
      }
    });
  };

  downloadLSPTemplate = () => {
    const { dispatch } = this.props;
    const {seeker_name, bound_type,type} = this.state
    dispatch({
      type: DOWNLOAD_LSP_TEMPLATE,
      params: {
        bound_type: this.state.bound_type,
        customer_id : seeker_name && seeker_name.value,
        type : type
      }
    });
  };

  uploadOrders = (file) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_ORDERS,
      params: {
        bound_type: this.state.bound_type
      },
      file,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
  };

  uploadDelayReason = (file) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_DELAY_REASONS,
      params: {
        bound_type: this.state.bound_type,
        type:this.state.type,
        sub_type:this.state.sub_type
      },
      file,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
  };

  uploadLSPDetails = (file) => {
    const { dispatch } = this.props;
    const {seeker_name, bound_type, type} = this.state
    dispatch({
      type: UPLOAD_LSP_DETAILS,
      params: {
        bound_type: this.state.bound_type,
        customer_id : seeker_name && seeker_name.value,
        type : type
      },
      file,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
  };

  uploadPODDetails = (file, lr_id, is_seeker_pod) => {
    const { dispatch } = this.props;
    const data = {
      lr_id,
      is_seeker_pod
    };
    dispatch({
      type: UPLOAD_POD_DETAILS,
      file,
      data,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
  };

  downloadUploadedFile = (details, pod, list) => {
    const { dispatch } = this.props;
    const params = {
      lr_id: details.lr_id,
      is_seeker_pod: pod.is_seeker_pod ? "1" : "0",
      pod_number: list
    }
    dispatch({
      type: DOWNLOAD_POD_UPLOADED_FILES,
      params,
      onSuccess: (data) => {
        if(data && data.length && data[0].pod_link){
          const link = document.createElement('a');
          link.href = data[0].pod_link;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    })
  }

  getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else if(value.length <= 0) {
      return `${placeholderButtonLabel}`;
    } else if(value.length == 1){
      return `${value[0].label}`;
    }
    else {
      return `${value.length} selected`;
    }
  }
  onChangeTransporterList = (selectedOptions, event) => {
    let selectedVendorIds = selectedOptions.map(option => option.value);
  
    // If the "*" (All) option is selected, include all options in the payload
    if (event.action === "select-option" && event.option.value === "*") {
      selectedVendorIds = this.props.serviceProviders.map(sp => sp.vendor_id);
    }
  
    this.setState(prevState => ({
      transporter_name: selectedOptions,
      vendor_ids: [...selectedVendorIds], // Spread the array to ensure immutability
    }), () => {
      this.milestoneOverview();
  
      // Now, the vendor_ids state property will always be an array
      console.log("Selected Vendor IDs:", this.state.vendor_ids);
    });
  }
  
  tempMilestoneOverview = (isDownload) => {
    const { dispatch,history } = this.props;
    const {
      limit,
      offset,
      from_date_search,
      to_date_search,
      transporter_name,
      type,
      search,
      key_vendor,
      consignor_name,
      from_location,
      to_location,
      // exc_type,
      sub_type,
      bound_type,
      sort_by,
      sort_type,
      seeker_name,
      movement_type,
      vendor_ids,
      countData
    } = this.state;
    let params = {
      limit,
      offset,
      type,
      from_date_search: from_date_search && from_date_search.valueOf(),
      to_date_search: to_date_search && to_date_search.valueOf(),
      vendor_ids: vendor_ids,
      download: isDownload ? 1 : undefined,
      global_search: search,
      ...(key_vendor ? { key_vendor: ((key_vendor && key_vendor.value) == 'Key Vendor') ? 1 : 0 } : {}),
      from_location,
      consignor_name,
      to_location,
      // exc_type: type===12? exc_type: null,
      sub_type: type===62? sub_type: null,
      bound_type:bound_type,
      sort_by,
      sort_type,
      company_id: seeker_name && seeker_name.value,
      movement_type: movement_type && movement_type.value
    };
    if(seeker_name && LOREAL.includes(seeker_name.value)){
      params.customer_id = seeker_name && seeker_name.value
      params.bound_type = 2
      delete params.company_id
      this.setState({bound_type:2})
      // history.push('/')
    }
    dispatch({
      type: GET_MILESTONE_OVERVIEW_DETAILS,
      params,
      onSuccess: (data) => {
        // dispatch({
        //   type: GET_MILESTONE_LR_COUNT_DETAILS,
        //   params,
        //   onSuccess : (countData) => {
        if (data) {
          var boundWithCount = BOUND_TYPE_TABS;
          if(seeker_name && LOREAL.includes(seeker_name.value)){
            boundWithCount = BOUND_TYPE_TABS_LOREAL
            boundWithCount[0].count = countData.total_trips_count[2]
          }else{
            boundWithCount[0].count = countData.total_trips_count[1]
            boundWithCount[1].count = countData.total_trips_count[2]
          }
          let companyTypeWithCount = COMPANY_TYPE_TABS;
          companyTypeWithCount[0].count = countData.total_trips_count[this.state.bound_type]
          this.setState({
            milestoneData: data.milestone_trips,
            count: data.filter_count,
            totalCount: countData.total_trips_count[this.state.bound_type],
            milestone_counts: countData.milestone_counts,
            milestone_sub_counts: countData.milestone_counts.sub_type,
            boundTypesWithCount: defaultColumns ? [boundWithCount[1]] : boundWithCount,
            companyTypeWithCount: companyTypeWithCount,
            // countData : countData
          });
        }
    //   }
    // })
      },
    });
  };

  milestoneOverview = (isDownload) => {
    const { dispatch,history } = this.props;
    const {
      limit,
      offset,
      from_date_search,
      to_date_search,
      transporter_name,
      type,
      search,
      key_vendor,
      consignor_name,
      from_location,
      to_location,
      // exc_type,
      sub_type,
      bound_type,
      sort_by,
      sort_type,
      seeker_name,
      movement_type,
      vendor_ids,
      is_pod_uploaded
    } = this.state;
    let params = {
      limit,
      offset,
      type,
      from_date_search: from_date_search && from_date_search.valueOf(),
      to_date_search: to_date_search && to_date_search.valueOf(),
      vendor_ids: vendor_ids,
      download: isDownload ? 1 : undefined,
      global_search: search,
      ...(key_vendor ? { key_vendor: ((key_vendor && key_vendor.value) == 'Key Vendor') ? 1 : 0 } : {}),
      from_location,
      consignor_name,
      to_location,
      // exc_type: type===12? exc_type: null,
      sub_type: type===62? sub_type: null,
      bound_type:bound_type,
      sort_by,
      sort_type,
      company_id: seeker_name && seeker_name.value,
      movement_type: movement_type && movement_type.value,
      is_pod_uploaded : is_pod_uploaded ? is_pod_uploaded.value : undefined
    };
    if(seeker_name && LOREAL.includes(seeker_name.value)){
      params.customer_id = seeker_name && seeker_name.value
      params.bound_type = 2
      delete params.company_id
      this.setState({bound_type:2})
      // history.push('/')
    }
    dispatch({
      type: GET_MILESTONE_OVERVIEW_DETAILS,
      params,
      onSuccess: (data) => {
        dispatch({
          type: GET_MILESTONE_LR_COUNT_DETAILS,
          params,
          onSuccess : (countData) => {
        if (data) {
          var boundWithCount = BOUND_TYPE_TABS;
          if(seeker_name && LOREAL.includes(seeker_name.value)){
            boundWithCount = BOUND_TYPE_TABS_LOREAL
            boundWithCount[0].count = countData.total_trips_count[2]
          }else{
            boundWithCount[0].count = countData.total_trips_count[1]
            boundWithCount[1].count = countData.total_trips_count[2]
          }
          let companyTypeWithCount = COMPANY_TYPE_TABS;
          companyTypeWithCount[0].count = countData.total_trips_count[this.state.bound_type]
          this.setState({
            milestoneData: data.milestone_trips,
            count: data.filter_count,
            totalCount: countData.total_trips_count[this.state.bound_type],
            milestone_counts: countData.milestone_counts,
            milestone_sub_counts: countData.milestone_counts.sub_type,
            boundTypesWithCount: defaultColumns ? [boundWithCount[1]] : boundWithCount,
            companyTypeWithCount: companyTypeWithCount,
            countData : countData
          });
        }
      }
    })
      },
    });
  };
  toggleRequestLogPopup=()=>{
    const {openRequestLogPopup}=this.state;
    this.setState({
      openRequestLogPopup:!openRequestLogPopup
    });
  }
  toggleSeeAllLogsPopup=(lrId)=>{
    const {SeeAllLogsPopup}=this.state;
    this.setState({
      SeeAllLogsPopup:!SeeAllLogsPopup,
      lrId:lrId
    });
  }

  onChangeRequestLogPopupLRnumber =(e)=>{
    let result = e.target.value
    this.setState({ requestLogLRnumber: result })                               

  }
  downlaodPickupRequestLogs = (requestLogLRnumber) => {
    const {openRequestLogPopup}=this.state;
    const { dispatch } = this.props;
    dispatch({ type: GET_BOOKING_ORDER_LOGS, lr_number: requestLogLRnumber,
      success: (data) => {
      this.setState({
        openRequestLogPopup:!openRequestLogPopup 
      })
    } });
}

  fetchOrderDetails = (lrNum) => {
    const { dispatch } = this.props;
    const params = {
      lr_number: lrNum,
    };
    dispatch({
      type: GET_MILESTONE_ORDER,
      params,
      onSuccess: (data) => {
        if (data) {
          this.setState({ orderDetails: data });
        }
      },
    });
  };

  fetchMilestoneList = (lr_search) => {
    const { dispatch } = this.props;
    const params = {
      lr_search,
    };
    dispatch({
      type: GET_MILESTONE_DOCKET_LIST,
      params,
      onSuccess: (data) => {
        this.setState({ docketList: data });
      },
    });
  };

  fetchLspNameList = ()=>{
    const { dispatch } = this.props;

    dispatch({
      type: GET_SERVICE_PROVIDER_SUCCESS,
     
      onSuccess: (data) => {
        this.setState({ lspNameList: data });
      },
    });
  }

  fetchResonsList = ()=>{
    const { dispatch } = this.props;

    dispatch({
      type: GET_REASONS_SUCCESS,
     
      onSuccess: (data) => {
        this.setState({ resonsList: data });
      },
    });
  }
  getDay = (date) => {
    const day = new Date(date).getDay();
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return weekDays[day];
  };

  getMovementType = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_MOVEMENT_TYPE
    })
  }

  pagination = (status, count, limit) => {
    let { offset } = this.state;
    if (status === "Next") {
      offset = offset + 50;
    } else if (status === "Previous") {
      offset = offset - 50;
    } else if (status === 'Last'){
      offset = ((Math.floor((count/limit)))*limit)
    } else if (status === 'First') {
      offset = 0
    } else {
      offset = offset;
    }
    this.setState({ offset }, this.milestoneOverview);
  };
  hideOverlay = () => {
    this.setState({
      showAlert: !this.state.showAlert
    });
  };
  hideOverlay1 = () => {
    this.setState({
      showAlert1: !this.state.showAlert1
    });
  };

  hideLostAlertOverlay = () => {
    this.setState({
      markAsLostAlert: !this.state.markAsLostAlert
    });
  };

  Lrlist = (value) => {
    const { dispatch } = this.props;
    const params = {
      alias: value.alias,
    };
    // dispatch({
    //   type: GET_MILESTONE_RESERVED_LR_LIST,
    //   params,
    //   onSuccess: (data) => {
    //     this.setState({ lrNumbers: data });
    //   },
    // });
  };
  milestoneLrlist = (value) => {
    const { dispatch } = this.props;
    const params = {
      alias: value.alias,
    };
    dispatch({
      type: GET_MILESTONE_RESERVED_LR_LIST,
      params,
      onSuccess: (data) => {
        this.setState({
          reserved_lrs: data.reserved_lrs,
          lsp_details: data.lsp_details,
        });
      },
    });
  };

  getReasonsList = (value) => {
    const { dispatch } = this.props;
    const params = {
      delay_type: this.state.sub_type,
      delay_sub_type:value.value,
      bound_type: this.state.bound_type
    };
    dispatch({
      type: GET_REASONS,
      params,
      onSuccess: (data) => {
        this.setState({
          delayRemarksList: data
        });
      },
    });
  };

  CancelPickup = (obj) => {
    const { dispatch } = this.props;
    const data = {
      current_lr_number: obj.lr_number,
      current_lsp_name: obj.vendor_name,
    };
    dispatch({
      type: CANCEL_EXCEPTION,
      data,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
    this.setState({
      showAlert: false,
    });
    this.milestoneOverview();
  };

  movetoException = (obj) => {
    const { dispatch } = this.props;
    const data = {
      type: 12,
      lr_id: obj.lr_id,
    };
    dispatch({
      type: MOVE_TO_EXCEPTION_MILESTONE_UPDATE,
      data,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
    this.setState({
      showAlert: false,
    });
    this.milestoneOverview();
  };
  confirm211=(obj)=>{
    const { dispatch } = this.props;
    const data = {
      // type: 62,
      lr_id: obj.lr_id,
    };
    dispatch({
      type: CANCEL_EXCEPTION,
      data,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
    this.setState({
      showAlert1: false,
    });
    this.milestoneOverview();
  }

  markAsLost = (obj) => {
    const { dispatch } = this.props;
    const data = {
      type: 8,
      lr_id: obj.lr_id,
      is_lr_lost: true
    };
    dispatch({
      type: MOVE_TO_EXCEPTION_MILESTONE_UPDATE,
      data,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
    this.setState({
      markAsLostAlert: false,
    });
    this.milestoneOverview();
  };

  updateReason = (obj) => {
    const { dispatch } = this.props;
    const {StateData} = this.state;
    const data = {
      type: this.state.sub_type,
      lr_id: this.state.order.lr_id,
      delay_text:this.state.pickup_delay_remarks.name,
      delay_remarks: this.state.delay_remarks_name.name
    };
    dispatch({
      type: MOVE_TO_EXCEPTION_MILESTONE_UPDATE,
      data,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
    this.setState({
      delaymodifyPopup: false,
      pickup_delay_remarks: '', 
      delay_remarks_name: ''
    });
    this.milestoneOverview();
  };

  SelectDate = (id, selectedOption) => {
    this.setState({ [id]: selectedOption.valueOf() });
  };


  modifySave = (value) => {
    const { dispatch } = this.props;
    const {  lsp_details, lrnumbersDb, order } = this.state;
  
    const { serviceProviders } = this.props;
    let data = {
      current_lr_id: order.lr_id,
      new_lr_number: lrnumbersDb.name,
      new_vendor_id: lsp_details.lsp_id,
      is_manual_lr:false
    };
    // (serviceProviders || []).forEach((sp) => {
    //   if (sp.vendor_id == order.vendor_id && sp.vendor_name == order.vendor_name) {
    //     data["current_lsp_alias"] = sp.lsp_alias;
    //   }
    // });
    dispatch({
      type: UPDATE_EXCEPTION_MILESTONE,
      data,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
    this.setState({
      modifyPopup: false,
    });
    this.milestoneOverview();
  };
  manualSave = (value) => {
    const { dispatch } = this.props;
    const {  lsp_details, order,booking_date,manual_lr_input } = this.state;
  
    const { serviceProviders } = this.props;
    let data = {
      current_lr_id: order.lr_id,
      new_vendor_id: lsp_details.lsp_id,
      new_lr_number: manual_lr_input,
      is_manual_lr:true,
      pickup_date:booking_date
    };
    // (serviceProviders || []).forEach((sp) => {
    //   if (sp.vendor_id == order.vendor_id && sp.vendor_name == order.vendor_name) {
    //     data["current_lsp_alias"] = sp.lsp_alias;
    //   }
    // });
    dispatch({
      type: UPDATE_EXCEPTION_MILESTONE,
      data,
      onSuccess: () => {
        this.milestoneOverview();
      },
    });
    this.setState({
      modifyPopup: false,
    });
    this.milestoneOverview();
  };

  setCounts = (tabs, counts) => {
    tabs.map((item) => {
      item.count = counts[item.id];
      return;
    });
  };

  setSubTypeCounts = (tabs, counts) => {
    tabs.map((item) => {
      item.count = counts[item.id];
      return;
    });
  };
 
onChangeHandler = (value, id) => {
  const { data } = this.state;
  data[id] = value;
  this.setState({ data });
}

onClickFilter = (action, value) => {
  this.setState({
    sort_by: value,
    sort_type: action == 'up' ? -1 : 1
  }, () => { this.milestoneOverview() })
}

getMovementTypeName = (value) => {
  const { movementTypes } = this.props;
  let movement = (movementTypes || []).filter(move => move.movement_type == value);
  if(movement && movement.length > 0){
    return movement[0].movement_text
  } else return;
}

getServiceTypeName = (value) => {
  let service = (serviceTypes || []).filter(ser => ser.value == value);
  if(service && service.length > 0){
    return service[0].name
  } else return;
}

  renderTable() {
    const { type, sub_type, milestoneData, bound_type } = this.state;
    const companyType = getCompanyType();
    const isLoreal = LOREAL.includes(getUser().company_id);
    const isPantaloons = PANTALOONS.includes(getUser().company_id);
    return(
      <div className="milestone-dashboard">
      <section className="mb-10 flex mt-20">
        <div className="table-responsive" style={{height: "570px"}}>
            <table id="table-scroll"
              className="table table-hover"
              // style={{
              //   boxShadow: "-6px -5px 24px 1px rgb(37 38 94 / 10%)",
              //   minWidth: "140%",
              //   overflow: "scroll",
              //   position: "absolute",
              // }}
            >
              <thead style={{ backgroundColor: "#e7edfa" }}>
                <tr
                  key={1}
                  className='bg-header'
                  // style={{
                  //   borderLeft: "2px solid #e7edfa",
                  //   borderRight: "2px solid #e7edfa",
                  // }}
                >
                  {companyType !== PROVIDER && [12,62,2].includes(type) && !isLoreal &&
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">Action</th>
                  }
                  {companyType !== PROVIDER && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Transporter</th>}
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details pl-10">{isLoreal ? 'LR Number' : 'Docket No'}</th>
                  {isLoreal && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Booking Date</th>}
                  {bound_type == 1 && <th style={{ borderRight: '1px solid white' }} className="table-header-details">PO No.</th>}
                  {!defaultColumns && <th style={{ borderRight: '1px solid white' }} className="table-header-details">{bound_type == 1 ? 'Inbound No.' : 'TU No.'}</th>}
                  {bound_type == 2 && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Delivery Number</th>}
                  {bound_type == 1 && 
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">
                    <div className="flex" style={{justifyContent: 'center'}}>
                      <div style={{ alignSelf: 'center' }}>{'Inbound date'}</div>
                      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '5px'}}>
                        <DropupArrow onClick={()=>{this.onClickFilter('up', 'inbound_date')}} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                        <DropdownArrow onClick={() => { this.onClickFilter('down', 'inbound_date') }} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                      </div>
                    </div>
                  </th>
                  }
                  {bound_type == 2 && !defaultColumns && 
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">
                    <div className="flex" style={{justifyContent: 'center'}}>
                      <div style={{ alignSelf: 'center' }}>{'Outbound date'}</div>
                      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '5px'}}>
                        <DropupArrow onClick={()=>{this.onClickFilter('up', 'outbound_date')}} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                        <DropdownArrow onClick={() => { this.onClickFilter('down', 'outbound_date') }} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                      </div>
                    </div>
                  </th>
                  }
                  {bound_type == 2 &&
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">Consignor Code</th>
                  }
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">{isLoreal ? 'Customer Code' : 'Origin'}</th>
                 {isLoreal && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Ship To City</th> }
                 {isLoreal && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Consignee Name</th> }
                 {isLoreal && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Invoice Value</th> }
                 
                  {bound_type == 2 &&
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">Consignee Code</th>
                  }
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">Destination</th>
                  {!isLoreal &&
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">
                      <div className="flex" style={{ justifyContent: 'center' }}>
                        <div style={{ alignSelf: 'center' }}>EPD</div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                          <DropupArrow onClick={() => { this.onClickFilter('up', 'expected_pickup_date') }} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                          <DropdownArrow onClick={() => { this.onClickFilter('down', 'expected_pickup_date') }} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                        </div>
                      </div>
                    </th>
                  }
                  {([6,8].includes(type) || (type == 62 && sub_type == 2)) && 
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">
                    <div className="flex" style={{justifyContent: 'center'}}>
                      <div style={{alignSelf: 'center'}}>APD</div>
                      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '5px'}}>
                        <DropupArrow onClick={()=>{this.onClickFilter('up', 'pickup_date')}} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                        <DropdownArrow onClick={() => { this.onClickFilter('down', 'pickup_date') }} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                      </div>
                    </div>
                  </th>
                  }
                  {[2,6,8].includes(type) && LOREAL.includes(getUser().company_id) &&
                  <>
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">
                    <div className="flex" style={{justifyContent: 'center'}}>
                      <div style={{alignSelf: 'center'}}>Invoice Date</div>
                      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '5px'}}>
                        <DropupArrow onClick={()=>{this.onClickFilter('up', 'invoice_date')}} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                        <DropdownArrow onClick={() => { this.onClickFilter('down', 'invoice_date') }} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                      </div>
                    </div>
                  </th>
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">TAT</th>
            
                  </>
                  }
                {([6, 8].includes(type) || (type == 62 && sub_type == 2)) && !defaultColumns && 
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">TAT</th>
                  }
                  {([6,8,9].includes(type) || (type == 62 && sub_type == 2)) && 
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">
                    <div className="flex" style={{justifyContent: 'center'}}>
                      <div style={{alignSelf: 'center'}}>EDD</div>
                      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '5px'}}>
                        <DropupArrow onClick={()=>{this.onClickFilter('up', 'expected_delivery_date')}} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                        <DropdownArrow onClick={() => { this.onClickFilter('down', 'expected_delivery_date') }} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                      </div>
                    </div>
                  </th>
                  }
                  {type == 8 &&
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">
                    <div className="flex" style={{justifyContent: 'center'}}>
                      <div style={{alignSelf: 'center'}}>ADD</div>
                      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '5px'}}>
                        <DropupArrow onClick={()=>{this.onClickFilter('up', 'actual_delivery_date')}} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                        <DropdownArrow onClick={() => { this.onClickFilter('down', 'actual_delivery_date') }} style={{ height: '15px', width: '15px', cursor: 'pointer' }} />
                      </div>
                    </div>
                  </th>
                  }
                  {type == 9 &&
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">Cancelled Date</th>
                  }
                  {!defaultColumns && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Vendor Type</th>}
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">
                    {/* {defaultColumns ? 'Consignor Name' : 'Vendor Name'} */}
                    {
                      lorealDefaultColumns ? 'Consignor Name' : (isLoreal ? 'Sending Location Name' : 'Vendor Name')
                    }
                    </th>
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">Invoice No.</th>
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">{isLoreal ? 'No of Packages Dispatched' : 'No of Packages'}</th>
                  <th style={{ borderRight: '1px solid white' }} className="table-header-details">{isLoreal ? 'Weight as per Loreal' : 'Weight(KGs)'}</th>
                  {isLoreal && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Appointment ID</th>}
                  {isLoreal && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Appointment date & time</th>}
                  {isLoreal && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Re appointment ID</th>}
                  {isLoreal && <th style={{ borderRight: '1px solid white' }} className="table-header-details">Re Appointment Appointment date & time</th>}

                  {[6,8,62].includes(type) &&
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">Status</th>
                  }
                  {[12,62].includes(type) &&
                  <>
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">Delay Type</th>
                    <th style={{ borderRight: '1px solid white' }} className="table-header-details">Reason for Delay</th>
                  </>
                  }
                  {type == 8 &&
                    <th className="table-header-details">POD Details</th>
                  }
                </tr>
              </thead>
              <tbody
                className="ml-5 mr-5"
                style={{ backgroundColor: "#ffffff" }}
              >
                {(milestoneData || []).map((obj, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        borderLeft: "2px solid #e7edfa",
                        borderRight: "2px solid #e7edfa",
                      }}
                    >
                      {/* Actions */}
                      {companyType !== PROVIDER && [12,2].includes(this.state.type) &&  (
                        <td className="table-body-details" style={{minWidth: '150px'}}>
                          {isPantaloons?
                          <>
                          {this.state.type != 2 && 
                          <div
                            className="wt-40 button-classic mb-5"
                            onClick={() =>
                              this.setState(
                                { showAlert1: true, order: obj }
                                // this.CancelPickup(obj)
                              )
                            }
                          >
                            <CancelIcon className="wt-20 ht-20 pt-5" />
                            <button className="icon-button">Cancel</button>
                          </div>
                          }
                          <div
                            className="wt-40 button-classic"
                            onClick={() =>
                              this.setState(
                                { modifyPopup: true, order: obj }
                                // this.fetchLspNameList(obj.lr_number)
                              )
                            }
                          >
                            <EditIcon className="wt-20 ht-20 pt-5" />
                            <button className="icon-button">Modify</button>
                          </div>
                          </>
                          :
                          <>
                          <div
                            className="wt-40 button-classic mb-5"
                            onClick={() =>
                              this.setState(
                                { showAlert: true, order: obj }
                                // this.CancelPickup(obj)
                              )
                            }
                          >
                            <CancelIcon className="wt-20 ht-20 pt-5" />
                            <button className="icon-button">Cancel</button>
                          </div>
                          <div
                            className="wt-40 button-classic"
                            onClick={() =>
                              this.setState(
                                { modifyPopup: true, order: obj }
                                // this.fetchLspNameList(obj.lr_number)
                              )
                            }
                          >
                            <EditIcon className="wt-20 ht-20 pt-5" />
                            <button className="icon-button">Modify</button>
                          </div>
                          </>}
                          
                        </td>
                        
                      )} 
                      {companyType !== PROVIDER && this.state.type === 62  && !isLoreal && 
                        <td className="table-body-details" style={{minWidth: '150px'}}>
                        {(this.state.type === 62 && this.state.sub_type === 3)  &&  (
                          <div
                            className=" button-classic mb-5 flex flex-middle"
                            style={{height:"fit-content"}}
                            onClick={() =>
                              this.setState(
                                { showAlert: true, order: obj }
                                // this.CancelPickup(obj)
                              )
                            }
                          >
                            <Move className="wt-20 ht-20 pt-5" />
                            <button className="icon-button">Move To Exception</button>
                          </div>)}

                          {(this.state.type === 62 && this.state.sub_type === 2) && (
                            <div
                              className="wt-40 button-classic mb-5"
                              onClick={() =>
                                this.setState(
                                  { markAsLostAlert: true, order: obj }
                                  // this.CancelPickup(obj)
                                )
                              }
                            >
                              <LostIcon style={{marginLeft: '-5px'}} className="wt-20 ht-20 pt-5" />
                              <button className="icon-button">Mark As Lost</button>
                            </div>)}

                          <div
                            className="wt-40 button-classic"

                            onClick={(e) =>
                              this.setState(
                                { delaymodifyPopup: true, order: obj },
                                this.fetchDelayNameList(obj.lr_number,e)
                              )
                            }
                          >
                            <Reason_select className="wt-20 ht-20 pt-5" />
                            <button className="icon-button">Delay Reasons</button>
                          </div>
                        </td>
                      }
                      {companyType === SEEKER &&
                        <td className="table-body-details">
                          <div>{obj.vendor_name}</div>
                        </td>
                      }
                      
                      <td className="table-body-details pl-10" style={{ color: "blue" }}>
                          {obj.lr_number}
                          {obj.is_reassigned && <span className="table-body-details pl-10" style={{ color: "red" }}>R</span>}
                      </td>
                      {isLoreal && <td className="table-body-details pl-10" style={{ color: "blue" }}>{obj.booking_date}</td>}
                      {bound_type == 1 && <td className="table-body-details">
                        {(obj.po_number )}
                      </td>}

                      {!defaultColumns && <td className="table-body-details">
                        {bound_type == 1 && (obj.inbound_no)}
                        {(bound_type == 2 && obj.invoice_details || []).map((inv) => (
                          <div>{inv.tu_no}</div>
                        ))}
                      </td>}

                      {bound_type == 2 &&
                      <td className="table-body-details">
                         {(obj.invoice_details || []).map((inv) => (
                          <div>{inv.delivery_no}</div>
                        ))}
                      </td>
                      }
                      
                      {!defaultColumns && <td className="table-body-details" style={{ padding: "5px" }}>
                        {bound_type == 1 ?
                          obj.inbound_date &&
                          moment(obj.inbound_date).format("DD MMM, YYYY")
                          :
                          obj.outbound_date &&
                          moment(obj.outbound_date).format("DD MMM, YYYY")
                        }
                      </td>}

                      {bound_type == 2 && <td className="table-body-details">{obj && obj.source_details && obj.source_details.code}</td>}
                      <td className="table-body-details">{obj.origin}</td>
                      {isLoreal &&  <td className="table-body-details">{obj.destination_details && obj.destination_details.city}</td>}
                      {isLoreal &&  <td className="table-body-details">{obj.destination_details && obj.destination_details.name}</td>}
                      {isLoreal &&  <td className="table-body-details">{Array.isArray(obj.invoice_details) && 
                      obj.invoice_details.length > 0 && obj.invoice_details[0].invoice_value
                      }</td>}
                      
                      {bound_type == 2 && <td className="table-body-details">{obj && obj.destination_details && obj.destination_details.code}</td>}
                      <td className="table-body-details">{obj.destination}</td>

                      {!isLoreal &&
                        <td className="table-body-details" style={{ padding: "5px" }}>
                        {obj.expected_pickup_date && moment(obj.expected_pickup_date).format("DD MMM, YYYY")}
                        </td>
                      }

                      {([6,8].includes(type) || (type == 62 && sub_type == 2)) && 
                        <td className="table-body-details" style={{ padding: "5px" }}>
                        {obj.pickup_date && moment(obj.pickup_date).format("DD MMM, YYYY")}
                        </td>
                      }
                      
                      {[2,6,8].includes(type) && LOREAL.includes(getUser().company_id) &&
                      <>
                      <td className="table-body-details" style={{ padding: "5px" }}>
                        {obj.invoice_details && obj.invoice_details.length && moment(obj.invoice_details[0].invoice_date).format("DD MMM, YYYY")}
                      </td>
                      <td className="table-body-details">{obj.delivery_tat}</td>
                      </>
                      }
                      {([6, 8].includes(type) || (type == 62 && sub_type == 2)) && !defaultColumns && 
                        <td className="table-body-details">{obj.delivery_tat}</td>
                      }

                      {([6,8,9].includes(type) || (type == 62 && sub_type == 2)) &&
                        <td className="table-body-details" style={{ padding: "5px" }}>
                          {obj.expected_delivery_date && moment(obj.expected_delivery_date).format("DD MMM, YYYY")}
                        </td>
                      }
                     
                     {type == 9 &&
                      <td className="table-body-details" style={{ padding: "5px" }}>
                        {obj.cancelled_date && moment(obj.cancelled_date).format("DD MMM, YYYY")}
                      </td>
                      }

                      {type == 8 &&
                        <td className="table-body-details" style={{ padding: "5px" }}>
                          {obj.actual_delivery_date && moment(obj.actual_delivery_date).format("DD MMM, YYYY")}
                        </td>
                      }

                  

                      {!defaultColumns && 
                      <td className="table-body-details">
                        {obj.key_vendor ? 'Key' : 'Non Key'}
                      </td>
                      }
                     
                      <td className="table-body-details">
                        <div>{obj && obj.source_details && obj.source_details.consignor_name}</div>
                      </td>
                      
                      <td className="table-body-details">
                        {(obj.invoice_numbers || []).map((inv) => (
                          <div>{inv}</div>
                        ))}
                      </td>

                      <td className="table-body-details">
                        <div>{obj.no_of_packages ? obj.no_of_packages : "-"}</div>
                      </td>

                      <td className="table-body-details">
                        <div>{`${obj.charged_weight || "-"}`}</div>
                      </td>

                      {isLoreal && <td className="table-body-details" style={{ padding: "5px" }}>
                        {Array.isArray(obj.appointment_details) && obj.appointment_details.length > 0 && obj.appointment_details[0].id}
                      </td>}
                      {isLoreal && <td className="table-body-details" style={{ padding: "5px" }}>
                        {Array.isArray(obj.appointment_details) && obj.appointment_details.length > 0 && moment(obj.appointment_details[0].datetime).format("DD MMM, YYYY")}
                      </td>}
                      {isLoreal && Array.isArray(obj.appointment_details) && obj.appointment_details.length > 1 && <td className="table-body-details" style={{ padding: "5px" }}>
                        {Array.isArray(obj.appointment_details) && obj.appointment_details.length > 0 && obj.appointment_details[obj.appointment_details.length - 1].id}
                      </td>}
                      {isLoreal && Array.isArray(obj.appointment_details) && obj.appointment_details.length > 1 && <td className="table-body-details" style={{ padding: "5px" }}>
                        {Array.isArray(obj.appointment_details) && obj.appointment_details.length > 0 && moment(obj.appointment_details[obj.appointment_details.length - 1].datetime).format("DD MMM, YYYY")}
                      </td>}
                     
                      {[6,8,62].includes(type) &&
                      <td className="table-body-details">
                        <div>
                          <div
                            className={this.getColor(
                              (obj.latest_event || {}).event_status
                            )}
                          >
                            {obj.latest_event.event_status}
                          </div>
                          <div
                            className="theme-color-light curP"
                            onClick={() =>
                              this.setState(
                                { milestonePopup: true, order: obj },
                                this.fetchOrderDetails(obj.lr_number)
                              )
                            }
                          >
                            See all updates
                          </div>
                        </div>
                        <div>
                        {PANTALOONS.includes(getUser().company_id) && 
                          <div
                            className="theme-color-light curP"
                            onClick={() =>{  
                              this.fetchApiLogTypes(obj.lr_id); 
                            }
                            }
                          >
                            See all Logs
                          </div>}
                        </div>
                      </td>
                      }

                      {[12,62].includes(type) &&
                      <>      
                      <td className="table-body-details">
                        <div>{(this.state.sub_type == 3 ? obj.pick_delay_text : obj.delivery_delay_text) || "-"}</div>
                      </td>
                            
                      <td className="table-body-details">
                        <div>{(this.state.sub_type == 3 ? obj.pick_delay_remarks : obj.delivery_delay_remarks) || "-"}</div>
                      </td>
                      </>
                      }
                     
                      {type == 8 &&
                        <td className="table-body-details wt-10p">
                          <div>
                            <div className="flex pl-10">
                              {"POD(S) :"}
                              {companyType === SEEKER && (
                                <UploadButton
                                  showIcon={true}
                                  multiple={"multiple"}
                                  className="icon-button icon"
                                  showFile={false}
                                  onChange={(e) =>
                                    this.uploadPODDetails(e, obj.lr_id, true)
                                  }
                                />
                              )}
                            </div>
                            {(obj.pod_details || []).map((pod) => {
                              return (
                                <div className="flex pl-10">
                                  {pod.is_seeker_pod &&
                                    (pod.pod_list || []).map((list, ind) => {
                                      return (
                                        <a
                                          href={list}
                                          download
                                          className={"theme-color-light curP"}
                                        >
                                          {`POD${ind + 1}${
                                            ind + 1 === pod.pod_list.length
                                              ? ""
                                              : ","
                                          }`}
                                        </a>
                                      );
                                    })}
                                </div>
                              );
                            })}
                            <div className="flex pl-10">
                              {"POD(P) :"}
                              {companyType === PROVIDER && (
                                <UploadButton
                                  showIcon={true}
                                  multiple={"multiple"}
                                  className="icon-button icon"
                                  showFile={false}
                                  onChange={(e) =>
                                    this.uploadPODDetails(e, obj.lr_id, false)
                                  }
                                />
                              )}
                            </div>
                            {(obj.pod_details || []).map((pod) => {
                              return (
                                <div className="flex pl-10">
                                  {!pod.is_seeker_pod &&
                                    (pod.pod_list || []).map((list, ind) => {
                                      return (
                                        <a
                                          href={list}
                                          download
                                          className={"theme-color-light curP"}
                                        >
                                          {`POD${ind + 1}${
                                            ind + 1 === pod.pod_list.length
                                              ? ""
                                              : ","
                                          }`}
                                        </a>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        }
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    )
  }
  
  render() {
    // const value = this.state.tempValue;
    // const suggestions = this.state.lrSuggestions;
    // const inputProps = {
    //   placeholder: "Lr number",
    //   value,
    //   onChange: this.onChange,
    // };

    const companyType = getCompanyType();
    const {
      offset,
      limit,
      count,
      milestoneData,
      orderDetails,
      order,
      docketList,
      totalCount,
      milestone_counts,
      manual_lr_number,
      lspNameList,
      milestone_sub_counts,
      delayremark,
      delayRemarksList,
      fetchApiLogDataLoading
    } = this.state;
    let { reserved_lrs, lsp_details, modify_type } = this.state;
    const { isLoading, serviceProviders ,reasonsList, seekers, movementTypes,fromAndToLocationsList,
      apiLogTypes,
      apiLogData,
      apiLogMessage,
    } = this.props;
    console.log('apiLogTypes:', apiLogTypes)
    console.log("usertype:",getUserType())
    const msCount = (arr, key) => {
        const res = {};
        arr.forEach(obj => {
            res[obj[key]] = obj['count'];
        })
        return res
    }
    let type_n_count = msCount(milestone_counts, 'type');
    this.setCounts(trackingModePantaloons, type_n_count);
  
    
    const msSubCount = (arr, key) => {
        const res = {};
        arr.forEach(obj => {
            if (obj.hasOwnProperty('sub_types')) {
              let sObjs = obj['sub_types'];
              sObjs.forEach(sObj => {
                res[sObj[key]] = sObj['count'];
              })
            }
        })
        return res
    }
    let type_sub_count = msSubCount(milestone_counts, 'sub_type');
    this.setSubTypeCounts(pantaloonsDelay, type_sub_count);
  
   

    let providerList = (serviceProviders || []).map((sp) => {
     
          return {
            name: sp.vendor_name,
            value: sp.vendor_id,
            alias: sp.lsp_alias,
          };
   
    });
    let reasonsListData = (delayremark || []).map((sp) => {

          return {
            name: sp.pickup_delay_text,
            value: sp.type
          };
   
    });
    
    
    let delay_remark_list = (delayRemarksList.pickup_delay_remarks || []).map((sp) => {
      return {
        name: sp.delay_remark,
      };
    });
    
    let lrList = (reserved_lrs || []).map((sp) => {
      return {
        name: sp.lr_number,
      };
    });

    let seekerList = (seekers || []).map((seeker) => {
      return {
        name: seeker.name,
        value: seeker.company_id,
      };
    });

    let movementTypeList = (movementTypes || []).map((move) => {
      return {
        name: move.movement_text,
        value: move.movement_type,
      };
    });
    
    return (
      <Sticky activeLink={"milestone"}>
        {isLoading && <ActionLoader fixed={true} />}
        <div
          className="p5 pr-30 default-milestone"
          style={{ backgroundColor: "#F6F9FF", width: "100%" }}
        >
          <section style={{ width: '100%' }} className="pl-15 mb-10 flex flex-between">
          <div className="flex-grow mr-10 dashboard-tab col-3">
          {/* {(PANTALOONS.includes(getUser().company_id) || CARGILL.includes(getUser().company_id)) && (
            <Tabs
              tabs={this.state.boundTypesWithCount}
              activeTabId={this.state.bound_type}
              selectTab={this.selectBound}
              tabStyle={'milestoneBound'}
            />
          ) } */}
              <Tabs
                tabs={this.state.boundTypesWithCount}
                activeTabId={this.state.bound_type}
                selectTab={this.selectBound}
                tabStyle={'milestoneBound'}
              />
        </div>
            <div className="flex-grow mr-10 dashboard-tab col-2">
              {PANTALOONS.includes(getUser().company_id) && (
                <Tabs
                  tabs={this.state.companyTypeWithCount}
                  activeTabId={this.state.company_type}
                  selectTab={this.selectCompany}
                />
              )}
            </div>
          </section>

          <section style={{ width: '100%', justifyContent: 'space-between' }} className="pl-15 mb-10 flex milestoneScreenTabRow">
            <div className="flex-grow mrr-10  dashboard-tab col-1-1" style={{width:'max-content'}}>
              {(!LOREAL.includes(getUser().company_id)) ? (
                <Tabs
                  tabs={trackingModePantaloons}
                  activeTabId={this.state.type}
                  selectTab={this.selectTrackingMode}
                  tabStyle={'milestone'}
                />
              ) : (
                <Tabs
                  tabs={trackingModeLoreal}
                  activeTabId={this.state.type}
                  selectTab={this.selectTrackingMode}
                  tabStyle={'milestone'}
                />
              )}
              {/* <Tabs
                tabs={trackingModePantaloons}
                activeTabId={this.state.type}
                selectTab={this.selectTrackingMode}
                tabStyle={'milestone'}
              /> */}
            </div>
            <section className="mb-10 mt-10 flex" style={{ height: '35px' }}>
              {companyType === SEEKER && (
                <Fragment>
                  <div style={{borderRight: '1px solid black'}} className="flex mr-10 pr-10 pt-2" onClick={this.downloadTemplate}>
                    <DownloadIcon className="wt-20 ht-20 pt-5" />
                    <button className="icon-button">Download Format</button>
                  </div>
                  <div style={{ borderRight: '1px solid black' }} className="flex mr-10 pr-10 pt-2">
                    <UploadIcon className="wt-20 ht-22" />
                    <UploadButton
                      value="Upload"
                      className="icon-button"
                      showFile={false}
                      onChange={(e) => this.uploadOrders(e)}
                    />
                  </div>

                  <div
                    className="flex mr-10 pr-10 pt-2"
                    style={PANTALOONS.includes(getUser().company_id)?{borderRight: '1px solid black'}:{}}
                    onClick={() => this.milestoneOverview(true)}
                  >
                    <DownloadIcon className="wt-20 ht-20 pt-5" />
                    <button className="icon-button">Download Data</button>
                  </div>
                  {PANTALOONS.includes(getUser().company_id) && getUserType()===1 &&
                  <div
                    className="flex mr-10 pr-10 pt-2"
                    onClick={() => this.toggleRequestLogPopup()}
                  >
                    <button className="icon-button">Pickup Request Logs</button>
                  </div>}
                </Fragment>
              )}

              {companyType === PROVIDER && (
                <Fragment>
                <div
                  className="ml-auto flex pt-5"
                  style={{ borderRadius: "5px"}}
                >
                  {/* <div
                    className="table-text-color fBolder p-10"
                    style={{ paddingTop: "5px" }}
                  >
                    Milestone Tracking:{" "}
                  </div> */}
                  {this.state.seeker_name &&
                    <>
                      <div
                      style={{borderRight: '1px solid black'}} className="mr-10 pr-5 curP"
                      onClick={this.downloadLSPTemplate}
                      >
                    
                      <DownloadIcon className="wt-20 ht-20 pt-5" />
                      <button className="icon-button">Download Format</button>
                  </div>
                  <div style={{ borderRight: '1px solid black' }} className="fLeft flex mr-10 pr-10 pt-2">
                    <UploadIcon className="wt-20 ht-22" />
                    <UploadButton
                      value="Upload"
                      className="icon-button"
                      showFile={false}
                      onChange={(e) => this.uploadLSPDetails(e)}
                    />
                  </div>
                  </>
                }
                  
                </div>
                {this.state.seeker_name && (PANTALOONS.includes((this.state.seeker_name || {}).value) || CARGILL.includes((this.state.seeker_name || {}).value)) &&
                  <div
                    className="wt-40 pt-5"
                    onClick={() => this.milestoneOverview(true)}
                  >
                    <DownloadIcon className="wt-20 ht-20 pt-5" />
                    <button className="icon-button">Download Data</button>
                  </div>}
                </Fragment>
              )}
            </section>
          </section>
          <section style={{ width: '100%' }} className="pl-15 mb-10 flex flex-between">
            <div style={{position: 'fixed', height: '32px' ,zIndex: 100000, top: 5, left: 300, width: '40%'}} className="dropdown">
              <SearchDropdown
                isMilestone={true}
                onChange={(e) => {
                  this.setState({ search: e }, this.milestoneOverview);
                }}
                onSelect={(value) => {
                  this.setState({ search: value }, this.milestoneOverview);
                }}
                label={"Search PO No/Inbound No/Invoice No/Docket No"}
                items={docketList || []}
                value={this.state.search}
                hasClearFilter={() => {
                  this.setState({ search: "" }, this.milestoneOverview);
                }}
                selectedClassName={"row-32"}
              />
            </div>
            <div className="dropdown" style={{ marginRight: "10px" ,zIndex: 999 , width:'200px'}}>
              {this.state.bound_type == 2 &&
              <SearchDropdown
                isMilestoneFilter={true}
                hideSearch={true}
                onChange={(e) => {
                  this.setState({ from_location: e }, this.milestoneOverview);
                }}
                onSelect={(value) => {
                  this.setState(
                    { from_location: value },
                    this.milestoneOverview
                  );
                }}
                label={"From"}
                items={docketList || []}
                value={this.state.from_location}
                hasClearFilter={() => {
                  this.setState({ from_location: "" }, this.milestoneOverview);
                }}
                className={"dropdown"}
              />}
                {this.state.bound_type == 1 &&
                <Select
                  options={fromAndToLocationsList.from_loctions && 
                    fromAndToLocationsList.from_loctions.map((location) => {
                      return { label: location, value: location }
                  })}
                  className={'wt-130 flex-3 mr-20'}
                  placeholder={'From'}
                  isClearable={true}
                  onChange={(selectedOption) => {
                    this.setState(
                    { from_location: selectedOption?
                      selectedOption.value : null }, 
                    this.milestoneOverview);
                  }}
              />}
            </div>
            <div className="dropdown mr-10" style={{zIndex: 999}}>
              {this.state.bound_type == 2 &&
              <SearchDropdown
                isMilestoneFilter={true}
                hideSearch={true}
                onChange={(e) => {
                  this.setState({ to_location: e }, this.milestoneOverview);
                }}
                onSelect={(value) => {
                  this.setState({ to_location: value }, this.milestoneOverview);
                }}
                label={"To"}
                items={docketList || []}
                value={this.state.to_location}
                hasClearFilter={() => {
                  this.setState({ to_location: "" }, this.milestoneOverview);
                }}
                className={"dropdown"}
              />}
              {this.state.bound_type == 1 &&
              <Select
                  options={fromAndToLocationsList.to_locations && 
                    fromAndToLocationsList.to_locations.map((location) => {
                      return { label: location, value: location }
                  })}
                  className={'wt-130 flex-3 mr-20 ht-33'}
                  placeholder={'To'}
                  isClearable={true}
                  onChange={(selectedOption) => {
                    this.setState(
                    { to_location: selectedOption ?
                       selectedOption.value : null }, 
                    this.milestoneOverview);
                  }}
              />}
            </div>
            <div className="flex date-select order-daterange mr-10" style={{zIndex: 999}}>
              <DateRangePicker
                startDate={
                  this.state.from_date_search
                    ? moment(this.state.from_date_search)
                    : null
                }
                startDateId="startDate"
                endDate={
                  this.state.to_date_search
                    ? moment(this.state.to_date_search)
                    : null
                }
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  let { from_date_search, to_date_search } = this.state;
                  
                  // Check if startDate is null or undefined
                  if (startDate) {
                    from_date_search = new Date(startDate).setHours(0, 0, 0);
                  }
                
                  // Check if endDate is null or undefined
                  if (endDate) {
                    to_date_search = new Date(endDate).setHours(23, 59, 59);
                  }
                
                  this.setState(
                    { from_date_search, to_date_search, offset: 0 },
                    this.milestoneOverview
                  );
                }}
                // onDatesChange={({ startDate, endDate }) => {
                //   let { from_date_search, to_date_search } = this.state;
                //   from_date_search = startDate
                //     ? new Date(startDate).setHours(0, 0, 0)
                //     : new Date(this.state.from_date_search).setHours(0, 0, 0);
                //   to_date_search = endDate
                //     ? new Date(endDate).setHours(23, 59, 59)
                //     : to_date_search;
                //   this.setState(
                //     { from_date_search, to_date_search, offset: 0 },
                //     this.milestoneOverview
                //   );
                // }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                displayFormat={"DD MMM YYYY"}
                noBorder={true}
                minimumNights={0}
                isOutsideRange={() => false}
              />
            </div>
            {companyType !== PROVIDER && this.state.bound_type == 1 && <div className="mr-10 dropdown" style={{zIndex: 999}}>
              <Dropdown
                isReason={true}
                isVendorType={true}
                isMilestoneFilter={true}
                value={this.state.key_vendor}
                items={["Key Vendor", "Non Key Vendor"].map((el) => {
                  return { value: el, name: el };
                })}
                label="Vendor Type"
                selectedClassName={"col-3 wt-150"}
                onSelect={(value) =>
                  this.setState({ key_vendor: value }, this.milestoneOverview)
                }
              />
            </div>}
            {companyType !== PROVIDER && this.state.bound_type == 1 && <div className="dropdown mr-10" style={{zIndex: 999 }}>
              <SearchDropdown
                isMilestoneFilter={true}
                hideSearch={true}
                onChange={(e) => {
                  this.setState({ consignor_name: e }, this.milestoneOverview);
                }}
                onSelect={(value) => {
                  this.setState(
                    { consignor_name: value },
                    this.milestoneOverview
                  );
                }}
                label={"Enter Vendor"}
                items={docketList || []}
                value={this.state.consignor_name}
                hasClearFilter={() => {
                  this.setState({ consignor_name: "" }, this.milestoneOverview);
                }}
                className={"dropdown"}
              />
            </div>}
            {companyType !== PROVIDER && PANTALOONS.includes(getUser().company_id) && this.state.type == 8 && <div className="mr-10 dropdown" style={{zIndex: 999}}>
              <Dropdown
                isReason={true}
                isVendorType={true}
                isMilestoneFilter={true}
                value={this.state.is_pod_uploaded}
                // className="width-r-12"
                items={[{
                  name : "POD Uploaded",
                  value : 1
                },{
                  name : "POD Not Uploaded",
                  value : "0"
                }]}
                label="Select PodType"
                selectedClassName={"col-2 wt-140"}
                onSelect={(value) =>
                //    {
                //   console.log("valuee->",value)
                // }
                  this.setState({ is_pod_uploaded: value }, this.milestoneOverview)
                }
              />
            </div>}
            {companyType === SEEKER && (
              <div className="mr-10 wt-140 dropdown" style={{zIndex: 999}}>
                <ReactMultiSelectCheckboxes
                options={(serviceProviders || []).map((sp) => {
                            return {
                              label: sp.vendor_name,
                              value: sp.vendor_id,
                              alias: sp.lsp_alias,
                            };
                      })}
                placeholderButtonLabel="Select Transporters"
                getDropdownButtonLabel={this.getDropdownButtonLabel}
                value={this.state.transporter_name || []}
                onChange={this.onChangeTransporterList}
                setState={(val) => this.setState({ transporter_name: [...val, ...transporter_name] })}
              />
              </div>
            )}
            {/* {companyType === PROVIDER && (
              <div className="mr-10 wt-200 dropdown" style={{zIndex: 999}}>
                <div className="flex">
                <Dropdown
                  // isReason={true}
                  // isTransporterName={true}
                  isMilestoneFilter={true}
                  value={this.state.seeker_name}
                  items={seekerList}
                  label="Seeker Name"
                  selectedClassName={"col-1 wt-200"}
                  onSelect={(value) =>
                    this.setState(
                      { seeker_name: value },
                      this.milestoneOverview
                    )
                  }
                /><span className={'error-color'}>*</span>
                </div>
                {!this.state.seeker_name && <div className={'error-color p-5'}>Please Select Customer</div>}
              </div>
            )} */}
            {this.state.bound_type == 2 && (!LOREAL.includes(getUser().company_id)) &&
              <div className="mr-10 wt-165 dropdown" style={{zIndex: 999}}>
              <Dropdown
                isReason={true}
                isMilestoneFilter={true}
                value={this.state.movement_type}
                items={movementTypeList}
                label="Movement Type"
                selectedClassName={"col-1 wt-165"}
                onSelect={(value) =>
                  this.setState(
                    { movement_type: value },
                    this.milestoneOverview
                  )
                }
              />
            </div>}
            <div style={{alignSelf: 'center'}}>
              <button
                className="icon-button wt-40"
                onClick={() =>
                  this.setState(
                    {
                      search: "",
                      from_date_search: null,
                      to_date_search: null,
                      transporter_name: "",
                      consignor_name: "",
                      key_vendor: "",
                      from_location: "",
                      to_location: "",
                      sort_by: null,
                      sort_type: null,
                      seeker_name: "",
                      movement_type: "",
                      vendor_ids : null,
                      is_pod_uploaded : undefined
                    },
                    this.milestoneOverview
                  )
                }
              >
                Clear Filters
              </button>
            </div>
            <div style={{ marginLeft: '4px', width: '700px', justifyContent: 'left', alignSelf: 'center' }} className={`flex`}>
              {
                (offset > 0) &&
                <p onClick={() => this.pagination("First", count, limit)} style={{ textDecoration: 'underline' }} className="theme-color curP fBolder ">
                  First
                </p>
              }
              <div className="ml-10 mr-10 theme-color fBolder">
                {`${count > 0 ? offset + 1 : 0} - ${count < offset + limit ? count : offset + limit
                  } of ${count}`}
                <LeftArrow
                  className={
                    offset > 0
                      ? "wt-10 ht-10 ml-10 curP"
                      : "disabled-button wt-10 ht-10 ml-10"
                  }
                  onClick={() => this.pagination("Previous")}
                />
                <RightArrow
                  className={
                    count >= offset + limit
                      ? "wt-10 ht-10 ml-10 curP"
                      : "disabled-button wt-10 ht-10 ml-10"
                  }
                  onClick={() => this.pagination("Next")}
                />
              </div>
              {
                (count >= offset + limit) &&
                <p onClick={() => this.pagination("Last", count, limit)} style={{ textDecoration: 'underline' }} className="theme-color curP fBolder">
                  Last
                </p>
              }
            </div>
          </section>
          

          <section className="pl-15 flex flex-between">
            <div className="flex-grow mr-10 dashboard-tab col-3">
              {this.state.type === 62 && (!LOREAL.includes(getUser().company_id)) && (
                <Tabs
                  tabs={pantaloonsDelay}
                  activeTabId={this.state.sub_type}
                  selectTab={this.selectDelayTypeMode}
                />
              )}
            </div>
            
          </section>

          <section className="mb-10 mt-10 flex" style={{ height: '35px' }}>
              {PANTALOONS.includes(getUser().company_id) && this.state.type === 62 &&(
                <Fragment>
                  <div style={{borderRight: '1px solid black'}} className="mr-10 pr-10 curP" onClick={this.generateDelayReasonTemplate}>
                    <DownloadIcon className="wt-20 ht-20 pt-5" />
                    <button className="icon-button">Delay Download Format</button>
                  </div>
                  <div style={{ borderRight: '1px solid black' }} className="fLeft flex mr-10 pr-10 pt-2">
                    <UploadIcon className="wt-20 ht-22" />
                    <UploadButton
                      value="Delay Upload"
                      className="icon-button"
                      showFile={false}
                      onChange={(e) => this.uploadDelayReason(e)}
                    />
                  </div>
                </Fragment>
              )}
            </section>

          {/* <section style={{ width: '80%' }} className="pl-15 mb-10 flex">
            <div style={{marginLeft: '75%'}} className={` mr-10 flex`}>
            {
              (offset > 0) &&
              <p onClick={() => this.pagination("First", count, limit)} style={{ textDecoration: 'underline' }} className="theme-color curP fBolder ">
                First
              </p>
            }
              <div className="ml-10 mr-10 theme-color fBolder">
                {`${count > 0 ? offset + 1 : 0} - ${
                  count < offset + limit ? count : offset + limit
                } of ${count}`}
                <LeftArrow
                  className={
                    offset > 0
                      ? "wt-10 ht-10 ml-10 curP"
                      : "disabled-button wt-10 ht-10 ml-10"
                  }
                  onClick={() => this.pagination("Previous")}
                />
                <RightArrow
                  className={
                    count >= offset + limit
                      ? "wt-10 ht-10 ml-10 curP"
                      : "disabled-button wt-10 ht-10 ml-10"
                  }
                  onClick={() => this.pagination("Next")}
                />
              </div>
                {
                (count >= offset + limit) &&
                <p onClick={() => this.pagination("Last", count, limit)} style={{ textDecoration: 'underline' }} className="theme-color curP fBolder">
                  Last
                </p>
                }
            </div>
          </section> */}
          {this.renderTable()} 
          

          {this.state.milestonePopup && !isLoading && (
            <div className="overlay">
              <div
                className="overlay-container modify-popup"
                style={{ width: "70%", marginLeft: "220px" }}
              >
                <span
                  className="overlay-close-button fs-20"
                  onClick={() => {
                    this.setState({ milestonePopup: false });
                  }}
                >
                  X
                </span>
                <div className="overlay-body create-location-popup">
                  <div className="fBolder fs-18">
                    <span className={this.getColor(order.status)}>
                      {order.latest_event.event_status}
                    </span>
                  </div>
                  <div className="fBolder fs-14 mt-10 mb-10">{`Order No.: ${
                    order.lr_number
                  }`}</div>
                  {(orderDetails || []).map((track) => {
                    return (
                      <>
                        {/* <div className="fBolder mt-10 mb-10 communication-color">{track.date && `${this.getDay(track.date)}, ${moment(track.date).format('DD MMM, YYYY')}`}</div> */}
                        {(track.events || []).map((event) => {
                          return (
                            <div className="mt-15 mb-15 flex">
                              <div className="wt-20p">
                                <div className="fBolder">
                                  {track.date &&
                                    moment(track.date).format("DD MMM, YYYY")}
                                </div>
                                <div>
                                  {event.record_datetime &&
                                    moment(event.record_datetime).format(
                                      "hh:mm a"
                                    )}
                                </div>
                              </div>
                              {/* <span></span> */}
                              <SideLine className="wt-30 ht-50" />
                              <div className="ml-20 wt-80p">
                                <div className="fBolder">
                                  {event.event_status}
                                </div>
                                <div className="fs-italic">
                                  {event.scanned_location}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {this.state.modifyPopup && (
            <div className="overlay">
              <div className="overlay-container exception-modify-popup">
                <span
                  className="overlay-close-button fs-20"
                  onClick={() => {
                    this.setState({ modifyPopup: false });
                  }}
                >
                  X{" "}
                </span>
                <div className="overlay-body create-location-popup">
                  <Tabs
                    tabs={ModifyExceptions}
                    activeTabId={this.state.modify_type}
                    selectTab={this.selectModifyExceptionTypeMode}
                  />
                  {this.state.modify_type === 0 ? (
                    <div>
                      <div className="fs-18 mt-10 mb-10 tCenter">
                        Reserved LR Modify Pickup Request
                      </div>
                      <div className="flex mb-200 mt-10">
                        <div className="wt-150">
                          <div className="fs-12 fBolder mb-5">LSP Name</div>
                          <Dropdown
                            onSelect={(value) =>
                              this.setState(
                                { alias: value },
                                this.milestoneLrlist(value)
                              )
                            }
                            value={this.state.alias || ""}
                            items={providerList}
                            customClass="flex-1 mr-20 "
                            selectedClassName={"col-1"}
                          />
                        </div>
                        <div className="wt-150">
                          <div className="fs-12 fBolder mb-5">LR Number</div>
                          <Dropdown
                            onSelect={(value) =>
                              this.setState(
                                { lrnumbersDb: value },
                                this.Lrlist(value)
                              )
                            }
                            value={this.state.lrnumbersDb || ""}
                            items={lrList}
                            customClass="flex-1 mr-20 "
                            selectedClassName={"col-1"}
                          />
                        </div>
                      </div>

                      <div className="flex ">
                        <Button
                          value="Cancel"
                          className="mb-30 wt-80 mr-30"
                          onClick={() => {
                            this.setState({ modifyPopup: false }, this.milestoneOverview());
                          }}
                        />
                        <Button
                          value="Modify Pickup Request"
                          className="mb-30 wt-80 mr-30"
                          onClick={(value) => this.modifySave(value)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="fs-18 mt-10 mb-10 tCenter">
                        Manual LR Modify Pickup Request
                      </div>
                      <div className="flex mb-200 mt-10">
                        <div className="wt-150">
                          <div className="fs-12 fBolder mb-5">LSP Name</div>
                          <Dropdown
                            onSelect={(value) =>
                              this.setState(
                                { alias: value },
                                this.milestoneLrlist(value)
                              )
                            }
                            //   onSelect={(value) => {
                            //     const { adhocFNR } = this.state;
                            //     adhocFNR.fnrMovementType = value.value;
                            //     this.setState({adhocFNR});
                            // }}
                            label={'Select Location Type'}
                            value={this.state.alias}
                            items={providerList}
                            customClass="flex-1 mr-20 "
                            selectedClassName={"col-1"}
                          />
                        </div>
                       
                        <div className="wt-150">
                          <div className="fs-12 fBolder mb-5">LR Number</div>
                          <input
                          style={{ border: 'solid 1px #d8dbf0', height:'35px',borderRadius:"4px", width:"200px"}}
                            // containerClassName="wt-150 "
                            // input-container={false}
                            id='manual_lr_number' 
                            value={this.state.manual_lr_input}
                            name="manual_lr_number"
                            type="number"
                            // changeHandler={(e) => this.setState({manual_lr_input: e.target.value})}
                            // changeHandler = {this.onChangeHandler}
                            onChange={(e) => this.setState({ manual_lr_input: e.target.value })}
                            // placeholder="Type"
                          />
                        </div>

                        <div className="wt-150">

                          <div className="data-picker">
                          <div className="fs-12 fBolder mb-5">
                            Pickup Date
                          </div>
                          <div className="myCustomDate-1">
                          <DatePicker
                          // className="myCustomDate-1"
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.booking_date}
                            onChange={this.SelectDate.bind(
                              this,
                              "booking_date"
                            )}
                            placeholderText={"DD/MM/YYYY"}
                          /></div>
                        </div>
                        </div>
                      </div>

                      <div className="flex ">
                        <Button
                          value="Cancel"
                          className="mb-30 wt-80 mr-30"
                          onClick={() => {
                            this.setState({ modifyPopup: false });
                          }}
                        />
                        <Button
                          value="update Lr Number"
                          className="mb-30 wt-80 mr-30"
                          onClick={(value) => this.manualSave(value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {this.state.delaymodifyPopup && (
            <div className="overlay">
              <div className="overlay-container exception-modify-popup">
                <span
                  className="overlay-close-button fs-20"
                  onClick={() => {
                    this.setState({ delaymodifyPopup: false, pickup_delay_remarks: '', delay_remarks_name: '' });
                  }}
                >
                  X{" "}
                </span>
                <div className="overlay-body create-location-popup">
                 
                  
                  <div className="fs-18 mt-10 mb-10 tCenter">
                    Select Delay Reason
                  </div>
                  <div className="flex mb-200 mt-10">
                  <div className="wt-150">
                  <div className="fs-12 fBolder mb-5">Select Delay Reason</div>
                  <Dropdown
                  onSelect={(value) =>
                    this.setState(
                      { pickup_delay_remarks: value },
                      this.getReasonsList(value)
                    )
                  }
                  value={this.state.pickup_delay_remarks}
                  items={reasonsListData}
                  customClass="flex-1 mr-20 "
                  selectedClassName={"col-1"}
                />
                  </div>
                  <div className="wt-150">
                    <div className="fs-12 fBolder mb-5">Select Delay Remark</div>
                    <Dropdown
                      onSelect={(value) =>
                        this.setState(
                          { delay_remarks_name: value },
                          // this.Lrlist(value)
                        )
                      }
                      value={this.state.delay_remarks_name}
                      items={delay_remark_list}
                      customClass="flex-1 mr-20 "
                      selectedClassName={"col-1"}
                    />
                  </div>
                </div>



                  <div className="flex ">
                    <Button
                      value="Cancel"
                      className="mb-30 wt-80 mr-30"
                      onClick={() => {
                        this.setState({ delaymodifyPopup: false,  pickup_delay_remarks: '', delay_remarks_name: '' }, this.milestoneOverview());
                      }}
                    />
                    <Button
                      value="Save"
                      className="mb-30 wt-80 mr-30"
                      onClick={(value) => this.updateReason(value)}
                    />
                  </div>
                
                </div>
              </div>
            </div>
          )}

          {this.state.showAlert && (
            <div className="overlay">
              <div className="overlay-container">
                <div className="overlay-body confirm-alert">
                  <div className={"col-1 share"}>
                    <div
                      className={"fBolder mt-10 mb-20 fBolder tCenter fs-16"}
                    >
                      Are you sure you want to Move the Request
                      <div>
                        {" "}
                        <Warning className="wt-50 ht-50 pt-5" />
                      </div>
                    </div>

                    <div className={"mt-30 ml-100"}>
                      <div
                        className={
                          "button-outlined button-yes share-button wt-100 tCenter fRight"
                        }
                        onClick={this.hideOverlay}
                      >
                        No
                      </div>
                      <div
                        className={
                          "button-outlined button-no share-button wt-100 tCenter fRight mr-20"
                        }
                        onClick={() => this.movetoException(this.state.order)}
                      >
                        Yes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.showAlert1 && (
            <div className="overlay">
              <div className="overlay-container">
                <div className="overlay-body confirm-alert">
                  <div className={"col-1 share"}>
                    <div
                      className={"fBolder mt-10 mb-20 fBolder tCenter fs-16"}
                    >
                      Are you sure you want to Move the Request
                      <div>
                        {" "}
                        <Warning className="wt-50 ht-50 pt-5" />
                      </div>
                    </div>

                    <div className={"mt-30 ml-100"}>
                      <div
                        className={
                          "button-outlined button-yes share-button wt-100 tCenter fRight"
                        }
                        onClick={this.hideOverlay1}
                      >
                        No
                      </div>
                      <div
                        className={
                          "button-outlined button-no share-button wt-100 tCenter fRight mr-20"
                        }
                        onClick={() => this.confirm211(this.state.order)}
                      >
                        Yes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.markAsLostAlert && (
            <div className="overlay">
              <div className="overlay-container">
                <div className="overlay-body confirm-alert">
                  <div className={"col-1 share"}>
                    <div
                      className={"fBolder mt-10 mb-20 fBolder tCenter fs-16"}
                    >
                      Are you sure you want to Mark this Request as a Lost
                      <div>
                        {" "}
                        <Warning className="wt-50 ht-50 pt-5" />
                      </div>
                    </div>

                    <div className={"mt-30 ml-100"}>
                      <div
                        className={
                          "button-outlined button-yes share-button wt-100 tCenter fRight"
                        }
                        onClick={this.hideLostAlertOverlay}
                      >
                        No
                      </div>
                      <div
                        className={
                          "button-outlined button-no share-button wt-100 tCenter fRight mr-20"
                        }
                        onClick={() => this.markAsLost(this.state.order)}
                      >
                        Yes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!!this.state.openRequestLogPopup &&
            <RequestLogPopup 
            openClosePopup={this.toggleRequestLogPopup}
            requestLogLRnumber={this.state.requestLogLRnumber}
            onChangeRequestLogPopupLRnumber={this.onChangeRequestLogPopupLRnumber}
            onSubmit={this.downlaodPickupRequestLogs}
            />

          }
          {!!this.state.SeeAllLogsPopup &&
            <SeeAllLogsPopup 
            openClosePopup={this.toggleSeeAllLogsPopup }
            // requestLogLRnumber={this.state.requestLogLRnumber}
            // onChangeRequestLogPopupLRnumber={this.onChangeRequestLogPopupLRnumber}
            // onSubmit={this.downlaodPickupRequestLogs}
            apiLogTypes={apiLogTypes}
            fetchApiLogData={this.fetchApiLogData}
            apiLogData={apiLogData}
            fetchApiLogDataLoading={fetchApiLogDataLoading}
            
            />

          }
        </div>
      </Sticky>
    );
  }
}

const mapStateToProps = ({ trips }) => {
  console.log('trips:', trips)
  console.log(trips.movementTypes || [])
  return {
    milestoneOrder: trips.milestoneOrder || [],
    isLoading: trips.isLoading || false,
    serviceProviders: trips.serviceProviders || [],
    fromAndToLocationsList:trips.fromAndToLocationsList ||[],
    reservedLrData: trips.reservedLrData || [],
    lsp_details: trips.lsp_details || [],
    milestone_counts: trips.milestone_counts || [],
    reasonsList: trips.reasonsList || [],
    seekers: trips.seekers.data || [],
    movementTypes: trips.movementTypes || [],
    apiLogTypes:trips.apiLogTypes||[],
    apiLogData:trips.apiLogData||[],
    apiLogMessage:trips.apiLogMessage||[],
  };
};

export default connect(mapStateToProps)(withRouter(MileStone));
