import React from 'react';
import Cx from 'classnames';

const Checkbox = ({label, id, checked, onCheck, className, type})=>(
    <div onClick={onCheck} className={"checkbox flex flex-middle curP " + className}>
        <div className ={Cx("outer-circle", {
            'square-box': type === 'square' && checked,
            'square-radius': type === 'square'
        })}>
            {
                checked && <div className={Cx("inner-circle pa", {
                    'square-box': type === 'square' && checked
                })} />
            }
        </div>
        <div className="label ml-5 table-text-color fs-16">{label}</div>
    </div>
);

export default Checkbox;