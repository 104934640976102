import Moment from 'moment';

export default class Trip {
    constructor(trip={}) {
        try {
            this.tripId = trip.trip_id;
            this.isTracked = trip.is_tracked;
            this.seekerId = trip.seeker_id;
            this.seekerName = trip.seeker_name;
            this.providerId = trip.provider_id;
            this.value = trip.value;
            this.itemName = trip.item_name;
            this.boundType = trip.bound_type;
            this.modeType = trip.mode_type;
            this.serviceType = trip.service_type;
            this.isAutoCreated = trip.is_auto_created;
            this.runType = trip.run_type;
            this.type = trip.type;
            this.comments = trip.comments;
            this.confirmGateIn = trip.can_confirm_gate_in;
            this.confirmGateOut = trip.can_confirm_gate_out;
            this.customer_name = trip.customer_name;
            this.rfqNo = trip.rfq_no;
            this.contractTenureFrom = trip.contract_tenure_from ? Moment(trip.contract_tenure_from) : '';
            this.contractTenureTo = trip.contract_tenure_to ? Moment(trip.contract_tenure_to) : '';

            this.isDoubleDriver = trip.is_double_driver;
            if (trip.gps_details) {
                this.gpsDetails = trip.gps_details;
            }
            this.doNo = trip.do_no;
            this.poNo = trip.po_no;
            this.lrNo = trip.lr_no;
            this.customerName = trip.customer_name;
            this.routeCode = trip.route_code;
            this.cargoWeight = trip.cargo || {};
            this.invoiceNo = trip.invoice_no;
            this.invoiceDate = trip.invoice_date;
            this.statusText = trip.status && trip.status.text ? trip.status.text : '';
            this.statusCode = trip.status && trip.status.code ? trip.status.code : '';
            this.trackingDetails = trip.tracking_details || {};
            this.distance = trip.distance || 0;
            this.closedBy = trip.closed_by;
            this.closureRemarks = trip.closure_remarks;
            this.trackedLogs = trip.tracked_logs || {};
            if (trip.route_details) {
                const routeDetails = trip.route_details;
                this.routeDetails = {
                    source: routeDetails.source,
                    destination: routeDetails.destination,
                    via: routeDetails.via,
                    inBetween: routeDetails.in_between,
                    currentlyAt: routeDetails.currently_at
                }
            }
            if (trip.transporter_details) {
                const transporterDetails = trip.transporter_details;
                this.transporterDetails = {
                    transporterName: transporterDetails.name,
                    number: transporterDetails.number,
                    transporterNumber: transporterDetails.number,
                    serviceType: transporterDetails.service_type,
                    transporterEmail: transporterDetails.email,
                    transporterPan: transporterDetails.pan_no
                }
            }
            if (trip.trip_dates) {
                const dateTat = trip.trip_dates;
                this.dateTat = {
                    startedDate: Moment(dateTat.start_date),
                    gateOutDate: dateTat.source_gate_out_time ? Moment(dateTat.source_gate_out_time) : '',
                    gateInDate: dateTat.source_gate_in_time ? Moment(dateTat.source_gate_in_time) : '',
                    destinationGateInTime: dateTat.destination_gate_in_time ? Moment(dateTat.destination_gate_in_time) : '',
                    destinationGateOutTime: dateTat.destination_gate_out_time ? Moment(dateTat.destination_gate_out_time) : '',
                    tat: trip.tat,
                    createdOn: dateTat.time_trip_created ? Moment(dateTat.time_trip_created) : '',
                    completedOn: dateTat.time_trip_completed ? Moment(dateTat.time_trip_completed) : '',
                    timeVehiclePlaced: dateTat.time_vehicle_placed ? Moment(dateTat.time_vehicle_placed) : ''
                }
            }
            if (trip.driver_details) {
                this.driverDetails = trip.driver_details.map((driver)=> {
                    return {
                        name: driver.name || '',
                        firstName: driver.first_name || '',
                        lastName: driver.last_name || '',
                        number: driver.number,
                        operatorType: driver.operator_type,
                        operatorName: driver.operator_name,
                        consentStatus: driver.consent_status,
                        ivrNumber: driver.ivr_number,
                        userResponse: driver.user_response,
                        responseTime: driver.response_time,
                        message: driver.message,
                        from: driver.from,
                        to: driver.to,
                        isTracked: driver.is_tracked || false
                    }
                });
            }
            if (trip.vehicle_details) {
                const vehicleDetails = trip.vehicle_details;
                this.vehicleDetails = {
                    vehicleNo: vehicleDetails.vehicle_no,
                    vehicleServiceType: vehicleDetails.service_type || '',
                    isoNo: vehicleDetails.iso_no || '',
                    loadingCapacity: vehicleDetails.loading_capacity || '',
                    truckType: vehicleDetails.vehicle_type,
                    vehicleType: vehicleDetails.vehicle_type,
                    bodyType: vehicleDetails.body_type,
                    vehicleHistory: vehicleDetails.vehicle_history,
                    washingStatus: vehicleDetails.service_type_option || vehicleDetails.washing_status,
                    noOfWashes: vehicleDetails.no_of_times_sealed || vehicleDetails.no_of_washes,
                    rejectRemarks: vehicleDetails.reject_remarks || ''
                }
            }
            if (trip.consignee_details) {
                const consigneeDetails = trip.consignee_details || [];
                this.consigneeDetails = consigneeDetails.map((consignee)=> {
                    return {
                        name: consignee.name,
                        number: consignee.number
                    }
                });
            }
            if (trip.trip_details && trip.trip_details.route_details) {
                this.tripRouteDetails = trip.trip_details.route_details.map((routeDetail)=> {
                    return {
                        branchId: routeDetail.branch_id,
                        name: routeDetail.name,
                        number: routeDetail.number,
                        location: routeDetail.location,
                        city: routeDetail.city,
                        locationType: routeDetail.location_type,
                        weeklyOff: routeDetail.weekly_off,
                        cutoffTime: routeDetail.cut_off_time,
                        pincode: routeDetail.pincode,
                        date: routeDetail.date,
                        time: routeDetail.time,
                        tat: routeDetail.tat,
                        notificationEmails: routeDetail.notification_emails,
                        notificationNumbers: routeDetail.notification_numbers,
                        displayName: routeDetail.display_name,
                        customerName: routeDetail.customer_name,
                        lrNo: routeDetail.lr_no
                    }
                });
            }
            if (trip.status) {
                this.status = {
                    text: trip.status.text,
                    code: trip.status.code
                }
            }
            if (trip.trip_details && trip.trip_details.detention_details) {
                const tripDetentionDetails = trip.trip_details.detention_details;
                const columns = tripDetentionDetails.columns || [];
                this.tripDetentionDetails = {
                    columns,
                    rows: (tripDetentionDetails.rows || []).map((row, index)=> {
                        const rowObject = tripDetentionDetails['row_' + (index + 1)] || {};
                        const returnObject = {}
                        returnObject.name = rowObject.name;
                        returnObject.detentionDetails = [];
                        for(let i=0; i < tripDetentionDetails.columns.length; ++i) {
                            returnObject.detentionDetails.push({name:'', details : []});
                        }
                        (rowObject.det_detail || []).forEach((detentionDetail, index)=> {
                            const name = detentionDetail.name;
                            returnObject.detentionDetails[columns.indexOf(name)] = {...detentionDetail};
                            returnObject.detentionDetails[columns.indexOf(name)].details = (returnObject.detentionDetails[columns.indexOf(name)].details || []).map((detail, index)=> {
                                return {
                                    city:detail.city,
                                    date: Moment(detail.date),
                                    from: detail.from,
                                    to: detail.to,
                                    location: detail.location,
                                    locationType: detail.location_type,
                                    time: detail.time
                                }
                            })
                        });
                        return returnObject;
                    })
                }
            }
            if (trip.tracking_preference) {
                this.trackingPreferences = (trip.tracking_preference || []).map((preference)=> {
                    return {
                        tracking_type: preference.tracking_type,
                        enabled: preference.enabled,
                        tracking_name: preference.tracking_name
                    }
                })
            }
            if (trip.notification_settings) {
                this.notificationSettings = (trip.notification_settings || []).map((setting)=> {
                    return {
                        plant_name: setting.plant_name,
                        plant_type: setting.plant_type,
                        accounts: setting.accounts || []
                    }
                })
            }
        } catch(e) {
            console.log('ERROR in Model Trip', e);
        }
    }
}
