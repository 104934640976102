import React, { Component } from 'react';
import ActionDetails from './ActionDetail';


class TruckAction extends Component {
    constructor() {
        super();
        this.state = {
            isAction: false,
            details: {}
        }
    }

    onAction = (data) => {
        this.setState((prevState) => ({
            isAction: !prevState.isAction,
            details: data ? data : {}
        }))
    }

    render() {
        return (
            <div>
                {this.props.data && Object.keys(this.props.data).length > 0 && Object.keys(this.props.data).map(val => {
                return(<div className={'wt-48p box-shadow mr-20 br-5 mt-10 inBlock'}>
                    <header className={'fs-16 fBolder tCenter pb-5 pt-5 bg-theme-color white-color bb-light'}>{`${val} (${this.props.data[val].length})`}</header>
                        <div className={'pb-5 pt-5 bb-light tCenter theme-color'}>
                            <div className={'wt-30p inBlock'}>{'DO No'}</div>
                            <div className={'wt-30p inBlock'}>{'Truck No'}</div>
                            <div className={'wt-30p inBlock'}>{'Route'}</div>
                        </div>
                        <div className={'list-action'}>
                        {this.props.data[val] && this.props.data[val].length > 0 && this.props.data[val].map(trip => {
                            return(
                            <div className={'pb-5 pt-5 truck-action curP bb-light tCenter'} onClick={() => this.onAction(trip)}>
                                <div className={'wt-30p inBlock fBolder'}>{trip.do_no}</div>
                                <div className={'wt-30p inBlock fBolder'}>{trip.po_no}</div>
                                <div className={'wt-30p inBlock fBolder'}>{trip.vehicle_details.vehicle_no}</div>
                                <div className={'wt-30p inBlock fBolder'}>{`${trip.route_details[0].city} - ${trip.route_details[trip.route_details.length-1].city}`}</div>
                            </div>)})}
                        </div>
                </div>)})}
                {/* <div className={'wt-250 box-shadow'}>
                    <header className={'fs-16 fBolder tCenter pb-5 pt-5 bg-theme-color white-color bb-light'}>Delayed</header>
                    <div className={'tCenter pb-5 pt-5 truck-action curP'} onClick={this.onAction}>
                        {'1234590'}
                    </div>
                </div> */}
                {this.state.isAction && <ActionDetails show={this.state.isAction} onAction={this.onAction} trip={this.state.details}/>}
            </div>
        )
    }
}

export default TruckAction;
