import React, { Component } from 'react';

import { ReactComponent as SearchIcon } from './icons/search.svg';
import crossMark from './icons/cross-mark.svg';


class SearchDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownShown: false
        }
    }
    showDropdown = (e) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({
            isDropdownShown: true
        });
    }
    hideDropdown = () => {
        setTimeout(() => {
            this.setState({
                isDropdownShown: false
            });
        }, 200)
    }
    onSelect = (e) => {
        this.props.onSelect(e.target.getAttribute('name'));
    }
    onChange = (e) => {
        this.props.onChange(e.target.value);
    }
    render() {
        const { items, value, label, containerClassName, itemsClassName, selectedClassName, isMilestoneFilter, hideSearch, onKeyPress, hasClearFilter, isList, displayKey, isMilestone, isAudit } = this.props;
        return (
            <div className={`dropdown-container ${isMilestoneFilter ? 'ht-30' : 'ht-40'} pr` + (containerClassName || "")}>
                <div className={`pa l-10 ${(isMilestone || isAudit) ? 't-5' : 't-10'}`}>
                    {!hideSearch && <SearchIcon />}
                </div>
               <input 
                    className={"selected-item row-100 flex flex-middle pl-40 pr-16 col-1 mile-input" + (selectedClassName || "")}
                    style={{ paddingLeft: `${hideSearch ? '10px' : '40px'}`}}
                    onFocus={this.showDropdown} 
                    tabIndex="1" 
                    onBlur={this.hideDropdown}
                    placeholder={label}
                    value={value && Object.keys(value) && isList ? value[displayKey] : value}
                    onChange={this.onChange}
                    onKeyPress={onKeyPress || (() => { })}
                />
                <div className="pa r-2 t-10">
                    {
                        value && (value.length > 0 || Object.keys(value).length > 0) && hasClearFilter &&
                        <span className="cross-img" style={{ backgroundImage: `url(${crossMark})` }} onClick={hasClearFilter}></span>
                    }
                </div>
                {this.state.isDropdownShown && value && !isList && <ul className="dropdown-items flex flex-column" style={{ 'maxWidth': '300px', 'overflow': 'scroll' }}>
                    {
                        items.map((item, index) => {
                            if (typeof item === 'string') {
                                return (
                                    <li
                                        name={item}
                                        key={index}
                                        className={"dropdown-item pl-16 ht-auto" + (itemsClassName || "")}
                                        onClick={this.onSelect}>
                                        {item}
                                    </li>
                                )
                            } else {
                                return (
                                    <li
                                        name={(item.display_name || item.plant_name || item.city || item.name)}
                                        key={index}
                                        className={"dropdown-item pl-16 ht-auto" + (itemsClassName || "")}
                                        onClick={() => { this.props.onSelect(item) }}>
                                        {(item.display_name || item.plant_name || item.city || item.name)}
                                    </li>
                                )
                            }
                        })
                    }
                </ul>}
                {this.state.isDropdownShown && value && isList && <ul className="dropdown-items flex flex-column" style={{ 'maxHeight': '300px', 'overflow': 'scroll' }}>
                    {
                        items.map((item, index) => {
                            return (
                                index >= 0 && <li
                                    key={index}
                                    name={item[displayKey]}
                                    className={"dropdown-item pl-16 min-ht40"}
                                    onClick={() => this.props.onSelect(item)}
                                >
                                    {item[displayKey]}
                                </li>
                            )
                        })
                    }
                </ul>}
            </div>
        );
    }
}

export default SearchDropdown;