import React, { Component, Fragment } from 'react';
import Dropdown from 'Components/Dropdown';
import { className } from 'classnames';
import  SearchDropdown  from 'Components/SearchDropdown';
import CheckBox from 'Components/Checkbox';
import Cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as EsclamationIcon } from 'Components/TripRow/icons/esclamation.svg';
import Select from 'react-select';
import { TTBIL } from 'Constants/commonConstants';
import { getUser } from 'Utils/storage';

// Manufacturing Plant : 1
// Warehouse :2
// Depot : 3
// Customer :4

// const typesOfLocation = [
//     {
//         value: 0,
//         name: "Type of location",
//     },
//     {
//         value: 1,
//         name: "Manufacturing Plant",
//     },
//     {
//         value: 2,
//         name: "Warehouse",
//     },
//     {
//         value: 3,
//         name: "Depot"
//     },
//     {
//         value: 4,
//         name: "Customer"
//     }
// ];

const days = [
    {
        value: 'Select the Day',
        name: 'Select the Day'
    },
    {
        value: "Sunday",
        name: "Sunday"
    },
    {
        value: "Monday",
        name: "Monday"
    },
    {
        value: "Tuesday",
        name: "Tuesday"
    },
    {
        value: "Wednesday",
        name: "Wednesday"
    },
    {
        value: "Thursday",
        name: "Thursday"
    },
    {
        value: "Friday",
        name: "Friday"
    },
    {
        value: "Saturday",
        name: "Saturday"
    }
]

const userDetails = getUser();




export default class RouteDetailForm extends Component {
    constructor() {
        super();
        this.state = {
            route_code: '',
            dest_plant_code: '',
            source_plant_code: '',
            isDropdown: false
        }}

    render() {
        const {typesOfLocation, route, index, handleRouteDataChange, label, locations, getSchedulerRoute, searchList, tripData} = this.props;
        return (
            <Fragment>
                
                <section className="flex flex-between route-form-row trip-route-details">
                    <div className={Cx('wt-65 ml-5', {
                            'mt-10': index === 'start',
                            'pt-10': index !== 'start'
                    })}>
                    {/* {
                            index === 'start' &&
                            <React.Fragment>
                                <a data-tip data-for={`${index}_src`} className={'ml-50'}>
                                    <div className="pb-5 fBold mt--15">Origin
                                        <EsclamationIcon style={{ width: '15px', height: '11px' }}/>
                                    </div>
                                </a>
                                <ReactTooltip id={`${index}_src`} type="info" effect="solid" border={true}>
                                    Only the INITIAL shipment LOADING POINT must be considered as origin.
                                </ReactTooltip>
                            </React.Fragment>
                        } */}
                        <CheckBox
                            onCheck={() => {handleRouteDataChange("source", !route.source, index)}}
                            checked={route.source}
                        />
                    </div>

                    <div className={Cx('wt-65', {
                            'mt-10': index === 'start',
                            'pt-10': index !== 'start'
                    })}>
                        {/* {
                            index === 'start' &&
                            <React.Fragment>
                                <a data-tip data-for={`${index}_destn`} className={'ml-50'}>
                                    <div className="pb-5 fBold mt--15">Destn.
                                        <EsclamationIcon style={{ width: '15px', height: '11px' }}/>
                                    </div>
                                </a>
                                <ReactTooltip id={`${index}_destn`} type="info" effect="solid" border={true}>
                                    Mark shipment UNLOADING POINT(S) as destination(in case of multiple drops each drop point needs to be marked as destination).
                                </ReactTooltip>
                            </React.Fragment>
                        } */}
                        <CheckBox
                            onCheck={() => {handleRouteDataChange("destination", !route.destination, index)}}
                            checked={route.destination}
                            type="square"
                        />
                    </div>
                    <SearchDropdown
                        hideSearch={true}
                        onChange={(value) => {handleRouteDataChange("location", value, index)} }
                        onSelect={(value) => {handleRouteDataChange("location", value, index)} }
                        label={label}
                        items={locations || []}
                        value={ route.location_type_name == 'Third Party' ? route.display_name : (route.plant_name || route.city) }
                        selectedClassName={"route-detail-search"}
                        containerClassName={' wt-200'}
                        hasClearFilter={() => {handleRouteDataChange("location", '' , index)}}
                    />

                    <Dropdown
                        onSelect={(value)=>{handleRouteDataChange("location_type", value, index)}}
                        items={typesOfLocation}
                        value={{name:route.location_type_name, value:route.location_type_name}}
                        label={'Type of location'}
                        selectedClassName={`wt-150 ${route.city ? 'disabled' : ''}`}
                        customClass={'wt-150'}
                    />

                    <SearchDropdown
                        hideSearch={true}
                        onChange={(value) => {handleRouteDataChange("pincode", value, index)} }
                        label={"PIN Code"}
                        items={[]}
                        selectedClassName={`route-detail-search ${route.city ? 'disabled' : ''}`}
                        containerClassName={' wt-80'}
                        value={route.pincode}
                        />

                    {/* <Dropdown
                        onSelect={(value)=>{handleRouteDataChange("weekly_off", value, index)}}
                        containerClassName=" "
                        items={days}
                        value={{name:route.weekly_off, value:route.weekly_off}}
                        label={'Weekly off'}
                        selectedClassName={`wt-110 ${route.city ? 'disabled' : ''}`}
                    />

                    <SearchDropdown
                        hideSearch={true}
                        onChange={(value) => {handleRouteDataChange("cut_off_time", value, index)} }
                        label={"Daily cut off"}
                        items={[]}
                        selectedClassName={`route-detail-search ${route.city ? 'disabled' : ''}`}
                        containerClassName={' wt-105'}
                        value={route.cut_off_time}
                    /> */}
                    <div className="wt-200 mr-10 pr notification-email">
                        <Select
                            className={'share route-details fs-14'}
                            isMulti
                            value={(route.notification_emails.length > 0) ? route.notification_emails.map(val => { return { label : val, value: val } } ) : null}
                            onBlur={(e) => {
                                handleRouteDataChange("notification_emails", e.target.value , index)
                            }}
                            isClearable={true}
                            onChange={(options) => {
                                const val = options.map(opt => opt.value).join(',');
                                handleRouteDataChange("notification_emails", val, index, true)
                            }}
                            placeholder={'Notification Emails'}
                        />
                        {
                            route.notification_emails && route.notification_emails.length > 0 &&
                            <div className="pa email-tooltip t-5 l-5">
                                <div className="detail-tooltip pa flex flex-column p5 tLeft wt-200">
                                    {(route.notification_emails|| []).map(val => <div className="table-text-color fs-12 tCenter">{val}</div>)}
                                </div>
                            </div>
                        }
                        {
                            route.notification_emails && route.notification_emails.length > 0 &&
                            route.notification_emails.filter(notEmail => {
                                const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                                const res = !emailReg.test(notEmail);
                                return (notEmail && res);
                            }).length > 0 &&
                            <div className="error-color fs-12 tCenter">Invalid Email Address</div>
                        }
                    </div>
                    <div className="wt-175 mr-10 pr notification-number">
                        <Select
                            className={'share route-details fs-14'}
                            isMulti
                            value={(route.notification_numbers.length > 0) ? route.notification_numbers.map(val => { return { label : val, value: val } } ) : null}
                            onBlur={(e) => {
                                handleRouteDataChange("notification_numbers", e.target.value , index)
                            }}
                            isClearable={true}
                            onChange={(options) => {
                                const val = options.map(opt => opt.value).join(',');
                                handleRouteDataChange("notification_numbers", val, index, true)
                            }}
                            placeholder={"Notification No.'s"}
                        />
                        {route.notification_numbers && route.notification_numbers.length > 0 &&
                            <div className="pa number-tooltip t-5 l-5">
                                <div className="detail-tooltip pa flex flex-column p5 tCenter wt-175">
                                    {(route.notification_numbers|| []).map(val => <div className="table-text-color fs-12">{val}</div>)}
                                </div>
                            </div>
                        }
                        {route.notification_numbers && route.notification_numbers.length > 0 &&
                            route.notification_numbers.filter(notNum => {
                                return (notNum.length < 10 || notNum.length > 10 || isNaN(Number(notNum)))
                            }).length > 0 &&
                            <div className="error-color fs-12 tCenter">Enter a valid 10digit phone no.</div>}
                    </div>

                    {
                        index !== 'start' ?
                        <SearchDropdown
                            hideSearch={true}
                            onChange={(value) => {handleRouteDataChange("tat", value, index)} }
                            label={"TAT"}
                            items={[]}
                            selectedClassName={`route-detail-search non-selectable tat-disable`}
                            containerClassName={' wt-80'}
                            value={route.tat}
                        /> :
                        <div className="route-detail-search wt-80 mr-10">
                        </div>
                    }

                    {
                        TTBIL.includes(userDetails.company_id) && !route.source ?
                        <SearchDropdown
                            hideSearch={true}
                            onChange={(value) => {handleRouteDataChange("customer_name", value, index)} }
                            label={"Customer Name"}
                            items={[]}
                            selectedClassName={'route-detail-search'}
                            containerClassName={' wt-150'}
                            value={route.customer_name}
                        /> :
                        <div className="route-detail-search wt-150">
                        </div>
                    }

                    {
                        TTBIL.includes(userDetails.company_id) && !route.source ?
                        <SearchDropdown
                            hideSearch={true}
                            onChange={(value) => {handleRouteDataChange("lr_no", value, index)} }
                            label={"LR Number"}
                            items={[]}
                            selectedClassName={'route-detail-search'}
                            containerClassName={' wt-100'}
                            value={route.lr_no}
                        /> :
                        <div className="route-detail-search wt-100">
                        </div>
                    }

                    {/*
                        <SearchDropdown
                            hideSearch={true}
                            onChange={(value) => {handleRouteDataChange("name", value, index)} }
                            label={"Consignee name"}
                            items={[]}
                            selectedClassName={'route-detail-search'}
                            value={route.name}
                        />

                        <SearchDropdown
                            hideSearch={true}
                            onChange={(value) => {handleRouteDataChange("number", value, index)} }
                            label={"Consignee No."}
                            items={[]}
                            selectedClassName={'route-detail-search'}
                            value={route.no}
                        />
                    */}
                </section> 
            </Fragment>
        )
    }
}
