import React, { Component, Fragment } from 'react';
import Moment from 'moment';

import Input from 'Components/Input';
import Dropdown from 'Components/Dropdown';

import Button from 'Components/Button';
import SearchDropdown from 'Components/SearchDropdown';
import ActionLoader from 'Components/ActionLoader';
import Checkbox from 'Components/Checkbox';
import Overlay from 'Components/Overlay';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as CloseIcon } from 'Components/TrackingSettings/icons/close-icon.svg';
import { ReactComponent as EsclamationIcon } from 'Components/TripRow/icons/esclamation.svg';
import { SingleDatePicker } from 'react-dates';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import uuid4 from 'uuid';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';

import ChangeDriver from 'Components/TransporterDetails/ChangeDriver';
import ChangeVehicle from 'Components/TransporterDetails/ChangeVehicle';
import { GOOGLE_API_KEY, GALAXY } from 'Constants/commonConstants';

import { scroll, getTrackingLabel } from '../../utils/common';
import classNames from 'classnames';
import { canUserEdit, getCookie, getUser } from 'Utils/storage';

const userCanEdit = canUserEdit();

const emptyGeoFenceLocation = {
    location: "",
}
const emptyGeoFenceLocationSec = {
    location: ""
}
const washingType = [
    {value: 1, name: "Sealed"},
    {value: 2, name: "Washed"}
]

const userDetails = getUser(); 


class TransporterDetails extends Component {
    constructor(props) {
        super(props);
        const { trip } = this.props;
        this.state = {
            isSuperAdmin:JSON.parse(getCookie("user-type")) == 4,
            isSuperAdminSeeker:JSON.parse(getCookie("user-type")) == 1,
            isSuperAdminOthers:JSON.parse(getCookie("user-type")) == 3,
            vehicleNo: trip.vehicleDetails.vehicleNo,
            vehicleServiceType: trip.vehicleDetails.vehicleServiceType ? props.vehicleServiceTypes.filter(item => { return item.name === trip.vehicleDetails.vehicleServiceType })[0] : {},
            isoNo: trip.vehicleDetails.isoNo,
            loadingCapacity: trip.vehicleDetails.loadingCapacity,
            vehicleType: trip.vehicleDetails.vehicleType ? props.vehicleTypes.filter(item => { return item.name === trip.vehicleDetails.vehicleType })[0] : {},
            bodyType: trip.vehicleDetails.bodyType ? props.vehicleBodyTypes.filter(item => { return item.name === trip.vehicleDetails.bodyType })[0] : {},
            vehicleHistory: trip.vehicleDetails.vehicleHistory ? trip.vehicleDetails.vehicleHistory : [],
            washingStatus: trip.vehicleDetails.washingStatus ? trip.vehicleDetails.washingStatus : '',
            noOfWashes: trip.vehicleDetails.noOfWashes ? trip.vehicleDetails.noOfWashes : 0,
            driverDetails: [...props.trip.driverDetails],
            gpsVendors: {id: 0, name: ''},
            transporterName: trip.transporterDetails.transporterName,
            transporterNumber: trip.transporterDetails.transporterNumber,
            transporterEmail: trip.transporterDetails.transporterEmail,
            transporterPan: trip.transporterDetails.transporterPan,
            transpoterId: 0,
            trackingPreferences: [...props.trip.trackingPreferences],
            error: false,
            showPopUp: false,
            time: null,
            date: null,
            open: false,
            geofenceLocations: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            geofenceLocationsSec: [{ ...emptyGeoFenceLocationSec, id: uuid4() }],
            locationLatLong: [],
            showChangeDriverPopUp: false,
            newDriverName: "",
            newDriverNumber: null,
            newDriverOperatorType: 0,
            newDriverOperatorName: "",
            newDriverOperatorFrom: {},
            newDriverOperatorTo: {},
            newDriverIsTracked: false,
            newgeofenceLocations: [{...emptyGeoFenceLocation, id: uuid4()}],
            newgeofenceLocationsSec: [{...emptyGeoFenceLocation, id: uuid4()}],
            isEnabledAddDriver: false,
            isTrackedIndex: null,
            numbersOperatorData: {},
            selectedCK: trip.isDoubleDriver,
            transporterComments: "",
        }
    }
    componentDidMount() {
        const { trip, gpsVendorType } = this.props;
        const {driverDetails} = this.props.trip;
        (driverDetails || []).map((driver) => {
            driver.from = {...driver.from, place: driver.from && driver.from.place ? driver.from.place : ''};
            driver.to = {...driver.to, place: driver.to && driver.to.place ? driver.to.place : ''};
        })

        var index = -1;
        for(let i=0; i<driverDetails.length; i++) {
            if(driverDetails[i].isTracked === true) {
                index = i;
                break;
            }
        }

        const vendorObj = (trip.gpsDetails && trip.gpsDetails.id) ? (gpsVendorType || []).filter((vendor) => {return vendor.id === trip.gpsDetails.id}) : [{id: 0, name: ''}];
        const gpsData = (vendorObj.length>0) ? vendorObj[0] : {id: 0, name: ''};
        console.log(trip.vehicleDetails)
        this.setState({
            vehicleNo: trip.vehicleDetails.vehicleNo,
            vehicleServiceType: trip.vehicleDetails.vehicleServiceType ? this.props.vehicleServiceTypes.filter(item => { return item.name === trip.vehicleDetails.vehicleServiceType })[0] || {} : {},
            isoNo: trip.vehicleDetails.isoNo,
            loadingCapacity: trip.vehicleDetails.loadingCapacity,
            vehicleType: trip.vehicleDetails.vehicleType ? this.props.vehicleTypes.filter(item => { return item.name === trip.vehicleDetails.vehicleType })[0] : {},
            bodyType: trip.vehicleDetails.bodyType ? this.props.vehicleBodyTypes.filter(item => { return item.name === trip.vehicleDetails.bodyType })[0] : {},
            vehicleHistory: trip.vehicleDetails.vehicleHistory ? trip.vehicleDetails.vehicleHistory : [],
            washingStatus: trip.vehicleDetails.washingStatus ? trip.vehicleDetails.washingStatus : '',
            noOfWashes: trip.vehicleDetails.noOfWashes ? trip.vehicleDetails.noOfWashes : 0,
            driverDetails: [...trip.driverDetails],
            gpsVendors: gpsData, //{id: 0, name: ''},
            transporterName: trip.transporterDetails.transporterName,
            transporterNumber: trip.transporterDetails.transporterNumber,
            transporterEmail: trip.transporterDetails.transporterEmail,
            transporterPan: trip.transporterDetails.transporterPan,
            transpoterId: 0,
            trackingPreferences: [...this.props.trip.trackingPreferences],
            error: false,
            showPopUp: false,
            time: null,
            date: null,
            open: false,
            geofenceLocations: [{ ...emptyGeoFenceLocation, id: uuid4() }],
            geofenceLocationsSec: [{ ...emptyGeoFenceLocationSec, id: uuid4() }],
            locationLatLong: [],
            showChangeDriverPopUp: false,
            newDriverName: "",
            newDriverNumber: null,
            newDriverOperatorType: 0,
            newDriverOperatorName: "",
            newDriverOperatorFrom: {},
            newDriverOperatorTo: {},
            newDriverIsTracked: false,
            newgeofenceLocations: [{...emptyGeoFenceLocation, id: uuid4()}],
            newgeofenceLocationsSec: [{...emptyGeoFenceLocation, id: uuid4()}],
            isEnabledAddDriver: false,
            isTrackedIndex: index,
            selectedCK: trip.isDoubleDriver,
        });
    }

    onChangeVehicle = () => {
        this.setState({
            showPopUp: !this.state.showPopUp
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.numbersOperatorData !== this.state.numbersOperatorData) {
            this.setState({
                numbersOperatorData: nextProps.numbersOperatorData
            });
            if (Object.keys(nextProps.numbersOperatorData).length > 0) {
                const { driverDetails } = this.state;
                driverDetails.map((driver) => {
                    if (driver.number === nextProps.numbersOperatorData.number) {
                        const opSelected = this.props.operatorTypes.filter((op) => { return op.id === nextProps.numbersOperatorData.operator_type })
                        driver.operatorType = (opSelected && opSelected.length > 0) ? opSelected[0].id : '';
                        driver.operatorName = (opSelected && opSelected.length > 0) ? opSelected[0].name : '';
                    }
                });
                this.setState({ driverDetails });
            }
        }
        console.log(nextProps.vehicleData)
        if (nextProps.vehicleData !== this.props.vehicleData) {
            const { vehicleTypes, vehicleBodyTypes } = this.props;
            const { loadingCapacity, vehicleType, bodyType, vehicleServiceType, washingStatus, noOfWashes } = this.state;

            const updatedData = {};
            const isRoadTanker = (vehicleServiceType.name || '').toLowerCase().includes('road');
            const isIsoTanker = (vehicleServiceType.name || '').toLowerCase().includes('iso');

            if (isRoadTanker || isIsoTanker) {
                updatedData.loadingCapacity = nextProps.vehicleData.loading_capacity || loadingCapacity;
                updatedData.washingStatus = nextProps.vehicleData.washing_status || washingStatus;
                updatedData.noOfWashes = nextProps.vehicleData.no_of_washes || noOfWashes;
            }

            if (nextProps.vehicleData.vehicle_type) {
                const vehicleTypeSelected = vehicleTypes.filter((item) => { return item.id === nextProps.vehicleData.vehicle_type });
                updatedData.vehicleType = (vehicleTypeSelected && vehicleTypeSelected.length > 0) ? vehicleTypeSelected[0] : vehicleType;
            }

            if (nextProps.vehicleData.body_type) {
                const bodyTypeSelected = vehicleBodyTypes.filter((item) => { return item.id === nextProps.vehicleData.body_type });
                updatedData.bodyType = (bodyTypeSelected && bodyTypeSelected.length > 0) ? bodyTypeSelected[0] : bodyType;
            }
            this.setState(updatedData);
        }
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     return (nextState.driverDetails.map((driver, index) => { driver.isTracked === nextProps.trip.driverDetails[index].isTracked }));
    // }

    onChangeDriver = () => {
        const { showChangeDriverPopUp } = this.state.showChangeDriverPopUp;
        const {driverDetails} = this.props.trip;
        if (showChangeDriverPopUp === true) {
            const driverDetails = [...this.state.driverDetails];
            driverDetails.splice(index, 1);
            this.setState({
                driverDetails
            });
        }
        this.setState({
            showChangeDriverPopUp: !this.state.showChangeDriverPopUp,
            driverDetails
        });
    }

    showVehicleDetails = () => {
        this.setState({
            showPopUp: !this.state.showPopUp
        });
    }

    showDriverDetails = () => {
        this.setState({
            showChangeDriverPopUp: !this.state.showChangeDriverPopUp
        });
    }

    setTime = (time) => {
        this.setState({
            time
        });
    };

    setOpen = ({ open }) => {
        this.setState({ open });
    };

    submitChangeDriverDetails = (e) => {
        const { date, time, vehicleNo, bodyType, vehicleType, vehicleRemarks } = this.state;
        const { trip } = this.props;

        const vehicleHistories = this.state.vehicleHistory;
        vehicleHistories.push({
            body_type: this.state.bodyType ? this.state.bodyType.name : '',
            vehicle_type: this.state.vehicleType ? this.state.vehicleType.name : '',
            vehicle_no: vehicleNo,
            remarks: vehicleRemarks,
            changed_on: Moment(date).utc().valueOf()
        });

        const dataToBeSubmitted = {
            trip_id: trip.tripId,
            vehicle_details: {
                body_type: this.state.bodyType ? this.state.bodyType.name : '',
                vehicle_type: this.state.vehicleType ? this.state.vehicleType.name : '',
                vehicle_no: vehicleNo,
                vehicle_history: vehicleHistories,
                washing_status: washingStatus,
                no_of_washes: noOfWashes,
            }
        };
        this.props.submitTransporterDetails(dataToBeSubmitted);
    };

    addGeofenceLocation() {
        this.setState({ geofenceLocations: this.state.geofenceLocations.concat([{ ...emptyGeoFenceLocation, id: uuid4() }]) }, () => {
        });
    }

    removeGeoFenceLocation = (id) => {
        const newGeofenceLocations = this.state.geofenceLocations.filter((route) => route.id !== id);
        this.setState({ geofenceLocations: newGeofenceLocations });
    }

    handleGeofenseLocationChange(value, id) {
        if (typeof value === "string") {
            const newLocs = this.state.geofenceLocations.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ geofenceLocations: newLocs });
        } else {
            const newLocs = this.state.geofenceLocations.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ geofenceLocations: newLocs });
        }
    }

    handleGeofenseLocationChangeSec(value, id) {
        if (typeof value === "string") {
            const newLocs = this.state.geofenceLocationsSec.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ geofenceLocationsSec: newLocs });
        } else {
            const newLocs = this.state.geofenceLocationsSec.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ geofenceLocationsSec: newLocs });
        }
    }

    handleNewGeofenseLocationChange(value, id) {
        if (typeof value === "string") {
            const newLocs = this.state.newgeofenceLocations.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocations: newLocs });
        } else {
            const newLocs = this.state.newgeofenceLocations.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocations: newLocs });
        }
    }

    handleNewGeofenseLocationChangeSec(value, id) {
        if (typeof value === "string") {
            const newLocs = this.state.newgeofenceLocationsSec.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocationsSec: newLocs });
        } else {
            const newLocs = this.state.newgeofenceLocationsSec.map((loc) => {
                if (loc.id !== id) {
                    return loc;
                }
                return { ...loc, "location": value };
            });
            this.setState({ newgeofenceLocationsSec: newLocs });
        }
    }

    async setGeoLocationCoordinates() {
        try {
            const { geofenceLocations, geofenceLocationsSec } = this.state;
            const fromLocation = geofenceLocations[0].location || "";
            const toLocation = geofenceLocationsSec[0].location || "";

            const from = await geocodeByAddress(fromLocation);
            const fromCoord = await getLatLng(from[0]);

            const to = await geocodeByAddress(toLocation);
            const toCoord = await getLatLng(to[0]);
            // const locData = Promise.all([]);

            const locationLatLong = [];
            locationLatLong.push({ "latitude": fromCoord.lat, "longitude": fromCoord.lng, "place": fromLocation });
            locationLatLong.push({ "latitude": toCoord.lat, "longitude": toCoord.lng, "place" : toLocation });
            this.setState({
                locationLatLong
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    onChange = (e) => {
        const reg = new RegExp('^[0-9]+$');
        const { trip } = this.props;
        const name = e.target.name;
        const value = e.target.value;
        if(
            (name == 'vehicleNo' && trip.type === 6 ) ||
            (name == 'transporterNumber' && ((value && !reg.test(value)) || value.length > 10))
        ) {
            return;
        }

        if (trip.type === 2 || trip.type === 3) {
            this.setState({
                [name]: value
            });
        }
    }

    // onNewDriverNameChange = (e) => {
    //     const newDriverDetails = {
    //         "name" : e.target.value,
    //     }
    //     this.setState({
    //         newDriverDetails
    //     });
    // }

    onNewDriverChange = (e) => {
        this.setState({
            newDriverName: e.target && e.target.name === "name" ? e.target.value : this.state.newDriverName,
            newDriverNumber: e.target && e.target.name === "number" ? e.target.value : this.state.newDriverNumber,
            newDriverOperatorType: e.id || this.state.newDriverOperatorType,
            newDriverOperatorName: e.name || this.state.newDriverOperatorName,
            newDriverOperatorFrom: this.state.newDriverOperatorFrom,
            newDriverOperatorTo: this.state.newDriverOperatorTo,
            newDriverIsTracked: false
        });
    }

    // onNewDriverOperatorType = (e) => {
    //     const newDriverDetails = {
    //         "operator_type" : e.id,
    //         "operator_name" : e.name
    //     };
    //     this.setState({
    //         newDriverDetails
    //     });
    // }

    onDriverChange = (index, e) => { 
        const { trip } = this.props;
        const { isSuperAdmin } =  this.state
        if (trip.type !== 8 || trip.type !== 9 || isSuperAdmin) {
            const reg = new RegExp('^[0-9]+$');
            if (e.target.name === 'number' && e.target.value && !reg.test(e.target.value)) {
                return;
            }
            if (e.target.name === 'number' && e.target.value.length > 10) {
                return;
            }
            if (e.target.name === 'number' && e.target.value.length == 10) {
                this.props.getNumbersOperator({number: e.target.value});
            }
            const driverDetails = [...this.state.driverDetails];
            driverDetails[index][e.target.name] = e.target.value;
            this.setState({
                driverDetails
            });
        }
    }

    selectVehicleServiceType = (vehicleServiceType) => {
        const { trip } = this.props;
        if (trip.type === 2 || trip.type === 3 || trip.type === 6) {
            const isRoadTanker = (vehicleServiceType.name || '').toLowerCase().includes('road');
            const isIsoTanker = (vehicleServiceType.name || '').toLowerCase().includes('iso');
            const data = { 
                vehicleServiceType
            };
            if (isRoadTanker) {
                data.isoNo = '';
            } else if (!isRoadTanker && !isIsoTanker) {
                data.isoNo = '';
                data.loadingCapacity = '';
            }
            this.setState(data);
        }
        this.props.selectVehicleServiceType(vehicleServiceType)
    }

    selectVehicleType = (vehicleType) => {
        const { trip } = this.props;
        if (trip.type !== 8 || trip.type !== 9) {
            this.setState({
                vehicleType
            });
        }
    }

    selectBodyType = (bodyType) => {
        const { trip } = this.props;
        if (trip.type !== 8 || trip.type !== 9) {
            this.setState({
                bodyType
            });
        }
    }

    onChangeComments = (e) => {
        this.setState({ transporterComments : e.target.value });
    }

    onChangeVehicleDetails = (field, value) => {
        const { trip, vehicleTypes, vehicleBodyTypes } = this.props;
        const data = {seeker_id: trip.seekerId};
        let callApi = false;
        value = value.toUpperCase();
        if (field === 'vehicleNo') {
            if ((value.match(/^\w+$/) || value === '') && value.length <= 11) {
                callApi = value.length >= 7;
            } else {
                return;
            }
            data.vehicle_no = value || '';
        } else {
            callApi = true;
            data.iso_no = value || '';
        }
        if(callApi) {
            this.props.onChangeVehicleDetails(data);
        }
        this.setState({
            [field]: value,
            error: false
        });
    }

    selectOperatorType = (index, operatorType) => {
        const { trip } = this.props;
        const { isSuperAdmin } = this.state;
        if (trip.type !== 8 || trip.type !== 9 || isSuperAdmin ) {
            const driverDetails = [...this.state.driverDetails];
            driverDetails[index].operatorType = operatorType.id;
            driverDetails[index].operatorName = operatorType.name;
            this.setState({
                driverDetails,
                error: false
            });
        }
    }

    selectGPSVendor = (gpsVendor) => {
        // const driverDetails = [...this.state.driverDetails];
        // driverDetails[index].gpsVendor = gpsVendor.id;
        const {trip} = this.props;
        const { isSuperAdmin, isSuperAdminSeeker, isSuperAdminOthers } = this.state;
        if(trip.type !== 8 || trip.type !== 9 || isSuperAdmin || isSuperAdminSeeker || isSuperAdminOthers){
            const gpsVendors = {
                id: gpsVendor.id,
                name: gpsVendor.name
            }
           return this.setState({
                gpsVendors
           });
        } 
    }

    addNewDriver = () => {
        const driverDetails = [...this.state.driverDetails];
        driverDetails.push({
            firstName: "",
            lastName: "",
            name: "",
            number: "",
            operatorType: 0,
            operatorName: "",
            isTracked: false,
            to: { place: '', latitude: 0, longitude: 0},
            from: { place: '', latitude: 0, longitude: 0 },
        });
        this.setState({
            driverDetails
        })
    }

    removeDriver = (index) => {
        const driverDetails = [...this.state.driverDetails];
        driverDetails.splice(index, 1);
        this.setState({
            driverDetails
        })
    }

    changeDriverPreference = (preferenceIndex) => {
        const {trip} = this.props;
        if(trip.type === 6 || trip.type === 7 || trip.type === 4) {
            //     const newState = {...this.state};
            //     const driverDetails = newState.driverDetails;
            //     driverDetails.map(driver => ({ ...driver, selected: false}));
            //     // driverDetails[preferenceIndex].selected = true;
            //     for(var i in driverDetails) {
            //         if(i == preferenceIndex) {
            //             driverDetails[i].selected = true;
            //         } else {
            //             driverDetails[i].selected = false;
            //         }
            //     }
            //     this.setState({
            //         driverDetails,
            //         isEnabledAddDriver: false
            //     });
            const { driverDetails } = this.state;
            driverDetails.map((driver, index) => {
                if(index === +preferenceIndex) {
                    driver.isTracked = true;
                } else {
                    driver.isTracked = false
                }
            })
            this.setState({ driverDetails, isEnabledAddDriver: false });
        }
    }

    addDriverPreference = () => {
        const newState = {...this.state};
        const driverDetails = newState.driverDetails;
        for(var i in driverDetails) {
            driverDetails[i].isTracked = false;
        }
        this.setState({
            driverDetails,
            isEnabledAddDriver: true
        });
    }

    changePreference = (preferenceIndex) => {
        const { trip } = this.props;
        const { isSuperAdmin, isSuperAdminSeeker, isSuperAdminOthers } = this.state;

        if (trip.type === 2 || trip.type === 3 || isSuperAdmin || isSuperAdminSeeker || isSuperAdminOthers ) {
            const newState = { ...this.state };
            const trackingPreferences = newState.trackingPreferences;
            // const currentTrackingPreference = trackingPreferences[preferenceIndex];
            // currentTrackingPreference.enabled = !currentTrackingPreference.enabled;
            // trackingPreferences.forEach((preference)=> {
            //     if(currentTrackingPreference.tracking_type === 5 && currentTrackingPreference.enabled && preference.tracking_type !== 5) {
            //         preference.enabled = false;
            //     } else if(currentTrackingPreference.tracking_type !== 5 && currentTrackingPreference.enabled && preference.tracking_type == 5) {
            //         preference.enabled = false;
            //     }
            // })

            for (var i in trackingPreferences) {
                if (i == preferenceIndex) {
                    trackingPreferences[i].enabled = true;
                } else {
                    trackingPreferences[i].enabled = false;
                }
            }

            newState.saved = false;
            this.setState(newState, () => { this.props.sendPreferenceToBanner(this.state.trackingPreferences) });
        }
    }

    validateRequiredFields = () => {
        const { vehicleServiceType, vehicleNo, washingStatus, isoNo, loadingCapacity, noOfWashes } = this.state;
        const isIsoTanker = (this.state.vehicleServiceType && this.state.vehicleServiceType.name || '').toLowerCase().includes('iso');
        const isRoadTanker = (this.state.vehicleServiceType && this.state.vehicleServiceType.name || '').toLowerCase().includes('road');
        if(!(vehicleServiceType && vehicleNo && ((isRoadTanker || isIsoTanker) ? (washingStatus && loadingCapacity) : true) && (isIsoTanker ? isoNo : true)  && ((isRoadTanker || isIsoTanker) && washingStatus === 'Sealed' ? noOfWashes : true))){
            this.setState({error: true})
            return;
        } 
        else if (this.state.driverDetails.length) {
            for (var i in this.state.driverDetails) {
                if (this.state.driverDetails[i].number && this.state.driverDetails[i].number.length < 10) {
                    this.setState({ error: true });
                    return;
                } else if (this.state.driverDetails[i].number && this.state.trackingPreferences[1].enabled && !this.state.driverDetails[i].operatorType) {
                    this.setState({ error: true });
                    return;
                }
            }
            return true;
        }
        
    }

    submitTransporterDetails = async () => {
        const { throwError, providerMode, isSeeker, operatorTypes } = this.props;
        await this.setGeoLocationCoordinates();
        try {
            const { trip } = this.props;
            if (this.validateRequiredFields()) {
                const dataToBeSubmitted = {
                    trip_id: trip.tripId,
                    gps_details: this.state.trackingPreferences[2].enabled ? this.state.gpsVendors : undefined,
                    tracking_preference: this.state.trackingPreferences,
                    transporter_comments: this.state.transporterComments,
                    driver_details: (this.state.driverDetails || []).map((driverDetails) => {
                        const opType = driverDetails.operatorType || this.state.trackingPreferences[1].enabled ? driverDetails.operatorType : 0;
                        const opTypeObj = operatorTypes.filter((op) => op.id === opType);
                        const opName = driverDetails.operatorName || (opTypeObj && opTypeObj.length > 0 ? opTypeObj[0].name : '');

                        return {
                            name: driverDetails.name,
                            first_name: driverDetails.firstName,
                            last_name: driverDetails.lastName,
                            operator_type: opType,
                            operator_name: opName,
                            number: driverDetails.number,
                            from: driverDetails.from,
                            to: driverDetails.to,
                            is_tracked: driverDetails.isTracked || false,
                        }
                    })
                }

                dataToBeSubmitted.is_double_driver = this.state.selectedCK || false;

                if (!providerMode) {
                    dataToBeSubmitted.vehicle_details = {
                        vehicle_no: this.state.vehicleNo,
                        service_type: this.state.vehicleServiceType ? this.state.vehicleServiceType.name : '',
                        iso_no: this.state.isoNo || '',
                        loading_capacity: parseInt(this.state.loadingCapacity || 0),
                        vehicle_type: this.state.vehicleType ? this.state.vehicleType.name : '',
                        body_type: this.state.bodyType ? this.state.bodyType.name : '',
                        washing_status: this.state.washingStatus ? this.state.washingStatus : '',
                        no_of_washes: this.state.noOfWashes ? this.state.noOfWashes : 0
                    };
                    if (isSeeker) {
                        dataToBeSubmitted.transporter_details = {
                            name: this.state.transporterName,
                            email: this.state.transporterEmail,
                            number: this.state.transporterNumber,
                            pan_no: this.state.transporterPan,
                            provider_id: this.state.transporterId
                        };
                    }
                }
                // if(this.state.trackingPreferences[2].enabled && trip.type === 3) {
                //     dataToBeSubmitted.gps_vendor = this.state.gpsVendors || {};
                // }
                this.props.submitTransporterDetails(dataToBeSubmitted);
            }
        } catch (e) {
            console.log(e);
            throwError({
                message: 'Please fill out the all the fields correctly'
            })
        }
    }

    fetchLocations = (value, name, index)=> {
        const {driverDetails} = this.state;

        driverDetails[index][name]['place'] = value;
        if (value.length <= 0) {
            driverDetails[index][name]['latitude'] = 0;
            driverDetails[index][name]['longitude'] = 0;
        }
        this.setState({
            driverDetails
        });
    }

    handleLocationSelect = (address, name, index)=> {
        const {driverDetails} = this.state;

        driverDetails[index][name]['place'] = address;
        this.setState({
            driverDetails
        })
        geocodeByAddress(address)
          .then(results => {
              this.setState({
                geoCodedAddress: results[0]
              }, () => {
                this.getLatLongForLocation(driverDetails[index], index);
              });
        });
    }

    getLatLongForLocation = async (data, index) => {
        await this.setLocationCoordinates(data, index);
    }

    async setLocationCoordinates(driverData, index) {
        try {
            const { driverDetails } = this.state;
            const item = driverDetails[index];

            if (item.from && item.from.place) {
                const fromLocation = item.from.place || "";
                const from = await geocodeByAddress(fromLocation);
                const fromCoord = await getLatLng(from[0]);

                driverDetails[index].from['latitude'] = fromCoord.lat;
                driverDetails[index].from['longitude'] = fromCoord.lng;
                driverDetails[index].from['place'] = fromLocation;
            }

            if (item.to && item.to.place) {
                const toLocation = item.to.place || "";
                const to = await geocodeByAddress(toLocation);
                const toCoord = await getLatLng(to[0]);

                driverDetails[index].to['latitude'] = toCoord.lat;
                driverDetails[index].to['longitude'] = toCoord.lng;
                driverDetails[index].to['place'] = toLocation;
            }
            this.setState({
                // locationLatLong,
                driverDetails
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    render() { 
    // console.log(JSON.parse(getCookie("user-type")) +"TD")
        const {
            isDataSubmitting, providers, providerMode, isSeeker, gpsVendorType, trip,
            vehicleServiceTypes, vehicleTypes, vehicleBodyTypes, operatorTypes, vehicleData
        } = this.props;
        const { driverDetails, isSuperAdmin, isSuperAdminSeeker, isSuperAdminOthers } = this.state;
        const enableEdit = (userCanEdit && [1, 2, 3, 4, 5, 6, 7].indexOf(trip.type) > -1 )|| isSuperAdmin || isSuperAdminSeeker || isSuperAdminOthers; 
        const enableChangeButton = (userCanEdit && [1, 2, 3, 4, 5, 6, 7].indexOf(trip.type) > -1) || isSuperAdmin || isSuperAdminSeeker || isSuperAdminOthers;
        const isIsoTanker = (this.state.vehicleServiceType && this.state.vehicleServiceType.name || '').toLowerCase().includes('iso');
        const isRoadTanker = (this.state.vehicleServiceType && this.state.vehicleServiceType.name || '').toLowerCase().includes('road');
        return (
            <div className={classNames({
                'disabled': !enableEdit
            })}>
                {isDataSubmitting && <ActionLoader fixed={true} />}
                <header className="fs-18 theme-color fBolder mb-30">Transporter Details</header>
                {!providerMode && <header className="fs-14 theme-color fBolder mb-20">Vehicle Details</header>}
                {
                    !providerMode &&
                    <Fragment>
                        <div className={`${!isSeeker ? 'box-shadow pl-15 pr-15 pt-15 pb-15 br-5' : ''}`}>
                        <div className='flex flex-wrap flex-middle mb-20 ml-10'>
                            {
                                vehicleServiceTypes && vehicleServiceTypes.length > 0 &&
                                <div className="mb-20 wt-200 mr-20">
                                    <div className="fs-12 communication-color fBold mb-5">Vehicle Service Type: <span className={'error-color fs-12'}>*</span></div>
                                    <Dropdown
                                        value={this.state.vehicleServiceType || trip.vehicleDetails.vehicleServiceType}
                                        onSelect={this.selectVehicleServiceType}
                                        items={enableEdit ? vehicleServiceTypes : vehicleServiceTypes.filter((type) => type.name === (this.state.vehicleServiceType || trip.vehicleDetails.vehicleServiceType))}
                                        label="Select Service type"
                                        selectedClassName={'col-1'}
                                    />
                                </div>
                            }
                            <div className="mb-20 wt-200 mr-20">
                            <div className="fs-12 communication-color fBold mb-5">Vehicle No.: <span className={'error-color fs-12'}>*</span></div>
                                <Input
                                    value={this.state.vehicleNo}
                                    name="vehicleNo"
                                    // placeholder="Vehicle No."
                                    selectedClassName={'col-1'}
                                    onChange={(e) => this.onChangeVehicleDetails('vehicleNo', e.target.value)}
                                />
                            </div>
                            <div className="mb-20 wt-300 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Vehicle Type: </div>
                                <Dropdown
                                    value={this.state.vehicleType || trip.vehicleDetails.vehicleType}
                                    onSelect={this.selectVehicleType}
                                    items={enableEdit ? vehicleTypes : vehicleTypes.filter((type) => type.name === (this.state.vehicleType || trip.vehicleDetails.vehicleType))}
                                    label="Select Vehicle type"
                                    selectedClassName={'col-1'}
                                />
                            </div>
                            <div className="mb-20 wt-250 mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Body Type: </div>
                                <Dropdown
                                    value={this.state.bodyType}
                                    onSelect={this.selectBodyType}
                                    items={enableEdit ? vehicleBodyTypes : vehicleBodyTypes.filter((type) => type.name === this.state.bodyType)}
                                    label="Select Body type"
                                    selectedClassName={'col-1'}
                                />
                            </div>
                        </div>

                        <div className='flex flex-wrap flex-middle mb-40 ml-10'>
                            {
                                (isRoadTanker || isIsoTanker) && GALAXY.every((id) => { return userDetails.seeker_ids.indexOf(id) !== -1}) && 
                                <Fragment>
                                    <div className="mb-20 wt-200 mr-20">
                                        <div className="fs-12 communication-color fBold mb-5">
                                                Washing Status<span className={'error-color fs-12'}>*</span>
                                        </div>
                                        <div className="dropdown-container ht-40 pr flex-1 mr-20">
                                            <Dropdown
                                                onSelect={(value)=>this.setState({washingStatus: value.value})}
                                                customClass="mr-20"
                                                items={(['Sealed','Washed']).map((el)=> { return { value: el, name: el }})}
                                                value={{name: this.state.washingStatus, value: this.state.washingStatus}}
                                                // value={this.state.washingStatus}
                                                label={'Select washing type'}
                                                selectedClassName={'col-1'}
                                                // searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {
                                GALAXY.every((id) => { return userDetails.seeker_ids.indexOf(id) !== -1}) && (isRoadTanker || isIsoTanker) && (this.state.washingStatus.name === 'Sealed' || this.state.washingStatus === "Sealed") &&
                                <Fragment>
                                    <div className="mb-20 wt-200 mr-20">
                                        <div className="fs-12 communication-color fBold mb-5">
                                                No. of Trips Completed<span className={'error-color fs-12'}>*</span>
                                        </div>
                                        <div className="dropdown-container ht-40 pr flex-1 mr-20">
                                            <Dropdown
                                                onSelect={(value)=>this.setState({noOfWashes: value.value})}
                                                customClass="mr-20"
                                                items={([1,2,3,4,5]).map((el)=> { return { value: el, name: el }})}
                                                value={{name: this.state.noOfWashes, value: this.state.noOfWashes}}
                                                label={'— Trips completed — '}
                                                selectedClassName={'col-1'}
                                                // searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {
                                isIsoTanker &&
                                <div className="mb-20 wt-150 mr-20">
                                    <div className="fs-12 communication-color fBold mb-5">ISO No.: <span className={'error-color fs-12'}>*</span></div>
                                    <Input
                                        value={this.state.isoNo}
                                        name="isoNo"
                                        // placeholder="ISO No."
                                        selectedClassName={'col-1'}
                                        onChange={(e) => this.onChangeVehicleDetails('isoNo', e.target.value)}
                                    />
                                </div>
                            }
                            {
                                (isIsoTanker || isRoadTanker) &&
                                <div className="mb-20 wt-200 mr-20">
                                    <div className="fs-12 communication-color fBold mb-5">Water Loading Capacity (litres): <span className={'error-color fs-12'}>*</span></div>
                                    <Input
                                        type="number"
                                        value={this.state.loadingCapacity || ''}
                                        onChange={this.onChange}
                                        name="loadingCapacity"
                                        // placeholder="Water Loading Capacity (litres)"
                                        selectedClassName={'col-1'}
                                    />
                                </div>
                            }
                        </div>
                        </div>
                    </Fragment>
                }
                

                {
                    !providerMode && enableChangeButton &&
                    <div className={`flex flex-wrap flex-between mb-40 ml-10 ${!isSeeker ? 'box-shadow pl-15 pr-15 pt-15 pb-15 br-5' : ''}`}>
                        <div className="mb-20 wt-300 mr-20">
                            <Button value='Change Vehicle' onClick={this.onChangeVehicle} className="change-vehicle-button" />
                            {
                                this.state.showPopUp &&
                                <ChangeVehicle
                                    selectVehicleServiceType={this.props.selectVehicleServiceType}
                                    onChangeVehicleDetails={this.props.onChangeVehicleDetails}
                                    vehicleServiceTypes={this.props.vehicleServiceTypes}
                                    vehicleTypes={this.props.vehicleTypes}
                                    vehicleBodyTypes={this.props.vehicleBodyTypes}
                                    vehicleData={this.props.vehicleData}
                                    onChangeVehicle={this.onChangeVehicle}
                                    submitTransporterDetails={this.props.submitTransporterDetails}
                                    vehicleHistory={trip.vehicleDetails.vehicleHistory}
                                    tripId={trip.tripId}
                                    trip={trip}
                                    oldVehicleNo={this.state.vehicleNo}
                                    oldVehicleServiceType={this.state.vehicleServiceType}
                                    oldIsoNo={this.state.isoNo}
                                    oldLoadingCapacity={this.state.loadingCapacity}
                                    oldVehicleType={this.state.vehicleType}
                                    oldBodyType={this.state.bodyType}
                                    oldWashingType={this.state.washingStatus}
                                    oldWashingCount={this.state.noOfWashes}
                                    getVehicleRemarks={this.props.getVehicleRemarks}
                                    vehicleRemarks={this.props.vehicleRemarks}
                                />
                            }
                        </div>
                    </div>
                }

                {GALAXY.every((id) => { return userDetails.seeker_ids.indexOf(id) !== -1}) && 
                    <Fragment>
                        <section className="pl-15 mb-20 flex mt-40">
                        <div className="fs-18 theme-color fBolder" ref={(e) => { this.transporterComments = e; }}>
                            Comments/Additional Information
                            {/* <span className="error-color">*</span>
                            {this.state.error && this.state.errorMessage && this.state.errorField == 'comments' &&
                            <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>} */}
                        </div>
                        </section>
                        <section className="mb-30 box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                                <div>
                                    <textarea className={'col-1 br-5 pb-5 pt-5 fs-14'} value={this.state.transporterComments} onChange={this.onChangeComments}/>
                                </div>
                        </section>
                    </Fragment>
                }

                {trip.vehicleDetails && trip.vehicleDetails.vehicleHistory && trip.vehicleDetails.vehicleHistory.length > 0 &&
                <Fragment>
                    <header className="fs-14 theme-color fBolder mb-20">Vehicle History</header>
                        <div className="border-theme pl-10 pr-10 pt-10 pb-10 mb-20">
                            <div className="flex flex-wrap flex-between mb-40 bb-light">
                                <div className="mb-20 fBolder wt-150 mr-20">
                                    Vehicle Number
                                </div>
                                {
                                    vehicleServiceTypes && vehicleServiceTypes.length > 0 &&
                                    <Fragment>
                                        <div className="mb-20 fBolder wt-150 mr-20">
                                            Vehicle Service Type
                                        </div>
                                        <div className="mb-20 fBolder wt-150 mr-20">
                                            ISO No.
                                        </div>
                                        <div className="mb-20 fBolder wt-150 mr-20">
                                            Water Loading Capacity (litres)
                                        </div>
                                    </Fragment>
                                }
                                <div className="mb-20 fBolder wt-150 mr-20">
                                    Vehicle Type
                                </div>
                                <div className="mb-20 fBolder wt-150 mr-20">
                                    Body Type
                                </div>
                                <div className="mb-20 fBolder wt-150 mr-20">
                                    Remarks
                                </div>
                                <div className="mb-20 fBolder wt-150 mr-20">
                                    Changed on
                                </div>
                            </div>
                            {trip.vehicleDetails.vehicleHistory.map((vehicleHistory, index) => {
                                return (
                                    <div key={index} className="flex flex-wrap flex-between mb-40">
                                        <div className="mb-20 fs-12 wt-150 mr-20">
                                            {vehicleHistory.vehicle_no}
                                        </div>
                                        {
                                            vehicleServiceTypes && vehicleServiceTypes.length > 0 &&
                                            <Fragment>
                                                <div className="mb-20 fs-12 wt-150 mr-20">
                                                    {vehicleHistory.service_type}
                                                </div>
                                                <div className="mb-20 fs-12 wt-150 mr-20">
                                                    {vehicleHistory.iso_no}
                                                </div>
                                                <div className="mb-20 fs-12 wt-150 mr-20">
                                                    {vehicleHistory.loading_capacity}
                                                </div>
                                            </Fragment>
                                        }
                                        <div className="mb-20 fs-12 wt-150 mr-20">
                                            {vehicleHistory.vehicle_type}
                                        </div>
                                        <div className="mb-20 fs-12 wt-150 mr-20">
                                            {vehicleHistory.body_type}
                                        </div>
                                        <div className="mb-20 fs-12 wt-150 mr-20">
                                            {vehicleHistory.remarks}
                                        </div>
                                        <div className="mb-20 fs-12 wt-150 mr-20">
                                            {Moment(vehicleHistory.changed_on).format('DD MMM YYYY, h:mm a')}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                </Fragment>
                }

                {!providerMode && isSeeker && <header className="fs-14 theme-color fBolder mb-20">Transporter Details</header>}
                {!providerMode && isSeeker && <div className="flex flex-wrap flex-between mb-40">
                    <div className="mb-20 wt-300 mr-20">
                        {/* <Input value={this.state.transporterName} onChange={this.onChange} name="transporterName" placeholder="Transporter Name" /> */}
                        <SearchDropdown
                            onChange={(value) => {
                                this.props.getProviders(value);
                                if (enableEdit) {
                                    this.setState({
                                        transporterName: value
                                    })
                                }
                            }}
                            onSelect={(provider) => {
                                if (enableEdit) {
                                    this.setState({
                                        transporterName: provider.name,
                                        transporterEmail: provider.email,
                                        transporterPan: provider.panNo,
                                        transporterId: provider.id
                                    })
                                }
                            }}
                            label={'Search Provider'}
                            items={providers || []}
                            value={this.state.transporterName}
                        />
                    </div>
                    <div className="mb-20 wt-300 mr-20">
                        <Input type="email" value={this.state.transporterEmail} onChange={this.onChange} name="transporterEmail" placeholder="Transporter Email" />
                    </div>
                    <div className="mb-20 wt-300 mr-20">
                        <Input type="number" value={this.state.transporterNumber} onChange={this.onChange} name="transporterNumber" placeholder="Transporter Number" />
                        {this.state.transporterNumber && this.state.transporterNumber.length < 10 && <span className={'fs-12 error-color'}>{'Invalid Phone Number'}</span>}
                    </div>
                    <div className="mb-20 wt-300 mr-20">
                        <Input value={this.state.transporterPan} onChange={this.onChange} name="transporterPan" placeholder="Transporter PAN" />
                    </div>
                </div>}
                <section className="pl-15 mb-20 flex mt-40">
                    <div className="fs-18 theme-color fBolder">
                        Tracking preference
                    </div>
                </section>
                <section className="pl-15 mb-30 flex flex-between ml-10 add-trip-tracking-preference box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                    {
                        this.state.trackingPreferences.map((preference, index) => {
                            return (
                                <Checkbox 
                                    onCheck={this.changePreference.bind(this, index)} 
                                    id={index} 
                                    key={index} 
                                    className="mr-70" 
                                    checked={preference.enabled} 
                                    label={preference.tracking_name} 
                                />
                            )
                        })
                    }
                </section>
                <section>
                    {
                        (this.state.trackingPreferences[2].enabled) &&
                        <div className="mb-20 wt-300">
                            <header className="fs-12 theme-color fBolder mb-20">GPS Vendor: </header>
                            <Dropdown 
                                value={this.state.gpsVendors || gpsVendorType[0]} 
                                onSelect={this.selectGPSVendor.bind(this)} 
                                onChange={this.selectGPSVendor.bind(this)} 
                                items={gpsVendorType} 
                                label="Select Vendor type" 
                                selectedClassName={'col-1 '} 
                            />
                        </div>
                    }
                </section>

                <header className="fs-14 theme-color fBolder mb-20">
                    Driver Details
                </header>
                <div className="fs-14 fBolder mb-10"> Is Double Driver? <span className={'error-color fs-12'}>*</span> </div>
                {
                    enableEdit ?
                    <div className="flex mb-15">
                        <Checkbox className="mr-20" label={'Yes'} onCheck={() => this.setState({ selectedCK: true })} checked={this.state.selectedCK===true} />
                        <Checkbox className="mr-20" label={'No'} onCheck={() => this.setState({ selectedCK: false })} checked={this.state.selectedCK===false} />
                    </div>
                    :
                    <div className="flex mb-15">
                        <Checkbox className="mr-20" label={'Yes'} checked={trip.isDoubleDriver===true} />
                        <Checkbox className="mr-20" label={'No'} checked={trip.isDoubleDriver===false} />
                    </div>
                }
                {
                    driverDetails.map((driver, index) => {
                        const trackingPreferenceObj = this.state.trackingPreferences.filter((selectedTrackingPreference) => {
                            return (selectedTrackingPreference.tracking_type === 2 && selectedTrackingPreference.enabled === true)
                        })[0];
                        const trackingPreference = trackingPreferenceObj && trackingPreferenceObj.tracking_type ? trackingPreferenceObj.tracking_type : '';
                        return (
                            <div className={`border-theme border-theme-tracked-${driver.isTracked} pl-15 pr-15 pt-15 pb-15 br-10 mb-30 br-7 pr`}>
                            <div className={`flex flex-wrap`}>
                                {
                                    enableEdit &&
                                    <span
                                        className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--13 r--13"
                                        onClick={this.removeDriver.bind(this, index)}
                                    >
                                        <CloseIcon />
                                    </span>
                                }
                                <div className="mb-20 mr-20 wt-150">
                                    <div className="fs-12 communication-color fBold mb-5">
                                        First Name
                                    </div>
                                    <Input value={driver.firstName} onChange={this.onDriverChange.bind(this, index)} name="firstName" placeholder="" />
                                </div>
                                <div className="mb-20 mr-20 wt-150">
                                    <div className="fs-12 communication-color fBold mb-5">
                                        Last Name
                                    </div>
                                    <Input value={driver.lastName} onChange={this.onDriverChange.bind(this, index)} name="lastName" placeholder="" />
                                </div>
                                <div className="mb-20 mr-20 wt-150">
                                    <div className="flex fs-12 communication-color fBold mb-5">
                                        Driver No.
                                        {
                                            trackingPreference &&
                                            <div
                                                className={
                                                    classNames('fs-10 fBolder ml-5', {
                                                        'green-color': driver.consentStatus == 'allowed',
                                                        'orange-color': driver.consentStatus == 'pending',
                                                        'error-color': driver.consentStatus == 'not_added',
                                                        'consent-error-color': driver.consentStatus == 'consent_check_error'
                                                    })
                                                }
                                                style={{textTransform: 'uppercase'}}
                                            >
                                                {
                                                    driver.consentStatus && driver.consentStatus.toLowerCase() != 'n/a' && +trackingPreference === 2 ? `(${driver.consentStatus})` : +trackingPreference === 3 && driver.gpsVendor ?  gpsVendors.filter(gps => gps.id === driver.gpsVendor)[0].name : ''
                                                }
                                            </div>
                                        }
                                    </div>
                                    <Input
                                        type="number"
                                        value={driver.number}
                                        onChange={this.onDriverChange.bind(this, index)}
                                        name="number"
                                        placeholder=""
                                        pattern="[1-9]{1}[0-9]{9}"
                                    />
                                    {
                                        driver.number && driver.number.length < 10 &&
                                        <span className={'fs-12 error-color'}>{'Invalid Phone Number'}</span>
                                    }
                                </div>
                                <div className="mb-20 wt-200">
                                    <div className="fs-12 communication-color fBold mb-5">
                                        Operator Type:
                                        {driver.number && driver.number.length > 0 && this.state.trackingPreferences.filter((selectedTrackingPreference) => { return (selectedTrackingPreference.tracking_type === 2 && selectedTrackingPreference.enabled === true) })[0]
                                            && <span className={'error-color'}>*</span>}
                                        {
                                            driver.userResponse &&
                                            <React.Fragment>
                                                <a data-tip data-for={`ivr-info-${index}`} className={''}>
                                                    <EsclamationIcon style={{ width: '15px', height: '11px' }}/>
                                                </a>
                                                <ReactTooltip id={`ivr-info-${index}`} type="info" effect="" border={true}>
                                                    {
                                                        driver.ivrNumber &&
                                                        <div className="fs-12 fBolder">
                                                            IVR Number: {driver.ivrNumber}
                                                        </div>
                                                    }
                                                    <div className="fs-12 fBolder">
                                                        IVR/SMS Response: {driver.userResponse}
                                                    </div>
                                                    <div className="fs-12 mb-5 fBolder">
                                                        IVR Time: {driver.responseTime}
                                                    </div>
                                                </ReactTooltip>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <Dropdown value={operatorTypes.filter(operator => operator.id === driver.operatorType)[0] || {}}
                                              onSelect={this.selectOperatorType.bind(this, index)}
                                              items={enableEdit ? operatorTypes: []}
                                              label="Select Operator type"
                                              selectedClassName={'col-1 mt-5'}
                                    />
                                    {/* <Dropdown value={operatorTypes.map(ot=> ot.name) || {}}
                                              onSelect={this.selectOperatorType.bind(this, index)}
                                              items={enableEdit ? operatorTypes: []}
                                              label="Select Operator type"
                                              selectedClassName={'col-1 mt-5'}
                                    /> */}
                                </div>
                                <div className="mb-20 flex flex-wrap">
                                    <div className="fs-12 communication-color fBold mb-5">
                                        <div className="mb-5">From Location</div>
                                        {
                                            // <SearchDropdown
                                            //     hideSearch={true}
                                            //     label={'From Location'}
                                            //     items={[]}
                                            //     value={''}
                                            //     onChange={() => false}
                                            //     selectedClassName={"route-detail-search"}
                                            //     containerClassName={'mt-10'}
                                            // />
                                        }

                                        {
                                            // this.state.geofenceLocationsFrom.map((loc) => {
                                            //     return
                                                <div className="mb-5 pr">
                                                    <PlacesAutocomplete
                                                        key={index}
                                                        onChange={(value) => {
                                                            if (!enableEdit) return;
                                                            this.fetchLocations(value, 'from', index)
                                                        }}
                                                        onSelect={(value) => {
                                                            if (!enableEdit) return;
                                                            this.handleLocationSelect(value, 'from', index)
                                                        }}
                                                        searchOptions={{type:['cities']}}
                                                        value={driver.from && driver.from.place ? driver.from.place : ''}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div className="dropdown-container">
                                                            <div className="dropdown-container 2 ht-40 pr">
                                                                <input
                                                                 {...getInputProps({
                                                                    placeholder: 'From Location',
                                                                    className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-200',
                                                                })} />
                                                            </div>
                                                            <div className="row-100">
                                                                 <ul className="dropdown-items flex flex-column" style={{'width': `600px`}}>
                                                                {
                                                                    enableEdit && suggestions.map((item, i) => {
                                                                        const className = "dropdown-item pl-16 ht-40";
                                                                        return (
                                                                            <li
                                                                                {...getSuggestionItemProps(item, {
                                                                                    className,
                                                                                })}
                                                                            >
                                                                                <abbr title={item.description}>
                                                                                    {item.description.substr(0, 75)}
                                                                                    {item.description.length > 75?'...':''}
                                                                                </abbr>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                            // })
                                        }
                                    </div>

                                    <div className="fs-12 communication-color ml-20 fBold mb-5">
                                        <div className="mb-5">To Location</div>
                                        {
                                            // <SearchDropdown
                                            //     hideSearch={true}
                                            //     label={'To Location'}
                                            //     items={[]}
                                            //     value={''}
                                            //     onChange={() => false}
                                            //     selectedClassName={"route-detail-search"}
                                            //     containerClassName={'mt-10'}
                                            // />
                                        }

                                        {
                                            // this.state.geofenceLocationsTo.map((loc) => {
                                            //     return
                                                <div className="mb-5 pr">
                                                    <PlacesAutocomplete
                                                        key={index}
                                                        onChange={(value) => {
                                                            if (!enableEdit) return;
                                                            this.fetchLocations(value, 'to', index)
                                                        }}
                                                        onSelect={(value) => {
                                                            if (!enableEdit) return;
                                                            this.handleLocationSelect(value, 'to', index)
                                                        }}
                                                        searchOptions={{type:['cities']}}
                                                        value={driver.to && driver.to.place ? driver.to.place : ''}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div className="dropdown-container">
                                                            <div className="dropdown-container 2 ht-40 pr">
                                                                <input
                                                                 {...getInputProps({
                                                                    placeholder: 'To Location',
                                                                    className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-200',
                                                                })} />
                                                            </div>
                                                            <div className="row-100">
                                                                 <ul className="dropdown-items flex flex-column" style={{'width': `600px`}}>
                                                                {
                                                                    enableEdit && suggestions.map((item, i) => {
                                                                        const className = "dropdown-item pl-16 ht-40";
                                                                        return (
                                                                            <li
                                                                                {...getSuggestionItemProps(item, {
                                                                                    className,
                                                                                })}
                                                                            >
                                                                                <abbr title={item.description}>
                                                                                    {item.description.substr(0, 75)}
                                                                                    {item.description.length > 75?'...':''}
                                                                                </abbr>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                            // })
                                        }
                                    </div>
                                </div>
                                {/* <div className="mb-20">

                                    {
                                        this.state.geofenceLocations.map((loc, index) => {
                                            return <div className="mb-5 pl-20" key={index}>
                                                <span className={classNames("location-side-marker", {
                                                    'last-in-geofence': index === this.state.geofenceLocations.length - 1
                                                })}>
                                                    <span className="blue"> </span>
                                                </span>
                                                <span className={classNames("remove-btn-row", {
                                                    'first-in-geofence': index === 0
                                                })}>
                                                    <span className="location-side-marker right ">
                                                    </span>

                                                </span>

                                                <PlacesAutocomplete
                                                    key={index}
                                                    value={loc.location}
                                                    // onChange={(value) => { this.handleGeofenseLocationChange(value, loc.id) }}
                                                    // onSelect={(value) => { this.handleGeofenseLocationChange(value, loc.id) }}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div className="dropdown-container">
                                                            <div className="dropdown-container 2 ht-40 pr">
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'From location',
                                                                        className: 'selected-item row-100 pl-40 pr-16  route-detail-search wt-400',
                                                                    })} />
                                                            </div>
                                                            <div className="row-100">
                                                                <ul className="dropdown-items flex flex-column" style={{ 'width': `600px` }}>
                                                                    {
                                                                        suggestions.map((item, i) => {
                                                                            const className = "dropdown-item pl-16 ht-40";
                                                                            return (
                                                                                <li
                                                                                    {...getSuggestionItemProps(item, {
                                                                                        className,
                                                                                    })}
                                                                                >
                                                                                    <abbr title={item.description}>
                                                                                        {item.description.substr(0, 75)}
                                                                                        {item.description.length > 75 ? '...' : ''}
                                                                                    </abbr>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </div>
                                        })
                                    }


                                    {
                                        this.state.geofenceLocationsSec.map((loc, index) => {
                                            return <div className="mb-5 pl-20" key={index}>
                                                <span className={classNames("location-side-marker", {
                                                    'last-in-geofence': index === this.state.geofenceLocationsSec.length - 1
                                                })}>
                                                    <span className="blue"> </span>
                                                </span>
                                                <span className={classNames("remove-btn-row", {
                                                    'first-in-geofence': index === 0
                                                })}>
                                                    <span className="location-side-marker right ">
                                                    </span>
                                                </span>

                                                <PlacesAutocomplete
                                                    key={index+index}
                                                    value={'haldia'}
                                                    // onChange={(value) => { this.handleGeofenseLocationChangeSec(value, loc.id) }}
                                                    // onSelect={(value) => { this.handleGeofenseLocationChangeSec(value, loc.id) }}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div className="dropdown-container">
                                                            <div className="dropdown-container 2 ht-40 pr">
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'To location',
                                                                        className: 'selected-item row-100 pl-40 pr-16  route-detail-search wt-400',
                                                                    })} />
                                                            </div>
                                                            <div className="row-100">
                                                                <ul className="dropdown-items flex flex-column" style={{ 'width': `600px` }}>
                                                                    {
                                                                        suggestions.map((item, i) => {
                                                                            const className = "dropdown-item pl-16 ht-40";
                                                                            return (
                                                                                <li
                                                                                    {...getSuggestionItemProps(item, {
                                                                                        className,
                                                                                    })}
                                                                                >
                                                                                    <abbr title={item.description}>
                                                                                        {item.description.substr(0, 75)}
                                                                                        {item.description.length > 75 ? '...' : ''}
                                                                                    </abbr>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </div>
                                        })
                                    }
                                </div> */}
                            </div>
                            <div>
                                {
                                    index === this.state.isTrackedIndex &&
                                    <div className="fs-12 tracked-note">*NOTE: Currently active driver</div>
                                }
                            </div>
                            </div>
                        )
                    })
                }
                {
                    enableChangeButton &&
                    <Button value='Change Driver' onClick={this.onChangeDriver} className="change-driver-btn" />
                }
                {
                    this.state.showChangeDriverPopUp &&
                    <ChangeDriver
                        numbersOperatorData={this.props.numbersOperatorData}
                        getNumbersOperator={this.props.getNumbersOperator}
                        trip={this.props.trip}
                        trackedIndex={this.state.isTrackedIndex}
                        operatorTypes={operatorTypes}
                        onChangeDriver={this.onChangeDriver}
                        submitTransporterDetails={this.props.submitTransporterDetails}
                    />
                }
                {
                    (enableEdit || isSuperAdmin || isSuperAdminSeeker || isSuperAdminOthers)&&
                    <div onClick={this.addNewDriver} className="curP bg-theme ht-40 flex flex-middle flex-center mt-10 fs-13 fMedium theme-color mb-40">
                        + Add New Driver
                    </div>
                   
                }
                {this.state.error && <div className={'error-color tCenter'}>{'Please fill all the mandatory fields'}</div>}
                {(enableEdit || isSuperAdmin || isSuperAdminSeeker || isSuperAdminOthers) && <Button value='Save' onClick={this.submitTransporterDetails} />}
                
            </div>
        );
    }
};


export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ["places"]
})(TransporterDetails);
