import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
    TURN_AROUND_TIMES,
    LOCATION_TYPES,
    VALUE_UNITS,
    SERVICE_TYPES,
    RUN_TYPES,
    SUB_REQUEST_TYPES,
    BOUND_TYPES,
    SHIMPMENT_VALUES,
    CARGO_UNITS,
    CPLY,
    RAJ_PETRO
} from 'Constants/commonConstants';

import ActionLoader from 'Components/ActionLoader';
import SearchDropdown from 'Components/SearchDropdown';
import Sticky from 'Components/Sticky';
import Button from 'Components/Button';
import Dropdown from 'Components/Dropdown';

import {ReactComponent as BackIcon} from './icons/back.svg';

import { SingleDatePicker } from 'react-dates';
import classNames from 'classnames';
import RouteDetailForm from '../../../components/RouteDetailForm/RouteDetailForm';
import Input from 'Components/Input';
import Tabs from 'Components/Tabs';
import Checkbox from 'Components/Checkbox';
import TimePicker from 'rc-time-picker';
import uuid4 from 'uuid';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as EsclamationIcon } from 'Components/TripRow/icons/esclamation.svg';
import { GoogleApiWrapper } from 'google-maps-react';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import {
    GET_ADD_TRIP_TEMPLATE, GET_LOCATIONS, ADD_TRIP, GET_GPS_VENDORS,
    GET_OPERATOR_TYPE, GET_VEHICLE_DETAIL_OPTIONS, GET_VEHICLE_DATA, 
    POST_VEHICLE_DATA, GET_LOCATIONS_SUCCESS, TOAST_ERROR,
    GET_TRACKED_LOG_DETAILS
} from 'Constants/actionConstants';
import { UPLOAD_TRIPS, GET_PROVIDERS, GET_SEEKERS, GET_NUMBERS_OPERATOR_TYPE, CREATE_LOCATION, GET_LOCATION_TYPE_FILTER } from 'Constants/actionConstants';
import UploadButton from 'Components/UploadButton';
import {checkSpecialCharacters, scroll} from '../../../utils/common';
import { ReactComponent as CloseIcon } from '../../../components/TrackingSettings/icons/close-icon.svg';
import { GET_TAT_DETAILS, GET_DO_NO_BASED_ON_FROM_LOC } from 'Constants/actionConstants';
import { getUser, getCompanyType, getMandatoryFeilds } from 'Utils/storage';
import { SEEKER, PROVIDER, TRANSPORT_TYPE, GOOGLE_API_KEY, TTBIL, AWL, GALAXY } from 'Constants/commonConstants';

const serviceTypes = [...SERVICE_TYPES];

const runTypes = [...RUN_TYPES];

const subRequestTypes = [...SUB_REQUEST_TYPES];

const boundTypes = [...BOUND_TYPES];

const turnAroundTimes = [...TURN_AROUND_TIMES];

const typesOfLocation = [...LOCATION_TYPES];

const valueUnits = [...VALUE_UNITS];

const shipmentValue = [...SHIMPMENT_VALUES];

const emptyLocation = {
    "city" : "",
    "location_type_name": "Type of location",
    "pincode" : "",
    "cut_off_time" : "08:00 PM",
    "weekly_off" : "Sunday",
    "state" : "",
    "country" : "",
    "latitude" : 0,
    "longitude" : 0,
    "location_type" : null,
    "name": "",
    "source": false,
    "destination": false,
    "plant_name":"",
    "tat": '',
    "notification_emails": [],
    "notification_numbers": [],
    "customer_name": '',
    "lr_no": ''
};

const emptyGeoFenceLocation = {
    location: "",
    latitude: 0,
    longitude: 0,
    geofence_radius: 1000
}


const emptyNotification = {
    "email": "",
    "sms": "",
    "id": 0
};


const emptyDriver = {
    "firstName": "",
    "lastName": "",
    "number": "",
    fromLocation: {
        place: '',
        latitude: 0,
        longitude: 0
    },
    toLocation: {
        place: '',
        latitude: 0,
        longitude: 0
    },
    operatorType: {},
    gpsVendor: {}
};

const userDetails = getUser();

const isSeeker =  getCompanyType() === SEEKER;

const seekerId = isSeeker ? userDetails.company_id : (userDetails.seeker_ids && userDetails.seeker_ids[0]);

const consigneeData =  {
    plant_name: '',
    location_code: '',
    zipcode: '',
    state: '',
    city: '',
    is_plant: false,
    country: 'India',
    weekly_off: '',
    cut_off_time: '08:00 PM',
    location_type: null,
    location_type_name: '',
    zone: '',
    district: '',
    plant_code: '',
    contact: '',
    name: '',
    email: '',
    pan_no: '',
    address: '',
    latitude: null,
    longitude: null,
};

const washingType = [
    {value: 1, name: "Sealed"},
    {value: 2, name: "Washed"}
]

class AddTrip extends React.Component {
  constructor() {
    super();
    this.state = {
        serviceType: serviceTypes[1],
        boundType: boundTypes[1],
        subRequestType: subRequestTypes[1],
        runType: runTypes[1],
        comments: "",
        startDate: moment(new Date()),
        focused: false,
        invoice_date : null,
        invoiceDateFocused: false,
        turnAroundTime: 2,
        routeCode: '',
       //turnAroundTime: this.props.tat ? turnAroundTimes.filter((day) => { return (this.props.tat === "2 Days")})[0] : turnAroundTimes.filter((day) => { return (day.value === "2 Days")})[0],
        routeDetails: [],
        startLocation: { ...JSON.parse(JSON.stringify(emptyLocation)), id: "start" },
        endLocation: { ...JSON.parse(JSON.stringify(emptyLocation)), id: "end"},
        geofenceLocations: [ { ...emptyGeoFenceLocation, id: uuid4() } ],
        geofenceLocationsFrom: [ { ...emptyGeoFenceLocation, id: uuid4() } ],
        geofenceLocationsTo: [ { ...emptyGeoFenceLocation, id: uuid4() } ],
        transporterName: isSeeker ? "" : userDetails.name,
        transporterEmail: isSeeker ? "" : userDetails.email,
        transporterContact: isSeeker ? "" : userDetails.contact_number,
        transporterPan: isSeeker ? "" : userDetails.pan_no,
        driverName: "",
        driverNo: "",
        driverDetails: [ {...emptyDriver, id: uuid4()} ],
        gpsVendors: {},
        vehicleBodyType: {
            id: 0,
            value: 0,
            name: ""
        },
        vehicleType: {
            id: 0,
            value: 0,
            name: ""
        },
        vehicleServiceType: {
            id: 0,
            value: 0,
            name: ""
        },
        vehicleRegNo: "",
        isoNo: "",
        loadingCapacity: 0,
        trackingPreference: 2,
        headOfficeNotification:
            {
                "plant_type": 1,
                "plant_name": "Head Office",
                "accounts": [{
                    ...emptyNotification
                }]
        },
        plantNotification:
            {
                "plant_type": 2,
                "plant_name": "Plant",
                "accounts": [{
                    ...emptyNotification
                }]
        },
        depoNotification:
            {
                "plant_type": 3,
                "plant_name": "Depo",
                "accounts": [{
                    ...emptyNotification
                }]
        },
        doNo: "",
        lrNo: "",
        invoiceNo: "",
        itemName: "",
        itemValue: "",
        customer_name: "",
        consignment_note_no: "",
        highValue: 0,
        // valueUnit: {
        //     name: "— Select Units — ",
        //     value: 0
        // },
        cargoWeight: "",
        cargoUnit: [...CARGO_UNITS].filter((cargo) => { return cargo.value === 'MT' })[0],
        trackingPreferences: [
            {
                "tracking_type": 1,
                "tracking_name": "App Tracking",
                "enabled": false
            },
            {
                "tracking_type": 2,
                "tracking_name": "SIM Tracking",
                "enabled": true
            },
            {
                "tracking_type": 3,
                "tracking_name": "GPS Tracking",
                "enabled": false
            },
            {
                "tracking_type": 4,
                "tracking_name": "Manual Tracking",
                "enabled": false
            },
            {
                "tracking_type": 5,
                "tracking_name": "None",
                "enabled": false
            }
        ],
        consigneeDetails: Object.assign({}, consigneeData),
        file: null,
        fromLoc: {},
        toLoc: {},
        transportType: TRANSPORT_TYPE[0],
        washingStatus: '',
        trackingType: '',
        vendor:'',
        trackingName:''
      };

      this.saveTrip = this.saveTrip.bind(this);
      this.getAddTripTemplate = this.getAddTripTemplate.bind(this);
      this.onFileUpload = this.onFileUpload.bind(this);
      this.uploadTrips = this.uploadTrips.bind(this);
      this.addLocation = this.addLocation.bind(this);
      this.handleRouteDataChange = this.handleRouteDataChange.bind(this);
      this.handleStartEndlocationChange = this.handleStartEndlocationChange.bind(this);
      this.handleGeofenseLocationChange = this.handleGeofenseLocationChange.bind(this);
      this.addGeofenceLocation = this.addGeofenceLocation.bind(this);
      this.addHeadOfficeAccount = this.addHeadOfficeAccount.bind(this);
      this.addPlantAccount = this.addPlantAccount.bind(this);
      this.addDepoAccount = this.addDepoAccount.bind(this);
      this.handleNotificationAccountChange = this.handleNotificationAccountChange.bind(this);
      this.removeLocation = this.removeLocation.bind(this);
      this.removeGeoFenceLocation = this.removeGeoFenceLocation.bind(this);
      this.addDriver = this.addDriver.bind(this);
      this.removeDriver = this.removeDriver.bind(this);
      this.handleDriverChange = this.handleDriverChange.bind(this);
      this.onChangeVehicleDetails = this.onChangeVehicleDetails.bind(this);
    }

    addDriver = () => {
        this.setState({driverDetails: this.state.driverDetails.concat([{...emptyDriver,
            fromLocation: { place: '', latitude: 0, longitude: 0 },
            toLocation: { place: '', latitude: 0, longitude: 0}, id: uuid4()}])});
        }

    removeDriver = (id) => {
        const newdriverDetails = this.state.driverDetails.filter((driver) => driver.id !== id);
        this.setState({driverDetails: newdriverDetails});
    }

    handleDriverChange = (field, value, id) => {
        const reg = new RegExp('^[0-9]+$');
        if(field == 'number' && value && !reg.test(value)){
            return;
        }
        if(field == 'number' && value.length > 10){
            this.setState({ error: false });
            return;
        }
        if(field == 'name' && value) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        const newDrivers = this.state.driverDetails.map((driver) => {
            if(driver.id !== id) {
                return driver;
            }

            return { ...driver, [field]: value };
        });
        this.setState({driverDetails: newDrivers});

        const tP = this.state.trackingPreferences.filter((pref)=> { return pref.enabled });

        if(field == 'number' && value.length == 10 && tP && tP.length > 0 && tP[0].tracking_type === 2) {
            this.props.dispatch({
                type: GET_NUMBERS_OPERATOR_TYPE,
                params: {'number': value},
                onSuccess: ((data) => {
                    const { operatorTypes } = this.props;
                    const driverDetails =[...this.state.driverDetails];
                    const operatorSelected = operatorTypes.filter((op) => { return op.id === data.operator_type });

                    driverDetails.map((driver) => {
                        if (driver.id === id) {
                            driver.operatorType = (operatorSelected && operatorSelected.length > 0) ? operatorSelected[0] : {};
                        }
                    });

                    this.setState({
                        driverDetails
                    })
                })
            })
        }
    }

    onChangeVehicleDetails = (field, value) => {
        const params = {seeker_id: seekerId};
        let callApi = false;
        value = value.toUpperCase();
        if (field === 'vehicleRegNo') {
            if ((value.match(/^\w+$/) || value === '') && value.length <= 11) {
                callApi = value.length >= 7;
            } else {
                return;
            }
            params.vehicle_no = value || '';
        } else {
            callApi = true;
            params.iso_no = value || '';
        }

        this.setState({
            [field]: value,
            error: false
        });

        if(callApi) {
            this.props.dispatch({
                type: GET_VEHICLE_DATA,
                params: params,
                onSuccess: ((data) => {
                    const { vehicleTypes, vehicleBodyTypes } = this.props;
                    const { loadingCapacity, vehicleType, vehicleBodyType, vehicleServiceType } = this.state;
                    const populateData = {};

                    const isRoadTanker = (this.state.vehicleServiceType.name || '').toLowerCase().includes('road');
                    if ((field == 'vehicleRegNo' && isRoadTanker) || field == 'isoNo') {
                        populateData.loadingCapacity = data.loading_capacity || loadingCapacity;
                    }

                    const vehicleTypeSelected = vehicleTypes.filter((item) => { return item.id === data.vehicle_type });
                    populateData.vehicleType = (vehicleTypeSelected && vehicleTypeSelected.length > 0) ? vehicleTypeSelected[0] : vehicleType;

                    const bodyTypeSelected = vehicleBodyTypes.filter((item) => { return item.id === data.body_type });
                    populateData.vehicleBodyType = (bodyTypeSelected && bodyTypeSelected.length > 0) ? bodyTypeSelected[0] : vehicleBodyType;

                    this.setState(populateData)
                })
            })
        }
        this.props.dispatch({
                type: GET_TRACKED_LOG_DETAILS,
                params: { vehicle_no: value},
                onSuccess: ((data) => {
                    // console.log("api data",data)
                    this.setState({
                        trackingType: data && data.tracking_type,
                        trackingName: data && data.tracking_name,
                        vendor: data && data.vendor_type,
                    });
                })
            }) 
    }

    selectVehicleServiceType = (vehicleServiceType) => {
        const { dispatch } = this.props;
        const isRoadTanker = (vehicleServiceType.name || '').toLowerCase().includes('road');
        const isIsoTanker = (vehicleServiceType.name || '').toLowerCase().includes('iso');
        const data = {
            vehicleServiceType
        };
        if (isRoadTanker) {
            data.isoNo = '';
        } else if (!isRoadTanker && !isIsoTanker) {
            data.isoNo = '';
            data.loadingCapacity = '';
        }
        this.setState(data);

        const params = {
            seeker_id: seekerId,
            service_type: vehicleServiceType.id
        }
        dispatch({ type: GET_VEHICLE_DETAIL_OPTIONS, params });
    }

    selectGPSVendor = (gpsVendor) => {
        const gpsVendors = {
            id: gpsVendor.id,
            name: gpsVendor.name
        }
        this.setState({
            gpsVendors
        });
    }

    removeLocation = (id) => {
        const newRouteDetails = this.state.routeDetails.filter((route) => route.id !== id);
        this.setState({routeDetails: newRouteDetails}, this.getTatDetails);
    }

    removeGeoFenceLocation = (id) => {
        const newGeofenceLocations = this.state.geofenceLocations.filter((route) => route.id !== id);
        this.setState({geofenceLocations: newGeofenceLocations});
    }

    changePreference = (preferenceIndex)=> {
        const newState = {...this.state};
        const trackingPreferences = newState.trackingPreferences;
        // const currentTrackingPreference = trackingPreferences[preferenceIndex];
        // currentTrackingPreference.enabled = !currentTrackingPreference.enabled;
        // trackingPreferences.forEach((preference)=> {
        //     if(currentTrackingPreference.tracking_type === 5 && currentTrackingPreference.enabled && preference.tracking_type !== 5) {
        //         preference.enabled = false;
        //     } else if(currentTrackingPreference.tracking_type !== 5 && currentTrackingPreference.enabled && preference.tracking_type == 5) {
        //         preference.enabled = false;
        //     }
        // })
        for(var i in trackingPreferences){
            if(i == preferenceIndex){
                trackingPreferences[i].enabled = true;
            } else {
                trackingPreferences[i].enabled = false;
            }
        }

        newState.saved = false;
        this.setState(newState);
    }

    addHeadOfficeAccount() {
        const currentAccounts = [...this.state.headOfficeNotification.accounts];
        const newHeadeNotification = {...this.state.headOfficeNotification };
        newHeadeNotification.accounts = ([]).concat(currentAccounts, {...emptyNotification, id: currentAccounts.length });
        this.setState({headOfficeNotification: {...newHeadeNotification}});
    }

    removeHeadOfficeAccount(id) {
        const newHeadeNotification = {...this.state.headOfficeNotification };
        newHeadeNotification.accounts.splice(id, 1);
        this.setState({headOfficeNotification: {...newHeadeNotification}});
    }

    addPlantAccount() {
        const currentAccounts = [...this.state.plantNotification.accounts];
        const newHeadeNotification = {...this.state.plantNotification};
        newHeadeNotification.accounts = ([]).concat(currentAccounts, {...emptyNotification, id: currentAccounts.length });
        this.setState({plantNotification: {...newHeadeNotification}});
    }

    removePlantAccount(id) {
        let newHeadeNotification = {...this.state.plantNotification};
        newHeadeNotification.accounts.splice(id, 1);
        this.setState({plantNotification: {...newHeadeNotification}});
    }

    addDepoAccount() {
        const currentAccounts = [...this.state.depoNotification.accounts];
        const newHeadeNotification = {...this.state.depoNotification};
        newHeadeNotification.accounts = ([]).concat(currentAccounts, {...emptyNotification, id: currentAccounts.length });
        this.setState({depoNotification: {...newHeadeNotification}});
    }

    removeDepoAccount(id) {
        const newHeadeNotification = {...this.state.depoNotification };
        newHeadeNotification.accounts.splice(id, 1);
        this.setState({depoNotification: {...newHeadeNotification}});
    }

    addLocation () {
        this.setState({routeDetails: this.state.routeDetails.concat([{...JSON.parse(JSON.stringify(emptyLocation)), id: uuid4()}])}, () => {
        });
    }

    addGeofenceLocation() {
        this.setState({geofenceLocations: this.state.geofenceLocations.concat([{...emptyGeoFenceLocation, id: uuid4()}])}, ()=>{
        });
    }

    handleRouteDataChange(key, value, id, isClear) {
        if(typeof value === 'string' && key==='location') {
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                if(value) {
                    return { ...route, city: value };
                } else {
                    return { ...route, ...JSON.parse(JSON.stringify(emptyLocation)) };
                }
            });

            this.setState({routeDetails: newRouteDetails});

            const { dispatch } = this.props;
            dispatch({
                type: GET_LOCATIONS,
                param: {
                    search: value,
                    location: 'destination'
                }
            });
            return;
        } else if(typeof value !== 'string' && key === 'location_type') {
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                return { ...route, location: value.value, location_type_name: value.name };
            });
            this.setState({routeDetails: newRouteDetails});
        } else if(typeof value !== 'string' && key === 'weekly_off') {
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                return { ...route,  weekly_off: value.value };
            });
            this.setState({routeDetails: newRouteDetails});
        } else if(typeof value === 'string' && ['notification_emails', 'notification_numbers'].indexOf(key) === -1){
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                return { ...route, [key]: value };
            });
            this.setState({routeDetails: newRouteDetails});
        } else if(['notification_emails', 'notification_numbers'].indexOf(key) > -1){
            let notification_emails = [];
            let notification_numbers = [];
            let data = value.split(',');
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                if(key === 'notification_emails') {
                    notification_emails = isClear ? [] : route.notification_emails;
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_emails.push(data[i].trim());
                        }
                    }
                    return { ...route, notification_emails };
                } else if( key === 'notification_numbers') {
                    notification_numbers = isClear ? [] : route.notification_numbers;
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_numbers.push(data[i].trim());
                        }
                    }
                    return { ...route, notification_numbers };
                }
                return { ...route, [key]: value };
            });
            this.setState({routeDetails: newRouteDetails});
        } else if(['source', 'destination'].indexOf(key) > -1) {
            let { endLocation, startLocation, routeDetails } = this.state;
            // if(key === 'source' && value) {
            //     endLocation.source = false;
            //     startLocation.source = false
            // }
            const newRouteDetails = routeDetails.map((route) => {
                if(route.id !== id) {
                    return { ...route}
                }
                return { ...route,
                    source: key === 'source' ? value : value ? !value : route.source,
                    destination: key === 'destination' ? value : value ? !value : route.destination
                };
            });
            this.setState({
                startLocation,
                endLocation,
                routeDetails: newRouteDetails
            });
        } else {
            const newRouteDetails = this.state.routeDetails.map((route) => {
                if(route.id !== id) {
                    return route;
                }
                return { ...route, ...value };
            });
            this.setState({routeDetails: newRouteDetails}, this.getTatDetails);
        }
    }


    handleStartEndlocationChange(key, value, which, isClear) {
        if (typeof value === 'string' && key==='location') {
            const { dispatch } = this.props;
            dispatch({
                type: GET_LOCATIONS_SUCCESS,
                data: {
                    locations: []
                }
            })
            if (which === "start") {
                let startLocation = this.state.startLocation;
                if (value){
                    startLocation.city = value;
                    startLocation.plant_name = value;
                } else {
                    startLocation =  {...JSON.parse(JSON.stringify(emptyLocation)), id: "start"}
                }
                this.setState({startLocation});
            }
            else if (which==='end'){
                let endLocation = this.state.endLocation;
                if (value){
                    endLocation.city = value;
                    endLocation.plant_name = value;
                } else {
                    endLocation = {...JSON.parse(JSON.stringify(emptyLocation)), id: "end"}
                }
                this.setState({endLocation});
            }
            dispatch({
                type: GET_LOCATIONS,
                param: {
                    search: value,
                    location: which === "start" ? 'source' : 'destination'
                }
            });
            return;
        }
        else if (typeof value === 'string' && ['notification_emails', 'notification_numbers'].indexOf(key) === -1) {
            if (which == "start") {
                this.setState({startLocation: {...this.state.startLocation, [key]:value}});
            }
            else {
                this.setState({endLocation: {...this.state.endLocation, [key]:value}});
            }
        }  else if (typeof value !== 'string' && key === 'location_type') {
            if (which == "start") {
                this.setState({startLocation: {...this.state.startLocation, location_type:value.value, location_type_name: value.name}});
            }
            else {
                this.setState({endLocation:  {...this.state.endLocation, location_type:value.value, location_type_name: value.name}});
            }
        } else if (typeof value !== 'string' && key === 'weekly_off') {
            if (which == "start") {
                this.setState({startLocation: {...this.state.startLocation, weekly_off: value.name}});
            }
            else {
                this.setState({endLocation:  {...this.state.endLocation, weekly_off: value.name}});
            }
        } else if(['notification_emails', 'notification_numbers'].indexOf(key) > -1){
            let notification_emails = isClear ? [] : (which == "start" ? this.state.startLocation.notification_emails : this.state.endLocation.notification_emails);
            let notification_numbers = isClear ? [] : (which == "start" ? this.state.startLocation.notification_numbers : this.state.endLocation.notification_numbers);
            let data = value.split(',');
            if (which == "start") {
                if(key === 'notification_emails') {
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_emails.push(data[i].trim());
                        }
                    }
                    this.setState({ startLocation: {...this.state.startLocation, notification_emails }});
                } else if( key === 'notification_numbers') {
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_numbers.push(data[i].trim());
                        }
                    }
                    this.setState({ startLocation: {...this.state.startLocation, notification_numbers }});
                }
            } else {
                if(key === 'notification_emails') {
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_emails.push(data[i].trim());
                        }
                    }
                    this.setState({ endLocation: {...this.state.endLocation, notification_emails }});
                } else if( key === 'notification_numbers') {
                    for(var i in data) {
                        if(data[i].trim()){
                            notification_numbers.push(data[i].trim());
                        }
                    }
                    this.setState({ endLocation: {...this.state.endLocation, notification_numbers }});
                } else if( key === 'customer_name' && TTBIL.includes(userDetails.company_id)) {
                    for(var i in data) {
                        if(data[i].trim()){
                            customer_name.push(data[i].trim());
                        }
                    }
                    this.setState({ endLocation: {...this.state.endLocation, customer_name }});
                } else if( key === 'lr_no' && TTBIL.includes(userDetails.company_id)) {
                    for(var i in data) {
                        if(data[i].trim()){
                            lr_no.push(data[i].trim());
                        }
                    }
                    this.setState({ endLocation: {...this.state.endLocation, lr_no }});
                }
            }
        }  else if(['source','destination'].indexOf(key) > -1) {
            let { endLocation, startLocation, routeDetails } = this.state;
            if (which == "start") {
                // if(key === 'source' && value) {
                //     endLocation.source = false;
                //     routeDetails.map(route => {
                //         route.source = false;
                //     })
                // }
                this.setState({
                    startLocation:  {...this.state.startLocation,
                                        source: key === 'source' ? value : value ? !value : this.state.startLocation.source,
                                        destination: key === 'destination' ? value :  value ? !value : this.state.startLocation.destination,
                                    },
                    endLocation,
                    routeDetails
                });
            } else {
                // if(key === 'source' && value) {
                //     startLocation.source = false;
                //     routeDetails.map(route => {
                //         route.source = false;
                //     })
                // }
                this.setState({
                    endLocation:  {...this.state.endLocation,
                                        source: key === 'source' ? value : value ? !value : this.state.endLocation.source,
                                        destination: key === 'destination' ? value :  value ? !value : this.state.endLocation.destination,
                                    },
                    startLocation,
                    routeDetails
                });
            }
        }
        else {
            if (which == "start") {
                                this.setState({startLocation: {...this.state.startLocation, ...value }}, () => {
                    const { dispatch } = this.props;
                    if(this.state.startLocation && TTBIL.includes(userDetails.company_id)) {
                        dispatch({
                            type: GET_DO_NO_BASED_ON_FROM_LOC,
                            params: {
                                branch_id: this.state.startLocation.branch_id,
                                start_date: this.state.startDate && this.state.startDate.valueOf()
                            },
                            onSuccess: (data) => {
                                if((data || {}).do_no) {
                                    this.setState({
                                        doNo: data.do_no
                                    })
                                }
                            }
                        })
                    }
                    if (this.state.endLocation.branch_id) {
                        const { routeDetails, startLocation, endLocation } = this.state;
                        // let destinations = ""
                        // if(routeDetails.length > 0) {
                        //     routeDetails.map(route => {
                        //         if (route.branch_id) {
                        //             destinations = `${destinations}${route.branch_id},`;
                        //         }
                        //     })
                        // }
                        // if (this.state.endLocation.branch_id) {
                        //     destinations = `${destinations}${this.state.endLocation.branch_id}`;
                        // }
                        // const data = {
                        //     source: this.state.startLocation.branch_id,
                        //     destinations,
                        //     // destinations: [this.state.endLocation.latitude, `${this.state.endLocation.longitude}| `]
                        //     // source_lng: this.state.startLocation.longitude,
                        //     // destination_lat: this.state.endLocation.latitude,
                        //     // destination_lng: this.state.endLocation.longitude
                        // }
                        // if (!isSeeker) {
                        //     data.seeker_id = this.state.company_id
                        // }
                        const data = {
                            seeker_id: isSeeker ? (userDetails.company_id || userDetails.user_id) : (this.state.company_id || userDetails.user_id),
                            route_details: []
                        }
                        if(startLocation) {
                            data.route_details.push(startLocation);
                        }
                        if(routeDetails.length > 0) {
                            routeDetails.map(route => {
                                data.route_details.push(route)
                            })
                        }
                        if(endLocation) {
                            data.route_details.push(endLocation);
                        }
                        dispatch({
                            type: GET_TAT_DETAILS,
                            data,
                            onSuccess: (data) => {
                                                                if(data.route_details && data.route_details.length > 0) {
                                    let { routeDetails, endLocation, startLocation } = this.state;
                                    if(data.route_details.length > 2) {
                                        routeDetails = [];
                                        data.route_details.map((route, index) => {
                                            if(index !== 0 && index !==(data.route_details.length - 1)) {
                                                routeDetails.push(route);
                                            }
                                        })
                                    }
                                    this.setState({
                                        startLocation: {
                                            ...startLocation,
                                            ...data.route_details[0]
                                        },
                                        routeDetails,
                                        endLocation: {
                                            ...endLocation,
                                            ...data.route_details[data.route_details.length - 1]
                                        },
                                        turnAroundTime: data.tat,
                                        routeCode: data.route_code
                                    })
                                }
                            }
                        });
                    }
                });
            }
            else {
                this.setState({endLocation: {...this.state.endLocation, ...value}}, () => {
                    if (this.state.startLocation.branch_id) {
                        const { dispatch } = this.props;
                        const { routeDetails, startLocation, endLocation } = this.state;
                        // let destinations = ""
                        // if(routeDetails.length > 0) {
                        //     routeDetails.map(route => {
                        //         if (route.branch_id) {
                        //             destinations = `${destinations}${route.branch_id},`;
                        //         }
                        //     })
                        // }
                        // if (this.state.endLocation.branch_id) {
                        //     destinations = `${destinations}${this.state.endLocation.branch_id}`;
                        // }
                        // const data = {
                        //     source: this.state.startLocation.branch_id,
                        //     // destinations: [this.state.endLocation.latitude, `${this.state.endLocation.longitude}| `]
                        //     destinations,
                        //     // source_lng: this.state.startLocation.longitude,
                        //     // destination_lat: this.state.endLocation.latitude,
                        //     // destination_lng: this.state.endLocation.longitude
                        // }
                        // if (!isSeeker) {
                        //     data.seeker_id = this.state.company_id
                        // }

                        const data = {
                            seeker_id: isSeeker ? (userDetails.company_id || userDetails.user_id) : (this.state.company_id || userDetails.user_id),
                            route_details: []
                        }
                        if(startLocation) {
                            data.route_details.push(startLocation);
                        }
                        if(routeDetails.length > 0) {
                            routeDetails.map(route => {
                                data.route_details.push(route)
                            })
                        }
                        if(endLocation) {
                            data.route_details.push(endLocation);
                        }
                            dispatch({
                                type: GET_TAT_DETAILS,
                                data,
                                onSuccess: (data) => {
                                    if(data.route_details && data.route_details.length > 0) {
                                        let { routeDetails, endLocation, startLocation } = this.state;
                                        if(data.route_details.length > 2) {
                                            routeDetails = [];
                                            data.route_details.map((route, index) => {
                                                if(index !== 0 && index !==(data.route_details.length - 1)) {
                                                    routeDetails.push(route);
                                                }
                                            })
                                        }
                                        this.setState({
                                            startLocation: {
                                                ...startLocation,
                                                ...data.route_details[0]
                                            },
                                            routeDetails,
                                            endLocation: {
                                                ...endLocation,
                                                ...data.route_details[data.route_details.length - 1]
                                            },
                                            turnAroundTime: data.tat,
                                            routeCode: data.route_code
                                        })
                                    }
                                }
                            });
                    }
                });
            }
        }
    }

    onChange = (e)=> {
        let { name, value, type } = e.target;
        const isRajPetro = RAJ_PETRO.includes(getUser().company_id)
        if (name == 'transporterContact' && value.length > 10) {
            return;
        } else if (name === 'vehicleRegNo') {
            if ((value.match(/^\w+$/) || value === '') && value.length <= 11) {
                value = value.toUpperCase();
            } else {
                return;
            }
        }
        if(isRajPetro && name === 'invoiceNo'){
            const result = value.replace(/[^0-9\\/]/g, '');
            value = result;
        }
        // else{
        //     let result = checkSpecialCharacters(e)
        //     value = result;
        // }
        if(type === 'text' && value) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        this.setState({
            [name]: value,
            error: false
        });
    }

    async getLatLngForConsignee(location, set_address) {
        try {
            const { consigneeDetails } = this.state;
            if (location) {
                const geocodeData = await geocodeByAddress(location);
                if (set_address) {
                    consigneeDetails['address'] = geocodeData[0].formatted_address;
                } else {
                    const coordinates = await getLatLng(geocodeData[0]);
                    consigneeDetails['latitude'] = coordinates.lat;
                    consigneeDetails['longitude'] = coordinates.lng;
                }
                this.setState({
                    consigneeDetails
                });
            }

        } catch (e) {
            console.log('error', e);
        }
    }

    onChangeConsignee = (e) => {
        let { name, value, type } = e.target;
        const { consigneeDetails } = this.state;
        if(type === 'text' && value) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        consigneeDetails[name] = value;

        if (name === 'address') {
            this.getLatLngForConsignee(consigneeDetails[name])
        } else if ((name === 'latitude' && consigneeDetails.longitude) || (name === 'longitude' && consigneeDetails.latitude)) {
            const coordinates = `${consigneeDetails.latitude},${consigneeDetails.longitude}`;
            this.getLatLngForConsignee(coordinates, true)
        }

        this.setState({
            consigneeDetails,
            // error: false
        });
    }

    selectTab = (activeTab)=> {
        this.setState({
            trackingPreference: activeTab});
    }

    selectTabValue = (activeTab)=> {
        this.setState({
            highValue: activeTab});
    }

    handleNotificationAccountChange(which, field, value, index) {
        if(which == "head") {
            const newAccounts = this.state.headOfficeNotification.accounts.map((account) => {
                if(account.id !== index) {
                    return account;
                }
                if(field === 'sms' && value.length > 10) {
                    return account;
                }
                return { ...account, [field]: value };
            });
            const newHeadeNotification = { ...this.state.headOfficeNotification};
            newHeadeNotification.accounts = newAccounts;
            this.setState({headOfficeNotification: newHeadeNotification});
        } else if(which == "plant") {
            const newAccounts = this.state.plantNotification.accounts.map((account) => {
                if(account.id !== index) {
                    return account;
                }
                if(field === 'sms' && value.length > 10) {
                    return account;
                }
                return { ...account, [field]: value };
            });
            const newPlantNotification = { ...this.state.plantNotification};
            newPlantNotification.accounts = newAccounts;
            this.setState({plantNotification: newPlantNotification});
        } else if(which == "depo") {
            const newAccounts = this.state.depoNotification.accounts.map((account) => {
                if(account.id !== index) {
                    return account;
                }
                if(field === 'sms' && value.length > 10) {
                    return account;
                }
                return { ...account, [field]: value };
            });
            const newDepoNotification = { ...this.state.depoNotification};
            newDepoNotification.accounts = newAccounts;
            this.setState({depoNotification: newDepoNotification});
        }
    }

    validateRequiredFields = () => {
       const isCPLY = CPLY.includes(getUser().company_id)
       const isTTBIL = TTBIL.includes(getUser().company_id) || false
        if(!isSeeker && !this.state.company_id) {
            scroll(this.company_id.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Select The Company.', errorField: 'company_id'});
            return;
        }
        const routeData = [
            {
                ...this.state.startLocation
            },
            ...this.state.routeDetails.map((loc) => {
                return {...loc}
            }),
            {
                ...this.state.endLocation
            }
        ];
        const sourceIndex = routeData.findIndex(route => route.source);
        const destinationIndex = routeData.findIndex(route => route.destination);
        let isRouteNotifEmail = [];
        let isRouteNotifPhoneNo = [];
        routeData.map(rte => {
            rte.notification_emails.map(notEmail => {
                const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                if(notEmail && !emailReg.test(notEmail)) {
                    isRouteNotifEmail.push(notEmail)
                }
            })
            rte.notification_numbers.map(notNum => {
                if(notNum.length < 10 || notNum.length > 10 || isNaN(Number(notNum))) {
                    isRouteNotifPhoneNo.push(notNum);
                }
            })
        })
        if(!isTTBIL && routeData && Array.isArray(routeData) && routeData.length > 0 && routeData[0].branch_id === routeData[routeData.length-1].branch_id){
            const { dispatch } = this.props;
            dispatch({
                type: TOAST_ERROR,
                message: "Start & End location should not be same"
            })
            return
        }
        const mandatoryFields = getMandatoryFeilds(this.state.company_id);
        if(!this.state.doNo) {
            scroll(this.doNo.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please enter the DO No.', errorField: 'doNo'});
            return;
        } else if (!this.state.poNo && mandatoryFields.po_no) {
            scroll(this.poNo.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please enter the PO No.', errorField: 'poNo'});
            return;
        } else if (!this.state.invoice_date && mandatoryFields.invoice_date && isCPLY) {
            scroll(this.poNo.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please enter the Invoice Date', errorField: 'invoice_date'});
            return;
        } else if (!this.state.lrNo && mandatoryFields.lr_no) {
            scroll(this.lrNo.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Enter The LR No.', errorField: 'lrNo'});
            return;
        } else if(!this.state.routeCode && mandatoryFields.route_code) {
            scroll(this.lrNo.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Enter The Route Code', errorField: 'routeCode'});
            return;
        }
        else if (!this.state.itemName) {
            scroll(this.itemName.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Enter The Item Name', errorField: 'itemName'});
            return;
        } else if (!this.state.startLocation.city || !this.state.endLocation.city) {
            scroll(this.RouteDetails.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Enter Route Details', errorField: 'routeDetails'});
            return;
        } else if (sourceIndex === -1 ||  destinationIndex === -1) {
            scroll(this.RouteDetails.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Mark the Source and Destination Points', errorField: 'routeDetails'});
            return;
        } else if (sourceIndex > destinationIndex) {
            scroll(this.RouteDetails.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Create Route and Mark Route Points in the Proper Order of Source to Destination', errorField: 'routeDetails'});
            return;
        } else if(isRouteNotifEmail.length > 0 || isRouteNotifPhoneNo.length > 0) {
            scroll(this.RouteDetails.offsetTop - 150);
            this.setState( { error : true, errorMessage : (isRouteNotifEmail.length > 0 && isRouteNotifPhoneNo.length > 0) ? 'Please Enter A Valid Email Address And 10digit Phone No.' : `Please Enter A Valid ${isRouteNotifEmail.length > 0  ? 'Email Address': '10digit Phone No.'}` , errorField: 'routeDetails'});
            return;
        }
        else if (!this.state.transporterName) {
            scroll(this.transporterDetails.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Enter Transporter Details', errorField: 'transporterDetails'});
            return;
        } else if (!this.state.selectedCK) {
            scroll(this.selectedCK.offsetTop - 150);
            this.setState( { error : true, errorMessage : 'Please Mention If It Is Double Driver', errorField: 'selectedCK'});
            return;
        }
        // else if(this.state.vehicleRegNo && this.state.vehicleRegNo.length < 10) {
        //     scroll(this.vehicleRegNo.offsetTop - 150);
        //     this.setState( { error : true, errorMessage : 'Vehicle Reg No. length should be 10', errorField: 'vehicleRegNo' });
        //     return;
        // }
        else if (this.state.driverDetails.length) {
            for(var i in this.state.driverDetails){
                if(this.state.driverDetails[i].number && this.state.driverDetails[i].number.length < 10){
                    scroll(this.driverNo.offsetTop - 150);
                    this.setState( { error : true, errorMessage : 'Invalid Phone No.', errorField: 'driverNo'});
                    return;
                } else if(this.state.driverDetails[i].number && !Object.keys(this.state.driverDetails[i].operatorType).length && this.state.trackingPreferences[1].enabled) {
                    scroll(this.driverNo.offsetTop - 150);
                    this.setState( { error : true, errorMessage : 'Please Select the Operator Type', errorField: 'driverNo'});
                    return;
                } else if(Object.keys(this.state.driverDetails[i].operatorType).length && this.state.trackingPreferences[1].enabled && !this.state.driverDetails[i].number ) {
                    scroll(this.driverNo.offsetTop - 150);
                    this.setState( { error : true, errorMessage : 'Please Enter the Driver No.', errorField: 'driverNo'});
                    return;
                }
            }
        }
        return true;
    }

    saveTrip() {
        const isCPLY = CPLY.includes(getUser().company_id)
        if(this.state.file != null) {
            this.uploadTrips();
        } else {
            if(this.validateRequiredFields()){
                const loadingCapacity = parseInt(this.state.loadingCapacity || 0);
                const invoiceDateString = this.state.invoice_date;
                const invoiceDateEpoch = moment(invoiceDateString).valueOf();
                const dataToSave = {
                    "trip_mode": 1,
                    "seeker_id": userDetails.company_id,
                    "provider_id": isSeeker ? this.state.transporterId : userDetails.company_id,
                    "do_no" : this.state.doNo,
                    "lr_no" : this.state.lrNo,
                    "po_no" : this.state.poNo,
                    "invoice_no" : this.state.invoiceNo,
                    ...(isCPLY && { "invoice_date": invoiceDateEpoch }),
                    "high_value_shipment" : this.state.highValue === 1,
                    "item_name" : this.state.itemName,
                    "customer_name" : this.state.customer_name,
                    "consignment_note_no" : this.state.consignment_note_no,
                    "service_type" : this.state.serviceType.value,
                    "bound_type" : this.state.boundType.value,
                    "run_type" : this.state.runType.value,
                    "sub_service_type" : this.state.subRequestType.value,
                    "start_date": this.state.startDate && this.state.startDate.valueOf(),
                    "tat": this.state.turnAroundTime,
                    "route_code": this.state.routeCode,
                    "is_double_driver": this.state.selectedCK === 'Yes' ? true : false,
                    "comments": this.state.comments,
                    "transport_type": this.state.transportType && Object.keys(this.state.transportType).length > 0 ? this.state.transportType.id : undefined,
                    // "value": {
                    //     "text": this.state.itemValue,
                    //     "type": this.state.valueUnit.value
                    // },
                    "cargo": {
                        "text": this.state.cargoWeight,
                        "type": this.state.cargoUnit.value
                    },
                    "route_details": [
                        {
                            ...this.state.startLocation,
                            'pincode': this.state.startLocation.pincode ? this.state.startLocation.pincode.toString() : this.state.startLocation.pincode,
                            'location': this.state.startLocation.city + ', ' + this.state.startLocation.state + ', ' + this.state.startLocation.country,
                            'customer_name': this.state.startLocation.customer_name ? this.state.startLocation.customer_name.toString() : this.state.startLocation.customer_name,
                            'lr_no': this.state.startLocation.lr_no ? this.state.startLocation.lr_no.toString() : this.state.startLocation.lr_no
                        },
                        ...this.state.routeDetails.map((loc) => {
                            const returnLocation = {...loc};
                            delete returnLocation.id;
                            delete returnLocation.plant_name;
                            return {
                                ...returnLocation,
                                'pincode': returnLocation.pincode ? returnLocation.pincode.toString() : returnLocation.pincode,
                                "location": returnLocation.city + ', ' + returnLocation.state + ', ' + returnLocation.country,
                                'customer_name': returnLocation.customer_name ? returnLocation.customer_name.toString() : returnLocation.customer_name,
                                'lr_no': returnLocation.lr_no ? returnLocation.lr_no.toString() : returnLocation.lr_no
                            }
                        }),
                        {
                            ...this.state.endLocation,
                            'pincode': this.state.endLocation.pincode ? this.state.endLocation.pincode.toString() : this.state.endLocation.pincode,
                            'location': this.state.endLocation.city + ', ' + this.state.endLocation.state + ', ' + this.state.endLocation.country,
                            'customer_name': this.state.endLocation.customer_name ? this.state.endLocation.customer_name.toString() : this.state.endLocation.customer_name,
                            'lr_no': this.state.endLocation.lr_no ? this.state.endLocation.lr_no.toString() : this.state.endLocation.lr_no
                        }
                    ],
                    "geofence_locations": (this.state.geofenceLocations || []).map((location)=> {
                        return {
                            location: location.location,
                            latitude: location.latitude,
                            longitude: location.longitude,
                            geofence_radius: location.geofence_radius,
                        }
                    }),
                    "transporter_details" : {
                        "name" : this.state.transporterName,
                        "email" : this.state.transporterEmail,
                        "number" : this.state.transporterContact,
                        "pan_no" : this.state.transporterPan
                    },
                    "driver_details" : this.state.driverDetails.map((driver, index) => {
                        return {
                            'name': driver.name || '',
                            first_name: driver.firstName,
                            last_name: driver.lastName,
                            'number': driver.number,
                            operator_type: driver.operatorType.id,
                            operator_name: driver.operatorType.name,
                            from: driver.fromLocation || {},
                            to: driver.toLocation || {}
                        }
                    }),
                    "vehicle_details" : {
                        "vehicle_type" : this.state.vehicleType.name || '',
                        "body_type" : this.state.vehicleBodyType.name || '',
                        "vehicle_no" : this.state.vehicleRegNo,
                        "service_type": this.state.vehicleServiceType.name || '',
                        "iso_no": this.state.isoNo || '',
                        "loading_capacity": loadingCapacity,
                        "service_type_option": this.state.washingStatus || '',
                        "no_of_times_sealed": this.state.noOfWashes ? this.state.noOfWashes.value : 0,
                    },
                    "tracking_preference" : this.state.trackingPreferences,
                    "notification_settings": [
                        this.state.headOfficeNotification,
                        this.state.plantNotification,
                        this.state.depoNotification
                    ],
                    "gps_details": this.state.trackingPreferences[2].enabled ? this.state.gpsVendors : undefined,
                }
                dataToSave.route_details = dataToSave.route_details.map((route)=> {
                    const returnRoute = {...route};
                    delete returnRoute.plant_name;
                    returnRoute.id ? delete returnRoute.id : null;
                    return returnRoute;
                })
                if(!isSeeker) {
                    dataToSave.seeker_id = this.state.company_id
                }
                const { dispatch } = this.props;
                dispatch({type: ADD_TRIP, trip: dataToSave});

                const payload = {
                    'seeker_id': dataToSave.seeker_id,
                    'vehicle_no': this.state.vehicleRegNo,
                    'iso_no': this.state.isoNo,
                    'loading_capacity': loadingCapacity,
                    'vehicle_type': this.state.vehicleType.id,
                    'body_type': this.state.vehicleBodyType.id,
                    'service_type_option': this.state.washingStatus || '',
                    'no_of_times_sealed': this.state.noOfWashes ? this.state.noOfWashes.value : 0,
                };
                dispatch({type: POST_VEHICLE_DATA, payload});
            }
        }
    }

    validateRequiredChainFields = () => {
        const { consigneeDetails } = this.state;
        if (!consigneeDetails.location_type) {
            this.setState( { error : true, errorMessage : 'Please select the Location Type', errorField: 'location_type'});
            return;
        } else if (!consigneeDetails.plant_name) {
            this.setState( { error : true, errorMessage : 'Please enter company name', errorField: 'plant_name'});
            return;
        } else if (!consigneeDetails.plant_code) {
            this.setState( { error : true, errorMessage : 'Please enter plant/vendor code', errorField: 'plant_code'});
            return;
        } else if (!consigneeDetails.address) {
            this.setState( { error : true, errorMessage : 'Please enter address', errorField: 'address'});
            return;
        } else if (!consigneeDetails.latitude) {
            this.setState( { error : true, errorMessage : 'Please enter latitude', errorField: 'latitude'});
            return;
        } else if (!consigneeDetails.longitude) {
            this.setState( { error : true, errorMessage : 'Please enter longitude', errorField: 'longitude'});
            return;
        } else if (!consigneeDetails.zipcode) {
            this.setState( { error : true, errorMessage : 'Please enter zipcode', errorField: 'zipcode'});
            return;
        } else if (!consigneeDetails.city) {
            this.setState( { error : true, errorMessage : 'Please enter city', errorField: 'city'});
            return;
        } else if (!consigneeDetails.state) {
            this.setState( { error : true, errorMessage : 'Please enter state', errorField: 'state'});
            return;
        } else if (!consigneeDetails.country) {
            this.setState( { error : true, errorMessage : 'Please enter country', errorField: 'country'});
            return;
        }
        return true;
    }

    createLocation = () => {
        const { dispatch } = this.props;
        if (this.validateRequiredChainFields()) {
            const data = {...this.state.consigneeDetails};
            delete data.week;
            delete data.location_item;
            dispatch({
                type: CREATE_LOCATION,
                data,
                onSuccess: () => {
                    this.setState({ isLinkAdded: false })
                }
            })
        }
    }

    getAddTripTemplate = (tripMode) => {
        const { dispatch } = this.props;
        dispatch({ type: GET_ADD_TRIP_TEMPLATE, tripMode: tripMode });
    }

    uploadTrips() {
        const { dispatch } = this.props;
        dispatch({
            type: UPLOAD_TRIPS,
            file: this.state.file,
            params: { trip_mode: 1 }
        });
        this.setState({file: null});
    }

    handleChange = address => {
        this.setState({ address });
      };

    handleSelect = address => {
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
    };
    goBack = ()=> {
        history.back();
    }

    onFileUpload(file) {
        this.setState({
            file: file
        });
    }

    selectOperatorType = (index, operatorType)=> {
        const driverDetails =[...this.state.driverDetails];
        driverDetails[index].operatorType = operatorType;
        this.setState({
            driverDetails
        })
    }

    getProviders = (search)=> {
        const { dispatch } = this.props;
        dispatch({
          type: GET_PROVIDERS,
          search
        })
    }

    getSeekers = (search) => {
        const { dispatch } = this.props;
        dispatch({
          type: GET_SEEKERS,
          search
        })
    }

    onChangeComments = (e) => {
        this.setState({ comments : e.target.value });
    }

    validateEmail = (email) => {
       const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
       const result = pattern.test(email);
       if(result){
         this.setState({
           emailError:false,
           email:email
         })
       } else{
         this.setState({
           emailError:true
         })
       }
    }

    searchLocation = (value, id, field , isSearch) => {
        const newDrivers = this.state.driverDetails.map((driver) => {
            if(driver.id !== id) {
                return driver;
            }
            const loc = {
                location : isSearch ? value : value.display_name,
                latitude : isSearch ? '' : value.latitude,
                longitude : isSearch ? '' : value.longitude
            }
            return { ...driver, [field]: loc }
        });
        this.setState({ driverDetails: newDrivers },() => {
            if(isSearch){
                const { dispatch } = this.props;
                dispatch({
                    type: GET_LOCATIONS,
                    param: {
                        search: value
                    }
                });
            }
        });
    }

    getTatDetails = (isCompany) => {
        const { startLocation, endLocation, company_id, routeDetails } = this.state;
        const { dispatch } = this.props;;
        // let destinations = "";
        // if (routeDetails.length > 0) {
        //     routeDetails.map(route => {
        //         if (route.branch_id) {
        //             destinations = `${destinations}${route.branch_id},`;
        //         }
        //     })
        // }
        // if (endLocation.branch_id) {
        //     destinations = `${destinations}${endLocation.branch_id}`;
        // }
        // const data = {
        //     source: startLocation.branch_id,
        //     destinations,
        //     // seeker_id: company_id
        // }
        // if (isCompany) {
        //     data.seeker_id = company_id
        // }
        // const data = {
        //     source_lat: startLocation.latitude,
        //     source_lng: startLocation.longitude,
        //     destination_lat: endLocation.latitude,
        //     destination_lng: endLocation.longitude,
        //     seeker_id: company_id
        // }
        const data = {
            seeker_id: isSeeker ? (userDetails.company_id || userDetails.user_id) : (this.state.company_id || userDetails.user_id),
            route_details: []
        }
        if(startLocation) {
            data.route_details.push(startLocation);
        }
        if(routeDetails.length > 0) {
            routeDetails.map(route => {
                data.route_details.push(route);
            })
        }
        if(endLocation) {
            data.route_details.push(endLocation);
        }
        dispatch({
            type: GET_TAT_DETAILS,
            data,
            onSuccess: (data) => {
                                if(data.route_details && data.route_details.length > 0) {
                    let { routeDetails, endLocation, startLocation } = this.state;
                    if(data.route_details.length > 2) {
                        routeDetails = [];
                        data.route_details.map((route, index) => {
                            if(index !== 0 && index !==(data.route_details.length - 1)) {
                                routeDetails.push(route);
                            }
                        })
                    }
                    this.setState({
                        startLocation: {
                            ...startLocation,
                            ...data.route_details[0]
                        },
                        routeDetails,
                        endLocation: {
                            ...endLocation,
                            ...data.route_details[data.route_details.length - 1]
                        },
                        turnAroundTime: data.tat,
                        routeCode: data.route_code
                    })
                }
            }
        })
    }

    handleGeofenseLocationChange(value, id) {
        const newLocs = this.state.geofenceLocations.map((loc) => {
            if(loc.id !== id) {
                return loc;
            }
            return { ...loc, "location": value };
        });
        this.setState({geofenceLocations: newLocs});
    }

    fetchLocationsForGeoFencing = (value, index)=> {
        const {geofenceLocations} = this.state;
        geofenceLocations[index]['location'] = value;
        if (value.length <= 0) {
            geofenceLocations[index]['latitude'] = 0;
            geofenceLocations[index]['longitude'] = 0;
        }
        this.setState({
            geofenceLocations
        });
    }

    handleGeoFencingLocationSelect = (address, index)=> {
        const {geofenceLocations} = this.state;
        geofenceLocations[index]['location'] = address;
        this.setState({
            geofenceLocations
        })
        geocodeByAddress(address)
        .then(results => {
            this.setState({
                geoCodedAddress: results[0]
            }, () => {
                this.getLatLongForGeoFenceLocation(geofenceLocations[index], index);
            });
        });
    }

    getLatLongForGeoFenceLocation = async (data, index) => {
        await this.setGeoFenceLocationCoordinates(data, index);
    }

    async setGeoFenceLocationCoordinates(geoFenceLocation, index) {
        try {
            const { geofenceLocations } = this.state;
            const item = geofenceLocations[index];
            const location = item.location || "";

            if (location) {
                const geocodeData = await geocodeByAddress(location);
                const coordinates = await getLatLng(geocodeData[0]);

                geofenceLocations[index]['latitude'] = coordinates.lat;
                geofenceLocations[index]['longitude'] = coordinates.lng;
                geofenceLocations[index]['location'] = location;
            }

            this.setState({
                geofenceLocations
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    fetchLocations = (value, name, index)=> {
        const {fromLoc, toLoc, driverDetails} = this.state;
        driverDetails[index][name]['place'] = value;
        if (value.length <= 0) {
            driverDetails[index][name]['latitude'] = 0;
            driverDetails[index][name]['longitude'] = 0;
        }
        this.setState({
            driverDetails
        });
    }

    handleLocationSelect = (address, name, index)=> {
        const {fromLoc, toLoc, driverDetails} = this.state;

        driverDetails[index][name]['place'] = address;
        this.setState({
            driverDetails
        })
        geocodeByAddress(address)
          .then(results => {
              this.setState({
                geoCodedAddress: results[0]
              }, () => {
                this.getLatLongForLocation(driverDetails[index], index);
                  // this.getLatLongForLocation()
              });
        });
    }

    getLatLongForLocation = async (data, index) => {
        await this.setGeoLocationCoordinates(data, index);
    }

    async setGeoLocationCoordinates(driverData, index) {
        try {
            const { driverDetails } = this.state;
            const item = driverDetails[index];
            const fromLocation = item.fromLocation.place || "";
            const toLocation = item.toLocation.place || "";

            if (item.fromLocation && item.fromLocation.place) {
                const from = await geocodeByAddress(fromLocation);
                const fromCoord = await getLatLng(from[0]);

                driverDetails[index].fromLocation['latitude'] = fromCoord.lat;
                driverDetails[index].fromLocation['longitude'] = fromCoord.lng;
                driverDetails[index].fromLocation['place'] = fromLocation;
            }

            if (item.toLocation && item.toLocation.place) {
                const to = await geocodeByAddress(toLocation);
                const toCoord = await getLatLng(to[0]);

                driverDetails[index].toLocation['latitude'] = toCoord.lat;
                driverDetails[index].toLocation['longitude'] = toCoord.lng;
                driverDetails[index].toLocation['place'] = toLocation;
            }
            this.setState({
                // locationLatLong,
                driverDetails
            });
        } catch (e) {
            console.log('error', e);
        }
    }

     getTrackingType = () => {
        const {trackingType,trackingName} = this.state
        const C1 = trackingType === 3
        if(C1){
            return "GPS Tracking"
        }  
        return trackingName
    }

  render() {
    const {
        locations, submitting, providers, gpsVendorType,
        vehicleTypes, vehicleBodyTypes, vehicleServiceTypes,
        seekers, operatorTypes, locationTypeFilter
    } = this.props;
    const { consigneeDetails,errorField,error,errorMessage ,trackingType, vendor} = this.state;
    let turnAroundTime = this.props.tat || this.state.turnAroundTime;
    (providers || []).map((provider) => {
        provider.display_name = provider.name + ' - (' + provider.email + ')';
    })
    const tripMode = 1;
    const mandatoryFields = getMandatoryFeilds(this.state.company_id);
    const isIsoTanker = (this.state.vehicleServiceType.name || '').toLowerCase().includes('iso');
    const isRoadTanker = (this.state.vehicleServiceType.name || '').toLowerCase().includes('road');
    const isCPLY = CPLY.includes(getUser().company_id)
    return (
      <Sticky activeLink={'trips'}>
        {submitting && <ActionLoader fixed={true} />}
        <section className="trip-detail-container pr-30 pl-10 add-trip-container add-t-main">
            <section className="trip-title-container flex flex-between mb-10">
                <div className="flex flex-middle flex-1">
                <BackIcon onClick={this.goBack}/>
                <span className="theme-color fs-24 fBolder mr-30 ml-15">New Trip</span>
                </div>
                <div className="wt-200 mr-10">
                    <Button value="Get Template" onClick={() => this.getAddTripTemplate(tripMode)} />
                </div>
                <div className="wt-200 mr-10">
                    <UploadButton value="Upload Trips" onChange={this.onFileUpload} />
                </div>
                <div className="wt-200">
                    <Button value="Save Trip" onClick={this.saveTrip} />
                </div>
            </section>
            <section className={'box-shadow pl-10 pr-10 pt-10 pb-10 br-5'}>
                <section className="pl-15 mb-30 flex mt-30">
                    <div className="flex-1 mr-20">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Service Type</div>
                        <Dropdown
                            onSelect={(value)=>{this.setState({serviceType: value})}}
                            customClass="flex-1 mr-20"
                            items={serviceTypes}
                            value={this.state.serviceType}
                            label={'— Select service — '}
                            selectedClassName={'col-1'}
                        />
                    </div>
                    <div className="flex-1 mr-20">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Bound Type</div>
                        <Dropdown
                            onSelect={(value)=>{this.setState({boundType: value})}}
                            customClass="flex-1 mr-20"
                            items={boundTypes}
                            value={this.state.boundType}
                            label={'— Select bound type — '}
                            selectedClassName={'col-1'}
                        />
                    </div>
                    <div className="flex-1 mr-20">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Run Type</div>
                        <Dropdown
                            onSelect={(value)=>{this.setState({runType: value})}}
                            customClass="flex-1 mr-20"
                            items={runTypes}
                            value={this.state.runType}
                            label={'— Select run type — '}
                            selectedClassName={'col-1'}
                        />
                    </div>
                    <div className="flex-1 mr-20">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Sub Request Type</div>
                        <Dropdown
                            onSelect={(value)=>{this.setState({subRequestType: value})}}
                            customClass="flex-1 mr-0"
                            items={subRequestTypes}
                            value={this.state.subRequestType}
                            label={'— Select sub service request — '}
                            selectedClassName={'col-1'}
                        />
                    </div>
            </section>
            <section className="flex pl-15 mb-30 flex mr-30">
                    <div className="wt-280">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Start Date<span className="error-color fs-14">*</span></div>
                        <div className="flex date-select">
                        <SingleDatePicker
                            noBorder={true}
                            displayFormat={"DD/MMM/YYYY"}
                            isOutsideRange={() => false}
                            hideKeyboardShortcutsPanel={true}
                            placeholder={"start date"}
                            isDayBlocked={(obj) => { return TTBIL.includes(userDetails.company_id) ? (obj < (new Date().setDate(new Date().getDate() - 4))) : (obj < moment(new Date())); }}
                            date={this.state.startDate ? this.state.startDate : moment(new Date())}
                            onDateChange={date => {
                                this.setState({
                                    startDate: moment(new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(this.state.startDate).format('HH:mm')).getTime())
                                })
                            }}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            id="startDate"
                        />
                        <TimePicker
                            showSecond={false}
                            value={this.state.startDate}
                            className="wt-110"
                            onChange={(time) => {
                                const { startDate } = this.state;
                                this.setState({
                                    startDate: time ? moment(new Date(moment(startDate).format('YYYY-MM-DD') + ' ' + (time || new Date()).format('HH:mm')).getTime()) : this.state.startDate
                                });
                            }}
                            format={'h:mm a'}
                            use12Hours
                            inputReadOnly
                        />
                        </div>
                        
                    </div>
                    <div className="mr-20 wt-280">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">Transporter Type</div>
                        <Dropdown
                            onSelect={(value)=>{this.setState({transportType: value})}}
                            customClass="flex-1 mr-20"
                            items={TRANSPORT_TYPE}
                            value={this.state.transportType}
                            label={'— Select transporter type — '}
                            selectedClassName={'col-1'}
                        />
                    </div>
            </section>
        </section>

        {!isSeeker && <Fragment>
            <section className={"pl-15 mb-20 flex mt-30"}>
                <div className="fs-18 theme-color fBolder" ref={(e) => {this.company_id = e; }}>
                    Seeker Details<span className="error-color">*</span>
                    {this.state.error && this.state.errorMessage && this.state.errorField == 'company_id' &&
                    <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>}
                </div>
            </section>
            <section className={"pl-15 mb-30 flex flex-between box-shadow pl-20 pr-20  pb-20 br-5"}>
                <div className="mt-20">
                <SearchDropdown
                    onChange={(value) => {
                        this.getSeekers(value);
                        if(value) {
                            this.setState({
                                company_id: value,
                                company: value
                            })
                        } else {
                            this.setState({
                                company_id: '',
                                company: ''
                            })
                        }
                    }}
                    onSelect = {(seeker)=> {
                        this.setState({
                            company_id: seeker.company_id,
                            company: seeker.name
                        }, () => {
                            const { startLocation , endLocation, company_id } = this.state;
                            if(startLocation.latitude &&  startLocation.longitude && endLocation.latitude && endLocation.longitude) {
                                this.getTatDetails(true);
                            }
                        })
                    }}
                    label={'Search Company'}
                    items={seekers || []}
                    // display_name={'emailName'}
                    value={this.state.company}
                    hasClearFilter = {() => {
                        this.setState({
                            company_id: '',
                            company: ''
                        })
                    }}
                    containerClassName={' wt-300'}
                >
                </SearchDropdown>
                </div>
            </section>
            </Fragment>
        }

        <section className="pl-15 mb-20 mt-40 flex ">
            <div className="fs-18 theme-color fBolder">
                Shipment Details
            </div>
        </section>
            <section className={'box-shadow pl-10 pr-10 pt-10 pb-10 br-5'}>
                <section className="pl-15 mb-40 flex flex-between">
                        <Input required containerClassName="mb-10 " name="doNo" type="text" value={this.state.doNo} placeholder="DO No." onChange={this.onChange} ref={(e) => { this.doNo = e; }} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField} disabled={TTBIL.includes(userDetails.company_id)}/>
                        <Input required={mandatoryFields.po_no} containerClassName="mb-10 " name="poNo" type="text" value={this.state.poNo} placeholder="PO No." onChange={this.onChange} ref={(e) => { this.poNo = e; }} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                        <Input required={mandatoryFields.lr_no} containerClassName="mb-10 " name="lrNo" type="text" value={this.state.lrNo} placeholder="LR No." onChange={this.onChange} ref={(e) => { this.lrNo = e; }} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                        <Input required={mandatoryFields.invoice_no} containerClassName="mb-10 " name="invoiceNo" title="Invoice number should contain only numerics and slash" type="text" value={this.state.invoiceNo} placeholder="Invoice No." onChange={this.onChange}/>
                        {isCPLY && <div className="wt-150 ml-20">
                            <b>Invoice Date</b>{mandatoryFields.invoice_date && <span className="error-color fs-14">*</span>}
                            <SingleDatePicker
                                    className={'box-shadow pl-10 pr-10 pt-10 pb-10 br-5'}
                                    noBorder={true}
                                    displayFormat={"DD/MMM/YYYY"}
                                    isOutsideRange={() => false}
                                    hideKeyboardShortcutsPanel={true}
                                    placeholder={"Select Date"}
                                    date={this.state.invoice_date } // Set the state for the date picker
                                    onDateChange={(date) => this.setState({ invoice_date : date })} // Handle date change
                                    focused={this.state.invoiceDateFocused} // Set the state for focus
                                    onFocusChange={({ focused }) => this.setState({ invoiceDateFocused: focused })} // Handle focus change
                                    name="invoice_date"
                                    numberOfMonths={1}  
                                />
                                {error && errorMessage && errorField == "invoice_date" && <div className={'error-color fs-12'}>{errorMessage}</div>}
                            </div>}
                        <Input required containerClassName="mb-10 " name="itemName" type="text" value={this.state.itemName} placeholder="Item Name" onChange={this.onChange} ref={(e) => { this.itemName = e; }} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                </section>
                <section className="pl-15 mb-30 flex flex-middle">
                        {/* <div>
                            <div className="mr-10 fs-14 high-value-shipment fBold">  Priority Shipment? </div>
                            <div className="tab-on-map">
                                <Tabs selectTab={this.selectTabValue} activeTabId={this.state.highValue} tabs={shipmentValue}/>
                            </div>
                        </div>

                        <Input required containerClassName="ml-20 " name="itemValue" type="text" value={this.state.itemValue} placeholder="Value" onChange={this.onChange}/>
                        <div className="wt-250">
                        <Dropdown
                                onSelect={(value)=>{this.setState({valueUnit: value})}}
                                customClass="ml-20"
                                items={valueUnits}
                                value={this.state.valueUnit}
                                label={''}
                            />
                        </div> */}
                        <Input name="cargoWeight" type="text" containerClassName="mr-20" value={this.state.cargoWeight} placeholder="Cargo Weight" onChange={this.onChange}/>
                        <div className="wt-100 mt-25 mr-10">
                        <Dropdown
                                onSelect={(value)=>{this.setState({cargoUnit: value})}}
                                items={[...CARGO_UNITS]}
                                value={this.state.cargoUnit}
                                label={'Select Cargo Unit'}
                                selectedClassName={'col-1'}
                            />
                        </div>
                        <Input name="customer_name" type="text" value={this.state.customer_name} placeholder="Customer Name" onChange={this.onChange}/>
                        {GALAXY.includes(userDetails.company_id) && <Input containerClassName="ml-20" name="consignment_note_no" type="text" value={this.state.consignment_note_no} placeholder="Consignment Note No." onChange={this.onChange}/>}
                </section>
             </section>
          <section className="pl-15 mb-20 flex mt-40">
                <div className="fs-18 theme-color fBolder" ref={(e) => { this.RouteDetails = e; }}>
                    Route details<span className="error-color">*</span>
                    {this.state.error && this.state.errorMessage && this.state.errorField == 'routeDetails' &&
                    <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>}
                    {isSeeker && <span><Button value="+ Add Location Network" className="mt-30" onClick={() => { this.setState({ isLinkAdded: true, consigneeDetails: Object.assign({}, consigneeData) }) }} /></span>}
                </div>
          </section>
          {
             this.state.isLinkAdded &&
            <div className="overlay">
                <div className="overlay-container change-vehicle-popup modify-popup">
                    <span className="overlay-close-button fs-20" onClick={() => { this.setState({ isLinkAdded: false }) }}> X </span>
                    <div className="overlay-body create-location-popup">
                        <div className="fs-18 mt-10 mb-10">Network or Consignee Details</div>
                        <div className="flex mb-20 mt-10">
                            {
                                // <Input containerClassName="mb-10 mr-10 z-1" name="location_type" type="text" value={this.state.consigneeDetails.location_type} placeholder="Type" onChange={this.onChangeConsignee}/>
                            }
                            <div className="wt-250">
                                <div className="fs-12 fBolder mb-5">Location Type<span className="error-color">{"*"}</span></div>
                                <Dropdown
                                    onSelect={(value)=>{
                                        const { consigneeDetails } = this.state;
                                        consigneeDetails.location_item = value;
                                        consigneeDetails.location_type = value.id;
                                        consigneeDetails.location_type_name = value.name;
                                        this.setState({ consigneeDetails });
                                    }}
                                    customClass="flex-1 mr-20 required"
                                    items={locationTypeFilter && locationTypeFilter.all_location_types ? locationTypeFilter.all_location_types : []}
                                    value={consigneeDetails.location_item}
                                    label={'Select Location Type'}
                                    selectedClassName={'col-1'}
                                />
                            </div>
                            <Input containerClassName="mb-10 mr-20 z-1" required name="plant_name" type="text" value={consigneeDetails.plant_name} placeholder="Company Name" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                            <Input containerClassName="mb-10 mr-20 z-1" required name="plant_code" type="text" value={consigneeDetails.plant_code} placeholder="Plant/Vendor Code" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                            <Input containerClassName="mb-10 mr-20 z-1" name="pan_no" type="text" value={consigneeDetails.pan_no} placeholder="PAN No." onChange={this.onChangeConsignee}/>
                        </div>
                        <div className="fs-18 mt-10 mb-10">Primary contact details</div>
                        <div className="flex mb-20 mt-10">
                            <Input containerClassName="mb-10 mr-10 z-1" name="name" type="text" value={consigneeDetails.name} placeholder="Name" onChange={this.onChangeConsignee}/>
                            <Input containerClassName="mb-10 mr-10 z-1" name="email" type="text" value={consigneeDetails.email} placeholder="Email" onChange={this.onChangeConsignee}/>
                            <Input containerClassName="mb-10 mr-10 z-1" name="contact" type="text" value={consigneeDetails.contact} placeholder="Phone No." onChange={this.onChangeConsignee}/>
                        </div>
                        <div className="fs-18 mt-10 mb-10">Address details</div>
                        <div className="mb-20 mt-10">
                            <div className="flex">
                                <Input containerClassName="mb-10 mr-10 wt-200 z-1" required name="address" type="text" value={consigneeDetails.address} placeholder="Address" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                                <Input containerClassName="mb-10 mr-10 z-1" required name="latitude" type="text" value={consigneeDetails.latitude} placeholder="Latitude" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                                <Input containerClassName="mb-10 mr-10 z-1" required name="longitude" type="text" value={consigneeDetails.longitude} placeholder="Longitude" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                            </div>
                            <div className="flex">
                                <Input containerClassName="mb-10 mr-10 z-1" required name="zipcode" type="text" value={consigneeDetails.zipcode} placeholder="Zipcode" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                                <Input containerClassName="mb-10 mr-10 z-1" required name="city" type="text" value={consigneeDetails.city} placeholder="City" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                                <Input containerClassName="mb-10 mr-10 z-1" required name="state" type="text" value={consigneeDetails.state} placeholder="State" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                                {!AWL.includes(userDetails.company_id) && <Input containerClassName="mb-10 mr-10 z-1" name="zone" type="text" value={consigneeDetails.zone} placeholder="Zone" onChange={this.onChangeConsignee}/>}
                                <Input containerClassName="mb-10 mr-10 z-1" required name="country" type="text" value={consigneeDetails.country} placeholder="Country" onChange={this.onChangeConsignee} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                            </div>
                        </div>
                        <div className="fs-18 mt-10 mb-10">Timings</div>
                        <div className="flex mb-20 mt-10">
                            {/*<div className="mr-20">
                                <div className="fs-12 communication-color fBold mb-5">Daily Report Cut Off Time</div>
                                <TimePicker
                                    showSecond={false}
                                    value={moment(consigneeDetails.cut_off_time)}
                                    className="ht-40 wt-250"
                                    onChange={(time) => {
                                                const { consigneeDetails } = this.state;
                                                consigneeDetails.cut_off_time = new Date(moment().format('YYYY-MM-DD') + ' ' + time.format('HH:mm')).getTime()
                                                this.setState({ consigneeDetails });
                                            }}
                                    format={'h:mm a'}
                                    use12Hours
                                />
                            </div>*/}
                            <Input containerClassName="mb-10 mr-10 z-1" name="cut_off_time" type="text" value={consigneeDetails.cut_off_time} placeholder="Daily Report Cut Off Time" onChange={this.onChangeConsignee}/>
                            <div className="wt-250">
                                <div className="fs-12 fBolder mb-5">Weekday</div>
                                <Dropdown customClass="flex-1 mr-20" label={'Select day'}
                                    onSelect={(value)=>{
                                        const { consigneeDetails } = this.state;
                                        consigneeDetails.week = value;
                                        consigneeDetails.weekly_off = value.name;
                                        this.setState({ consigneeDetails });
                                    }}
                                    items={[{name: 'Sunday'}, {name: 'Monday'}, {name: 'Tuesday'}, {name: 'Wednessday'}, {name: 'Thursday'}, {name: 'Friday'}, {name: 'Saturday'}]}
                                    value={consigneeDetails.week}
                                    selectedClassName={'col-1'}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <Button value="Save" className="mb-30 wt-150 mr-30" onClick={this.createLocation} />
                            <Button value="Cancel" className="mb-30 wt-150 mr-30" onClick={() => { this.setState({ isLinkAdded: false }) }} />
                        </div>
                    </div>
                </div>
            </div>
          }
          <section className="mb-30 box-shadow pl-20 pr-20 pt-20 pb-20 br-5" style={{width: '1450px'}}>
                <div className="flex pl-20 pr-30 fBolder">
                        <div className="wt-65 mt--5">
                            <React.Fragment>
                                <a data-tip data-for={`src`} className={'ml-50'}>
                                    <div className="pb-5 fBold mt--15">Origin
                                        <EsclamationIcon style={{ width: '15px', height: '11px' }}/>
                                    </div>
                                </a>
                                <ReactTooltip id={`src`} type="info" effect="solid" border={true}>
                                    Only the INITIAL shipment LOADING POINT must be considered as origin.
                                </ReactTooltip>
                            </React.Fragment>
                        </div>
                        <div className="wt-65 mt--5">
                            <React.Fragment>
                                <a data-tip data-for={`destn`} className={'ml-50'}>
                                    <div className="pb-5 fBold mt--15">Destn.
                                        <EsclamationIcon style={{ width: '15px', height: '11px' }}/>
                                    </div>
                                </a>
                                <ReactTooltip id={`destn`} type="info" effect="solid" border={true}>
                                    Mark shipment UNLOADING POINT(S) as destination(in case of multiple drops each drop point needs to be marked as destination).
                                </ReactTooltip>
                            </React.Fragment>
                        </div>
                        <div className="wt-200 ml-10">
                            Location
                        </div>
                        <div className="wt-150 ml-10">
                            Location Type
                        </div>
                        <div className="wt-80 ml-10">
                            PIN Code
                        </div>
                        <div className="wt-200 ml-10">
                            Notification Emails
                        </div>
                        <div className="wt-175 ml-10">
                            Notification Numbers
                        </div>
                        <div className="wt-80 pl-10 ml-10">
                            TAT
                        </div>
                        {TTBIL.includes(userDetails.company_id) &&
                        <>
                        <div className="wt-150 ml-10">
                            Customer Name
                        </div>
                        <div className="wt-100 pl-10 ml-10">
                            LR Number
                        </div>
                        </>}
                </div>
                <div className="flex pl-20 pr-30 route-form-row-container">
                    <span className="location-side-marker">
                        <span className="blue"> </span>
                    </span>
                    <RouteDetailForm
                        locations = {locations}
                        route={this.state.startLocation}
                        typesOfLocation={typesOfLocation}
                        index={"start"}
                        handleRouteDataChange={this.handleStartEndlocationChange}
                        label={"Start Location"} />
                </div>

                {
                    this.state.routeDetails.map((route, index) => {
                        return <div key={index + '-div'} className="flex mt-10 pl-20 pr-30 route-form-row-container">
                                <span className="location-side-marker">
                                    <span className="blue hole"> </span>
                                </span>
                                {/* <span className="location-side-marker right">
                                </span> */}
                                <span className="remove-button">
                                    <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.removeLocation(route.id) }}> - </span>
                                </span>
                                 <RouteDetailForm
                                    locations = {locations}
                                    key={route.id}
                                    route={route}
                                    typesOfLocation={typesOfLocation}
                                    index={route.id}
                                    handleRouteDataChange={this.handleRouteDataChange}
                                    label={"Enter Location"}/>
                                </div>
                    })
                }
                <div className="flex flex-right add-trip-add-btn-row">
                    <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.addLocation(uuid4()) }}> + </span>
                    <span className="fs-24 add-trip-add-row-btn left"></span>
                </div>
                <div className="flex pl-20 pr-30 end-row route-form-row-container">
                    <span className="location-side-marker">
                        <span className="blue"> </span>
                    </span>
                    <RouteDetailForm
                        locations = {locations}
                        route={this.state.endLocation}
                        typesOfLocation={typesOfLocation}
                        index={"end"}
                        handleRouteDataChange={this.handleStartEndlocationChange}
                        label={"End Location"} />
                </div>
                <div className="wt-200 mt-10 ml-20 tat-disp inBlock">
                    <Input required containerClassName="mb-10 " name="turnAroundTime" type="number" value={this.state.turnAroundTime} placeholder="TAT (TURN AROUND TIME) Days" onChange={this.onChange} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                </div>
                <div className="wt-200 mt-10 ml-50 tat-disp inBlock">
                    <Input required={mandatoryFields.route_code} containerClassName="mb-10 " name="routeCode" value={this.state.routeCode} placeholder="ROUTE CODE" onChange={this.onChange} error={this.state.error} errorMessage={this.state.errorMessage} errorField={this.state.errorField}/>
                </div>
          </section>
          <section className="pl-15 mb-20 flex mt-40">
                <div className="fs-18 theme-color fBolder" ref={(e) => { this.comments = e; }}>
                    Comments/Additional Information
                    {/* <span className="error-color">*</span>
                    {this.state.error && this.state.errorMessage && this.state.errorField == 'comments' &&
                    <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>} */}
                </div>
          </section>
          <section className="mb-30 box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                <div>
                    <textarea className={'col-1 br-5 pb-5 pt-5 fs-14'} value={this.state.comments} onChange={this.onChangeComments}/>
                </div>
          </section>
          <section className="pl-15 mb-20 flex mt-40">
                <div className="fs-18 theme-color fBolder">
                    Geofence Locations
                </div>
          </section>
          <section className="mb-30 geofence box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
            {
                this.state.geofenceLocations.map((loc, index) => {
                    return <div className="flex mb-5 pl-20 pr" key={index}>
                        <span className={classNames("location-side-marker", {
                            'last-in-geofence': index === this.state.geofenceLocations.length - 1
                        })}>
                            <span className="blue"> </span>
                        </span>
                        <span className={classNames("remove-btn-row", {
                            'first-in-geofence': index === 0
                        })}>
                            <span className="location-side-marker right ">
                            </span>
                            <span className="remove-button">
                                <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.removeGeoFenceLocation(loc.id) }}> - </span>
                            </span>
                        </span>

                        <PlacesAutocomplete
                            key={index}
                            value={loc.location}
                            onChange={(value) => {this.fetchLocationsForGeoFencing(value, index)}}
                            onSelect={(value) => { this.handleGeoFencingLocationSelect(value, index)}}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="dropdown-container">
                                <div className="dropdown-container 2 ht-40 pr">
                                    <input
                                     {...getInputProps({
                                        placeholder: 'Add location',
                                        className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-400',
                                    })} />
                                </div>
                                <div className="row-100">
                                     <ul className="dropdown-items flex flex-column" style={{'width': `600px`}}>
                                    {
                                        suggestions.map((item, i) => {
                                            const className = "dropdown-item pl-16 ht-40";
                                            return (
                                                <li
                                                    {...getSuggestionItemProps(item, {
                                                        className,
                                                    })}
                                                >
                                                    <abbr title={item.description}>
                                                        {item.description.substr(0, 75)}
                                                        {item.description.length > 75?'...':''}
                                                    </abbr>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                })
            }

            <div className="flex flex-right add-trip-add-btn-row wt-450">
                <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.addGeofenceLocation(this.state.geofenceLocations.length) }}> + </span>
                <span className="fs-24 add-trip-add-row-btn left"></span>
            </div>
          </section>
          <section className={"pl-15 mb-20 flex"}>
                <div className="fs-18 theme-color fBolder mt-30" ref={(e) => { this.transporterDetails = e; }}>
                    Transporter Details<span className="error-color">*</span>
                    {this.state.error && this.state.errorMessage && this.state.errorField == 'transporterDetails' &&
                    <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>}
                </div>
          </section>
          <section className={classNames("pl-15 mb-30 flex flex-between box-shadow pl-20 pr-20  pb-20 br-5",{
              'disabled' : !isSeeker
          })}>
                {/* <Input required containerClassName="mb-10 " name="transporterName" type="text" value={this.state.transporterName} placeholder="NAME" onChange={this.onChange}/> */}
                <div className="mt-20">
                <SearchDropdown
                    onChange={(value) => {
                        this.getProviders(value);
                        if(value) {
                        this.setState({
                            transporterName: value
                        })
                        } else {
                            this.setState({
                                transporterName: '',
                                transporterEmail: '',
                                transporterPan: '',
                                transporterId: '',
                                transporterContact: ''
                            })
                        }
                    }}
                    onSelect = {(provider)=> {
                        this.setState({
                            transporterName: provider.name,
                            transporterEmail: provider.email,
                            transporterPan: provider.pan_no,
                            transporterId: provider.provider_id,
                            transporterContact: provider.number,
                            error: false
                        })
                    }}
                    label={'Search Provider'}
                    items={providers || []}
                    value={this.state.transporterName}
                    hasClearFilter = {() => {
                        this.setState({
                            transporterName: '',
                            transporterEmail: '',
                            transporterPan: '',
                            transporterId: '',
                            transporterContact: ''
                        })
                    }}
                />
                </div>
                <Input containerClassName="mb-10 " name="transporterEmail" type="text" value={this.state.transporterEmail} placeholder="EMAIL ID" onChange={this.onChange}/>
                <Input containerClassName="mb-10 " name="transporterContact" type="number" value={this.state.transporterContact} placeholder="CONTACT NO." onChange={this.onChange}/>
                <Input containerClassName="mb-10 " name="transporterPan" type="text" value={this.state.transporterPan} placeholder="PAN NO." onChange={this.onChange}/>
          </section>

          <section className="pl-15 mb-20 flex mt-40">
                <div className="fs-18 theme-color fBolder">
                    Tracking preference
                </div>
          </section>
            <section className="pl-15 mb-30 flex flex-between add-trip-tracking-preference box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                {
                    this.state.trackingPreferences.map((preference, index)=> {
                        return (
                            <Checkbox 
                                onCheck={this.changePreference.bind(this, index)} 
                                id={index} key={index} className="mr-70" 
                                checked={preference.enabled} 
                                label={preference.tracking_name}
                            />
                        )
                    })
                }
            </section>
          <section>
            {
                this.state.trackingPreferences[2].enabled &&
                    <div className="mb-20 wt-300">
                        <header className="fs-12 theme-color fBolder mb-20">GPS Vendor: </header>
                        <Dropdown
                            value={this.state.gpsVendors || gpsVendorType[0]}
                            onSelect={this.selectGPSVendor.bind(this)}
                            onChange={this.selectGPSVendor.bind(this)}
                            items={gpsVendorType}
                            label="Select Vendor type"
                            selectedClassName={'col-1 disabled'}
                        />
                    </div>
            }
          </section>
          <section className="pl-15 mb-20 flex mt-40">
                <div className="fs-18 theme-color fBolder" ref={(e) => {this.driverNo = e; }}>
                    Driver Details
                    {this.state.error && this.state.errorMessage && this.state.errorField == 'driverNo' &&
                    <span className={'error-color fs-12'}>{`  ${this.state.errorMessage}`}</span>}
                </div>
          </section>

          <section className="mb-30 driver-container box-shadow pl-20 pr-20 pt-20 pb-20 br-5">
                <div className="fs-16 fBolder mb-10" ref={(e) => { this.selectedCK = e; }}>
                    Is Double Driver? <span className={'error-color fs-12'}>*</span>
                </div>
                <div className="flex mb-15">
                    <Checkbox className="mr-20" label={'Yes'} onCheck={() => { this.setState({ selectedCK: 'Yes' }) }} checked={this.state.selectedCK === 'Yes'} />
                    <Checkbox className="mr-20" label={'No'} onCheck={() => { this.setState({ selectedCK: 'No' }) }} checked={this.state.selectedCK === 'No'} />
                </div>
                {!this.state.selectedCK && this.state.error && this.state.errorMessage && this.state.errorField == 'selectedCK' &&<div className="selectedCK error-color fs-12">{`${this.state.errorMessage}`}</div>}
                {
                    (this.state.driverDetails || []).map((driver, index) => {
                        return <div className="flex flex-row">
                            <Input containerClassName="mb-10" name="driverName" type="text" value={driver.name} placeholder="FIRST NAME" onChange={(e) => this.handleDriverChange("firstName", e.target.value, driver.id)}/>
                            <Input containerClassName="mb-10 ml-20" name="driverName" type="text" value={driver.name} placeholder="LAST NAME" onChange={(e) => this.handleDriverChange("lastName", e.target.value, driver.id)}/>
                            <div>
                                <Input
                                    containerClassName="mb-10 ml-20"
                                    name="driverNo"
                                    type="number"
                                    pattern="[1-9]{1}[0-9]{9}"
                                    value={driver.number}
                                    placeholder="CONTACT NO."
                                    onChange={(e) => this.handleDriverChange("number", e.target.value, driver.id)}
                                />
                                {
                                    driver.number.length > 1 && driver.number.length < 10 &&
                                    <span className={'error-color fs-12 ml-50'}>{`Invalid Phone No.`}</span>
                                }
                            </div>
                            {
                            // <SearchDropdown
                            //     hideSearch={true}
                            //     onChange={(value)=>this.searchLocation(value, driver.id, 'fromLocation', true)}
                            //     onSelect={(value)=>this.searchLocation(value, driver.id, 'fromLocation', false)}
                            //     label={'From Location'}
                            //     items={locations || []}
                            //     value={driver.fromLocation.location}
                            //     selectedClassName={"route-detail-search"}
                            //     containerClassName={' mt-20 ml-10'}
                            //     hasClearFilter={(value)=>this.searchLocation('', driver.id, 'fromLocation', true)}
                            // />
                            }
                            {
                                // this.state.geofenceLocationsFrom.map((loc) => {
                                //     return
                                    <div className="mb-5 pl-20 pr">
                                        <div className="fs-12 communication-color fBolder text-uppercase mb-5">From Location:</div>
                                        <PlacesAutocomplete
                                            key={index}
                                            onChange={(value) => { this.fetchLocations(value, 'fromLocation', index)}}
                                            onSelect={(value) => { this.handleLocationSelect(value, 'fromLocation', index)}}
                                            searchOptions={{type:['cities']}}
                                            value={driver.fromLocation && driver.fromLocation.place ? driver.fromLocation.place : ''}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="dropdown-container">
                                                <div className="dropdown-container 2 ht-40 pr">
                                                    <input
                                                     {...getInputProps({
                                                        placeholder: 'From Location',
                                                        className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-200',
                                                    })} />
                                                </div>
                                                <div className="row-100">
                                                     <ul className="dropdown-items flex flex-column" style={{'width': `600px`}}>
                                                    {
                                                        suggestions.map((item, i) => {
                                                            const className = "dropdown-item pl-16 ht-40";
                                                            return (
                                                                <li
                                                                    {...getSuggestionItemProps(item, {
                                                                        className,
                                                                    })}
                                                                >
                                                                    <abbr title={item.description}>
                                                                        {item.description.substr(0, 75)}
                                                                        {item.description.length > 75?'...':''}
                                                                    </abbr>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                // })
                            }
                            {
                            // <SearchDropdown
                            //     hideSearch={true}
                            //     onChange={(value)=>this.searchLocation(value, driver.id, 'toLocation', true)}
                            //     onSelect={(value)=>this.searchLocation(value, driver.id, 'toLocation', false)}
                            //     label={'To Location'}
                            //     items={locations || []}
                            //     value={driver.toLocation.location}
                            //     selectedClassName={"route-detail-search"}
                            //     containerClassName={' mt-20 ml-10'}
                            //     hasClearFilter={()=>this.searchLocation('', driver.id, 'toLocation', true)}
                            // />
                            }
                            {
                                // this.state.geofenceLocationsTo.map((loc) => {
                                //     return
                                    <div className="mb-5 pl-20 pr">
                                        <div className="fs-12 communication-color fBolder text-uppercase mb-5">To Location:</div>
                                        <PlacesAutocomplete
                                            key={index}
                                            onChange={(value) => { this.fetchLocations(value, 'toLocation', index)}}
                                            onSelect={(value) => { this.handleLocationSelect(value, 'toLocation', index)}}
                                            searchOptions={{type:['cities']}}
                                            value={driver.toLocation && driver.toLocation.place ? driver.toLocation.place : ''}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="dropdown-container">
                                                <div className="dropdown-container 2 ht-40 pr">
                                                    <input
                                                     {...getInputProps({
                                                        placeholder: 'To Location',
                                                        className: 'selected-item row-100 flex flex-middle pl-40 pr-16  route-detail-search wt-200',
                                                    })} />
                                                </div>
                                                <div className="row-100">
                                                     <ul className="dropdown-items flex flex-column" style={{'width': `600px`}}>
                                                    {
                                                        suggestions.map((item, i) => {
                                                            const className = "dropdown-item pl-16 ht-40";
                                                            return (
                                                                <li
                                                                    {...getSuggestionItemProps(item, {
                                                                        className,
                                                                    })}
                                                                >
                                                                    <abbr title={item.description}>
                                                                        {item.description.substr(0, 75)}
                                                                        {item.description.length > 75?'...':''}
                                                                    </abbr>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                // })
                            }


                            {
                                index > 0 &&
                                <div className={classNames("flex flex-right add-trip-add-btn-row driver-remove wt-450 r-40", {
                                    'opr-gps': this.state.trackingPreferences[1].enabled && this.state.trackingPreferences[2].enabled
                                })}>
                                    <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.removeDriver(driver.id) }}> - </span>
                                </div>
                            }
                            {<div className="mb-20 wt-300 ml-10">
                                <div className="fs-12 communication-color fBolder text-uppercase mb-5">Operator Type:
                                    {driver.number.length > 0 && <span className={'error-color'}>*</span>}
                                </div>
                                <Dropdown value={driver.operatorType} onSelect={this.selectOperatorType.bind(this, index)} items = {operatorTypes} label="Select Operator type"  selectedClassName={'col-1'}/>
                            </div>}
                            {/* {this.state.trackingPreferences[2].enabled && <div className="mb-20 wt-300 ml-20">
                                <div className="fs-12 communication-color fBolder text-uppercase mb-5">GPS Vendor: </div>
                                <Dropdown value={driver.gpsVendor} onSelect={this.selectGPSVendor.bind(this, index)} items = {gpsVendorType} label="Select Vendor type"  selectedClassName={'col-1'}/>
                            </div>} */}
                        </div>
                    })
                }

                <div className={classNames('flex flex-right add-trip-add-btn-row driver-add wt-450 r-40',{
                    'opr-gps': this.state.trackingPreferences[1].enabled && this.state.trackingPreferences[2].enabled
                })}>
                    <span className="fs-24 add-trip-add-row-btn" onClick={() => { this.addDriver() }}> + </span>
                </div>
          </section>
            <section className="pl-15 mb-20 flex mt-40">
                <div className="fs-18 theme-color fBolder">
                    Vehicle Details
                </div>
            </section>
            <section className="box-shadow pl-10 pr-10 pt-10 pb-10 br-5">
                <section className="pl-15 mb-10 flex flex-middle">
                    {
                        vehicleServiceTypes && vehicleServiceTypes.length > 0 &&
                        <div className="mb-20 wt-250 ml-10">
                            <div className="fs-12 communication-color fBolder text-uppercase mb-5">
                                Vehicle Service Type
                            </div>
                            <Dropdown
                                onSelect={this.selectVehicleServiceType.bind(this)}
                                onChange={this.selectVehicleServiceType.bind(this)}
                                customClass="mr-20"
                                items={vehicleServiceTypes}
                                value={this.state.vehicleServiceType}
                                label={'— Select Service type —'}
                                selectedClassName={'col-1'}
                                searchEnabled={true}
                            />
                        </div>
                    }
                    <div className="wt-300" ref={(e) => {this.vehicleRegNo = e; }}>
                        <Input
                            containerClassName="mb-10 "
                            name="vehicleRegNo"
                            type="text"
                            value={this.state.vehicleRegNo}
                            placeholder="VEHICLE REG NO."
                            onChange={(e) => this.onChangeVehicleDetails('vehicleRegNo', e.target.value)}
                        />
                        {trackingType && vendor && (
                        <div>
                        This vehicle was previously tracked with 
                        "<span style={{color:"#8f00ff"}}>{this.getTrackingType()}</span>"
                        by the vendor 
                        "<span style={{color:"#8f00ff"}}>{vendor}</span>".
                        </div>
                        )}
                    </div>
                    {
                        <div className="mb-10 wt-400 ml-10">
                            <div className="fs-12 communication-color fBolder text-uppercase mb-5">
                                Vehicle Type
                            </div>
                            <Dropdown
                                onSelect={(value)=>{this.setState({vehicleType: value})}}
                                customClass="mr-20"
                                items={vehicleTypes}
                                value={this.state.vehicleType}
                                label={'— Select vehicle type — '}
                                selectedClassName={'col-1'}
                                searchEnabled={true}
                            />
                        </div>
                    }
                    {
                        <div className="mb-10 wt-300 ml-10">
                            <div className="fs-12 communication-color fBolder text-uppercase mb-5">
                                Body Type
                            </div>
                            <Dropdown
                                onSelect={(value)=>{this.setState({vehicleBodyType: value})}}
                                customClass="mr-20"
                                items={vehicleBodyTypes}
                                value={this.state.vehicleBodyType}
                                label={'— Select body type — '}
                                selectedClassName={'col-1'}
                            />
                        </div>
                    }
                </section>
                <section className="pl-15 pt-20 mb-40 flex flex-middle">
                    {
                        (isRoadTanker || isIsoTanker) && GALAXY.includes(userDetails.company_id) &&
                        <Fragment>
                            <div className="mb-20 wt-220 ml-10">
                                <div className="fs-12 communication-color fBolder text-uppercase mb-5">
                                        Washing Status
                                </div>
                                <div className="dropdown-container ht-40 pr flex-1">
                                    <Dropdown
                                        onSelect={(value)=>{ 
                                            let { washingStatus } = this.state;
                                            washingStatus = value.name;
                                            this.setState({washingStatus})
                                            }}
                                        customClass="mr-20"
                                        items={washingType || []}
                                        value={{name: this.state.washingStatus, value: this.state.washingStatus}}
                                        label={'— Select washing type — '}
                                        selectedClassName={'col-1'}
                                        // searchEnabled={true}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    }
                    {
                        GALAXY.includes(userDetails.company_id) && (isRoadTanker || isIsoTanker) && this.state.washingStatus === 'Sealed' &&
                        <Fragment>
                            <div className="mb-20 wt-250 ml-10">
                                <div className="fs-12 communication-color fBolder text-uppercase mb-5">
                                        No. of Trips Completed
                                </div>
                                <div className="dropdown-container ht-40 pr flex-1">
                                    <Dropdown
                                        onSelect={(value)=>this.setState({noOfWashes: value})}
                                        customClass="mr-20"
                                        items={([1,2,3,4,5]).map((el)=> { return { value: el, name: el }})}
                                        value={this.state.noOfWashes}
                                        label={'— Trips completed — '}
                                        selectedClassName={'col-1'}
                                        // searchEnabled={true}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    }
                    {
                        isIsoTanker &&
                        <div className="pl-15 wt-250" ref={(e) => {this.isoNo = e; }}>
                            <Input
                                containerClassName="mb-10 "
                                name="isoNo"
                                type="text"
                                value={this.state.isoNo}
                                placeholder="ISO NO."
                                onChange={(e) => this.onChangeVehicleDetails('isoNo', e.target.value)}
                            />
                        </div>
                    }
                    {
                        (isRoadTanker || isIsoTanker) &&
                        <div className="pl-15 wt-200" ref={(e) => {this.loadingCapacity = e; }}>
                            <Input
                                containerClassName="mb-10"
                                name="loadingCapacity"
                                type="number"
                                value={this.state.loadingCapacity || ""}
                                placeholder="Water Loading Capacity (litres)"
                                onChange={this.onChange}
                            />
                        </div>
                    }
                </section>
            </section>
          {/* <section className="pl-15 flex mt-40">
                <div className="fs-18 theme-color fBolder">
                    Notification Setting
                </div>
          </section> */}
          {/* <section className="pl-15 pr-15 mb-10 flex flex-between mt-20 box-shadow pl-10 pr-10 pt-20 pb-20 br-5">
                <div className="">
                    <div className="fs-16 mb-20 fBolder communication-address-type">Head office</div>
                    <div >
                        {
                            this.state.headOfficeNotification.accounts.map((account, index) => {
                                return <div className="communication-address mt-10 pr"  key={index}>
                                    {this.state.headOfficeNotification.accounts.length > 1 && <span className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--15 r--15"
                                        onClick={() => this.removeHeadOfficeAccount(index)}>
                                        <CloseIcon />
                                    </span>}
                                    <Input containerClassName="mb-10 " name="email" type="text" value={account.email} placeholder="@ EMAIL ID" onChange={(e)=>{ this.handleNotificationAccountChange("head", "email", e.target.value, index)}}/>
                                    <Input containerClassName="mb-10 " name="contactNo" type="number" value={account.sms} placeholder="&#9993; SMS" onChange={(e)=>{ this.handleNotificationAccountChange("head", "sms", e.target.value, index) }}/>
                                </div>
                            })
                        }
                    </div>
                    <Button value="+ Add new communication account" className="add-communication-button mt-30" onClick={this.addHeadOfficeAccount} />
                </div>
                <div className="">
                    <div className="fs-16 mb-20 fBolder communication-address-type">Plant</div>
                    <div >
                        {
                            this.state.plantNotification.accounts.map((account, index) => {
                                return <div className="communication-address mt-10 pr"  key={index}>
                                {this.state.plantNotification.accounts.length > 1 && <span className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--15 r--15"
                                    onClick={() => this.removePlantAccount(index)}>
                                    <CloseIcon />
                                </span>}
                                <Input containerClassName="mb-10 " name="email" type="text" value={account.email} placeholder="@ EMAIL ID" onChange={(e)=>{ this.handleNotificationAccountChange("plant", "email", e.target.value, index)}}/>
                                <Input containerClassName="mb-10 " name="contactNo" type="number" value={account.sms} placeholder="&#9993; SMS" onChange={(e)=>{ this.handleNotificationAccountChange("plant", "sms", e.target.value, index) }}/>
                                </div>
                            })
                        }
                    </div>
                    <Button value="+ Add new communication account" onClick={this.addPlantAccount} className="add-communication-button mt-30" />
                </div>
                <div className="">
                    <div className="fs-16 mb-20 fBolder communication-address-type">Depot</div>
                    <div >
                        {
                            this.state.depoNotification.accounts.map((account, index) => {
                                return <div className="communication-address mt-10 pr"  key={index}>
                                    {this.state.depoNotification.accounts.length > 1 && <span className="flex flex-middle curP ml-10 border-theme-light-dashed br-50p pl-5 pb-5 pr-5 pt-5 pa t--15 r--15"
                                        onClick={() => this.removeDepoAccount(index)}>
                                        <CloseIcon />
                                    </span>}
                                    <Input containerClassName="mb-10 " name="email" type="text" value={account.email} placeholder="@ EMAIL ID" onChange={(e)=>{ this.handleNotificationAccountChange("depo", "email", e.target.value, index)}}/>
                                    <Input containerClassName="mb-10 " name="contactNo" type="number" value={account.sms} placeholder="&#9993; SMS" onChange={(e)=>{ this.handleNotificationAccountChange("depo", "sms", e.target.value, index) }}/>
                                </div>
                            })
                        }
                    </div>
                    <Button value="+ Add new communication account" onClick={this.addDepoAccount} className="add-communication-button mt-30" />
                </div>
          </section> */}
        </section>
        <div className="wt-200 ml-25 mt-50">
            <Button value="Save Trip" onClick={this.saveTrip} />
        </div>
      </Sticky>
    );
  }
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch({ type: GET_GPS_VENDORS, seekerId: seekerId });
    dispatch({ type: GET_OPERATOR_TYPE });
    dispatch({ type: GET_VEHICLE_DETAIL_OPTIONS, params: {seeker_id: seekerId} });
    dispatch({ type: GET_LOCATION_TYPE_FILTER });
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.notificationSettings !== this.props.notificationSettings) {
        const headOffice = (nextProps.notificationSettings || []).filter(obj => obj.plant_type===1);
        const plant = (nextProps.notificationSettings || []).filter(obj => obj.plant_type===2);
        const depotWarehouse = (nextProps.notificationSettings || []).filter(obj => obj.plant_type===3);

        if (headOffice && headOffice.length > 0 && headOffice[0].accounts && headOffice[0].accounts.length > 0) {
            const { headOfficeNotification } = this.state;
            headOfficeNotification.accounts = headOffice[0].accounts;
            this.setState({ headOfficeNotification })
        }

        if (plant && plant.length > 0 && plant[0].accounts && plant[0].accounts.length > 0) {
            const { plantNotification } = this.state;
            plantNotification.accounts = plant[0].accounts;
            this.setState({ plantNotification })
        }

        if (depotWarehouse && depotWarehouse.length > 0 && depotWarehouse[0].accounts && depotWarehouse[0].accounts.length > 0) {
            const { depoNotification } = this.state;
            depoNotification.accounts = depotWarehouse[0].accounts;
            this.setState({ depoNotification })
        }

        // const turnAroundTime = nextProps.tat ? nextProps.tat.split(' ')[0] : 0 ;
        // this.setState({ turnAroundTime });
    }
  }

}


const mapStateToProps = ({trips}) => {
    return {
        locations: trips.locations.data,
        templateUrl: trips.templateLink,
        submitting: trips.addTrip.submitting,
        providers: trips.providers.data || [],
        gpsVendorType: trips.gpsVendors || [],
        tat: trips.tat || '2 Days',
        vehicleServiceTypes: trips.vehicleServiceTypes || [],
        vehicleTypes: trips.vehicleTypes || [],
        vehicleBodyTypes: trips.vehicleBodyTypes || [],
        vehicleData: trips.vehicleData || {},
        seekers: trips.seekers.data || [],
        operatorTypes: trips.operatorTypes || [],
        locationTypeFilter: trips.locationTypeFilter || [],
        notificationSettings: trips.notificationSettings || [],
      }
};

const WrappedContainer = GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ["places"]
})(AddTrip);

export default connect(mapStateToProps)(WrappedContainer);
