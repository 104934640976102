import { Polyline } from "react-google-maps";
import React, { useState } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import moment from 'moment';
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";

const MapT1 = withScriptjs(withGoogleMap((props) => {
  const [hoveredPolyline, setHoveredPolyline] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [hoveredPolylineMarker, setHoveredPolylineMarker] = useState(null);

  const handlePolylineHover = (polylineId) => {
    setHoveredPolyline(polylineId);
  };

  const handlePolylineLeave = () => {
    setHoveredPolyline(null);
  };

  const handleMarkerHover = (polylineId) => {
    setHoveredMarker(polylineId);
  };

  const handleMarkerLeave = () => {
    setHoveredMarker(null);
  };

  const handlePolylineMarkerHover = (markerId) => {
    setHoveredPolylineMarker(markerId);
  };

  const handlePolylineMarkerLeave = () => {
    setHoveredPolylineMarker(null);
  };

  console.log("sourcemarker", props.currentLocationMarker)
  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: -34.397, lng: 150.644 }}
      mapContainerStyle={props.mapStyles}
      center={props.center}
      zoom={props.zoom}
    >
      {/* First Polyline */}
      <Polyline
        path={props.polyline1Path}
        options={{
          strokeColor: hoveredPolyline === 'polyline1' ? '#008744' : '#0057e7',
          strokeOpacity: 1,
          strokeWeight: hoveredPolyline === 'polyline1' ? 8 : 5,
          zIndex: hoveredPolyline === 'polyline1' ? 2 : 1,
        }}
        onMouseOver={() => handlePolylineHover('polyline1')}
        onMouseOut={handlePolylineLeave}
      />
      {props.polyline1Path.map((coordinate, index) => (
        <Marker
          key={`marker1-${index}`}
          position={coordinate}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5,
            fillColor: 'blue',
            fillOpacity: 1,
            strokeWeight: 1
          }}
          onMouseOver={() => handlePolylineMarkerHover(`polyline1-marker-${index}`)}
          onMouseOut={handlePolylineMarkerLeave}
        />
      ))}

      {/* Second Polyline */}
      <Polyline
        path={props.polyline2Path}
        options={{
          strokeColor: hoveredPolyline === 'polyline2' ? 'red' : '#ffa700',
          strokeOpacity: 1,
          strokeWeight: hoveredPolyline === 'polyline2' ? 8 : 5,
          zIndex: hoveredPolyline === 'polyline2' ? 2 : 1,
        }}
        onMouseOver={() => handlePolylineHover('polyline2')}
        onMouseOut={handlePolylineLeave}
      />
      {props.polyline2Path.map((coordinate, index) => (
        <Marker
          key={`marker2-${index}`}
          position={coordinate}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 2,
            fillColor: 'white',
            fillOpacity: 1,
            strokeWeight: 1
          }}
          onMouseOver={() => handlePolylineMarkerHover(`polyline2-marker-${index}`)}
          onMouseOut={handlePolylineMarkerLeave}
        />
      ))}

      {/*Source Marker */}
      {props.sourceMarker && (
        <Marker
          position={{ lat: props.sourceMarker.latitude, lng: props.sourceMarker.longitude }}
          // label="Source"
          icon={{
            url: `https://maps.google.com/mapfiles/ms/icons/green-dot.png`,
            scale: 1,
            fillOpacity: 1,
            strokeWeight: 1
          }}
          onMouseOver={() => handleMarkerHover('sourceMarker')}
          onMouseOut={handleMarkerLeave}
        />
      )}

      {/* Destination Marker */}
      {props.destinationMarker && (
        <Marker
          position={{ lat: props.destinationMarker.latitude, lng: props.destinationMarker.longitude }}
          // label="Destination"
          icon={{
            url: `https://maps.google.com/mapfiles/ms/icons/red-dot.png`,
            scale: 1,
            fillOpacity: 1,
            strokeWeight: 1
          }}
          onMouseOver={() => handleMarkerHover('DestinationMarker')}
          onMouseOut={handleMarkerLeave}
        />
      )}
      {/*Train current location*/}
      {props.currentLocationMarker && (
        <Marker
          position={{ lat: props.currentLocationMarker.latitude, lng: props.currentLocationMarker.longitude }}
          label="current location"
          icon={{
            path: "M12 2c-4 0-8 .5-8 4v9.5C4 17.43 5.57 19 7.5 19L6 20.5v.5h2.23l2-2H14l2 2h2v-.5L16.5 19c1.93 0 3.5-1.57 3.5-3.5V6c0-3.5-3.58-4-8-4zM7.5 17c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5 14s1.5.67 1.5 1.5S8.33 17 7.5 17zm3.5-7H6V6h5v4zm2 0V6h5v4h-5zm3.5 7c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z",
            scale: 1,
            fillOpacity: 1,
            fillColor: "blue",
            strokeWeight: 1
          }}
          onMouseOver={() => handleMarkerHover('CurrentLocationMarker')}
          onMouseOut={handleMarkerLeave}
        />
      )}

      {hoveredMarker === 'sourceMarker' && (
        <InfoBox
          defaultPosition={{
            lat: props.sourceMarker.latitude,
            lng: props.sourceMarker.longitude,
          }}
          options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <div style={{ backgroundColor: '#fff', padding: '8px', borderRadius: '5px', zIndex: '999' }}>
            {/* Example Text for Source Marker */}
            <div className="flex flex-column p5 tLeft">
              <div className="theme-color fs-14 fBolder">Source</div>
              <div className="fs-12 mb-2 table-text-color">Station Code:{props.sourceMarker.sourceStation}</div>
            </div>
          </div>
        </InfoBox>
      )}

      {hoveredMarker === 'DestinationMarker' && (
        <InfoBox
          defaultPosition={{
            lat: props.destinationMarker.latitude,
            lng: props.destinationMarker.longitude,
          }}
          options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <div style={{ backgroundColor: '#fff', padding: '8px', borderRadius: '5px', zIndex: '999' }}>
            <div className="flex flex-column p5 tLeft">
              <div className="theme-color fs-14 fBolder">Destination</div>
              <div className="fs-12 mb-2 table-text-color">Station Code:{props.destinationMarker.destStation}</div>
            </div>
          </div>
        </InfoBox>
      )}
      {hoveredMarker === 'CurrentLocationMarker' && (
        <InfoBox
          defaultPosition={{
            lat: props.currentLocationMarker.latitude,
            lng: props.currentLocationMarker.longitude,
          }}
          options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <div style={{ backgroundColor: '#fff', padding: '8px', borderRadius: '5px', zIndex: '999' }}>
            {/* Example Text for Source Marker */}
            <div className="flex flex-column p5 tLeft">
              <div className="fs-12 mb-2 table-text-color">Current Location</div>
              <div className="theme-color fs-14 fBolder">{props.currentLocationMarker.Currentstation}-{props.currentLocationMarker.CurrentLocationstatus}</div>
              <div className="fs-12 mb-2 table-text-color">Time</div>
              <div className="theme-color fs-14 fBolder">{props.currentLocationMarker.CurrentLocationCreatedOnTime}</div>
            </div>
          </div>
        </InfoBox>
      )}
      {props.polyline1Path.map((coordinate, index) => (
        // console.log('coordinate:', coordinate),
        <React.Fragment key={`infobox1-${index}`}>
          {hoveredPolylineMarker === `polyline1-marker-${index}` && (
            <InfoBox
              defaultPosition={{
                lat: coordinate.lat,
                lng: coordinate.lng,
              }}
              options={{ closeBoxURL: '', enableEventPropagation: true }}
            >
              <div style={{ backgroundColor: '#fff', padding: '8px', borderRadius: '5px', zIndex: '999' }}>
                <div className="flex flex-column p5 tLeft">
                  <div className="fs-12 mb-2 table-text-color">Location</div>
                  <div className="theme-color fs-14 fBolder">{coordinate.station_code}-{coordinate.last_reported_status}</div>
                  <div className="fs-12 mb-2 table-text-color">Time</div>
                  <div className="theme-color fs-14 fBolder"> {coordinate.created_on && moment(coordinate.created_on).format('DD MM YYYY, HH:mm')}</div>

                </div>
              </div>
            </InfoBox>
          )}
        </React.Fragment>
      ))}
      {props.polyline2Path.map((coordinate, index) => (
        console.log('coordinate:', coordinate),
        <React.Fragment key={`infobox2-${index}`}>
          {hoveredPolylineMarker === `polyline2-marker-${index}` && (
            <InfoBox
              defaultPosition={{
                lat: coordinate.lat,
                lng: coordinate.lng,
              }}
              options={{ closeBoxURL: '', enableEventPropagation: true }}
            >
              <div style={{ backgroundColor: '#fff', padding: '8px', borderRadius: '5px', zIndex: '999' }}>
                <div className="flex flex-column p5 tLeft">
                  <div className="fs-12 mb-2 table-text-color">Location</div>
                  <div className="theme-color fs-14 fBolder">{coordinate.station_code}-{coordinate.station_name}</div>
                </div>
              </div>
            </InfoBox>
          )}
        </React.Fragment>
      ))}


    </GoogleMap>

  );
}));

export default MapT1;