import React, { Component } from 'react';

class DetentionRowDetail extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { detentionDetail, width } = this.props;
        return (
            <div onMouseEnter={this.showDetail} onMouseOut={this.hideDetail} className="pr detention-detail-row-wrap tCenter ht-50" style={{width: width + '%'}}>
                <span  className="fs-16 table-text-color">{detentionDetail.total_time || '-'}</span>
                    <div className="detail-tooltip pa">
                        {
                            <div className="flex flex-column max-content">
                                {
                                    (detentionDetail.details || []).map((detail, index)=> {
                                        return (
                                            <div key={index} className="flex flex-column p10 tLeft">
                                                <div className="theme-color fs-14 fBolder">{detail.city}</div>
                                                <div className="fs-12 table-text-color">{detail.locationType}</div>
                                                <div className="fs-10 fBolder table-text-color">{detail.date.format('DD MMM, YYYY')}</div>
                                                <div className="fs-10 table-text-color">
                                                    <span>{detail.from} - {detail.to}</span>
                                                    <span className="table-text-color pl-5 pr-5"> | </span>
                                                    <span className="theme-color fs-10">{detail.time}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
            </div>
        )
    }
}

export default DetentionRowDetail;