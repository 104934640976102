import React, { Fragment }  from "react";
import { GoogleMap, withGoogleMap, withScriptjs, withStateHandlers, DirectionsRenderer, Marker, InfoWindow } from "react-google-maps";
import { compose, lifecycle, withProps, withState, withHandlers } from "recompose";
import { MapStyles } from 'Components/Map/MapStyles';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { LocationRoute } from 'Components/LocationRoute';
import Moment from 'moment';
import MapControl from 'Components/MapControl';
import { GOOGLE_API_KEY } from 'Constants/commonConstants';

const img = "M21.474,377.522V117.138c0-14.469,11.729-26.199,26.199-26.199h260.25c14.469,0,26.198,11.73,26.198,26.199v260.385 c0,4.823-3.909,8.733-8.733,8.733H30.207C25.383,386.256,21.474,382.346,21.474,377.522z M231.634,466.724 c0,30.01-24.329,54.338-54.338,54.338c-30.009,0-54.338-24.328-54.338-54.338c0-30.011,24.329-54.338,54.338-54.338 C207.305,412.386,231.634,436.713,231.634,466.724z M204.464,466.724c0-15.005-12.164-27.169-27.169-27.169 s-27.17,12.164-27.17,27.169s12.165,27.17,27.17,27.17S204.464,481.729,204.464,466.724z M130.495,412.385H8.733 c-4.823,0-8.733,3.91-8.733,8.733v26.495c0,4.823,3.91,8.733,8.733,8.733h97.598C108.879,438.862,117.704,423.418,130.495,412.385z  M515.938,466.724c0,30.01-24.329,54.338-54.338,54.338c-30.01,0-54.338-24.328-54.338-54.338 c0-30.011,24.328-54.338,54.338-54.338C491.609,412.385,515.938,436.713,515.938,466.724z M488.77,466.724 c0-15.005-12.165-27.169-27.17-27.169c-15.006,0-27.169,12.164-27.169,27.169s12.164,27.17,27.169,27.17 S488.77,481.729,488.77,466.724z M612,421.118v26.495c0,4.823-3.91,8.733-8.733,8.733h-70.704 c-5.057-34.683-34.906-61.427-70.961-61.427c-36.062,0-65.912,26.745-70.969,61.427H248.261 c-2.549-17.483-11.373-32.928-24.164-43.961h134.994V162.594c0-9.646,7.82-17.466,17.466-17.466h82.445 c23.214,0,44.911,11.531,57.9,30.77l53.15,78.721c7.796,11.547,11.962,25.161,11.962,39.094v118.672h21.253 C608.09,412.385,612,416.295,612,421.118z M523.408,256.635l-42.501-60.393c-1.636-2.324-4.3-3.707-7.142-3.707H407.47 c-4.822,0-8.733,3.91-8.733,8.733v60.393c0,4.824,3.91,8.733,8.733,8.733h108.798C523.342,270.394,527.48,262.421,523.408,256.635z"
const RED = "#DB0000",
      GREY = "#A5A5A5",
      BLACK = "#000000";
const redCircleImageUrl = "/images/redCircle.svg",
      greyCircleImageUrl = "/images/greyCircle.svg",
      blackCircleImageUrl = "/images/blackCircle.svg";

const ActionMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div className="map-loading-element" style={{ height: `100%` }} />,
        containerElement: <div className="map-container-element" style={{height: `calc(100vh - 225px)`, width: `calc(100vw - 154px)`}} />,
        mapElement: <div className="map-element"  style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    withState('showTripDetail', 'setShowTripDetail', null),
    lifecycle({
        state: { directions: [], directionPoints: [], mapProjection: {} },
        componentWillMount() {
            const refs = {};
            this.setState({
                mapProjection: {},
                onMapMounted: ref => {
                    refs.map = ref;
                        const bounds = new window.google.maps.LatLngBounds();
                        refs.map.fitBounds(bounds);
                },
                projectionChanged: () => {
                    this.setState({
                        mapProjection: refs.map.getProjection()
                    });
                },
                onZoomChanged: () => {
                },
                mapCenter: new google.maps.LatLng(23.23, 69.79),
                activePoint: null
            });
        },
    }),
    withHandlers({
        handleShowTripDetail : (props) => (val) => {
            if (props.showTripDetail === val) {
                props.setShowTripDetail(null);
            } else {
                props.setShowTripDetail(val);
            }
        }
    })
  )(props => (
    <GoogleMap
        defaultZoom={4}
        defaultCenter={new google.maps.LatLng(22.9734, 78.6569)}
        defaultOptions={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: true,
            styles: MapStyles
        }}
    >
        <MarkerClusterer
            averageCenter
            styles={[
                {
                    width: 32,
                    height: 33,
                    url: props.truckColor === RED ? redCircleImageUrl :
                    (
                        props.truckColor === GREY ? greyCircleImageUrl :
                        (
                            props.truckColor === BLACK ? blackCircleImageUrl : blackCircleImageUrl
                        )
                    ),
                    textColor: 'white'
                }
            ]}
            // enableRetinaIcons
        >
            {
                props.trips && props.trips.length > 0 &&
                props.trips.map((trip, index) => {
                    return (
                        <Fragment key={index}>
                            <Marker
                                onClick={() => props.handleShowTripDetail(trip.trip_id)}
                                openInfoWindow={true}
                                position={new google.maps.LatLng(trip.last_known_location.latitude, trip.last_known_location.longitude)}
                                icon={{
                                    path: img,
                                    height: 0.5,
                                    width: 0.5,
                                    fillColor: props.truckColor,
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: 'transparent',
                                    scale: 0.04
                                }}
                                zIndex={0}
                                key={'marker-currentLoc- ' + index}
                            />
                            {
                                props.showTripDetail === trip.trip_id &&
                                <InfoWindow
                                    disableAutoPan={true}
                                    defaultOptions={{ disableAutoPan: true }}
                                    defaultPosition={new google.maps.LatLng(trip.last_known_location.latitude, trip.last_known_location.longitude)}
                                    options={{ closeBoxURL: ``, enableEventPropagation: true }}
                                >
                                    <Fragment>
                                        <div>
                                            <div className={"fBolder error-color tCenter mb-10"}>{props.truckType}</div>
                                            <span className={''}>{'DO No.: '}</span>
                                            <span className={'fBolder'}>{trip.do_no}</span>
                                            <section className="flex flex-middle tag-text-color fs-10 flex-wrap">
                                                {
                                                    trip.is_auto_created &&
                                                    <div className="pl-10 pr-10 flex flex-middle ht-20 br-15 mr-10 mb-5 orange-bg border-orange-1">
                                                        Auto Created
                                                    </div>
                                                }
                                            </section>
                                        </div>
                                        <div className={'mt-5'}>{'Route'}</div>
                                        <div className={"last-known-location action-trip mt-10 ml-5"}>
                                            {
                                                trip.route_details && trip.route_details.map((loc, index) => {
                                                    loc.date = Moment(loc.date);
                                                    return <LocationRoute loc={loc} first={index == 0} last={index == trip.route_details.length -1} key={index} />
                                                })
                                            }
                                        </div>
                                        <div className={'mt-10'}>
                                            {'Driver Details'}
                                            {
                                                trip.driver_details.map((driver, index) => {
                                                    return (
                                                        <div className={'mt-5 fs-12 fBolder ml-10'}>
                                                            <span>
                                                                {(driver.firstName || driver.lastName) ? `${driver.firstName} ${driver.lastName}` : driver.name}
                                                            </span>
                                                            <br/>
                                                            <span>{driver.number}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className={'mt-10'}>
                                            {'Shipment Details'}
                                            {trip.lr_no && <div className={'mt-5 fs-12 fBolder ml-10'}>LR No: {trip.lr_no}</div>}
                                            {trip.invoice_no && <div className={'mt-5 fs-12 fBolder ml-10'}>Invoice No: {trip.invoice_no}</div>}
                                            {trip.item_name && <div className={'mt-5 fs-12 fBolder ml-10'}>Item(s): {trip.item_name}</div>}
                                        </div>
                                        <div className={'mt-10'}>
                                            {'Vehicle Details'}
                                            <div className={'mt-5 fs-12 fBolder ml-10'}>
                                                <span>{trip.vehicle_details.vehicle_no}<br/></span>
                                                {   trip.vehicle_details.loading_capacity &&
                                                    <span>{trip.vehicle_details.service_type}: {trip.vehicle_details.loading_capacity}L<br/></span>
                                                }
                                                {
                                                    trip.vehicle_details.iso_no &&
                                                    <span>{trip.vehicle_details.iso_no}<br/></span>
                                                }
                                                {
                                                    trip.vehicle_details.vehicle_type &&
                                                    <span>{trip.vehicle_details.vehicle_type}<br/></span>
                                                }
                                                <span>{trip.vehicle_details.body_type}</span>
                                            </div>
                                        </div>
                                        {
                                            trip.last_known_location.eta && trip.last_known_location.eta !== '--' &&
                                            <div className={'mt-10'}>
                                                {'ETA : '}
                                                <span className={'fs-12 fBolder'}>
                                                    {Moment(trip.last_known_location.eta).format('DD MMM, YYYY HH:mm')}
                                                </span>
                                            </div>
                                        }
                                        {
                                            trip.last_known_location.distance_covered >= 0 && trip.last_known_location.distance_remaining >= 0 &&
                                            <div className={'mt-10'}>
                                                {'Distance : '}
                                                <span className={'fs-12 fBolder'}>
                                                    {`${parseInt(trip.last_known_location.distance_covered)}/${parseInt(trip.distance)}km`}
                                                </span>
                                            </div>
                                        }
                                        {
                                            trip.last_known_location.location &&
                                            <div className={'mt-10'}>
                                                {'Last Known Location & Date : '} <br/>
                                                <span className={'fs-12 fBolder'}>{trip.last_known_location.location}</span>
                                                <br/>
                                                <span className={'fs-12 fBolder'}>
                                                    {trip.last_known_location.date ? Moment(trip.last_known_location.date).format('DD MMM, YYYY HH:mm') : ''}
                                                </span>
                                            </div>
                                        }
                                    </Fragment>
                                </InfoWindow>
                            }
                        </Fragment>
                    )
                })
            }
        </MarkerClusterer>
        <MapControl position={google.maps.ControlPosition.TOP_LEFT}>
            <div className={'ht-25 box-shadow bg-white fs-14 fBolder mt-10 ml-10 color-error tCenter pt-5 pl-5 pr-5 error-color'}>{props.truckType}</div>
        </MapControl>
    </GoogleMap>
  ));

  export default ActionMap;
