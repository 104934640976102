import React, { Component, Fragment } from "react";
import "./LoginForm.scss";
import LoginFormCard from "./LoginFormCard";
import OtpLoginFormCard from "./OtpLoginFormCard";
import OtpVerifyFormCard from "./OtpVerifyFormCard";
import ForgotPassword from "./ForgotPassword";
import LoginWithSso from "./LoginWithSso";
import { connect } from 'react-redux';
import { SEEKER } from "Constants/commonConstants";
import { getToken, getUser } from "Utils/storage";
import { FORGOT_PASSWORD, LOGIN_WITH_OTP, LOGIN_WITH_SSO, REQUEST_OTP, VALIDATE_SSO_USER } from "Constants/actionConstants";
import { getSecureRandomKey } from "Utils/index";
import { SSO_BASE_URL, SSO_URL } from '../../utils/api';
import Recaptcha from 'react-recaptcha';
import { ReactComponent as AlertIcon } from './esclamation.svg';
import cx from 'classnames';
import Toast from "Components/Toast/index";
import { ToastContainer, toast } from "react-toastify";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyType: SEEKER,
      isPasswordVisible: false,
      password: "",
      isOtpLogin: false,
      isVerifyOtp: false,
      otp: "",
      isForgotPassword: false,
      isLoginWithSso: false,
      email: "",
      password: "",
      contact_number: null,
      expire_time: null,
      start_time: null,
      hasExpired: false,
      invalidOTP: false,
      invalidContact: false,
      otp: '',
      errorMsg: '',
      isVerified: false,
      isSSOLogin: false,
      forgotPasswordPage: false,
      reCaptchaKey: 1,
      captcha_value: '',
      isOtpSent: false,
      emailOrMobileInput:"",
      show_checkbox:false,
      isUserAlreadyLoggedin: false,
      hideLoginBtn: false,
    };
  }

  companyTypeTabButton = [
    {
      id: 1,
      value: "Seeker Login",
    },
    {
      id: 2,
      value: "Provider Login",
    },
  ];

  componentDidMount() {
    const isSSOLogin = getUser().isSSOLogin;
    const token = getToken();
    setTimeout(function () {
      this.setState({ showTag: true })
    }.bind(this), 1000)
    if (window.name && window.name.length > 0 && (!token || (JSON.parse(window.name))['teg-session-token'] !== token) && !(JSON.parse(window.name))['sso-logout-duration']) {
      Object.entries(JSON.parse(window.name)).forEach(([key, value]) => {
        setCookie(key, value, 200);
      });
      location.reload();
    }

    if (!token) {
      return;
    } else {
      if (isSSOLogin) {
        localStorage.setItem('teg-component', false);
        localStorage.setItem('sso-session', true);
        localStorage.setItem('isSSOUser', true);
        localStorage.setItem('teg-sso-session', true);
      }
      const { dispatch } = this.props;
      dispatch({
        type: LOGIN_WITH_SSO,
      })

    }
  }

  selectCompanyType = (companyType) => {
    this.setState({
      companyType,
      isVerified: false,
      reCaptchaKey: getSecureRandomKey()
    });
  };

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({
      password,
    });
  };
  handleEmailChange = (e) => {
    const email = e.target.value;
    this.setState({
      email,
    });
  };

  handleChangeOtp = (otp) => {
    const numericOtp = otp.replace(/[^0-9]/g, '');
    this.setState({ otp: numericOtp });
  };

  handleEmailOrMobileChange = (e) => {
    const emailOrMobileInput = e.target.value;
      const mobilePattern = /^[0-9]*$/;
      if (mobilePattern.test(emailOrMobileInput)) {
        if (emailOrMobileInput.length <= 10) {
          this.setState({
            emailOrMobileInput,
          });
        }
      } else {
        this.setState({
          emailOrMobileInput,
        });
      }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  toggleOtpLogin = () => {
    this.setState((prevState) => ({
      isOtpLogin: !prevState.isOtpLogin,
      email:"",
      password:""
    }));
  };

  onClickSendOtp = () => {
    const { emailOrMobileInput, isVerifyOtp } = this.state;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const mobilePattern = /^[0-9]{10}$/;

    if (!emailOrMobileInput) {
      toast.error("Enter email address or mobile number");
      return;
    }

    if (emailPattern.test(emailOrMobileInput)) {
      this.setState({ email: emailOrMobileInput, contact_number: '' }, () => {
        this.sendOTP();
      });
    } else if (mobilePattern.test(emailOrMobileInput)) {
      this.setState({ contact_number: emailOrMobileInput, email: ''}, () => {
        this.sendOTP();
      });
    } else {
      toast.error("Please enter a valid email address or mobile number");
    }
    
  };
  onClickReSendOtp = () => {
    const { emailOrMobileInput, isVerifyOtp } = this.state;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const mobilePattern = /^[0-9]{10}$/;

    if (emailPattern.test(emailOrMobileInput)) {
      this.setState({ email: emailOrMobileInput, contact_number: '' }, () => {
        this.reSendOTP();
      });
    } else if (mobilePattern.test(emailOrMobileInput)) {
      this.setState({ contact_number: emailOrMobileInput, email: ''}, () => {
        this.reSendOTP();
      });
    } 
    
  };

  backtoSendOtp = () =>{
    this.setState((prevState) => ({
        isVerifyOtp: !prevState.isVerifyOtp,
      }));
  }

  toggleForgotPassword = () => {
    this.setState((prevState) => ({
      isForgotPassword: !prevState.isForgotPassword,
      isVerified: false,
      email:"",
      password: "",
      reCaptchaKey: getSecureRandomKey()
    }));
  };
  
  toggleLoginWithSso = () => {
    this.setState((prevState) => ({
      isLoginWithSso: !prevState.isLoginWithSso,
      isSSOLogin: true,
      email: "",
      password:""
    }));
  };

  shouldRenderLoginFormCard = () => {
    const {
      isOtpLogin,
      isVerifyOtp,
      isForgotPassword,
      isLoginWithSso,
    } = this.state;

    const C1 = !isOtpLogin;
    const C2 = !isVerifyOtp;
    const C3 = !isForgotPassword;
    const C4 = !isLoginWithSso;

    if (C1 && C2 && C3 && C4) {
      return true;
    }
  };

  shouldRenderOtpLoginFormCard = () => {
    const {
      isOtpLogin,
      isVerifyOtp,
      isForgotPassword,
      isLoginWithSso,
    } = this.state;

    const C1 = isOtpLogin;
    const C2 = !isVerifyOtp;
    const C3 = !isForgotPassword;
    const C4 = !isLoginWithSso;

    if (C1 && C2 && C3 && C4) {
      return true;
    }
  };

  shouldRenderOtpVerifyFormCard = () => {
    const { isVerifyOtp, isForgotPassword, isLoginWithSso } = this.state;

    const C1 = isVerifyOtp;
    const C2 = !isForgotPassword;
    const C3 = !isLoginWithSso;

    if (C1 && C2 && C3) {
      return true;
    }
  };

  shouldRenderForgotPasswordFormCard = () => {
    const { isForgotPassword, isLoginWithSso } = this.state;

    const C1 = isForgotPassword;
    const C2 = !isLoginWithSso;

    if (C1 && C2) {
      return true;
    }
  };

  shouldRenderLoginWithSsoFormCard = () => {
    const { isLoginWithSso } = this.state;

    const C1 = isLoginWithSso;

    if (C1) {
      return true;
    }
  };

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  formSubmit = (e, isDirectSSO = false) => {
    const { tabType, otp, isSSOLogin, email, password, companyType, isOtpLoginEnable } = this.state;
    e.preventDefault();
    if (!email || !password) {
      toast.error("Email and password must not be empty!");
      return;
    }
    if(!this.validateEmail(email)) {
      toast.error("Please enter a valid email address!");
      return;
    }
    // this.props.emailPasswordCallback(this.state);
    if (isDirectSSO) {
      localStorage.setItem('isSSOUser', true);
      window.location.replace(`${SSO_BASE_URL}${SSO_URL}/?sso&next=${window.location.origin}/dashboard&module=3`);
    } else {
      this.props.signin(e, email, password, companyType);
    }

    this.setState((prevState) => {
      const updatedState = { ...prevState };
      updatedState.isVerified = false
      updatedState.reCaptchaKey = getSecureRandomKey()
      return updatedState
    })
  }

  sendOTP = () => {
    const { dispatch } = this.props;
    const {
      contact_number,
      companyType,
      captcha_value,
      email,
      isVerifyOtp
    } = this.state;
    dispatch({
      type: REQUEST_OTP,
      data: {
        contact_number: contact_number || undefined,
        company_type: companyType,
        email: email || undefined,
        module_id: 3,
        device_type: 3,
        captcha_value,
      },
      onSuccess: (detail) => {
        this.setState({
          expire_time: detail.expire_time,
          start_time: detail.created_on,
          hasExpired: false,
          invalidOTP: false,
          invalidContact: false,
          show_checkbox: false,
          otp: '',
          isOtpSent: true,
          isVerifyOtp: !isVerifyOtp
        })
      },
      onError: (message) => {
        this.setState({
          invalidContact: true,
          // show_checkbox: true,
          errorMessage: message
        })
      }
    })
  }

  reSendOTP = () => {
    const { dispatch } = this.props;
    const {
      contact_number,
      companyType,
      captcha_value,
      email,
      isVerifyOtp
    } = this.state;
    dispatch({
      type: REQUEST_OTP,
      data: {
        contact_number: contact_number || undefined,
        company_type: companyType,
        email: email || undefined,
        module_id: 3,
        device_type: 3,
        captcha_value,
      },
      onSuccess: (detail) => {
        this.setState({
          expire_time: detail.expire_time,
          start_time: detail.created_on,
          hasExpired: false,
          invalidOTP: false,
          invalidContact: false,
          show_checkbox: false,
          otp: '',
          isOtpSent: true,
          // isVerifyOtp: !isVerifyOtp
        })
      },
      onError: (message) => {
        this.setState({
          invalidContact: true,
          // show_checkbox: true,
          errorMessage: message
        })
      }
    })
  }

  otpSignin = () => {
    const { dispatch } = this.props;
    const { companyType, contact_number, otp,
      captcha_value, email,
      // forceOtpLogin
    } = this.state;
    dispatch({
      type: LOGIN_WITH_OTP,
      data: {
        contact_number: contact_number || undefined,
        companyType,
        otp_number: otp,
        email: email || undefined,
        device_type: 3,
        module_id: 3,
        captcha_value,
        force_logout: false
      },
      onError: (msg) => {
        const isUserAlreadyLoggedin = msg == 1
        const hideLoginBtn = msg == 1
        const isForceLogout = msg == 1
        this.setState({
          invalidOTP: true,
          errorMsg: msg,
          show_checkbox: isForceLogout ? true : undefined,
          forceOtpLogin: isForceLogout,
          isUserAlreadyLoggedin,
          hideLoginBtn
        },);
      }
    })
  }

  forceOtpSignin = () => {
    const { dispatch } = this.props;
    const { companyType, contact_number, otp,
      captcha_value, email, forceOtpLogin
    } = this.state;
    dispatch({
      type: LOGIN_WITH_OTP,
      data: {
        contact_number: contact_number || undefined,
        companyType,
        otp_number: otp,
        email: email || undefined,
        device_type: 3,
        module_id: 3,
        captcha_value,
        force_logout: forceOtpLogin ? true : undefined
      },
      onError: (msg) => {
        const isUserAlreadyLoggedin = msg == 1
        const hideLoginBtn = msg == 1
        const isForceLogout = msg == 1
        this.setState({
          invalidOTP: true,
          errorMsg: msg,
          // show_checkbox: isForceLogout ? true : undefined,
          // forceOtpLogin: isForceLogout,
          isUserAlreadyLoggedin,
          hideLoginBtn
        },);
      }
    })
  }

  otpExpired = () => {
    const { expire_time, start_time } = this.state;
    const systemTime = new Date().getTime();
    if (expire_time && start_time && expire_time < systemTime) {
      this.setState({ hasExpired: true, otp: '' });
    }
    // else {
    //   this.setState({ hasExpired: false });
    // }
  }

  ssoLogin = () => {
    const { email } = this.state;
    const { dispatch } = this.props;
    const data = {
      email,
      module: 3
    }
    dispatch({
      type: VALIDATE_SSO_USER,
      data,
      onSuccess: () => {
        localStorage.setItem('isSSOUser', true);
        window.location.replace(`${SSO_BASE_URL}${SSO_URL}?sso&email=${email}&next=http://${window.location.host}/dashboard&module=3`);
      }
    })

  }

  verifyCallback = (response) => {
    const { dispatch, handleSetCaptchaValue } = this.props;
    // dispatch({
    //   type: VALIDATE_RECAPTCHA,
    //   data: {
    //     captcha_value: response
    //   },
    //   onSuccess: (successData) => {
    //     if(successData && successData.success){
    //       this.setState({
    //         isVerified:true
    //       });
    //       this.setState({
    //         errorMessage:""
    //       });
    //     }
    //   }
    // })

    this.setState((prevState) => {
      const updatedState = { ...prevState };
      updatedState.captcha_value = response
      updatedState.isVerified = true
      return updatedState
    })
    handleSetCaptchaValue(response)
  }

  callback = () => {
    console.log('Done!!!!');
  };

  onExpire = () => {
    this.setState({
      isVerified: false
    });
  };

  forgotPassword = (e) => {
    const { dispatch } = this.props;
    const { email, companyType,
      captcha_value
    } = this.state;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      toast.error("Email field cannot be empty.");
      return;
    } else if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    dispatch({
      type: FORGOT_PASSWORD,
      data: {
        email,
        company_type: companyType,
        url_link: window.location.href,
        captcha_value,
        module_id: 3,
      }
    })
  }

  result = (text) => { this.setState({ actualCaptcha: text }) }

  shouldRenderSelectRecaptchaMsg = () => {
    const { isUserAlreadyLoggedin } = this.props;
    const { isVerified } = this.state;
    const C1 = isUserAlreadyLoggedin && !isVerified

    if (C1) {
      return true;
    }

    return false;
  }

  maskInput = (emailOrMobileInput) => {
    if (/^\d+$/.test(emailOrMobileInput)) {
      return `XXXXXX ${emailOrMobileInput.slice(-4)}`;
    } else if (emailOrMobileInput.includes('@')) {
      const [name, domain] = emailOrMobileInput.split('@');
      const visiblePart = name.slice(0, 2);
      const maskedPart = '*'.repeat(name.length - 2);
      return `${visiblePart}${maskedPart}@${domain}`;
    } else {
      return emailOrMobileInput;
    }
  }

  render() {
    const { 
      companyType, 
      isPasswordVisible, 
      password, 
      otp ,
      start_time, 
      expire_time, 
      hasExpired, 
      invalidOTP,
      contact_number, 
      invalidContact, 
      errorMessage, 
      errorMsg, 
      isVerified,
      forgotPasswordPage,
      reCaptchaKey,
      email,
      emailOrMobileInput,
      show_checkbox
    } = this.state;
    // console.log("render state",this.state)
    const { 
      error, 
      showTag, 
      ssoConfigData,
      hideLoginBtn,
      shouldEnableRecaptcha,
      onGenerateAnotherSession,
      // show_checkbox
    } = this.props;
    // console.log("render props", this.props)

    const systemTime = new Date().getTime();

    let disableLogin = null;

    if (shouldEnableRecaptcha()) {
      disableLogin = (!otp ? (start_time && !hasExpired) : (isVerified ? otp.length != 6 : true)) || !isVerified;   // actual code
    } else {
      disableLogin = (!otp ? (start_time && !hasExpired) : (isVerified ? otp.length != 6 : true))  // temp for few days
    }

    const disableTegLogin = ssoConfigData.disable_teg_login;
    const isCustomDomain = ssoConfigData.is_custom_domain;

    const maskedInput = this.maskInput(emailOrMobileInput);

    return (
      <div className="loginFormMain">
        {this.shouldRenderLoginFormCard() && (
          <LoginFormCard
            companyTypeTabButton={this.companyTypeTabButton}
            companyType={companyType}
            selectCompanyType={this.selectCompanyType}
            isPasswordVisible={isPasswordVisible}
            togglePasswordVisibility={this.togglePasswordVisibility}
            handlePasswordChange={this.handlePasswordChange}
            password={password}
            toggleOtpLogin={this.toggleOtpLogin}
            toggleForgotPassword={this.toggleForgotPassword}
            toggleLoginWithSso={this.toggleLoginWithSso}
            handleEmailChange={this.handleEmailChange}
            email={email}
            onSubmit={this.formSubmit}
            
          />
        )}
        {this.shouldRenderOtpLoginFormCard() && (
          <OtpLoginFormCard
            companyTypeTabButton={this.companyTypeTabButton}
            companyType={companyType}
            selectCompanyType={this.selectCompanyType}
            toggleOtpLogin={this.toggleOtpLogin}
            onClickSendOtp={this.onClickSendOtp}
            handleEmailOrMobileChange={this.handleEmailOrMobileChange}
            emailOrMobileInput={emailOrMobileInput}
          />
        )}
        {this.shouldRenderOtpVerifyFormCard() && (
          <OtpVerifyFormCard
            companyTypeTabButton={this.companyTypeTabButton}
            companyType={companyType}
            selectCompanyType={this.selectCompanyType}
            handleChangeOtp={this.handleChangeOtp}
            otp={otp}
            backtoSendOtp={this.backtoSendOtp}
            maskInput={maskedInput}
            hasExpired={hasExpired}
            start_time={start_time}
            expire_time={expire_time}
            systemTime={systemTime}
            otpExpired={this.otpExpired}
            otpSignin={this.otpSignin}
            onSubmit={this.formSubmit}
            onClickReSendOtp={this.onClickReSendOtp}
          />
        )}
        {this.shouldRenderForgotPasswordFormCard() && (
          <ForgotPassword
            companyTypeTabButton={this.companyTypeTabButton}
            companyType={companyType}
            selectCompanyType={this.selectCompanyType}
            toggleForgotPassword={this.toggleForgotPassword}
            handleEmailChange={this.handleEmailChange}
            email={email}
            forgotPassword={this.forgotPassword}
          />
        )}
        {this.shouldRenderLoginWithSsoFormCard() && (
          <LoginWithSso
            companyTypeTabButton={this.companyTypeTabButton}
            companyType={companyType}
            selectCompanyType={this.selectCompanyType}
            toggleLoginWithSso={this.toggleLoginWithSso}
            handleEmailChange={this.handleEmailChange}
            email={email}
            // onSubmit={this.formSubmit}
            ssoLogin={this.ssoLogin}
          />
        )}
        {shouldEnableRecaptcha() && <Fragment>
          {showTag &&
            <Recaptcha
              sitekey="6LcHtbwUAAAAAKkjbo7nNh9ajzF5GEHk9uC5skGG"
              render="explicit"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.callback}
              expiredCallback={this.onExpire}
              key={reCaptchaKey}
            />}
          {this.shouldRenderSelectRecaptchaMsg() && <Fragment>
            <h6
              className='error-color m-0 fs-16'
            >
              Please select I'm not a robot
            </h6>
          </Fragment>}
          <br></br>
        </Fragment>}
        {
          show_checkbox === true &&
          <div 
          className="fs-14 flex flex-center col-1" 
          style={{ 
            background: "linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%)", 
            marginTop: "-200px" }}
          >
              <div 
              className="theme-color fBold flex flex-center pt-5 mb-40 tCenter " 
              style={{ 
                background: "wheat", 
                padding: "1rem", 
                "border-radius": "5px", 
                "margin-top": "1rem" ,
                zIndex: 4
              }}
              >
                <AlertIcon className="wt-20 flex ht-20 alert-icon" />
                <div style={{ "padding-left": "5rem" }}>
                  {
                    <Fragment>
                      <span className="error-color flex flex-center" style={{ fontSize: "16px" }}>
                        Your Previous Login / Session is still active and not closed properly.
                        To Logout previous sessions,
                        {<Fragment>
                          <a
                            onClick={this.forceOtpSignin}
                            style={{
                              color: "white",
                              "box-shadow": "0 0 5px 0 black",
                              padding: "0.25rem",
                              "margin-left": " 0.5rem",
                              "border-radius": "5px",
                              background: "green"
                            }}
                            className={cx('btnT2', {
                              disabledT1: shouldEnableRecaptcha() ? isUserAlreadyLoggedin : false
                            })}
                          >
                            Click Here...
                          </a>
                        </Fragment>}
                      </span>
                    </Fragment>}
                  {<div className="error-color flex flex-center fBold mt-5" style={{ fontSize: "16px" }}>Note: Any unsaved data in previous sessions will be lost</div>}
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {

  }
};
export default connect(mapStateToProps)(LoginForm);
