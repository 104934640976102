import React, {Component} from 'react';
import { connect } from 'react-redux';

import { FORGOT_PASSWORD } from 'Constants/actionConstants';

import Logo from 'Components/Logo';
import Input from 'Components/Input';
import Button from 'Components/Button';
import { withRouter } from 'react-router-dom';
import { deleteToken, clearStorage } from 'Utils/storage';
import ActionLoader from 'Components/ActionLoader';


class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: '',
            }
        }
    }

    onChange = (e)=> {
        const { data } = this.state;
        data[e.target.name] = e.target.value
        this.setState({ data })
    }

    forgotPassword = (e)=> {
        const { dispatch, history } = this.props;
        const { data } = this.state;
        e.preventDefault();
        dispatch({
            type: FORGOT_PASSWORD,
            data: {
                ...data,
                company_type: 1,
                module_id: 3,
                url_link: window.location.href,
            },
            onSuccess: () => {
                history.push('/');
                deleteToken();
            }
        })
    }

    render() {
        return (
            <div style={{background: "rgb(82 138 234 / 85%)", height:"100vh" }}>
                <div className="center pr" style={{width: "40rem", padding: "3rem", transform: "translate(0px, 10rem)", borderRadius: "10px", "box-shadow": "0 0 20px 0px black", background:"#fff"}}>
                <form className="form flex flex-column" onSubmit={this.forgotPassword}>
                    <div className="tCenter wt-70 flex flex-center pt-50 flex-self-center">
                        <Logo className="ht-130"/>
                    </div>
                    <Input containerClassName="mb-20" name="email" type="text" value={this.state.data.email} placeholder="Email ID" onChange={this.onChange}/>

                    <br/>
                    <Button type="submit" className="mb-15" value="Submit"/>
                    {this.props.isDataLoading && <ActionLoader fixed={true}/>}
                </form>
            </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isDataLoading: state.authentication.isDataLoading || false,
    }
};


export default connect(mapStateToProps)(withRouter(ForgotPassword));
