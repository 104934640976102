import React from 'react';

const Link = ({href, value, className})=> {
    return (
        <a
            href={href}
            className={"fs-13 theme-color " + (className || "") }
        >{value}</a>
    )
}

export default Link;