import React, { Component } from 'react';
import Input from 'Components/Input';

class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remarks: '',
            isError: false
        }
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSave = () => {
        const { remarks } = this.state;
        if(remarks) {
            this.props.cancelCloseTrip(remarks);
        } else {
            this.setState({ isError: true });
        }
    }

    render() {
        const { type } = this.props;
        return (
            <div className={'col-1 share'}>
                <div className={'fBolder mt-10 mb-20 fBolder tCenter fs-16'}>{`${type === 9 ? 'Cancel' : 'Close'} Trip`}</div>
                <div className={'mt-10'}>
                    <Input required containerClassName="mb-10 " name="remarks" type="text" value={this.state.remarks} placeholder="Remarks" onChange={this.onChange} error={this.state.isError} errorMessage={'Please Enter the remarks'} errorField={'remarks'}/>
                </div>

                <div className={'mt-30 mr-40'}>
                <div className={'button-outlined share-button wt-100 tCenter fRight mr-40'} onClick={this.props.hideOverlay}>No</div> 
                    <div className={'button-outlined share-button wt-100 tCenter fRight mr-20'} onClick={this.onSave}>Yes</div> 
                </div>
            </div>
        )
    }
}

export default Confirmation;
