import React from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, lifecycle, withProps } from "recompose";
import { MapStyles } from 'Components/Map/MapStyles';
import MapControl from 'Components/MapControl';
import Tabs from 'Components/Tabs';
import SearchDropdown from 'Components/SearchDropdown';


const Map = compose(
    withProps({
      	googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCvqKiCs-cWJm3u6fxtyZXSzQasYIq-mgY&v=3.exp&libraries=geometry,drawing,places",
      	loadingElement: <div className="map-loading-element" style={{ height: `100%` }} />,
      	containerElement: <div className="map-container-element" style={{ height: `calc(100vh - 70px)`, width: `calc(100vw - 480px)`}} />,
      	mapElement: <div className="map-element"  style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
		componentWillMount() {
			this.setState({
				mapProjection: null,
				zoom: 7,
				mapCenter: new google.maps.LatLng(22.9734, 78.6569)
			});
		}
    })
  )(props => (
	<GoogleMap
		defaultZoom={4}
		defaultCenter={props.mapCenter || new google.maps.LatLng(22.9734, 78.6569)}
		defaultOptions={{
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			styles: MapStyles
		}}
		zoom={5}
	>
            <MapControl position={google.maps.ControlPosition.TOP_LEFT}>
				<div className="p10 tab-on-map">
					<div className="col25">
						<Tabs 
							selectTab={(trackingType)=> {
								props.searchWithTrackingType(trackingType);
							}} 
							activeTabId={props.trackingType || -1} 
							tabs={props.tabs}
						/>
					</div>    
				</div>
			</MapControl>
			<MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
				<div className="p10">
					<div className="col25">
						<SearchDropdown onChange={props.searchMap} label="Search place, checkpost" items={[]}/>
					</div>    
				</div>
			</MapControl>
	</GoogleMap>
  ));

export default Map;