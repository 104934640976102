import React, { Component } from 'react'
import { Marker } from 'react-google-maps';
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import Moment from 'moment';
import { PANTALOONS } from 'Constants/commonConstants';
import { getUser } from 'Utils/storage';

export default class CustomMarker extends Component {

    constructor() {
        super();
        this.state = {
            isInfoBoxShown: false
        }
    }

    showInfoBox = (e) => {
        this.setState({isInfoBoxShown: true});
    }

    hideInfoBox = () => {
        setTimeout(() => {
            this.setState({isInfoBoxShown: false});
        }, 1000);
    }

    render() {
        const { location, showInfoWindowAlways } = this.props;
        const queryParams = new URLSearchParams(window.location.search);
        const url_branchId = queryParams.get('branch_id') || null;
        return (
            <div>
                <Marker {...this.props} onMouseOver={this.showInfoBox} onMouseOut={this.hideInfoBox} />
                {
                    (this.state.isInfoBoxShown || showInfoWindowAlways) && location &&  location.location && location.latitude && location.longitude &&
                    <InfoBox
                        defaultPosition={new google.maps.LatLng(location.latitude, location.longitude)}
                        options={{ closeBoxURL: ``, enableEventPropagation: true }}
                    >
                        <div style={{ backgroundColor: `#fff`, padding: `8px`, borderRadius: `5px`, zIndex: `999` }}>
                            <div className="flex flex-column p5 tLeft">
                                <div className="fs-12 mb-2 table-text-color">{location.number ? `Location of ${location.number}` : 'Location'}</div>
                                    {
                                        location.source === false && url_branchId !==null ? (
                                            <div className="theme-color fs-14 fBolder">
                                                {location && url_branchId === location.branch_id ? location.display_name || location.location : location.city}
                                            </div>
                                        ) : (
                                            <div className="theme-color fs-14 fBolder">
                                                {location.display_name || location.location}
                                            </div>
                                        )
                                    }
                            </div>
                            {
                                location.date &&
                                <div className="flex flex-column p5 tLeft">
                                    <div className="fs-12 mb-2 table-text-color">Time</div>
                                    <div className="theme-color fs-14 fBolder">{Moment(location.date).format('DD MMM YYYY, HH:mm')}</div>
                                </div>
                            }
                            { PANTALOONS.includes(getUser().company_id) &&

                                <div className="flex flex-column p5 tLeft">
                                    <div className="fs-12 mb-2 table-text-color">Drop-point</div>
                                    <div className="theme-color fs-14 fBolder">{location.reach_sequence?location.reach_sequence:"Not yet reached"}</div>
                                </div>
                            }
                        </div>
                        {/*
                            <div className="tooltip">
                                <div className="flex flex-column p5 tLeft">
                                    <div className="fs-12 mb-2 table-text-color">Location</div>
                                    <div className="theme-color fs-14 fBolder">{location.location}</div>
                                </div>
                                {
                                    location.date &&
                                    <div className="flex flex-column p5 tLeft">
                                        <div className="fs-12 mb-2 table-text-color">Time</div>
                                        <div className="theme-color fs-14 fBolder">{Moment(location.date).format('DD MMM YYYY')}, {location.time}</div>
                                    </div>
                                }
                            </div>
                        */}
                    </InfoBox>
                }
            </div>
        )
    }
}
