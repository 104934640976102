import React from 'react';

const Button = ({value, className, onClick, type, style})=> {
    return (
        <button 
            type={type || "button"}
            className={"button " + (className || "")}
            value={value}
            onClick={onClick}
            style={style}
        >{value}</button>
    )
}

export default Button;