import React, { Component } from 'react';
import Dropdown from 'Components/Dropdown';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from 'Components/Button';
import { POST_TRANSPORTER_DETAILS } from 'Constants/actionConstants';


class GPSVendors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gpsVendors:''
        }
    }

    selectGPSVendor = (selectedOption) => {
        this.setState( { gpsVendors : selectedOption });
    }

    submitGPSVendorDetails = () => {
        const { dispatch, match } = this.props;
        const data = {
            'trip_id' : match.params.id,
            'gps_details': this.state.gpsVendors
        }
        dispatch({type: POST_TRANSPORTER_DETAILS, data });
    }

    render() {
        return (<div className="pb-150 pr manual-tracking">
            <header className="fs-18 theme-color fBolder mb-30">GPS Vendors</header>
            <div className="wt-350 inBlock">
            <Dropdown value={this.state.gpsVendors} onSelect={this.selectGPSVendor} items = {this.props.gpsVendors} label="Select GPS Vendors" selectedClassName={'col-1'}/>
            </div>
            {this.state.gpsVendors &&
            <div className="wt-100 inBlock">
                <Button value='Save' onClick={this.submitGPSVendorDetails}/>
            </div>}
        </div>
        )
    }
}

export default connect(null)(withRouter(GPSVendors));
