import React from 'react';
import classNames from 'classnames';

import ActiveCardIcon from './icons/arrow.gif';
import { ReactComponent as TruckIcon } from 'Components/TrackingMap/icons/truck-icon.svg';
import { Tooltip } from 'reactstrap';

const AnalyticsCard = ({status, onClick, activeStatus, onSubTypeClick })=> {
    return (
        <div>
            <div onClick={onClick} className={classNames("wt-150 pr br-5 mb-20 hover-effect", {
                'ht-100': status.sub_types && status.sub_types.length > 0,
                'ht-40': !(status.sub_types && status.sub_types.length > 0)
            })} style={{background: status.code}}>
                {activeStatus === status.status && <img src={ActiveCardIcon} alt={'current'} className="pa l-5 t-27 l--35 wt-30"/>}
                {/* <div className="status white-color fs-10 fMedium">{status.status}</div> */}
                <div className="ml-20 primary-type">
                    <div className="status white-color fs-10 fMedium inBlock">
                        {status.status}
                    </div>
                    {/* <TruckIcon style={{width: '20px', height: '20px'}}/> */}
                    <div className="status white-color fMedium fs-24 inBlock ml-10">
                        {status.count || '-'}
                    </div>
                    {/* {<div className="status white-color fMedium fs-12 tRight">{status.value || '-'}</div>} */}
                    {/* <div className="status white-color fMedium fs-12 tRight">{">6hrs"}</div> */}
                </div>
                {status.sub_types && status.sub_types.length > 0 && <div className="wt-150 flex ht-50 flex-between flex-middle">
                    {status.sub_types.length > 0 && status.sub_types.map((subType, index) => {
                        return (
                            <div
                                className={classNames("wt-150 ht-40 br-5 tCenter sub-type", {})}
                                style={{background: '#fff', margin: '1px'}}
                                onClick={(e) => onSubTypeClick(e, subType.parent_status_type, status.status, status.location_type_status, true, subType.type, subType.code)}
                                key={`analytics-${index}`}
                            >
                                {['AT-ORIGIN','AT-DESTINATION'].indexOf(status.status) == -1 && <TruckIcon style={{width: '20px', height: '20px', fill: subType.code }}></TruckIcon>}
                                {['AT-ORIGIN','AT-DESTINATION'].indexOf(status.status) > -1 &&
                                    <div className="status fs-10 fMedium mb-10" style={{'color': subType.code }}>
                                        {subType.status == 'WITHIN 24Hrs' ? '<= 24Hrs' : subType.status == 'ABOVE 24Hrs' ? '> 24Hrs' : subType.status}
                                    </div>
                                }
                                <span className="tooltiptext">
                                    {subType.status == 'WITHIN 24Hrs' ? '<= 24Hrs' : subType.status == 'ABOVE 24Hrs' ? '> 24Hrs' : subType.status}
                                </span>
                                <div className="status fMedium fs-14" style={{color: 'black'}}>
                                    {subType.count || 0 }
                                </div>
                            </div>
                        )})
                    }
                </div>}
            </div>
            {/* { activeStatus === status.status  && status.sub_categories.map((subType) => {
                return(<div className={classNames("wt-85 ml-20 br-5 pr pr-15 mb-10 pl-15 pb-10 pt-10 hover-effect ht-50", {
                    })} style={{background: subType.code}}>
                        <div className="status white-color fs-10 fMedium">{subType.status}</div>
                        <div className="flex flex-between flex-middle">
                            <div className="status white-color fMedium fs-24">{subType.count || '-'}</div>
                        </div>
                </div>)
            })
            } */}
        </div>
    )
};

export default AnalyticsCard;
