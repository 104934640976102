import React, { Component, Fragment } from 'react';
import Dropdown from 'Components/Dropdown';
import { className } from 'classnames';
import  SearchDropdown  from 'Components/SearchDropdown';
import CheckBox from 'Components/Checkbox';
import Cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as EsclamationIcon } from 'Components/TripRow/icons/esclamation.svg';
import Select from 'react-select';
import { TTBIL } from 'Constants/commonConstants';
import { getUser } from 'Utils/storage';

const days = [
    {
        value: 'Select the Day',
        name: 'Select the Day'
    },
    {
        value: "Sunday",
        name: "Sunday"
    },
    {
        value: "Monday",
        name: "Monday"
    },
    {
        value: "Tuesday",
        name: "Tuesday"
    },
    {
        value: "Wednesday",
        name: "Wednesday"
    },
    {
        value: "Thursday",
        name: "Thursday"
    },
    {
        value: "Friday",
        name: "Friday"
    },
    {
        value: "Saturday",
        name: "Saturday"
    }
];

const userDetails = getUser();

export default class SecondaryRouteDetailForm extends Component {
    constructor() {
        super();
        this.state = {
            route_code: '',
            dest_plant_code: '',
            source_plant_code: '',
            isDropdown: false
        }}
    render() {
        const {typesOfLocation, route, index, handleRouteDataChange, label, locations, getSchedulerRoute, searchList, tripData, filterScheduler} = this.props;
        let {route_code, dest_plant_code, source_plant_code, isDropdown} = this.state;
        if(filterScheduler && filterScheduler.route_code === ''){
            route_code = filterScheduler.route_code;
            isDropdown = false;
        }
        return (
            <Fragment>
            {!TTBIL.includes(userDetails.company_id) ?
            <section className="flex flex-between route-form-row">
                <SearchDropdown
                    hideSearch={true}
                    onChange={(value) => {handleRouteDataChange("location", value, index)} }
                    onSelect={(value) => {handleRouteDataChange("location", value, index)} }
                    label={label}
                    items={locations || []}
                    value={ route.location_type_name == 'Third Party' ? route.display_name : (route.plant_name || route.city) }
                    selectedClassName={"route-detail-search"}
                    hasClearFilter={() => {handleRouteDataChange("location", '' , index)}}
                />

                <Dropdown
                    onSelect={(value) => {handleRouteDataChange("location_type", value, index)}}
                    items={typesOfLocation}
                    value={{name:route.location_type_name, value:route.location_type_name}}
                    label={'Type of location'}
                    selectedClassName={`wt-200 ${route.city ? 'disabled' : ''}`}
                />

                <SearchDropdown
                    hideSearch={true}
                    onChange={(value) => {handleRouteDataChange("pincode", value, index)} }
                    label={"PIN Code"}
                    items={[]}
                    selectedClassName={`route-detail-search ${route.city ? 'disabled' : ''}`}
                    value={route.pincode}
                />

                <Dropdown
                    onSelect={(value) => {handleRouteDataChange("weekly_off", value, index)}}
                    containerClassName=" "
                    items={days}
                    value={{name:route.weekly_off, value:route.weekly_off}}
                    label={'Weekly off'}
                    selectedClassName={`wt-150 ${route.city ? 'disabled' : ''}`}
                />

                <TimePicker
                    showSecond={false}
                    value={Moment(new Date())}
                    format={'h:mm a'}
                    use12Hours
                />

                {/*
                    <SearchDropdown
                        hideSearch={true}
                        onChange={(value) => {handleRouteDataChange("name", value, index)} }
                        label={"Consignee name"}
                        items={[]}
                        selectedClassName={'route-detail-search'}
                        value={route.name}
                    />

                    <SearchDropdown
                        hideSearch={true}
                        onChange={(value) => {handleRouteDataChange("number", value, index)} }
                        label={"Consignee No."}
                        items={[]}
                        selectedClassName={'route-detail-search'}
                        value={route.no}
                    />
                */}
            </section> : 
                <Fragment>
                <section className="flex flex-between route-form-row trip-route-details">
                    <div className="wt-180  mr-10">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">
                            Route Code<span className="error-color fs-14">*</span>
                        </div>
                    {!isDropdown  ? 
                    <SearchDropdown
                        hideSearch={true}
                        onChange={(value) => {this.setState({route_code: value, isDropdown: false},getSchedulerRoute("route_code", value, true))} }
                        onSelect={(value) => {this.setState({route_code: value.name, isDropdown: true },getSchedulerRoute("route_code", value.name))} }
                        label={'Route Code'}
                        items={tripData.routeCode || []}
                        value={searchList.plans && searchList.plans.length === 1 ? searchList.plans[0].route_code : route_code}
                        selectedClassName={"route-detail-search"}
                        containerClassName={' wt-150'}
                        hasClearFilter={() => {this.setState({route_code: '',isDropdown: false},getSchedulerRoute("route_code", ''))}}
                    /> :
                    <Dropdown
                        onSelect={(value) => {this.setState({route_code: value.name},getSchedulerRoute("route_code", value.name))} }
                        items={tripData.routeCode || []}
                        value={searchList.plans && searchList.plans.length === 1 ? {name: searchList.plans[0].route_code, value: searchList.plans[0].source_plant_code} : {name:route_code, value:route_code}}
                        // value={route_code}
                        label={'Route Code'}
                        selectedClassName={"route-detail-search"}
                        containerClassName={' wt-150'}
                        hasClearFilter={() => {this.setState({route_code: ''},getSchedulerRoute("route_code", ''))}}
                    />}
                    </div>
                    <div className="wt-180  mr-10">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">
                            Source Location<span className="error-color fs-14">*</span>
                        </div>
                        {!isDropdown ?                
                        <SearchDropdown
                            hideSearch={true}
                            onChange={(value) => {this.setState({source_plant_code: value},getSchedulerRoute("source_plant_code", value, true))} }
                            onSelect={(value) => {this.setState({source_plant_code: value, isDropdown: true},getSchedulerRoute("source_plant_code", value.name))} }
                            label={'Source Code'}
                            items={tripData.sourceList || []}
                            value={searchList.plans && searchList.plans.length === 1 ? searchList.plans[0].source_plant_code : source_plant_code}
                            selectedClassName={"route-detail-search"}
                            containerClassName={' wt-150'}
                            hasClearFilter={() => {this.setState({source_plant_code: '',isDropdown: false},getSchedulerRoute("source_plant_code", ''))}}
                        /> :
                        <Dropdown
                            onSelect={(value) => {this.setState({source_plant_code: value.name},getSchedulerRoute("source_plant_code", value.name))} }
                            items={tripData.sourceList || []}
                            // value={{name:route.location_type_name, value:route.location_type_name}}
                            value={searchList.plans && searchList.plans.length === 1 ? ({name: searchList.plans[0].source_plant_code, value: searchList.plans[0].source_plant_code}) : (searchList.src_codes && searchList.src_codes.length === 1 ? {name: searchList.src_codes[0], value: searchList.src_codes[0]} : {name: source_plant_code, value: source_plant_code})}
                            label={'Source Code'}
                            selectedClassName={"route-detail-search"}
                            containerClassName={' wt-150'}
                            hasClearFilter={() => {this.setState({source_plant_code: '',isDropdown: (route_code === '') ? false : true},getSchedulerRoute("source_plant_code", ''))}}
                        />}
                    </div>
                    <div className="wt-180  mr-10">
                        <div className="fs-12 theme-color fBolder mb-10 add-trip-form-label">
                            Destination Location<span className="error-color fs-14">*</span>
                        </div>
                        {!isDropdown ?               
                        <SearchDropdown
                            hideSearch={true}
                            onChange={(value) => {this.setState({dest_plant_code: value},getSchedulerRoute("dest_plant_code", value, true))}}
                            onSelect={(value) => {this.setState({dest_plant_code: value, isDropdown: true},getSchedulerRoute("dest_plant_code", value.name))} }
                            label={'Destination Code'}
                            items={tripData.destList || []}
                            value={searchList.plans && searchList.plans.length === 1 ? searchList.plans[0].dest_plant_code : dest_plant_code}
                            selectedClassName={"route-detail-search"}
                            containerClassName={'wt-150'}
                            // hasClearFilter={() => {handleRouteDataChange("location", '' , index)}}
                        /> : 
                        <Dropdown
                            onSelect={(value) => {this.setState({dest_plant_code: value.name},getSchedulerRoute("dest_plant_code", value.name))} }
                            items={tripData.destList || []}
                            value={(searchList.plans && searchList.plans.length === 1) ? {name:searchList.plans[0].dest_plant_code, value:searchList.plans[0].dest_plant_code} : (searchList.dest_codes && searchList.dest_codes.length === 1 ? {name: searchList.dest_codes[0], value: searchList.dest_codes[0]} : {name:dest_plant_code, value:dest_plant_code})}
                            // value={dest_plant_code}
                            label={'Destination Code'}
                            selectedClassName={"route-detail-search"}
                            containerClassName={' wt-150'}
                            hasClearFilter={() => {this.setState({dest_plant_code: '',isDropdown: (route_code === '') ? false : true},getSchedulerRoute("dest_plant_code", ''))}}
                        />}
                    </div>
                    </section> 
                </Fragment>
                }
                </Fragment>
        );
    }
}
