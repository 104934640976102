import React, { Component } from 'react';
import ResponsiveTable from 'Components/ResponsiveTable'
import RouteRow from './RouteRow';
import Button from 'Components/Button';
import Input from 'Components/Input';
import { canUserEdit } from 'Utils/storage';

const userCanEdit = canUserEdit();


class RouteDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            tripRouteDetails: props.trip && props.trip.tripRouteDetails || [],
            routeCode: props.trip && props.trip.routeCode || ''
        }
    }

    handleNotification = (key, value, index, isClear) => {
        let data = value.split(',');
        const { tripRouteDetails } = this.state;
        if(isClear) {
            let notificationEmails = [];
            let notificationNumbers = [];
            if(key === 'notificationEmails') {
                for(var i in data) {
                    if(data[i].trim()){
                        notificationEmails.push(data[i].trim());
                    }
                }
                tripRouteDetails[index].notificationEmails = notificationEmails;
            } else if( key === 'notificationNumbers') {
                for(var i in data) {
                    if(data[i].trim()){
                        notificationNumbers.push(data[i].trim());
                    }
                }
                tripRouteDetails[index].notificationNumbers = notificationNumbers;
            }
            this.setState({ tripRouteDetails });
        } else {
            if(key === 'notificationEmails') {
                for(var i in data) {
                    if(data[i].trim()){
                        tripRouteDetails[index].notificationEmails.push(data[i].trim());
                    }
                }
            } else if( key === 'notificationNumbers') {
                for(var i in data) {
                    if(data[i].trim()){
                        tripRouteDetails[index].notificationNumbers.push(data[i].trim());
                    }
                }
            }
            this.setState({ tripRouteDetails });
        }
    }

    submitDetails = () => {
        const { tripRouteDetails, routeCode } = this.state;
        let emailError = false;
        let phoneError = false;
        const data = tripRouteDetails.map(route => {
            route.notificationEmails.map(notEmail => {
                const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                if(notEmail && !emailReg.test(notEmail)) {
                    emailError = true;
                }
            })
            route.notificationNumbers.map(notNum => {
                if(notNum.length < 10 || notNum.length > 10 || isNaN(Number(notNum))) {
                    phoneError = true;
                }
            })
            return {
                branch_id: route.branchId,
                name: route.name,
                number: route.number,
                location: route.location,
                city: route.city,
                location_type: route.locationType,
                weekly_off: route.weeklyOff,
                cut_off_time: route.cutoffTime,
                pincode: route.pincode,
                date: route.date,
                time: route.time,
                tat: route.tat,
                notification_emails: route.notificationEmails,
                notification_numbers: route.notificationNumbers,
                customer_name: route.customerName,
                lr_no: route.lrNo
            }
        })
        if(emailError || phoneError) {
            this.setState({
                emailError,
                phoneError
            })
        } else {
            this.setState({
                emailError,
                phoneError
            })
            this.props.submitRouteDetails({
                "trip_id": this.props.trip.tripId,
                "route_code" : routeCode,
                "route_details" : data
            });
        }
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value})
    }

    render() {
        const { tripRouteDetails } = this.state;
        return (
            <ResponsiveTable>
                <div className="route-details">
                    <header className="fs-18 theme-color fBolder mb-30">Route Details</header>
                    <div className="route-details-table">
                        <div className="flex flex-between mb-15">
                            <span className="p5 fBolder fs-12 header-light-color col-7">Location</span>
                            <span className="p5 fBolder fs-12 header-light-color col-7">Location type</span>
                            <span className="p5 fBolder fs-12 header-light-color col-7">PIN code</span>
                            {/* <span className="p5 fBolder fs-12 header-light-color col-7">Weekly off</span> */}
                            <span className="p5 fBolder fs-12 header-light-color col-7">Stopping point</span>
                            {/* <span className="p5 fBolder fs-12 header-light-color col-7">Daily cutoff time</span> */}
                            <span className="p5 fBolder fs-12 header-light-color col-7">Consignee Details</span>
                            <span className="p5 fBolder fs-12 header-light-color col-7">TAT(Days)</span>
                            <span className="p5 fBolder fs-12 header-light-color col-7">Notification Emails</span>
                            <span className="p5 fBolder fs-12 header-light-color col-7">Notification Numbers</span>
                        </div>
                        {
                            (tripRouteDetails || []).map((route, index)=> {
                                return (<RouteRow key={index} route={route} routeIndex={index} handleNotification={this.handleNotification}/>);
                            })
                        }
                    </div>
                    <div className="wt-200 mt-10 mb-40">
                        <Input containerClassName="mb-10 " name="routeCode" value={this.state.routeCode} placeholder="ROUTE CODE" onChange={this.onChange} />
                    </div>
                    {(this.state.emailError || this.state.phoneError) && <div className="mt-10 error-color fs-14">{(this.state.emailError && this.state.phoneError) ? 'Please Enter A Valid Email Address And 10digit Phone No.' : `Please Enter A Valid ${this.state.emailError ? 'Email Address': '10digit Phone No.'}`}</div>}
                    {
                        userCanEdit &&
                        <div className="tCenter mt-20 wt-200">
                            <Button value='Save' onClick={this.submitDetails} />
                        </div>
                    }
                </div>
            </ResponsiveTable>
        )
    }
}
export default RouteDetails;
