import React, { Component } from 'react';
import Input from 'Components/Input';
import Select from 'react-select';
import Button from 'Components/Button';

class Share extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emails: props.shareDetails && Object.keys(props.shareDetails).length > 0 ? props.shareDetails.emails : [],
            phone_numbers: props.shareDetails && Object.keys(props.shareDetails).length > 0 ? props.shareDetails.phone_numbers : [],
            url: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.shareDetails && Object.keys(nextProps.shareDetails).length > 0) {
            const { emails, phone_numbers } = nextProps.shareDetails;
            this.setState({ emails , phone_numbers });
        }
    }


    onHandleChange = (id, evt) => {
        if(evt.target.value){
            let data = evt.target.value.split(',');
            let { emails, phone_numbers} = this.state;
            if(id === 'emails') {
                for(var i in data) {
                    if(data[i].trim()){
                        emails.push(data[i].trim());
                    }
                }
            } else if( id === 'phone_numbers') {
                for(var i in data) {
                    if(data[i].trim()){
                        phone_numbers.push(data[i].trim());
                    }
                }
            } 
            this.setState({ emails, phone_numbers });
        }
    }

    onChange = (id, options) => {
        let emails = [],
        phone_numbers = [];
        if(id === 'emails') {
            for(var i in options) {
                emails.push(options[i].label);
           }
           phone_numbers = this.state.phone_numbers;
        } else if(id === 'phone_numbers') {
            for(var i in options) {
                phone_numbers.push(options[i].label);
           }
           emails = this.state.emails;
        }
       this.setState({ emails, phone_numbers });
    }

    onSave = () => {
        const { emails, phone_numbers } = this.state;
        const data = {
            emails,
            phone_numbers,
            url: ''
        }
        this.props.onSaveSharedDetails(data);
        this.props.hideOverlay();
    }

    render() {
        const { emails, phone_numbers } = this.state;
        return (
            <div className={'col-1 share'}>
                <div className={'mt-10 ml-25'}>
                    <label>Email Ids (comma separated)</label>
                    <Select className={'wt-300'} isMulti value={emails.length > 0 ? emails.map(val => { return { label : val, value: val } } ) : null} onBlur={this.onHandleChange.bind(this, 'emails')} isClearable={true} onChange={this.onChange.bind(this, 'emails')} placeholder={'Email Id'}/>
                </div>
                <div className={'mt-20 ml-25'}>
                    <label>Phone No's (comma separated)</label>
                    <Select className={'wt-300'} isMulti value={phone_numbers.length > 0 ? phone_numbers.map(val => { return { label : val, value: val } } ) : null} onBlur={this.onHandleChange.bind(this, 'phone_numbers')} isClearable={true} onChange={this.onChange.bind(this, 'phone_numbers')} placeholder={'Phone No.'}/>
                </div>

                <div className={'mt-20'}>
                    <div className={'button-outlined share-button wt-100 tCenter fRight mr-40'} onClick={this.onSave}>Send</div>
                    <div className={'button-outlined share-button wt-100 tCenter fRight mr-20'} onClick={this.props.hideOverlay}>Cancel</div>  
                </div>
            </div>
        )
    }
}

export default Share;
