import React, { Fragment } from 'react';
import classNames from 'classnames';
const DashboardCard = ({status, onClick, tripSubStatus, activeType})=> {
    const isLocationStatus = activeType && status.location_type_status && (status.location_type_status.toString() === activeType.toString());
    return (
        <Fragment>
            {
                tripSubStatus ?
                <div onClick={onClick} className={classNames("min-wt-150 br-5 box-shadow mr-10 display-inline mt-5 curP ht-40", {
                        'op-3': activeType && (!isLocationStatus && (activeType !== status.type)),
                    })} style={{background: (activeType && (isLocationStatus || activeType === status.type)) ? 'white' : status.code, border: `1px solid ${status.code}`}}>
                        <div className={classNames("tCenter br-3 pl-2 pr-2 pb-1", {
                        'm2': activeType && (isLocationStatus || activeType === status.type)
                        })} style={{background: status.code}}>
                            <div className={classNames("status white-color fs-10 fMedium mr-5 display-inline mt-6", {
                            'fs-14': tripSubStatus
                            })}>{`${status.status} `}</div>
                            {/* <div>{' '}</div> */}
                            <div className={classNames('white-color fMedium display-inline mt-6', {
                            'fs-20': tripSubStatus,
                            'fs-28': !tripSubStatus
                            })}>{` ${status.count || 0}`}</div>
                        </div>
                </div>
                :
                <div onClick={onClick} className={classNames("min-wt-150 br-5 pr-5 pl-5 pb-5 box-shadow mr-10 display-inline mt-5 curP", {
                    'ht-50 pt-5': status.status === 'TOTAL TRIPS',
                    'ht-40': status.status !== 'TOTAL TRIPS',
                    'op-3': activeType && activeType !== status.type
                })} style={{background: status.code}}>
                    <div className={classNames("status white-color fs-10 fMedium tCenter pt-15 mr-5 display-inline", {
                        'fs-14': tripSubStatus
                    })}>{`${status.status} `}</div>
                    {/* <div>{'  '}</div> */}
                    <div className={classNames('white-color fMedium display-inline', {
                        'fs-20': tripSubStatus,
                        'fs-28': !tripSubStatus
                    })}>{` ${status.count || 0}`}</div>
                    {/* <div className="flex flex-between flex-middle">
                        <div className="status white-color fMedium fs-28">{status.count || '-'}</div>
                        {/* <div className="status white-color fMedium fs-14 tRight">{status.value || '-'}</div> */}
                    {/* </div> */}
                </div>
            }

        </Fragment>
    )
};

export default DashboardCard;
