import React from 'react';
import classNames from 'classnames';

const Banner = ({data, type, islogin})=> {
    let messages = [];
    return (
        <div className={classNames("banner col-1 z-100 psticky t-0", {})}>
            {/*<div className={classNames("m-hide toast pf ht-60 br-5 mwt-500 flex flex-center flex-middle fMedium fs-16 white-color pl-100 pr-100 r-25 transition-bottom", {
                'bg-success': type==='SUCCESS',
                'bg-error': type==='ERROR',
                'active': (type && message)
            })}>
                {message}
            </div>
            <div className={classNames("d-hide toast pf ht-60 br-5 mwt-400 flex flex-center flex-middle fMedium fs-16 white-color pl-20 pr-20 r-25 transition-bottom", {
                'bg-success': type==='SUCCESS',
                'bg-error': type==='ERROR',
                'active': (type && message)
            })}>
                {message}
            </div>*/}
            {/* {(data.length > 0) && <ul className={classNames("mwt-500 fBolder fs-12 bg-orange p-5 pl-25 pr-25 tCenter mb-0", {})}>
                {
                    (data || []).map((title, index) => {
                        return (
                            <li key={`banner-${index}`}> {title} <br /></li>
                        )
                    })
                }
            </ul>} */}
            {(data.length > 0) &&
            (data || []).map((title, index) => {
                messages.push(title.message)
            })}
            <marquee width="100%" direction="left" height="18px">
                <div className="fs-16 fBolder">{messages.join(' | ')}</div>
            </marquee>
        </div>
    )
}

export default Banner;
