import React, { Component, Fragment } from "react";
import "./ForgotPassword.scss";
import Logo from "Components/Logo/index";
import Tabs from "Components/Tabs/index";
import { ReactComponent as PasswordVisible } from "./passwordvisible.svg";
import { ReactComponent as PasswordNotVisible } from "./passwordNotVisible.svg";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
import { ToastContainer, toast } from "react-toastify";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailFocused: false,
    };
  }
  render() {
    const {
      companyTypeTabButton,
      companyType,
      selectCompanyType,
      toggleForgotPassword,
      forgotPassword,
      email,
      handleEmailChange,
    } = this.props;
    return (
      <Fragment>
        {/* <Rectangle className={"otpRectangleTopL"} /> */}
        {/* <Rectangle className={"otpRectangleTopS"} /> */}
        <div className="forgotPasswordFormCardMain">
          <ToastContainer />
          <div className="forgotPasswordFormCard">
            <div className="forgotPasswordFormLogo">
              <Logo className="forgotPasswordLogoImage" />
            </div>
            <div className="companyTypeButton">
              <Tabs
                tabs={companyTypeTabButton}
                activeTabId={companyType}
                selectTab={selectCompanyType}
              />
            </div>
            <div className="forgotPasswordFormEmailMain">
              <p className="forgotPasswordFormEmailLable">Email</p>
              <div className={`forgotPasswordFormEmailBox ${this.state.isEmailFocused ? 'box-focused' : ''}`}>
                <input
                  type="text"
                  name="email"
                  className="forgotPasswordFormEmailInput"
                  value={email}
                  onChange={handleEmailChange}
                  onFocus={() => this.setState({ isEmailFocused: true })}
                  onBlur={() => this.setState({ isEmailFocused: false })}
                >

                </input>
              </div>
            </div>

            <div className="forgotPasswordButtonBox">
              <Button
                Name={"Submit"}
                className={"forgotPasswordButton"}
                onClick={forgotPassword} 
                value={"Submit"}
              />
            </div>
            <div className="forgotPasswordBackToSignInLableBox">
              <p
                className="forgotPasswordBackToSignInLable"
                onClick={toggleForgotPassword}
              >
                Back To SignIn
              </p>
            </div>
          </div>
        </div>
        {/* <Rectangle className={"otpRectangleBottomL"} /> */}
        {/* <Rectangle className={"otpRectangleBottomS"} /> */}
      </Fragment>
    );
  }
}
export default ForgotPassword;
