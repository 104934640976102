import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Tabs from '../Tabs';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';

import SearchDropdown from '../SearchDropdown/index';
import DetailedLogRow from '../DetailedLogRow/DetailedLogRow';
import Button from '../Button';
import ActionLoader from 'Components/ActionLoader';
import {ReactComponent as SortArrow } from './icons/sort-arrow.svg';

import { DETAILED_LOG_PAGINATION_COUNT } from 'Constants/commonConstants';
import { DOWNLOAD_LOCATION_LOGS  } from 'Constants/actionConstants';


class DetailedLog extends Component {

    constructor(props) {
        super();
        this.state = {
            startDate: '',
            endDate: '',
            activeTab: props.trackingType || 1,
            searchText: "",
            offset: 0,
            focusedStart: false,
            focusedEnd: false,
            order_by: '-date'
        };
    }

    search = () => {
        const { searchText, startDate, endDate, activeTab, offset, order_by} = this.state;
        this.props.search(
            searchText,
            startDate && startDate.valueOf(),
            endDate && endDate.valueOf(),
            activeTab,
            offset,
            DETAILED_LOG_PAGINATION_COUNT,
            order_by
        );
    }

    selectTab = (activeTab) => {
        this.setState({
            activeTab,
            offset: 0
        }, this.search);
    }

    changeSearchText = (searchText) => {
        this.setState({
            searchText,
            offset: 0
        }, this.search);
    }

    incrementOffset = () => {
        const { offset } = this.state;
        this.setState({
            offset: offset + DETAILED_LOG_PAGINATION_COUNT
        }, this.search);
    }

    decrementOffset = () => {
        const { offset } = this.state;
        this.setState({
            offset: offset - DETAILED_LOG_PAGINATION_COUNT
        }, this.search);
    }

    onClickSort = () => {
        let { order_by } = this.state;
        order_by = order_by === '-date' ? 'date' : '-date' ;
        this.setState({
            order_by
        }, this.search);
    }

    downlaodLocationLogs = (tripId) => {
        const { dispatch } = this.props;
        dispatch({ type: DOWNLOAD_LOCATION_LOGS, tripId: tripId });
    }

    render() {
        const { locations, isDetailedLogLoaded, tripId } = this.props;
        const { offset } = this.state;
        const tabFilters = [{
            id: 1,
            value: "App"
        },{
            id: 2,
            value: "Sim"
        },{
            id: 3,
            value: "Third Party"
        }, {
            id: 4,
            value: 'Manual'
        }];
        const operatorType = tabFilters.find(val => { return val.id === this.state.activeTab });
        return (
            <div className="detail-log-table-container flex-1 ht-550">
                <header className="pl-15 fs-24 theme-color fBolder  mr-40 text-center mb-30 ">Detailed log</header>
                <div className="detail-log-header-controls flex flex-between mb-30 mr-25">
                    <div className="theme-color tCenter fBolder pt-10 pr-40 pl-40 br-5" style={{backgroundColor: '#3f51b5',color: '#fff' }}>
                        {operatorType.value}
                    </div>
                    <div className="download-logs theme-color tCenter fBolder pt-10 pr-40 pl-40 br-5" style={{backgroundColor: '#3f51b5'}}>
                        <Button className={'download-button'} value={'Download'} onClick={() => this.downlaodLocationLogs(tripId)} />
                    </div>

                    {/* <Tabs selectTab={this.selectTab} activeTabId={this.state.activeTab} tabs={tabFilters} /> */}
                    <SingleDatePicker
                        noBorder={true}
                        displayFormat={"DD/MMM/YYYY"}
                        isOutsideRange={() => false}
                        hideKeyboardShortcutsPanel={true}
                        placeholder={"start date"}
                        numberOfMonths={1}
                        date={this.state.startDate}
                        onDateChange={startDate => this.setState({ startDate }, this.search)}
                        focused={this.state.focusedStart}
                        onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                        id="startDate"
                    />

                    <SingleDatePicker
                        noBorder={true}
                        displayFormat={"DD/MMM/YYYY"}
                        isOutsideRange={date => date.isBefore(this.state.startDate)}
                        hideKeyboardShortcutsPanel={true}
                        placeholder={"end date"}
                        numberOfMonths={1}
                        date={this.state.endDate}
                        onDateChange={endDate => this.setState({ endDate }, this.search)}
                        focused={this.state.focusedEnd}
                        onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                        id="endDate"
                    />
                    <SearchDropdown onChange={this.changeSearchText} items={[]} label={"Search place, checkpost"} />
                </div>
                <div className="detail-log-table flex-1">
                    <div className="flex flex-between mb-15">
                        <span className="fBolder fs-12 header-light-color wt-150 pl-15">Location</span>
                        <span className="fBolder fs-12 header-light-color wt-250 pl-15">Address</span>
                        <span className="fBolder fs-12 header-light-color wt-200 pl-20">Location type</span>
                        <div className="fBolder fs-12 header-light-color wt-150 pl-15">
                            <span className="fBolder fs-12 header-light-color pl-15">Location Time</span>
                            <span className={'ml-10'} onClick={this.onClickSort}>
                                <SortArrow style={{height: '12px', width:'12px'}} />
                            </span>
                        </div>
                        <span className="fBolder fs-12 header-light-color wt-200 pl-20">System Time</span>
                        {/* <span className="fBolder fs-12 header-light-color col-6 pl-15">Time</span> */}
                        <span className="fBolder fs-12 header-light-color wt-100 pl-15">Distance Covered</span>
                        <span className="fBolder fs-12 header-light-color wt-100 pl-15">Distance Remaining</span>
                        {/* <span className="fBolder fs-12 header-light-color wt-100 pl-15">Status</span> */}
                        {/* <span className="fBolder fs-12 header-light-color col-6 pl-15">ETA</span> */}
                    </div>
                </div>
                {
                    isDetailedLogLoaded && (locations || []).map((loc, index) => {
                        return <DetailedLogRow loc={loc} />
                    })
                }
                {
                    isDetailedLogLoaded && locations && locations.length === 0 &&
                    <div className="mt-20 contentCenter fBolder fs-14 pl-15 tCenter">
                        No location is available
                    </div>
                }
                {
                    isDetailedLogLoaded &&
                    <section className="detail-log-buttons mb-10 mt-30 col-1 ht-50">
                        {
                            offset > 0 &&
                            <div className="fLeft">
                                <Button onClick={this.decrementOffset} className="button-outlined fs-13" value="<< Previous"></Button>
                            </div>
                        }
                        {
                            locations && locations.length >= DETAILED_LOG_PAGINATION_COUNT &&
                            <div className="fRight">
                                <Button onClick={this.incrementOffset} className="mr-25 button-outlined fs-13" value="Next >>"></Button>
                            </div>
                        }
                    </section>
                }
                {!isDetailedLogLoaded && <ActionLoader />}
            </div>
        )
    }

    componentDidMount() {
        this.search();
    }
}

export default connect()(withRouter(DetailedLog));
