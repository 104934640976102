export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN_ERROR= 'SIGN_IN_ERROR';
export const SIGN_IN_SUBMITTING= 'SIGN_IN_SUBMITTING';
export const STORE_USER_DATA = 'SIGN_IN_SUCCESS';


export const TOAST_ERROR = 'TOAST_ERROR';
export const TOAST_SUCCESS = 'TOAST_SUCCESS';
export const HIDE_TOAST = 'HIDE_TOAST';


export const GET_TRIPS = 'GET_TRIPS';
export const GET_TRIPS_SUCCESS = 'GET_TRIPS_SUCCESS';
export const CLEAR_TRIPS = 'CLEAR_TRIPS';

export const GET_TRIP_DETAIL = 'GET_TRIP_DETAIL';
export const GET_TRIP_DETAIL_SUCCESS = 'GET_TRIP_DETAIL_SUCCESS';
export const CLEAR_TRIP_DETAIL = 'CLEAR_TRIP_DETAIL';

export const GET_TRACKING_DETAIL = 'GET_TRACKING_DETAIL';
export const GET_TRACKING_DETAIL_SUCCESS = 'GET_TRACKING_DETAIL_SUCCESS';
export const CLEAR_TRACKING_DETAIL = 'CLEAR_TRACKING_DETAIL';

export const SEARCH_ON_MAP = 'SEARCH_ON_MAP';
export const SEARCH_ON_MAP_SUCCESS = 'SEARCH_ON_MAP_SUCCESS';
export const SEARCH_ON_MAP_FAILURE = 'SEARCH_ON_MAP_FAILURE';

export const GET_DETAILED_LOG = 'GET_DETAILED_LOG';
export const GET_DETAILED_LOG_SUCCESS = 'GET_DETAILED_LOG_SUCCESS';
export const CLEAR_DETAILED_LOG = 'CLEAR_DETAILED_LOG';
export const DOWNLOAD_LOCATION_LOGS = 'DOWNLOAD_LOCATION_LOGS';
export const DOWLOAD_LOCATION_LOGS_SUCCESS = 'DOWLOAD_LOCATION_LOGS_SUCCESS'
export const CLEAR_DOWNLOAD_LOG = 'CLEAR_DOWNLOAD_LOG';

export const GET_TRANSPORTER_SEACH = 'GET_TRANSPORTER_SEACH';
export const GET_TRANSPORTER_SEACH_SUCCESS = 'GET_TRANSPORTER_SEACH_SUCCESS';

export const GET_PLANT_SEACH = 'GET_PLANT_SEACH';
export const GET_PLANT_SEACH_SUCCESS = 'GET_PLANT_SEACH_SUCCESS';

export const GET_ITEMS_SEACH = 'GET_ITEMS_SEACH';
export const GET_ITEMS_SEACH_SUCCESS = 'GET_ITEMS_SEACH_SUCCESS';

export const GET_CUSTOMER_SEARCH = 'GET_CUSTOMER_SEARCH';
export const GET_CUSTOMER_SEARCH_SUCCESS = 'GET_CUSTOMER_SEARCH_SUCCESS';

export const UPDATE_TRACKING_PREFERENCES='UPDATE_TRACKING_PREFERENCES';


export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';

export const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS';
export const GET_DASHBOARD_DETAILS_SUCCESS = 'GET_DASHBOARD_DETAILS_SUCCESS';
export const CLEAR_DASHBOARD_DETAILS = 'CLEAR_DASHBOARD_DETAILS';

export const GET_MULTIPLE_TRIPS = 'GET_MULTIPLE_TRIPS';
export const GET_MULTIPLE_TRIPS_SUCCESS = 'GET_MULTIPLE_TRIPS_SUCCESS';
export const CLEAR_MULTIPLE_TRIPS = 'CLEAR_MULTIPLE_TRIPS';

export const ADD_TRIP =  'ADD_TRIP';
export const ADD_TRIP_SUCCESS =  'ADD_TRIP_SUCCESS';
export const ADD_TRIP_SUBMITTING = 'ADD_TRIP_SUBMITTING';

export const GET_ADD_TRIP_TEMPLATE = 'GET_ADD_TRIP_TEMPLATE';
export const GET_ADD_TRIP_TEMPLATE_SUCESS = 'GET_ADD_TRIP_TEMPLATE_SUCESS';

export const UPLOAD_TRIPS = 'UPLOAD_TRIPS';
export const UPLOAD_TRIPS_SUCCESS = 'UPLOAD_TRIPS_SUCCESS';

export const POST_MANUAL_TRACKING = 'POST_MANUAL_TRACKING';
export const POST_MANUAL_TRACKING_SUCCESS = 'POST_MANUAL_TRACKING_SUCCESS';
export const POST_MANUAL_TRACKING_SUBMITTING = 'POST_MANUAL_TRACKING_SUBMITTING';
export const POST_MANUAL_TRACKING_ERROR = 'POST_MANUAL_TRACKING_ERROR';

export const POST_TRANSPORTER_DETAILS = 'POST_TRANSPORTER_DETAILS';
export const POST_TRANSPORTER_DETAILS_SUCCESS = 'POST_TRANSPORTER_DETAILS_SUCCESS';
export const POST_TRANSPORTER_DETAILS_SUBMITTING = 'POST_TRANSPORTER_DETAILS_SUBMITTING';
export const POST_TRANSPORTER_DETAILS_ERROR = 'POST_TRANSPORTER_DETAILS_ERROR';

export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const CLEAR_PROVIDERS = 'CLEAR_PROVIDERS';

export const CANCEL_TRIP = 'CANCEL_TRIP';

export const GET_GPS_VENDORS = 'GET_GPS_VENDORS';
export const STORE_GPS_VENDORS_DETAILS = 'STORE_GPS_VENDORS_DETAILS';

export const GET_HOLISTICS = 'GET_HOLISTICS';
export const GET_HOLISTICS_SUBMITTING = 'GET_HOLISTICS_SUBMITTING';
export const GET_HOLISTICS_SUCCESS = 'GET_HOLISTICS_SUCCESS';

export const GET_CURRENT_LOCATION = 'GET_CURRENT_LOCATION';
export const STORE_CURRENT_LOCATION = 'STORE_CURRENT_LOCATION';

export const GET_TAT_DETAILS = 'GET_TAT_DETAILS';
export const STORE_TAT_DETAILS = 'STORE_TAT_DETAILS';

export const GET_SHARE_DETAILS = 'GET_SHARE_DETAILS';
export const STORE_SHARE_DETAILS = 'STORE_SHARE_DETAILS';
export const SAVE_SHARE_DETAILS = 'SAVE_SHARE_DETAILS';

export const CONFIRM_AT_ORIGIN = 'CONFIRM_AT_ORIGIN';

export const GET_ACTION_DETAILS = 'GET_ACTION_DETAILS';
export const STORE_ACTION_DETAILS = 'STORE_ACTION_DETAILS';

export const GET_EXCEPTION_TRIP_DETAILS = 'GET_EXPECTION_TRIP_DETAILS';
export const STORE_EXCEPTION_TRIP_DETAILS = 'STORE_EXCEPTION_TRIP_DETAILS';
export const CLEAR_EXCEPTION_TRIP_DETAILS = 'EXCEPTION_TRIP_DETAILS';

export const GET_VEHICLE_TYPES = 'GET_VEHICLE_TYPES';
export const STORE_VEHICLE_TYPES = 'STORE_VEHICLE_TYPES';
export const GET_VEHICLE_BODY_TYPES = 'GET_VEHICLE_BODY_TYPES';
export const STORE_VEHICLE_BODY_TYPES = 'STORE_VEHICLE_BODY_TYPES';
export const GET_VEHICLE_DETAIL_OPTIONS = 'GET_VEHICLE_DETAIL_OPTIONS';
export const STORE_VEHICLE_DETAIL_OPTIONS = 'STORE_VEHICLE_DETAIL_OPTIONS';
export const GET_VEHICLE_DATA = 'GET_VEHICLE_DATA';
export const STORE_VEHICLE_DATA = 'STORE_VEHICLE_DATA';
export const POST_VEHICLE_DATA = 'POST_VEHICLE_DATA';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const DATA_LOADING = 'DATA_LOADING';
export const CLEAR_DATA_LOAD = 'CLEAR_DATA_LOAD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const GET_SEEKERS = 'GET_SEEKERS';
export const CLEAR_SEEKERS = 'CLEAR_SEEKERS';
export const GET_SEEKER_SUCCESS = 'GET_SEEKER_SUCCESS';

export const GET_OPERATOR_TYPE = 'GET_OPERATOR_TYPE';
export const SAVE_OPERATOR_TYPE = 'SAVE_OPERATOR_TYPE';

export const NUMBERS_OPERATOR_TYPE = 'NUMBERS_OPERATOR_TYPE';
export const GET_NUMBERS_OPERATOR_TYPE = 'GET_NUMBERS_OPERATOR_TYPE';
export const NUMBERS_OPERATOR_LOADING = 'NUMBERS_OPERATOR_LOADING';

export const GET_LOCATION_TYPE_FILTER = 'GET_LOCATION_TYPE_FILTER';
export const SAVE_LOCATION_TYPE_FILTER = 'SAVE_LOCATION_TYPE_FILTER';

export const GET_SSO_CONFIG = 'GET_SSO_CONFIG';
export const STORE_SSO_CONFIG_DATA = 'STORE_SSO_CONFIG_DATA';

export const GET_BROADCAST_MSG = 'GET_BROADCAST_MSG';
export const STORE_BROADCAST_MSG = 'STORE_BROADCAST_MSG';
export const CLEAR_BROADCAST_MSG = 'CLEAR_BROADCAST_MSG';
export const HIDE_BANNER = 'HIDE_BANNER';

export const CREATE_LOCATION = 'CREATE_LOCATION';

export const GET_TRIP_STATUS_COUNT = 'GET_TRIP_STATUS_COUNT';
export const SAVE_TRIP_STATUS_COUNT = 'SAVE_TRIP_STATUS_COUNT';

export const DOWNLOAD_TRIP = 'DOWNLOAD_TRIP';

export const GET_DO_NO_BASED_ON_FROM_LOC = 'GET_DO_NO_BASED_ON_FROM_LOC';
export const SAVE_DO_NO_BASED_ON_FROM_LOC = 'SAVE_DO_NO_BASED_ON_FROM_LOC';

export const GET_SCHEDULER_DASHBOARD_SUCCESS = 'GET_SCHEDULER_DASHBOARD_SUCCESS';
export const GET_SCHEDULER_DASHBOARD = 'GET_SCHEDULER_DASHBOARD';

export const SAVE_BRANCH_TYPE_FILTER = "SAVE_BRANCH_TYPE_FILTER";
export const GET_BRANCH = "GET_BRANCH";
export const SAVE_DI_TIME_FILTER ="SAVE_DI_TIME_FILTER";

export const GET_SCHEDULER_ROUTE_SUCCESS = "GET_SCHEDULER_ROUTE_SUCCESS";
export const GET_SCHEDULER_ROUTE_DETAILS = "GET_SCHEDULER_ROUTE_DETAILS";

export const GET_FOIS_TEMPLATE = "GET_FOIS_TEMPLATE";
export const POST_FOIS_UPLOAD_FILE = "POST_FOIS_UPLOAD_FILE";

export const GET_FOIS_DETAILS = "GET_FOIS_DETAILS";
export const GET_FOIS_TRACKING_DATA = "GET_FOIS_TRACKING_DATA";

export const GET_FOIS_STATION_DATA = "GET_FOIS_STATION_DATA";
export const POST_FOIS_FNR = "POST_FOIS_FNR";

export const GET_FOIS_COUNT = "GET_FOIS_COUNT";
export const GET_FOIS_MOVEMENT_TYPE = "GET_FOIS_MOVEMENT_TYPE";
export const GET_FOIS_MOVEMENT_TYPE_SUCCESS = "GET_FOIS_MOVEMENT_TYPE_SUCCESS";

export const REMINDER_EMAIL_DATA = 'REMINDER_EMAIL_DATA';
export const GET_REMINDER_EMAIL_SUCCESS = 'GET_REMINDER_EMAIL_SUCCESS';
export const EDIT_FNR_DATA = 'EDIT_FNR_DATA';

export const REQUEST_OTP_SUBMITTING = 'REQUEST_OTP_SUBMITTING';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const REQUEST_OTP = 'REQUEST_OTP';
export const LOGIN_WITH_OTP = 'LOGIN_WITH_OTP';
export const LOGIN_WITH_SSO = "LOGIN_WITH_SSO";
export const VALIDATE_SSO_USER = "VALIDATE_SSO_USER";
export const SSO_DELETE_TOKEN = "SSO_DELETE_TOKEN";

export const DOWNLOAD_MILESTONE_TEMP = 'DOWNLOAD_MILESTONE_TEMP';
export const UPLOAD_ORDERS = 'UPLOAD_ORDERS';
export const GENERATE_DELAY_REASON_TEMPLATE = 'GENERATE_DELAY_REASON_TEMPLATE';
export const UPLOAD_DELAY_REASONS = 'UPLOAD_DELAY_REASONS';
export const GET_MILESTONE_OVERVIEW_DETAILS = 'GET_MILESTONE_OVERVIEW_DETAILS';
export const GET_MILESTONE_LR_COUNT_DETAILS = 'GET_MILESTONE_LR_COUNT_DETAILS';
export const LOADING_START = 'LOADING_START';
export const GET_MILESTONE_OVERVIEW_SUCCESS = 'GET_MILESTONE_OVERVIEW_SUCCESS';
export const GET_MILESTONE_ORDER_SUCCESS = 'GET_MILESTONE_ORDER_SUCCESS';
export const GET_MILESTONE_ORDER = 'GET_MILESTONE_ORDER';
export const GET_MILESTONE_DOCKET_LIST = 'GET_MILESTONE_DOCKET_LIST';
export const LOADING_END = 'LOADING_END';

export const GET_VEHICLE_REMARKS = 'GET_VEHICLE_REMARKS';
export const GET_VEHICLE_REMARKS_SUCCESS = 'GET_VEHICLE_REMARKS_SUCCESS';
export const CONFIRM_REJECT_VEHICLE = 'CONFIRM_REJECT_VEHICLE';
export const DOWNLOAD_LSP_TEMPLATE = 'DOWNLOAD_LSP_TEMPLATE';
export const UPLOAD_LSP_DETAILS = 'UPLOAD_LSP_DETAILS';
export const UPLOAD_POD_DETAILS = 'UPLOAD_POD_DETAILS';
export const GET_SERVICE_PROVIDER_SUCCESS = 'GET_SERVICE_PROVIDER_SUCCESS';
export const GET_SERVICE_PROVIDERS = 'GET_SERVICE_PROVIDERS';

export const GET_MILESTONE_RESERVED_LR_LIST = 'GET_MILESTONE_RESERVED_LR_LIST';
export const GET_MILESTONE_RESERVED_LR_SUCCESS = 'GET_MILESTONE_RESERVED_LR_SUCCESS';
export const CANCEL_EXCEPTION = 'CANCEL_EXCEPTION';
export const UPDATE_EXCEPTION_MILESTONE  = 'UPDATE_EXCEPTION_MILESTONE ';
export const MOVE_TO_EXCEPTION_MILESTONE_UPDATE  = 'MOVE_TO_EXCEPTION_MILESTONE_UPDATE ';
export const GET_REASONS  = 'GET_REASONS ';
export const GET_REASONS_SUCCESS  = 'GET_REASONS_SUCCESS ';

export const GET_AUDIT_LISTING = 'GET_AUDIT_LISTING'
export const GET_AUDIT_LIST_COUNT = 'GET_AUDIT_LIST_COUNT'
export const GET_AUDIT_LISTING_SUCCESS = 'GET_AUDIT_LISTING_SUCCESS'
export const GET_AUDIT_DETAILS = 'GET_AUDIT_DETAILS';
export const GET_AUDIT_DETAILS_SUCCESS = 'GET_AUDIT_DETAILS_SUCCESS';
export const COMPLETE_AUDIT = 'COMPLETE_AUDIT';

export const SUCCESS_TOAST = 'SUCCESS_TOAST';

export const GET_MOVEMENT_TYPE_DATA = 'GET_MOVEMENT_TYPE_DATA';
export const GET_MOVEMENT_TYPE = 'GET_MOVEMENT_TYPE';

export const GET_SHORTAGE_TYPE = 'GET_SHORTAGE_TYPE';
export const GET_SHORTAGE_TYPE_DATA = 'GET_SHORTAGE_TYPE_DATA';

export const GET_VEHICLE_LIST = 'GET_VEHICLE_LIST';
export const GET_VEHICLE_DETAILS = 'GET_VEHICLE_DETAILS';

export const GET_MULTIMODAL_TRACKING_DETAILS = 'GET_MULTIMODAL_TRACKING_DETAILS';
export const GET_MULTIMODAL_TRACKING_DETAILS_SUCCESS = 'GET_MULTIMODAL_TRACKING_DETAILS_SUCCESS';

export const DOWNLOAD_POD_UPLOADED_FILES = 'DOWNLOAD_POD_UPLOADED_FILES';

export const GET_MILESTONE_LOCATIONS_DROPDOWN = 'GET_MILESTONE_LOCATIONS_DROPDOWN';
export const SET_MILESTONE_LOCATIONS_DROPDOWN = 'SET_MILESTONE_LOCATIONS_DROPDOWN';

export const GET_TRIP_CONSIGNEE_DROPDOWN = 'GET_TRIP_CONSIGNEE_DROPDOWN';
export const SET_TRIP_CONSIGNEE_DROPDOWN = 'SET_TRIP_CONSIGNEE_DROPDOWN';
export const GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN = 'GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN';
export const SET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN = 'SET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN';
export const GET_TRIP_TRANSPORTERS_DROPDOWN = 'GET_TRIP_TRANSPORTERS_DROPDOWN';
export const SET_TRIP_TRANSPORTERS_DROPDOWN = 'SET_TRIP_TRANSPORTERS_DROPDOWN';

export const GET_BOOKING_ORDER_LOGS = 'GET_BOOKING_ORDER_LOGS';
export const SET_BOOKING_ORDER_LOGS = 'SET_BOOKING_ORDER_LOGS';

export const GET_API_LOG_TYPES = 'GET_API_LOG_TYPES';
export const SET_API_LOG_TYPES = 'SET_API_LOG_TYPES';

export const GET_LOG_DETAILS = 'GET_LOG_DETAILS';
// export const SET_LOG_DETAILS = 'SET_LOG_DETAILS';
export const SET_LOG_DETAILS = 'SET_LOG_DETAILS';

export const GET_BOOKING_ORDER_FAILED_DOWNLOADS ='GET_BOOKING_ORDER_FAILED_DOWNLOADS';
export const GET_USER_SESSION ='GET_USER_SESSION';
export const GET_SERVICE_TYPES_DROPDOWN ='GET_SERVICE_TYPES_DROPDOWN';
export const SET_SERVICE_TYPES_DROPDOWN ='SET_SERVICE_TYPES_DROPDOWN';

export const SET_FAST_TAG_ROUTES = 'SET_FAST_TAG_ROUTES';
export const GET_FAST_TAG_ROUTES = 'GET_FAST_TAG_ROUTES';

export const SET_FAST_TAG_LOGS = 'SET_FAST_TAG_LOGS';
export const GET_FAST_TAG_LOGS = 'GET_FAST_TAG_LOGS';

export const GET_TRACKED_LOG_DETAILS = 'GET_TRACKED_LOG_DETAILS';
export const GET_UNTRACKED_LOG_DETAILS = 'GET_UNTRACKED_LOG_DETAILS';

export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_SUBMITTING = 'FORGOT_PASSWORD_SUBMITTING';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const VALIDATE_FORGOT_PASSWORD = "VALIDATE_FORGOT_PASSWORD";

export const GET_CHANNEL_TYPES = 'GET_CHANNEL_TYPES';
export const GET_CHANNEL_TYPE_SUCCESS = 'GET_CHANNEL_TYPE_SUCCESS';