import {
  SIGN_IN_ERROR,
  SIGN_IN_SUBMITTING,
  DATA_LOADING,
  CLEAR_DATA_LOAD,
  REQUEST_OTP_SUBMITTING,
  REQUEST_OTP_SUCCESS
} from 'Constants/actionConstants';

const authentication = (state={
  signin: {
    error: '',
    isSubmitting: false
  },
  isDataLoading: false
}, action) => {
  let newState;
  if (action.type === SIGN_IN_ERROR) {
    newState = {...state};
    newState.signin = {
      error: action.error,
      isSubmitting: false
    }
  } else if (action.type === SIGN_IN_SUBMITTING) {
    newState = {...state};
    newState.signin = {
      error: '',
      isSubmitting: true
    }
  } else if (action.type === DATA_LOADING) {
    newState = {...state};
    newState.isDataLoading = true;
  } else if (action.type === CLEAR_DATA_LOAD) {
    newState = {...state};
    newState.isDataLoading = false;
  } else if(action.type === REQUEST_OTP_SUBMITTING) {
    newState = {...state};
    newState.isDataLoading = true;
  } else if (action.type === REQUEST_OTP_SUCCESS) {
    newState = {...state};
    newState.isDataLoading = false;
  }
  return newState || state;
}

export default authentication;
