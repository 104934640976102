import React from 'react';


const ActionLoader = ({fixed}) => {
    return (
        <div className="pre-loader" id="pre-loader" style={{
            position: fixed ? 'fixed' : 'absolute'
        }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default ActionLoader;
